import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CompanyInfo } from "../../../domain/store/types/companies";
import {
  RButton,
  RButtonSecondary,
  RSwitch,
  RTextField,
  RRadio,
} from "../ratifikaElements";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import { direcciones, ubigeos } from "../../static/ubigeo";
import { LocationOn } from "@material-ui/icons";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import CommentIcon from "@material-ui/icons/Comment";
import SearchIcon from "@material-ui/icons/Search";
import { DeleteTwoTone as DeleteIcon, Send } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { incrementSequential, Measure, measureList } from "../../static/util";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import moment from "moment";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Alert from "@material-ui/lab/Alert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ButtonPrimary, ButtonSecondary } from "../button";
import { Colors } from "src/infrastructure/static/colors";
import { ContainerForm, ContainerTitle } from "./styled";
import TextStyle from "../textStyle";
import { useHistory } from "react-router-dom";

interface Ubicacion {
  code: string;
  departamento: string;
  provincia: string;
  distrito: string;
}
interface ErrorReport {
  title: string;
  message: string;
  details?: Array<string>;
}
interface Product {
  code: string;
  measure: string;
  description: string;
  detalle?: string;
  is_new?: boolean;
  quantity: string;
}
interface ProductItem extends Product {
  count: number;
  line: number;
  measureSelected: Measure;
}

interface Contributor {
  addresses: Array<object>;
  number_type: string;
  number: string;
  legal_name: string;
  is_new?: boolean;
}

interface Transportista {
  addresses: string;
  number_type: string;
  number: string;
  legal_name: string;
  is_new?: boolean;
}

interface Conductor {
  name: string;
  number: string;
  number_type: string;
}
interface MultiSelectItem {
  label: string;
  value: string;
}

interface Anotacion {
  codigo?: string;
  valor: string;
}

function ColorlibStepIcon(props: StepIconProps) {
  const icons: { [index: string]: React.ReactElement } = {
    1: <AssignmentIcon />,
    2: <EmojiTransportationIcon />,
    3: <FormatListNumberedIcon />,
    4: <CheckCircleIcon />,
  };

  return <div>{icons[String(props.icon)]}</div>;
}

function getSteps() {
  return ["INFORMACION BASICA", "DATOS DE ENVIO", "PRODUCTOS", "CONFIRMAR"];
}

const FormNewGuieRemission = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const history = useHistory();
  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const emitter: CompanyInfo = companies.find(
    (c: CompanyInfo) => c.ruc === config.workspace
  );
  const [responseError, setResponseError] = useState<ErrorReport | null>(null);
  const [codeUbigeoOrigen, setCodeUbigeoOrigen] = useState<string | undefined>(
    ""
  );
  const [selectUbigeoOrigen, setSelectUbigeoOrigen] =
    useState<Ubicacion | null>(null);
  const [addressOrigen, setAddressOrigen] = useState<string>("");
  const [hasAdressOrigenError, setHasAdressOrigenError] = useState(false);
  const [hasUbigeoOrigenError, setHasUbigeoOrigenError] = useState(false);
  const [codeUbigeoDestino, setCodeUbigeoDestino] = useState<
    string | undefined
  >("");
  const [selectUbigeoDestino, setSelectUbigeoDestino] =
    useState<Ubicacion | null>(null);
  const [addressDestino, setAddressDestino] = useState<string>("");
  const [hasAdressDestinoError, setHasAdressDestinoError] = useState(false);
  const [hasUbigeoDesError, setHasUbigeoDesError] = useState(false);
  const [tipoEnvio, setTipoEnvio] = useState<string>("");
  const [hasTypeSendError, setHasTypeSendError] = useState(false);
  const [typeTransport, setTypeTransport] = useState<string>("02");
  const [buyerKey, setBuyerKey] = useState<string>("");
  const [availableCustomers, setAvailableCustomers] = useState<
    Array<Contributor>
  >([]);
  const [autocompleteCustomers, setAutocompleteCustomers] = useState<
    Array<Contributor>
  >([]);
  const [legalName, setLegalName] = React.useState<string>("");
  const [errorMsgLegalName, setErrorMsgLegalName] = React.useState<
    string | null
  >(null);
  const [identification, setIdentification] = React.useState<string>("");
  const [errorMsgIdentification, setErrorMsgIdentification] = React.useState<
    string | null
  >(null);
  const [docType, setDocType] = React.useState<string>("");
  const [selectedAddress, setSelectedAddress] =
    useState<MultiSelectItem | null>(null);
  const [productKey, setProductKey] = useState<string>("");
  const [availableProducts, setAvailableProducts] = useState<Array<Product>>(
    []
  );
  const [autocompleteProducts, setAutocompleteProducts] = useState<
    Array<Product>
  >([]);
  const [selectedItems, setSelectedItems] = useState<Array<ProductItem>>([]);
  const [addDescription, setAddDescription] = useState<boolean>(false);
  const [hasErrorItem, setHasErrorItem] = useState(false);
  const [errorMsgItems, setErrorMsgItems] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [observaciones, setObservaciones] = useState<string>("");
  const [notes, setNotes] = useState<Array<string>>([]);

  const [dateSend, setDateSend] = React.useState<Date | null>(new Date());
  const [numBultos, setNumBultos] = useState<number>(0);
  const [hasNumBultosError, setHasNumBultosError] = useState(false);
  const [pesoTotal, setPesoTotal] = useState<number>(0.0);
  const [hasPesoError, setHasPesoError] = useState(false);
  const [dniConductor, setDniConductor] = useState<string>("");
  const [conductorSuggestions, setConductorSuggestions] = useState<
    Array<Conductor>
  >([]);
  const [numberDriver, setNumberDriver] = useState<string>("");
  const [numberTypeDriver, setNumberTypeDriver] = useState<string>("");
  const [hasNumTypeDriverError, setHasNumTypeDriverError] = useState(false);
  const [nameDriver, setNameDriver] = useState<string>("");
  const [licensePlate, setLicensePlate] = useState<string>("");
  const [errorMsgLicense, setErrorMsgLicense] = useState<string | null>(null);
  const [hasLicenseError, setHasLicenseError] = useState(false);
  const [errorMsgDni, setErrorMsgDni] = React.useState<string | null>(null);
  const [rucCarrier, setRucCarrier] = useState<string>("");
  const [carrierSuggestions, setCarrierSuggestions] = useState<
    Array<Transportista>
  >([]);
  const [numberCarrier, setNumberCarrier] = useState<string>("");
  const [numberTypeCarrier, setNumberTypeCarrier] = useState<string>("");
  const [hasNumTypeCarrierError, setHasNumTypeCarrierError] = useState(false);
  const [nameCarrier, setNameCarrier] = useState<string>("");
  const [errorMsgCarrier, setErrorMsgCarrier] = React.useState<string | null>(
    null
  );
  const [referenceDoc, setReferenceDoc] = useState<boolean>(false);
  const [serieReference, setSerieReference] = useState<string>("");
  const [hasSerieError, setHasSerieError] = useState(false);
  const [numberReference, setNumberReference] = useState<string>("");
  const [hasCorrelativeError, setHasCorrelativeError] = useState(false);
  const [disableAll, setDisableAll] = useState<boolean>(false);
  const [errorMsgTryingSend, setErrorMsgTryingSend] = React.useState<
    string | null
  >("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  useEffect(() => {
    async function fetchCostumersRuc() {
      const { data } = await axios.get<Contributor[]>(
        "/api/contributor/customersWithTaxID"
      );
      setAvailableCustomers(data);
    }
    async function fetchCostumersDni() {
      const { data } = await axios.get<Contributor[]>(
        "/api/contributor/customersWithoutTaxID"
      );
      let customer = [...availableCustomers];
      customer = data;
      setAvailableCustomers(customer);
    }
    async function fetchProducts() {
      const { data } = await axios.get<Product[]>("/api/product/list");
      setAvailableProducts(data);
    }
    if (!referenceDoc) {
      setDisableAll(false);
      setSelectedItems([]);
    }

    fetchCostumersRuc();
    fetchCostumersDni();
    fetchProducts();
  }, [referenceDoc]);

  /*Funciones */
  const searchInvoice = async () => {
    if (serieReference === "" || serieReference.trim().length < 4) {
      setHasSerieError(true);
    }
    if (numberReference === "" || numberReference.length > 8) {
      setHasCorrelativeError(true);
    }
    if (!hasCorrelativeError && !hasSerieError) {
      const { data } = await axios.get(
        `/api/search/document/reference/${config.workspace}?serie=${serieReference}&correlativo=${numberReference}`
      );
      if (data.result) {
        setDisableAll(true);
        setIdentification(data.doc.receiver.number);
        setDocType(data.doc.receiver.number_type);
        setLegalName(data.doc.receiver.legal_name);
        setSelectedAddress({
          label: "",
          value: data.doc.receiver_address.address_line,
        });
        setSelectedItems(data.doc.products);
        setBuyerKey(data.doc.receiver.legal_name);
        setCodeUbigeoOrigen(emitter.ubigeo);
        setSelectUbigeoOrigen({
          code: emitter.ubigeo,
          departamento: emitter.department,
          provincia: emitter.province,
          distrito: emitter.district,
        });
        setAddressOrigen(emitter.address_line);
        setHasAdressOrigenError(false);
        setHasUbigeoOrigenError(false);
      } else {
        toast.warn("Documento no encontrado !");
      }
    }
  };
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const handleNext = async () => {
    if (activeStep === 0) {
      let hasGuideErrors = false;
      if (identification.trim() === "") {
        setErrorMsgIdentification(
          "Especifique una identificación para el comprador"
        );
        hasGuideErrors = true;
      }
      if (codeUbigeoOrigen === "") {
        hasGuideErrors = true;
        setHasUbigeoOrigenError(true);
      }
      if (addressOrigen === "") {
        hasGuideErrors = true;
        setHasAdressOrigenError(true);
      }

      if (codeUbigeoDestino === "") {
        hasGuideErrors = true;
        setHasUbigeoDesError(true);
      }
      if (addressDestino === "") {
        hasGuideErrors = true;
        setHasAdressDestinoError(true);
      }
      /*              if (codeUbigeoOrigen === "" || codeUbigeoDestino === "" || codeUbigeoDestino === "" || addressDestino === "") {
                            hasGuideErrors = true;
                            setHasAdressDestinoError(true);
                            setHasAdressOrigenError(true);
                            setHasUbigeoDesError(true);
                            setHasUbigeoOrigenError(true);
                        }  */
      if (!hasGuideErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 1) {
      let hasDetailSendErrors = false;
      if (
        tipoEnvio === "" ||
        dateSend === null ||
        numBultos === 0 ||
        pesoTotal === 0
      ) {
        hasDetailSendErrors = true;
        setHasTypeSendError(true);
        setHasNumBultosError(true);
        setHasPesoError(true);
      }
      if (typeTransport === "02") {
        /* const validStructuredni: boolean = isValidDNI(dniConductor);
                                    if (!validStructuredni) {
                                        hasDetailSendErrors = true;
                                        setHasNumTypeDriverError(true);
                                        setErrorMsgDni("Número de DNI incorrecto");
                                   
                                    } */
        if (numberTypeDriver === "" || dniConductor.trim().length < 8) {
          hasDetailSendErrors = true;
          setHasNumTypeDriverError(true);
          setErrorMsgDni("Seleccione un conductor");
        }

        if (licensePlate.trim().length > 8 || licensePlate.trim().length <= 4) {
          setHasLicenseError(true);
          hasDetailSendErrors = true;
          setErrorMsgLicense("Número de placa incorecta");
        }
      }

      if (typeTransport === "01") {
        /*                 
                                const validStructure: boolean = isValidRUC(numberCarrier); */
        /*                 if (!validStructure) {
                                    hasDetailSendErrors = true;
                                    setHasNumTypeCarrierError(true)
                                    setErrorMsgCarrier("Número de RUC incorrecto");
                               
                                } */
        if (numberTypeCarrier === "" || rucCarrier.trim().length < 11) {
          hasDetailSendErrors = true;
          setHasNumTypeCarrierError(true);
          setErrorMsgCarrier("Seleccione un Transportista");
        }
      }
      if (!hasDetailSendErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 2) {
      let hasItemErrors = false;
      if (selectedItems.length === 0) {
        hasItemErrors = true;
        setHasErrorItem(true);
        setErrorMsgItems("Debes incluir algún item en la guia");
      }

      if (!hasItemErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    if (activeStep === 3) {
      setLoading(true);
      sendGuia();
    }
  };

  const sendGuia = async () => {
    const responseSequential = await axios.get(
      `/api/company/lastSequential/${emitter.ruc}/09`
    );
    if (!responseSequential.data.result) {
      setResponseError({
        title: "Ha ocurrido un problema",
        message: responseSequential.data.msg,
      });
      return;
    }

    const json = generateJsonGuia(
      responseSequential.data.serie,
      responseSequential.data.sequential
    );

    let { data } = await axios.post("/api/emission/send", json);

    setLoading(false);
    if (data.procesado) {
      history.push("/ventas");
    } else {
      setErrorMsgTryingSend(`${data.mensaje}`);
    }
  };

  const onSwitchDocEmitido = () => {
    setReferenceDoc(!referenceDoc);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*  const handleReset = () => {
        setActiveStep(0);
    }; */
  const handleChangeTipoEnvio = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTipoEnvio(event.target.value as string);
    setHasTypeSendError(false);
  };
  const handleChangeTransport = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTypeTransport((event.target as HTMLInputElement).value);
  };

  /*Products */

  const getSuggestionProductValue = (suggestion: Product) => {
    const measureSelected =
      measureList.find((m) => m.code === suggestion.measure) || measureList[0];

    const newItem: ProductItem = {
      code: suggestion.code,
      measureSelected,
      measure: suggestion.measure, // original
      description: suggestion.description,
      quantity: suggestion.quantity,
      line: 1.0,
      count: 1.0,
    };
    const updatedItems = [...selectedItems, newItem];
    setSelectedItems(updatedItems);
    setHasErrorItem(false);
    return "";
  };

  const renderProductSuggestion = (suggestion: Product, { query }: any) => {
    if (suggestion.is_new) {
      return (
        <span>
          Agregar nuevo producto "<strong>{suggestion.description}</strong>"
        </span>
      );
    } else {
      const indexOfSuggestion = suggestion.description
        .toLowerCase()
        .indexOf(query.toLowerCase());
      const substring = suggestion.description.substring(
        indexOfSuggestion,
        indexOfSuggestion + query.length
      );
      const highlight = suggestion.description.replace(
        substring,
        `<strong>${substring}</strong>`
      );
      return (
        <div>
          <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
        </div>
      );
    }
  };

  const onSuggestionsFetchProductsRequested = async (param: any) => {
    setAutocompleteProducts(await getProductsSuggestions(param.value));
  };

  const onSuggestionsClearProductRequested = () => {
    setAutocompleteProducts([]);
  };

  const getProductsSuggestions = async (value: string): Promise<Product[]> => {
    const inputValue = value.trim();
    let results = availableProducts.filter(
      (product) =>
        product.description.toLowerCase().includes(inputValue.toLowerCase()) ||
        product.code === inputValue
    );

    if (results.length === 0) {
      results = [
        {
          code: "",
          measure: measureList[0].code,
          description: inputValue,
          is_new: true,
          quantity: "",
        },
      ];
    }

    const products = results.sort((a, b) => {
      return (
        a.description.indexOf(inputValue) - b.description.indexOf(inputValue)
      );
    });

    return products;
  };

  const updateItemCountChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.target.value) < 0) return;
      let newArr = [...selectedItems];
      newArr[index].line = Number(e.target.value);
      newArr[index].count = Number(e.target.value);
      setSelectedItems(newArr);
    };

  const updateItemCodeChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].code = e.target.value;
      setSelectedItems(newArr);
    };

  const updateItemDescriptionChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].description = e.target.value;
      setSelectedItems(newArr);
    };
  const updateItemDetalleChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].detalle = e.target.value;
      setSelectedItems(newArr);
    };

  const updateItemUnitChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...selectedItems];
    newArr[index].measureSelected =
      measureList.find((m) => m.code === e.target.value) || measureList[0];
    setSelectedItems(newArr);
  };

  const deleteItemClick = (index: any) => {
    let newArr = [...selectedItems];
    newArr.splice(index, 1);
    setSelectedItems(newArr);
  };
  /*End Products */

  /*Contributor */
  const getCustomersSuggestions = async (
    value: string
  ): Promise<Contributor[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results = [];

    if (Number.isInteger(Number(inputValue))) {
      // posiblemente sea un RUC
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter(
                (contrib) => contrib.number.slice(0, inputLength) === inputValue
              )
              .sort((a, b) => {
                return (
                  a.number.indexOf(inputValue) - b.number.indexOf(inputValue)
                );
              });

      if (results.length === 0) {
        if (inputLength === 8) {
          await axios
            .get(`/api/info/by/dni/${Number(inputValue)}`)
            .then((e) => {
              const data = e.data;
              results = [
                {
                  legal_name: data.nombre,
                  number: inputValue,
                  number_type: data.tipoDocumento,
                  addresses: [
                    {
                      ubigeo: data.ubigeo || null,
                      address_line: data.direccion || null,
                      district: data.distrito || null,
                      province: data.provincia || null,
                      department: data.departamento || null,
                      default: true,
                    },
                  ],
                  is_new: true,
                },
              ];
            });
        }
        if (inputLength === 11) {
          await axios
            .get<any>(`/api/info/by/ruc/?ruc=${Number(inputValue)}`)
            .then((e) => {
              const data = e.data;
              results = [
                {
                  legal_name: data.nombre,
                  number: inputValue,
                  number_type: data.tipoDocumento,
                  addresses: [
                    {
                      ubigeo: data.ubigeo || null,
                      address_line: data.direccion || null,
                      district: data.distrito || null,
                      province: data.provincia || null,
                      department: data.departamento || null,
                      default: true,
                    },
                  ],
                  is_new: true,
                },
              ];
            });
        }
      }
    } else {
      // Posiblemente sea un nombre legal
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter((contrib) =>
                contrib.legal_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
              .sort((a, b) => {
                return (
                  a.legal_name.indexOf(inputValue) -
                  b.legal_name.indexOf(inputValue)
                );
              });
    }
    return results;
  };

  const getSuggestionValue = (suggestion: Contributor) => {
    if (suggestion.is_new) {
      ////Direccion del Nuevo sugerido
      const defaultAddress =
        suggestion.addresses.length !== 0
          ? (suggestion.addresses.find(
              (item: any) => item.default === true
            ) as any)
          : null;

      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        const codeUbigeo = direcciones.find(
          (e) => e.code === defaultAddress.ubigeo
        );
      }

      //
      setLegalName(suggestion.legal_name || "");
      setIdentification(suggestion.number || "");
      setDocType(suggestion.number_type);
      setSelectedAddress(
        defaultAddress
          ? {
              label: defaultAddress.address_line,
              value: defaultAddress.address_line,
            }
          : null
      );
    } else {
      /*  debugger */
      // Ya existe data del cliente
      setLegalName(suggestion.legal_name);
      setIdentification(suggestion.number);
      setDocType(suggestion.number_type);

      const defaultAddress = suggestion.addresses
        ? (suggestion.addresses.find(
            (item: any) => item.default === true
          ) as any)
        : null;

      setSelectedAddress(
        defaultAddress
          ? {
              label: defaultAddress.address_line,
              value: defaultAddress.address_line,
            }
          : null
      );

      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        const codeUbigeo = direcciones.find(
          (e) => e.code === defaultAddress.ubigeo
        );
      }
    }

    if (errorMsgLegalName) {
      setErrorMsgLegalName(null);
    }
    if (errorMsgIdentification) {
      setErrorMsgIdentification(null);
    }

    return suggestion.legal_name || suggestion.number;
  };

  const renderSuggestion = (suggestion: Contributor, { query }: any) => {
    if (suggestion.is_new) {
      if (suggestion.legal_name) {
        return (
          <span>
            "<strong>{suggestion.legal_name}</strong>"
          </span>
        );
      } else {
        return (
          <span>
            "<strong>Ruc invalido</strong>"
          </span>
        );
      }
    } else {
      const handleNumber = Number.isInteger(Number(query));
      const suggestionText = handleNumber
        ? suggestion.number
        : suggestion.legal_name;

      const indexOfSuggestion = suggestionText
        .toLowerCase()
        .indexOf(query.toLowerCase());
      const substring = suggestionText.substring(
        indexOfSuggestion,
        indexOfSuggestion + query.length
      );
      const highlight = suggestionText.replace(
        substring,
        `<strong ${
          handleNumber ? `style="font-size: 12px"` : ""
        }>${substring}</strong>`
      );
      return (
        <div>
          {!handleNumber ? (
            <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
          ) : (
            <span>{suggestion.legal_name}</span>
          )}
          <br></br>
          {handleNumber ? (
            <span
              style={{ fontSize: 12 }}
              dangerouslySetInnerHTML={{ __html: highlight }}
            ></span>
          ) : (
            <strong style={{ fontSize: 12 }}>{suggestion.number}</strong>
          )}
        </div>
      );
    }
  };

  const onSuggestionsFetchRequested = async (param: any) => {
    setAutocompleteCustomers(await getCustomersSuggestions(param.value));
  };

  const onSuggestionsClearRequested = () => {
    setAutocompleteCustomers([]);
  };
  /*Fin contributor */

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  /*Fecha de emision */
  const handleDateSendChange = (date: Date | null) => {
    setDateSend(date);
  };

  /*Transportista */
  const getRucSuggestions = async (value: string): Promise<Transportista[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results: any[] = [];
    if (Number.isInteger(Number(inputValue))) {
      if (inputLength === 11) {
        await axios
          .get<any>(`/api/info/by/ruc/?ruc=${Number(inputValue)}`)
          .then((e) => {
            const data = e.data;
            results = [
              {
                legal_name: data.nombre,
                number: inputValue,
                number_type: data.tipoDocumento,
              },
            ];
          });
      } else {
        results = [
          {
            number: inputValue,
            number_type: "",
            legal_name: "",
          },
        ];
      }
    }

    return results;
  };
  const onSuggestionsFetchCarrier = async (param: any) => {
    setCarrierSuggestions(await getRucSuggestions(param.value));
  };

  const getSuggestionValCarrier = (suggestion: Transportista) => {
    setNumberCarrier(suggestion.number);
    setNumberTypeCarrier(suggestion.number_type);
    setNameCarrier(suggestion.legal_name);
    setHasNumTypeCarrierError(false);
    setErrorMsgCarrier(null);
    return suggestion.legal_name || suggestion.number;
  };

  const renderCarrier = (suggestion: Transportista, { query }: any) => {
    if (suggestion.legal_name) {
      return (
        <span>
          <strong>{suggestion.legal_name || suggestion.number}</strong>
        </span>
      );
    }
  };
  const carrierClearRequested = () => {
    setCarrierSuggestions([]);
  };

  /*Conductor*/
  const getDniSuggestions = async (value: string): Promise<Conductor[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results: any[] = [];
    if (Number.isInteger(Number(inputValue))) {
      if (inputLength === 8) {
        await axios.get(`/api/info/by/dni/${Number(inputValue)}`).then((e) => {
          const data = e.data;
          results = [
            {
              name: data.nombre,
              number: inputValue,
              number_type: data.tipoDocumento,
            },
          ];
        });
      } else {
        results = [
          {
            number: inputValue,
            number_type: "",
          },
        ];
      }
    }

    return results;
  };
  const onSuggestionsFetchConductor = async (param: any) => {
    setConductorSuggestions(await getDniSuggestions(param.value));
  };

  const getSuggestionValConductor = (suggestion: Conductor) => {
    setNumberDriver(suggestion.number);
    setNumberTypeDriver(suggestion.number_type);
    setNameDriver(suggestion.name);
    setHasNumTypeDriverError(false);
    setErrorMsgDni(null);
    return suggestion.name || suggestion.number;
  };

  const renderConductor = (suggestion: Conductor, { query }: any) => {
    return (
      <span>
        <strong>{suggestion.name}</strong>
      </span>
    );
  };
  const conductorClearRequested = () => {
    setConductorSuggestions([]);
  };

  /*Procesamiento de la guia */
  const generateJsonGuia = (serie: string, sequential: string) => {
    let jsonGuia: any = {
      ubl: "2.1",
      tipoComprobante: "09",
      serie: `T${serie}`,
      numeroComprobante: incrementSequential(sequential),
      actualizarSecuencial: true,
      fechaEmision: moment(selectedDate).format("YYYY-MM-DD"),
      horaEmision: moment().format("HH:mm:ss"),
      emisor: {
        numeroDocumento: emitter.ruc,
        tipoDocumento: "6",
        nombre: emitter.legal_name,
        direccion: {
          codigo: "0000",
          ubigeo: emitter.ubigeo,
          provincia: emitter.province,
          departament: emitter.department,
          distrito: emitter.district,
          direccionCompleta: emitter.address_line,
        },
      },
      receptor: {
        numeroDocumento: identification,
        tipoDocumento: docType,
        nombre: legalName,
        direccion: {
          direccionCompleta:
            selectedAddress?.value != null ? selectedAddress?.value : "-",
          pais: "PE", // TODO: incluir pais
        },
      },
      Origen: {
        Ubigeo: codeUbigeoOrigen,
        Direccion: addressOrigen,
      },
      Destino: {
        Ubigeo: codeUbigeoDestino,
        Direccion: addressDestino,
      },
      infoTraslado: {
        fechaSalida: moment(dateSend).format("YYYY-MM-DD"),
        motivoTraslado: tipoEnvio,
        //descripcionTraslado: description,
        transbordoProgramado: false,
        modalidadTraslado: typeTransport,
      },
      infoBulto: {
        pesoBruto: pesoTotal.toFixed(2).toString(),
        numeroBultos: numBultos.toString(),
        unidadmedida: "Kg",
      },
      items: selectedItems.map((item, index) => {
        return {
          numeroItem: (index + 1).toString(),
          descripcion: item.description,
          codigo: item.code || "",
          unidadMedida: disableAll ? item.measure : item.measureSelected.code,
          cantidad: item.count
            ? item.count.toString()
            : item.quantity.toString(),
          detalle: item.detalle || "",
        };
      }),
    };
    if (typeTransport === "02") {
      jsonGuia = {
        ...jsonGuia,
        conductor: {
          numeroDocumento: numberDriver,
          tipoDocumento: numberTypeDriver,
          placaVehiculo: licensePlate,
        },
      };
    } else if (typeTransport === "01") {
      jsonGuia = {
        ...jsonGuia,
        transportista: {
          numeroDocumento: numberCarrier,
          tipoDocumento: numberTypeCarrier,
          nombre: nameCarrier,
        },
      };
    }
    let anotaciones: Array<Anotacion> = notes
      .filter((n) => n.trim() !== "")
      .map((n) => {
        return { valor: n };
      });
    if (observaciones != null) {
      jsonGuia = {
        ...jsonGuia,
        notas: anotaciones,
      };
    }
    return jsonGuia;
  };
  /*Notas */

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ContainerTitle>
        <TextStyle color={Colors.WHITE} type="h1" textAlign="center" bold={700}>
          {"Guía de Remisión"}
          <span style={{ fontSize: 15, marginLeft: 15 }}>
            / emitida por: {emitter.legal_name}
          </span>
        </TextStyle>
      </ContainerTitle>
      <ContainerForm>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                  <StepContent>
                    {index === 0 && (
                      <>
                        <Typography color="textSecondary" variant="caption">
                          Documento emitido
                        </Typography>
                        <br />
                        <FormControlLabel
                          value={referenceDoc}
                          control={
                            <RSwitch
                              checked={referenceDoc}
                              onChange={onSwitchDocEmitido}
                            />
                          }
                          label=""
                          labelPlacement="end"
                        />
                        {referenceDoc ? (
                          <div style={{ marginBottom: 25 }}>
                            <div
                              className="clearfix"
                              style={{ marginBottom: 20 }}
                            ></div>

                            <Grid container>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: 5 }}
                              >
                                <Grid item>
                                  <RTextField
                                    variant="outlined"
                                    autoFocus
                                    size="small"
                                    autoComplete="off"
                                    label="Serie *"
                                    style={{ width: 100 }}
                                    value={serieReference}
                                    onChange={(e) => {
                                      setSerieReference(e.target.value);
                                      if (e.target.value.trim().length === 4) {
                                        setHasSerieError(false);
                                      }
                                    }}
                                    error={hasSerieError}
                                  />
                                </Grid>
                                <h6>-</h6>
                                <Grid item>
                                  <RTextField
                                    variant="outlined"
                                    size="small"
                                    autoComplete="off"
                                    label="Correlativo"
                                    style={{ width: 120 }}
                                    type="number"
                                    value={numberReference}
                                    onChange={(e) => {
                                      setNumberReference(e.target.value);
                                      if (e.target.value.trim().length <= 5) {
                                        setHasCorrelativeError(false);
                                      }
                                    }}
                                    error={hasCorrelativeError}
                                    required
                                  />
                                  <RButton
                                    aria-label="search"
                                    style={{ marginLeft: "10px" }}
                                    onClick={searchInvoice}
                                  >
                                    <SearchIcon />
                                  </RButton>
                                  <Popover
                                    open={openPopOver}
                                    anchorEl={anchorEl}
                                    onClose={handleClosePopOver}
                                    anchorOrigin={{
                                      vertical: "center",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "center",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography style={{ padding: "10px" }}>
                                      Lista de posibles documentos
                                    </Typography>
                                  </Popover>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} sm={6}>
                            <Typography color="textSecondary" variant="caption">
                              DESTINATARIO
                            </Typography>
                            <br />
                            {/*  <RTextField

                                                                id="input-with-icon-textfield"
                                                                variant="outlined"
                                                                size="small"
                                                                autoComplete="off"
                                                                label="Razon social*"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <PersonIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            /> */}
                            <div
                              className={
                                disableAll
                                  ? "autocompleteDisable"
                                  : "autocomplete"
                              }
                            >
                              <Autosuggest
                                suggestions={autocompleteCustomers}
                                onSuggestionsFetchRequested={
                                  onSuggestionsFetchRequested
                                }
                                onSuggestionsClearRequested={
                                  onSuggestionsClearRequested
                                }
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={{
                                  placeholder: "Identificación o nombre legal",
                                  value: buyerKey,
                                  onChange: (
                                    event: React.FormEvent<any>,
                                    {
                                      newValue,
                                      method,
                                    }: Autosuggest.ChangeEvent
                                  ) => {
                                    setBuyerKey(newValue);
                                    if (newValue === "") {
                                      setLegalName("");
                                      setIdentification("");
                                      setDocType("");
                                    }
                                  },
                                  ref: React.createRef<HTMLInputElement>(),
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6} sm={6}>
                            <div style={{ float: "right" }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date_emission"
                                  label="Fecha de Emisión "
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} sm={6}>
                            <Card variant="outlined" className="cardDireccion">
                              <CardContent>
                                <Typography
                                  color="textSecondary"
                                  variant="caption"
                                >
                                  ORIGEN
                                </Typography>
                                <FlightTakeoffIcon />
                                <br />
                                <Autocomplete
                                  id="ubigeo-origen"
                                  options={direcciones}
                                  getOptionLabel={(option) =>
                                    `${option.departamento}-${option.provincia}-${option.distrito}`
                                  }
                                  fullWidth={true}
                                  popupIcon={<LocationOn />}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Distrito *"
                                      placeholder="Buscar..."
                                      variant="outlined"
                                      error={hasUbigeoOrigenError}
                                    />
                                  )}
                                  renderOption={(option: any) => (
                                    <React.Fragment>
                                      <Typography
                                        style={{ padding: "5px" }}
                                        variant="body2"
                                      >
                                        {option.departamento}-{option.provincia}
                                        -{option.distrito}
                                        <br />
                                        <Typography variant="body2">
                                          <small style={{ color: "#777" }}>
                                            {option.code}
                                          </small>
                                        </Typography>
                                      </Typography>
                                    </React.Fragment>
                                  )}
                                  size="small"
                                  value={selectUbigeoOrigen}
                                  onChange={(event, value) => {
                                    setSelectUbigeoOrigen(value);
                                    setCodeUbigeoOrigen(value?.code);
                                    setHasUbigeoOrigenError(false);
                                    if (value === null) {
                                      setSelectUbigeoOrigen(null);
                                      setCodeUbigeoOrigen("");
                                      setHasUbigeoOrigenError(true);
                                    }
                                  }}
                                />
                                <RTextField
                                  id="direccion-origen"
                                  label="Dirección"
                                  variant="outlined"
                                  size="small"
                                  autoFocus={true}
                                  required
                                  fullWidth={true}
                                  margin="normal"
                                  error={hasAdressOrigenError}
                                  value={addressOrigen}
                                  onChange={(e) => {
                                    setAddressOrigen(e.target.value);
                                    setHasAdressOrigenError(false);
                                  }}
                                />
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} md={6} sm={6}>
                            <Card variant="outlined" className="cardDireccion">
                              <CardContent>
                                <Typography
                                  color="textSecondary"
                                  variant="caption"
                                >
                                  DESTINO
                                </Typography>
                                <FlightLandIcon />
                                <br />
                                <Autocomplete
                                  id="ubigeo-destino"
                                  options={direcciones}
                                  getOptionLabel={(option) =>
                                    `${option.departamento}-${option.provincia}-${option.distrito}`
                                  }
                                  fullWidth={true}
                                  popupIcon={<LocationOn />}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Distrito *"
                                      placeholder="Buscar..."
                                      variant="outlined"
                                      error={hasUbigeoDesError}
                                    />
                                  )}
                                  renderOption={(option: any) => (
                                    <React.Fragment>
                                      <Typography
                                        style={{ padding: "5px" }}
                                        variant="body2"
                                      >
                                        {option.departamento}-{option.provincia}
                                        -{option.distrito}
                                        <br />
                                        <Typography variant="body2">
                                          <small style={{ color: "#777" }}>
                                            {option.code}
                                          </small>
                                        </Typography>
                                      </Typography>
                                    </React.Fragment>
                                  )}
                                  size="small"
                                  value={selectUbigeoDestino}
                                  onChange={(event, value) => {
                                    setSelectUbigeoDestino(value);
                                    setCodeUbigeoDestino(value?.code);
                                    setHasUbigeoDesError(false);
                                    if (value === null) {
                                      setSelectUbigeoDestino(null);
                                      setCodeUbigeoDestino("");
                                      setHasUbigeoDesError(true);
                                    }
                                  }}
                                />

                                <RTextField
                                  id="direccion-destino"
                                  label="Dirección"
                                  variant="outlined"
                                  size="small"
                                  autoFocus={true}
                                  required
                                  fullWidth={true}
                                  margin="normal"
                                  error={hasAdressDestinoError}
                                  value={addressDestino}
                                  onChange={(e) => {
                                    setAddressDestino(e.target.value);
                                    setHasAdressDestinoError(false);
                                  }}
                                />
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {/* SECCION 2 */}
                    {index === 1 && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} sm={6}>
                            <FormControl variant="outlined">
                              <InputLabel id="demo-simple-select-outlined-label">
                                Tipo de envío
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={tipoEnvio}
                                onChange={handleChangeTipoEnvio}
                                label="Tipo de envío"
                                style={{ minWidth: 220 }}
                                error={hasTypeSendError}
                              >
                                <MenuItem value="02">Compra</MenuItem>
                                <MenuItem value="01">Venta</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label="Fecha de envío"
                                id="date-picker-send"
                                value={dateSend}
                                onChange={handleDateSendChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          {/*  <Grid item xs={10} md={10} sm={10}>
                                                            <RTextField
                                                                type="text"
                                                                value={description}
                                                                onChange={(e) => {
                                                                    setDescription(e.target.value)
                                                                }}
                                                                required
                                                                size="small"
                                                                label="Descripción del traslado"
                                                                variant="outlined"
                                                                fullWidth
                                                                margin={'normal'}
                                                            >

                                                            </RTextField>
                                                        </Grid> */}

                          <Grid item xs={12} md={6} sm={6}>
                            <RTextField
                              id="num-bultos"
                              label="Cantidad bultos"
                              variant="outlined"
                              size="small"
                              autoFocus={true}
                              autoComplete="number"
                              required
                              type="number"
                              value={numBultos}
                              error={hasNumBultosError}
                              InputProps={{
                                endAdornment: (
                                  <FontAwesomeIcon
                                    icon={faCubes}
                                    style={{
                                      fontSize: "18px",
                                      paddingRight: "3px",
                                    }}
                                  />
                                ),
                              }}
                              onChange={(e) => {
                                let bultos = Number(e.target.value);
                                setNumBultos(bultos);
                                setHasNumBultosError(false);
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} sm={6}>
                            <RTextField
                              id="peso-total"
                              label="Peso Total"
                              variant="outlined"
                              autoFocus={true}
                              autoComplete="number"
                              required
                              size="small"
                              type="number"
                              value={pesoTotal}
                              error={hasPesoError}
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <InputAdornment position="end">
                                      {" "}
                                      <b>Kg</b>
                                    </InputAdornment>
                                    {/* <FontAwesomeIcon
                                                                            icon={faWeightHanging}
                                                                            style={{ fontSize: '18px', paddingRight: '3px' }}
                                                                        /> */}
                                  </>
                                ),
                              }}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                let peso = Number(event.target.value);
                                setPesoTotal(peso);
                                setHasPesoError(false);
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={12} sm={12}>
                            <Typography color="textSecondary" variant="caption">
                              Modalidad traslado
                            </Typography>
                            <br />
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={typeTransport}
                                onChange={handleChangeTransport}
                                row
                              >
                                <FormControlLabel
                                  value="01"
                                  control={<RRadio />}
                                  label="Público"
                                />
                                <FormControlLabel
                                  value="02"
                                  control={<RRadio />}
                                  label="Privado"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          {typeTransport === "02" ? (
                            <>
                              <Grid item xs={12} md={6} sm={6}>
                                <div
                                  style={{
                                    paddingBottom: "10px",
                                    display: "flex",
                                    borderRadius: "4px",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Autosuggest
                                    suggestions={conductorSuggestions}
                                    onSuggestionsFetchRequested={
                                      onSuggestionsFetchConductor
                                    }
                                    onSuggestionsClearRequested={
                                      conductorClearRequested
                                    }
                                    getSuggestionValue={
                                      getSuggestionValConductor
                                    }
                                    renderSuggestion={renderConductor}
                                    inputProps={{
                                      placeholder: "DNI conductor",
                                      value: dniConductor,
                                      onChange: (
                                        event: React.FormEvent<any>,
                                        {
                                          newValue,
                                          method,
                                        }: Autosuggest.ChangeEvent
                                      ) => {
                                        setDniConductor(newValue);

                                        if (newValue === "") {
                                          setNumberDriver("");
                                          setNumberTypeDriver("");
                                          setHasNumTypeDriverError(true);
                                        }
                                      },
                                      ref: React.createRef<HTMLInputElement>(),
                                    }}
                                  />
                                  {hasNumTypeDriverError ? (
                                    <Typography variant="caption" color="error">
                                      {errorMsgDni}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Grid>
                              <Grid item xs={12} md={6} sm={6}>
                                <RTextField
                                  id="placa"
                                  label="Placa vehículo"
                                  variant="outlined"
                                  size="small"
                                  style={{ width: 250 }}
                                  autoFocus={true}
                                  autoComplete="number"
                                  required
                                  error={hasLicenseError}
                                  value={licensePlate}
                                  helperText={errorMsgLicense}
                                  onChange={(e) => {
                                    setLicensePlate(e.target.value);
                                    if (e.target.value.trim.length >= 4) {
                                      setHasLicenseError(false);
                                      setErrorMsgLicense(null);
                                    }
                                  }}
                                />
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12} md={6} sm={6}>
                              <div
                                style={{
                                  paddingBottom: "10px",
                                  display: "flex",
                                  borderRadius: "4px",
                                  flexDirection: "column",
                                }}
                              >
                                <Autosuggest
                                  suggestions={carrierSuggestions}
                                  onSuggestionsFetchRequested={
                                    onSuggestionsFetchCarrier
                                  }
                                  onSuggestionsClearRequested={
                                    carrierClearRequested
                                  }
                                  getSuggestionValue={getSuggestionValCarrier}
                                  renderSuggestion={renderCarrier}
                                  inputProps={{
                                    placeholder: "Ruc Transportista",
                                    value: rucCarrier,
                                    //type: "search",
                                    onChange: (
                                      event: React.FormEvent<any>,
                                      {
                                        newValue,
                                        method,
                                      }: Autosuggest.ChangeEvent
                                    ) => {
                                      setRucCarrier(newValue);
                                      if (newValue === "") {
                                        setNumberCarrier("");
                                        setNumberTypeCarrier("");
                                        setHasNumTypeDriverError(true);
                                      }
                                    },
                                    ref: React.createRef<HTMLInputElement>(),
                                  }}
                                />
                                {hasNumTypeCarrierError ? (
                                  <Typography variant="caption" color="error">
                                    {errorMsgCarrier}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                    {/* SECCION 3 */}
                    {index === 2 && (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          sm={6}
                          className={
                            disableAll ? "autocompleteDisable" : "autocomplete"
                          }
                        >
                          <Autosuggest
                            suggestions={autocompleteProducts}
                            onSuggestionsFetchRequested={
                              onSuggestionsFetchProductsRequested
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearProductRequested
                            }
                            getSuggestionValue={getSuggestionProductValue}
                            renderSuggestion={renderProductSuggestion}
                            inputProps={{
                              placeholder:
                                "Descripción del producto o código del prod.",
                              value: productKey,
                              onChange: (
                                event: React.FormEvent<any>,
                                { newValue, method }: Autosuggest.ChangeEvent
                              ) => setProductKey(newValue),
                              ref: React.createRef<HTMLInputElement>(),
                            }}
                          />
                        </Grid>
                        <Grid container>
                          {hasErrorItem ? (
                            <Typography variant="body2" color="error">
                              {errorMsgItems}
                            </Typography>
                          ) : (
                            ""
                          )}
                          {selectedItems.length > 0 && (
                            <Table
                              size="small"
                              aria-label="purchases"
                              style={{ marginTop: 30, marginBottom: 20 }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{ fontWeight: "bold" }}
                                    align="center"
                                  >
                                    Cantidad
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Código
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Nombre
                                  </TableCell>
                                  {/*                                                                          {addDescription ? (
 
                                                                            <TableCell style={{ fontWeight: "bold" }}>Descripción</TableCell>
                                                                       ) : ''
                                                                        } */}
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Descripción
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedItems.map((product, index) => (
                                  <TableRow
                                    key={index}
                                    style={{
                                      borderColor: "transparent",
                                      borderStyle: "solid",
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{ minWidth: 120, maxWidth: 80 }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <Tooltip title="Remover Item" arrow>
                                          <IconButton
                                            style={{
                                              marginRight: 10,
                                              width: 34,
                                            }}
                                            size="small"
                                            onClick={() =>
                                              deleteItemClick(index)
                                            }
                                          >
                                            <DeleteIcon
                                              fontSize="small"
                                              color="error"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Input
                                          disabled={disableAll}
                                          autoComplete="off"
                                          value={
                                            product.quantity || product.count
                                          }
                                          onChange={updateItemCountChanged(
                                            index
                                          )}
                                          type="number"
                                          style={{
                                            marginRight: 10,
                                            minWidth: 30,
                                          }}
                                        />
                                        {!disableAll ? (
                                          <Select
                                            value={product.measureSelected.code}
                                            disabled={disableAll}
                                            onChange={(e) =>
                                              updateItemUnitChanged(index, e)
                                            }
                                          >
                                            {measureList.map((m) => (
                                              <MenuItem
                                                key={m.code}
                                                value={m.code}
                                              >
                                                <Tooltip title={m.description}>
                                                  <span>{m.display}</span>
                                                </Tooltip>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        ) : (
                                          <Select
                                            value={product.measure}
                                            disabled={true}
                                          >
                                            {measureList.map((m) => (
                                              <MenuItem
                                                key={m.code}
                                                value={m.code}
                                              >
                                                <Tooltip title={m.description}>
                                                  <span>{m.display}</span>
                                                </Tooltip>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 50, maxWidth: 30 }}
                                    >
                                      <Input
                                        disabled={disableAll}
                                        style={{ width: "100%" }}
                                        autoComplete="off"
                                        value={product.code}
                                        onChange={updateItemCodeChanged(index)}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 130, maxWidth: 500 }}
                                    >
                                      <Input
                                        disabled={disableAll}
                                        style={{ width: "100%" }}
                                        autoComplete="off"
                                        error={product.description === ""}
                                        value={product.description}
                                        onChange={updateItemDescriptionChanged(
                                          index
                                        )}
                                      />
                                    </TableCell>
                                    {!addDescription ? (
                                      <TableCell
                                        style={{ minWidth: 130, maxWidth: 500 }}
                                      >
                                        <Tooltip
                                          title="Agregar información adicional"
                                          aria-label="add"
                                        >
                                          <Button
                                            variant="contained"
                                            color="default"
                                            disableElevation
                                            onClick={() =>
                                              setAddDescription(true)
                                            }
                                          >
                                            <CommentIcon
                                              fontSize="small"
                                              style={{ marginRight: 5 }}
                                            />
                                          </Button>
                                        </Tooltip>
                                      </TableCell>
                                    ) : (
                                      ""
                                    )}

                                    {addDescription ? (
                                      <TableCell>
                                        <Input
                                          style={{ width: "100%" }}
                                          autoComplete="off"
                                          error={product.detalle === ""}
                                          value={product.detalle}
                                          onChange={updateItemDetalleChanged(
                                            index
                                          )}
                                        />
                                      </TableCell>
                                    ) : (
                                      ""
                                    )}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          )}
                          <Grid item xs={10} md={10} sm={10}>
                            <RTextField
                              id="nota"
                              label="Observaciones para el traslado "
                              variant="outlined"
                              size="small"
                              multiline
                              rows={2}
                              fullWidth={true}
                              margin="normal"
                              onChange={(e) => {
                                let newArr = [];
                                newArr.push(e.target.value);
                                setNotes(newArr);
                                setObservaciones(e.target.value);
                              }}
                              value={observaciones}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {index === 3 && (
                      <>
                        <div style={{ marginTop: 20 }}>
                          <Typography variant="h6">
                            ¿Ha completado su Guia?
                          </Typography>
                          <Typography variant="body2">
                            Se enviará el documento a la SUNAT
                          </Typography>
                        </div>
                        {errorMsgTryingSend !== "" && (
                          <Alert severity="error">{errorMsgTryingSend}</Alert>
                        )}
                      </>
                    )}
                    <div style={{ marginBottom: 5, marginTop: 25 }}>
                      <div>
                        {activeStep !== 0 && (
                          <ButtonSecondary
                            onClick={handleBack}
                            border={Colors.GREEN}
                            color={Colors.GREEN}
                            size="16px"
                            width="200px"
                          >
                            Atras
                          </ButtonSecondary>
                        )}
                        <ButtonPrimary
                          onClick={handleNext}
                          disabled={loading}
                          background={Colors.GREEN}
                          color={Colors.WHITE}
                          size="16px"
                          width="200px"
                        >
                          {activeStep === steps.length - 1 ? (
                            <>
                              {loading ? (
                                <>
                                  <CircularProgress color="inherit" size={14} />
                                </>
                              ) : (
                                <>
                                  <Send
                                    fontSize="small"
                                    style={{ marginRight: 5 }}
                                  />
                                  {"Enviar"}
                                </>
                              )}
                            </>
                          ) : (
                            "Continuar"
                          )}
                        </ButtonPrimary>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </ContainerForm>
    </>
  );
};

export default FormNewGuieRemission;
