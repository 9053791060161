import { Colors, Sizes } from "src/infrastructure/static/colors";
import styled from "styled-components";

export const ButtonClose = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: -10px;
`;

export const ButtonNewUser = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: end;
  align-items: center;
  background: transparent;
`;

export const ContainerLinks = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  div {
    margin: 0px 10px;
    cursor: pointer;
  }
  @media ${Sizes.TABLET} {
    display: none;
  }
`;

export const ButtonNew = styled.button`
  background: transparent;
  display: flex;
  margin-left: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  p {
    margin: 0px 5px;
  }
  @media ${Sizes.TABLET} {
    margin-left: 0px;
    width: 100%;
    display: block;
    p {
      margin: 0px;
    }
  }
`;

export const LogoRatifika = styled.div`
  @media ${Sizes.TABLET} {
    display: flex;
    flex-grow: 1;
  }
`;

export const ContainerHeader = styled.div`
  background: ${Colors.GREEN};
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;
