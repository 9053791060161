import { useSelector } from "react-redux";
import { useState } from "react";
import { CompanyInfo } from "../../store/types/companies";
import { Typography, IconButton } from "@material-ui/core";
import { WrapTextTwoTone as ChangeIcon } from "@material-ui/icons";
import { CompaniesMenu } from "../companiesMenu";
import '../../views/emission/Header.css';

interface Props {
  title: string;
}

export const HeaderTitle: React.FC<Props> = ({ title }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const companies = useSelector((state: any) => state.companiesInfo);
  const config = useSelector((state: any) => state.config);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div  className="header-title">
      <Typography
        variant="h5"
        component="h2"
        style={{ /* fontFamily: "Corbel", */ fontSize: 28, fontWeight: "lighter",textAlign: "left" }}
      >
        {title}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: 20,
          paddingTop:".2rem",
          justifyContent:title==="Reportes"?"center":""
        }}
      >
        <Typography color="textSecondary" style={{ fontSize: 14, fontWeight: "bold" }}>
          {config.workspace !== null
            ? companies.find((c: CompanyInfo) => c.ruc === config.workspace).legal_name
            : ""}
        </Typography>
        {companies.length > 1 && (
          <>
            <IconButton size="small" onClick={handleOpenMenu}>
              <ChangeIcon
                style={{
                  fontSize: 20,
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              />
            </IconButton>
            <CompaniesMenu anchorEl={anchorEl} onCloseMenu={handleClose} />
          </>
        )}
      </div>
    </div>
  );
};
