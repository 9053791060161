import React, { useState } from "react";
import { Divider, Slide } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { afterLogin } from "../../../domain/store/afterLogin";
import { setConfigParam } from "../../../domain/store/actions/config";
import { handleBlurValidation } from "../../static/util";
import { TermsConditions } from "../termsConditions";
import { PrivacyPolicy } from "../privacyPolicy";
import TextStyle from "../textStyle";
import { Colors } from "../../static/colors";
import { InputLetter, InputNumber, InputPassword, InputText } from "../input";
import { ButtonAuth, ButtonPrimary, ButtonSecondary } from "../button";
import CustomCheckbox from "../checkbox";
import { useForm } from "../../hooks/useForm";
import {
  validatorRegister,
  validatorVerification,
} from "../../static/validations";
import { PATH } from "../../http/Path";
import { userService } from "../../../domain/services/User.service";
import { Container } from "./styled";

interface Props {
  openLoginForm: () => void;
}

export const RegisterForm: React.FC<Props> = ({ openLoginForm }) => {
  const config = useSelector((state: any) => state.config);

  const { form, onChange } = useForm({
    name: "",
    phone: "",
    email: "",
    password: "",
    session_id: config.session_id,
  });

  const {
    form: formError,
    onChangeError,
    onReset,
  } = useForm({
    nameError: false,
    nameErrorText: "",
    emailError: false,
    emailErrorText: "",
    phoneError: false,
    phoneErrorText: "",
    passwordError: false,
    passwordErrorText: "",
  });

  const { form: formVerification, onChange: onChangeVerification } = useForm({
    email: config.validatingEmail || "",
    code: "",
  });

  const {
    form: formErrorVerification,
    onChangeError: onChangeErrorVerification,
    onReset: onResetVerification,
  } = useForm({
    codeError: false,
    codeErrorText: "",
  });

  const [failRegisterMsg, setFailRegisterMsg] = useState("");
  const [failCodeMsg, setFailCodeMsg] = useState<string | null>(null);
  const [openTermsConditions, setOpenTermsConditions] = React.useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [agreeTermsConditions, setAgreeTermsConditions] = React.useState(false);
  const [isValidAgreeTermsConditions, setIsValidAgreeTermsConditions] =
    React.useState(true);

  const dispatch = useDispatch();

  const handleRegister = async () => {
    if (agreeTermsConditions === false) {
      setIsValidAgreeTermsConditions(false);
      return;
    }
    try {
      const validateRegister = validatorRegister(form, formError);
      if (validateRegister) {
        onChangeError(validateRegister);
      } else {
        setIsValidAgreeTermsConditions(true);
        onReset();
        let response = await userService.postUserRegister(form);
        const data = response.data;
        if (data.result) {
          dispatch(setConfigParam("validatingEmail", form.email));
        } else {
          setFailRegisterMsg(
            "No se ha podido registrar la nueva cuenta, intente más adelante. Si el problema persiste, por favor contacte con el equipo de soporte."
          );
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setFailRegisterMsg("Ya existe una cuenta con esta dirección de correo");
      } else {
        setFailRegisterMsg(
          "Ha ocurrido un error inesperado, intente más adelante. Si el problema persiste, por favor contacte con el equipo de soporte."
        );
      }
    }
  };

  const handleVerifyUser = async () => {
    try {
      const validateVerification = validatorVerification(
        formVerification,
        formErrorVerification
      );
      if (validateVerification) {
        onChangeErrorVerification(validateVerification);
      } else {
        onResetVerification();
        let response = await userService.postUserVerify({
          ...formVerification,
          email: config.validatingEmail || "",
        });
        const data = response.data;
        if (data.result) {
          afterLogin(data.token, data.userName, data.email, false);
          dispatch(setConfigParam("validatingEmail", null));
        } else {
          setFailCodeMsg(
            "No se ha podido validar la cuenta, intente más adelante. Si el problema persiste, por favor contacte con el equipo de soporte."
          );
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setFailCodeMsg("El código ingresado es incorrecto");
      } else {
        setFailCodeMsg(
          "Ha ocurrido un error inesperado, intente más adelante. Si el problema persiste, por favor contacte con el equipo de soporte."
        );
      }
    }
  };

  const handleCancelVerification = () => {
    setFailCodeMsg(null);
    dispatch(setConfigParam("validatingEmail", null));
  };

  const handleChangeAgreeTermsConditions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAgreeTermsConditions(event.target.checked);
  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Container>
        <TextStyle bold={900} color={Colors.GREEN} type="h1" margin="10px 0px">
          {!config.validatingEmail ? "Registrate" : "Verificar cuenta"}
        </TextStyle>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!config.validatingEmail ? (
            <div>
              <InputLetter
                name="name"
                value={form.name}
                placeholder="Nombre"
                onChange={onChange}
                onBlur={() =>
                  handleBlurValidation(
                    validatorRegister,
                    onChangeError,
                    form,
                    formError,
                    "name"
                  )
                }
                error={formError.nameError}
                helperText={formError.nameErrorText}
              />
              <InputNumber
                name="phone"
                value={form.phone}
                placeholder="Celular"
                onChange={onChange}
                onBlur={() =>
                  handleBlurValidation(
                    validatorRegister,
                    onChangeError,
                    form,
                    formError,
                    "phone"
                  )
                }
                error={formError.phoneError}
                helperText={formError.phoneErrorText}
              />
              <InputText
                name="email"
                value={form.email}
                placeholder="Correo electrónico"
                onChange={onChange}
                onBlur={() =>
                  handleBlurValidation(
                    validatorRegister,
                    onChangeError,
                    form,
                    formError,
                    "email"
                  )
                }
                error={formError.emailError}
                helperText={formError.emailErrorText}
              />
              <InputPassword
                name="password"
                value={form.password}
                placeholder="Contraseña"
                onChange={onChange}
                onBlur={() =>
                  handleBlurValidation(
                    validatorRegister,
                    onChangeError,
                    form,
                    formError,
                    "password"
                  )
                }
                error={formError.passwordError}
                helperText={formError.passwordErrorText}
              />
              <CustomCheckbox
                color={Colors.GREEN}
                name="agreeTermsConditions"
                value={agreeTermsConditions}
                onChange={(e) => handleChangeAgreeTermsConditions(e)}
                error={!isValidAgreeTermsConditions}
                helperText="Debe aceptar la política de privacidad y los términos y
                condicones para poder registrarse"
                renderText={() => (
                  <TextStyle bold={400} color={Colors.TEXT} type="h4">
                    Estoy de acuerdo con la{" "}
                    <span
                      style={{
                        fontWeight: 700,
                        color: Colors.GREEN,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenPrivacyPolicy(true);
                      }}
                    >
                      política de privacidad
                    </span>{" "}
                    y los{" "}
                    <span
                      style={{
                        fontWeight: 700,
                        color: Colors.GREEN,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenTermsConditions(true);
                      }}
                    >
                      términos y condiciones
                    </span>
                  </TextStyle>
                )}
              />
              <TermsConditions
                isOpen={openTermsConditions}
                close={() => setOpenTermsConditions(false)}
              />
              <PrivacyPolicy
                isOpen={openPrivacyPolicy}
                close={() => setOpenPrivacyPolicy(false)}
              />
              {failRegisterMsg !== "" && (
                <TextStyle bold={400} color={Colors.RED} type="h3">
                  {failRegisterMsg}
                </TextStyle>
              )}
              <ButtonPrimary
                background={Colors.GREEN}
                color={Colors.WHITE}
                onClick={handleRegister}
                size="16px"
              >
                Crear cuenta
              </ButtonPrimary>
              <div
                onClick={() => openLoginForm()}
                style={{ cursor: "pointer" }}
              >
                <TextStyle
                  bold={400}
                  color={Colors.GREEN}
                  type="h4"
                  textAlign="end"
                >
                  ¿Ya tienes cuenta? Ingresa
                </TextStyle>
              </div>
              <Divider
                style={{
                  marginTop: "3em",
                  marginBottom: "2em",
                  marginInline: "3em",
                }}
              />
              <ButtonAuth
                border={Colors.GREEN}
                color={Colors.GREEN}
                size="16px"
                href={PATH.AUTH_GOOGLE}
              >
                <FontAwesomeIcon
                  icon={faGoogle}
                  style={{ fontSize: "14px", marginRight: 15 }}
                />
                Ingresa con Google
              </ButtonAuth>
            </div>
          ) : (
            <div>
              <TextStyle bold={400} color={Colors.TEXT} type="h4">
                Ingresa a continuación el código de seis dígitos que se ha
                enviado a tu correo para poder validar tu cuenta.
              </TextStyle>
              <InputNumber
                name="code"
                value={formVerification.code}
                placeholder="Código de verificación"
                onChange={onChangeVerification}
                onBlur={() =>
                  handleBlurValidation(
                    validatorVerification,
                    onChangeErrorVerification,
                    formVerification,
                    formErrorVerification,
                    "code"
                  )
                }
                error={formErrorVerification.codeError}
                helperText={formErrorVerification.codeErrorText}
              />
              {failCodeMsg && (
                <TextStyle bold={400} color={Colors.RED} type="h3">
                  {failCodeMsg}
                </TextStyle>
              )}
              <ButtonPrimary
                background={Colors.GREEN}
                color={Colors.WHITE}
                onClick={handleVerifyUser}
                size="16px"
                disabled={formVerification.code.length < 6}
              >
                Verificar
              </ButtonPrimary>
              <ButtonSecondary
                border={Colors.GREEN}
                color={Colors.GREEN}
                size="16px"
                onClick={handleCancelVerification}
              >
                Cancelar
              </ButtonSecondary>
            </div>
          )}
        </div>
      </Container>
    </Slide>
  );
};
