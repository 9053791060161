import React from "react";
import {
  ButtonClose,
  ButtonNew,
  ButtonNewUser,
  ContainerHeader,
  ContainerLinks,
  LogoRatifika,
} from "./styled";
import logoRatifika from "../../assets/imgs/ratifika_white.svg";
import logoRatifikaMobile from "../../assets/imgs/logo_mobile.svg";
import TextStyle from "../textStyle";
import { Colors } from "src/infrastructure/static/colors";
import { useHistory } from "react-router-dom";
import {
  DescriptionOutlined,
  LibraryAdd as AddDocumentIcon,
  ReceiptOutlined,
  FileCopyOutlined,
  AccountCircle,
  ExpandMore,
  SettingsOutlined as SettingsIcon,
  ExitToAppRounded as ExitIcon,
} from "@material-ui/icons";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import CallToActionIcon from "@material-ui/icons/CallToAction";
import {
  Container,
  Drawer,
  Hidden,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CompanyInfo } from "src/domain/models/Company";
import { toast } from "react-toastify";
import { truncateString } from "src/infrastructure/static/util";
import { setConfigParam } from "src/domain/store/actions/config";
import { fetch } from "src/domain/store/fetch";
import { flushStorage } from "src/domain/store/flush";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import RegisterCompanyModal from "../registerCompanyModal";
import { setCompanyInfoList } from "src/domain/store/actions/companies";
import { companyService } from "src/domain/services/Company.service";
import { ConfigurationModal } from "../configurationModal";
import { NewDocumentTxtModal } from "../newDocumentTxtModal";
interface IMenuNewProps {
  open: null | HTMLElement;
  handleClose: any;
}

interface IMenuUserProps {
  open: null | HTMLElement;
  handleClose: any;
  handleRegisterNewCompany: any;
  handleOpenConfiguration: any;
}

const MenuUser: React.FC<IMenuUserProps> = ({
  open,
  handleClose,
  handleRegisterNewCompany,
  handleOpenConfiguration,
}) => {
  const companies = useSelector((state: any) => state.companiesInfo);
  const config = useSelector((state: any) => state.config);
  const history = useHistory();

  const dispatch = useDispatch();

  const handleSelectCompany = (
    event: React.MouseEvent<HTMLElement>,
    workspace: number
  ) => {
    dispatch(setConfigParam("workspace", workspace));
    handleClose();
    dispatch(setConfigParam("emittedPageCurrent", 0));
    fetch(["reception", "emission", "reports"]);
  };

  const handleLogout = () => {
    handleClose();
    flushStorage(false);
  };

  return (
    <>
      <Menu
        id="lock-menu"
        anchorEl={open}
        keepMounted
        open={Boolean(open)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {companies.map((c: CompanyInfo) => (
          <MenuItem
            key={c.ruc}
            disabled={c.status === "NEW"}
            selected={c.ruc === config.workspace}
            onClick={(event) => handleSelectCompany(event, c.ruc)}
          >
            {c.status === "NEW" ? (
              <ListItemText
                secondary={
                  <span style={{ fontStyle: "italic" }}>
                    Pendiente de validación
                  </span>
                }
                primary={truncateString(c.legal_name, 27)}
              />
            ) : (
              <ListItemText
                secondary={c.ruc}
                primary={truncateString(c.legal_name, 27)}
              />
            )}
          </MenuItem>
        ))}
        <MenuItem
          style={{
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
          onClick={handleRegisterNewCompany}
        >
          <p
            style={{
              borderWidth: 2,
              borderColor: "#dadada",
              borderStyle: "dotted",
              borderRadius: 10,
              padding: 8,
              width: "100%",
              textAlign: "center",
            }}
          >
            Registrar nueva empresa
          </p>
        </MenuItem>
        {/* <MenuItem
          disabled={!config.workspace}
          onClick={handleOpenConfiguration}
        >
          <SettingsIcon style={{ fontSize: 20, paddingRight: 5 }} />
          Configuración
        </MenuItem> */}
        <MenuItem
          disabled={!config.workspace}
          onClick={() => history.push("/configuracion/misdatos")}
        >
          <SettingsIcon style={{ fontSize: 20, paddingRight: 5 }} />
          Configuración
        </MenuItem>
        {/* <div onClick={() => history.push("/flujos")}>
          <TextStyle bold={700} type="h3" color={Colors.WHITE}>
            Flujos
          </TextStyle>
        </div> */}
        <MenuItem onClick={handleLogout}>
          <ExitIcon style={{ fontSize: 20, paddingRight: 5 }} />
          Cerrar sessión
        </MenuItem>
      </Menu>
    </>
  );
};

const MenuNew: React.FC<IMenuNewProps> = ({ open, handleClose }) => {
  const history = useHistory();
  const companies = useSelector((state: any) => state.companiesInfo);
  const config = useSelector((state: any) => state.config);
  const [disableGuias, setDisableGuias] = React.useState(true);
  const [disableTxt, setDisableTxt] = React.useState(true);
  const [disableRetenciones, setDisableRetenciones] = React.useState(true);
  const [openModalTxt, setOpenModalTxt] = React.useState(false);

  const handleNotAvailable = () => {
    toast.warn(
      "Lo siento, parece que esta funcionalidad no está incluida en su plan actual. Contacte con un ejecutivo de EXIMIO para más información.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
  };

  const handleOpenModaltxt = () => {
    setOpenModalTxt(true);
  };

  const handleCloseTxt = () => {
    setOpenModalTxt(false);
    // setTimeout(() => fetch(["emission"]), 1000);
  };

  React.useEffect(() => {
    if (config.workspace) {
      setDisableGuias(true);
      setDisableTxt(true);
      setDisableRetenciones(true);
      if (
        companies.find((c: CompanyInfo) => c.ruc === config.workspace) &&
        companies.find((c: CompanyInfo) => c.ruc === config.workspace).customize
      ) {
        if (
          companies.find((c: CompanyInfo) => c.ruc === config.workspace)
            .customize.envioGuias === "YES"
        ) {
          setDisableGuias(false);
        }
        if (
          companies.find((c: CompanyInfo) => c.ruc === config.workspace)
            .customize.envioTxt === "YES"
        ) {
          setDisableTxt(false);
        }
        if (
          companies.find((c: CompanyInfo) => c.ruc === config.workspace)
            .customize.envioRetenciones === "YES"
        ) {
          setDisableRetenciones(false);
        }
      }
    }
  }, [config.workspace]);

  return (
    <>
      <Menu
        id="lock-menu"
        anchorEl={open}
        keepMounted
        open={Boolean(open)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => history.push("/nueva-factura-boleta")}
          style={{ fontSize: 14 }}
        >
          <DescriptionOutlined style={{ fontSize: 20, marginRight: 5 }} /> Nueva
          factura / boleta
        </MenuItem>
        <MenuItem
          onClick={
            disableGuias
              ? handleNotAvailable
              : () => history.push("/nueva-guia")
          }
          style={{ fontSize: 14 }}
        >
          <LocalShippingOutlinedIcon style={{ fontSize: 20, marginRight: 5 }} />
          Nueva guía de remisión
        </MenuItem>
        <MenuItem
          onClick={
            disableRetenciones
              ? handleNotAvailable
              : () => history.push("/nueva-retencion")
          }
          style={{ fontSize: 14 }}
        >
          <CallToActionIcon style={{ fontSize: 20, marginRight: 5 }} />
          Nueva Retencion
        </MenuItem>
        <MenuItem
          onClick={disableTxt ? handleNotAvailable : handleOpenModaltxt}
          style={{ fontSize: 14 }}
        >
          <FileCopyOutlined style={{ fontSize: 20, marginRight: 5 }} />
          Cargar txt / json
        </MenuItem>
        <NewDocumentTxtModal
          isOpen={openModalTxt && !disableTxt}
          closeForm={handleCloseTxt}
        />
      </Menu>
    </>
  );
};

const Header = () => {
  const companies = useSelector((state: any) => state.companiesInfo);
  const config = useSelector((state: any) => state.config);
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [openMenuMobile, setOpenMenuMobile] = React.useState<boolean>(false);
  const [openRegisterCompanyModal, setOpenRegisterCompanyModal] =
    React.useState(false);
  const [showIntroForNewCompany, setshowIntroForNewCompany] =
    React.useState(false);
  const [openConfigModal, setOpenConfigModal] = React.useState(false);

  const handleRegisterNewCompany = () => {
    setAnchorEl(null);
    setOpenRegisterCompanyModal(true);
  };

  const handleOpenConfiguration = () => {
    setAnchorEl(null);
    setOpenConfigModal(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUser = () => {
    setAnchorElUser(null);
  };

  const handleCloseRegisterCompany = async (success: boolean) => {
    if (success) {
      // Actualizo la lista de empresas
      const { data } = await companyService.getCompanyInfoList();
      dispatch(setCompanyInfoList(data as CompanyInfo[]));
    }
    setOpenRegisterCompanyModal(false);
  };

  React.useEffect(() => {
    if (!config.workspace) {
      setshowIntroForNewCompany(true);
      setOpenRegisterCompanyModal(true);
    }
  }, []);

  return (
    <ContainerHeader>
      <Hidden mdDown>
        <LogoRatifika>
          <img
            src={logoRatifika}
            alt="logo_ratifika"
            width="100px"
            onClick={() => history.push("/ventas")}
          />
        </LogoRatifika>
      </Hidden>
      <Hidden mdUp>
        <LogoRatifika>
          <IconButton onClick={() => setOpenMenuMobile(true)}>
            <MenuIcon style={{ color: "#ffffff" }} />
          </IconButton>
        </LogoRatifika>
        <Drawer
          anchor="right"
          open={openMenuMobile}
          onClose={() => setOpenMenuMobile(false)}
        >
          <Container style={{ width: "200px" }}>
            <ButtonClose>
              <img src={logoRatifikaMobile} alt="logoRatifikaMobile" />
              <IconButton onClick={() => setOpenMenuMobile(false)}>
                <CloseIcon />
              </IconButton>
            </ButtonClose>
            {config.workspace && (
              <>
                <ButtonNew onClick={handleClick}>
                  <TextStyle bold={700} type="h2" textAlign="end">
                    <AddDocumentIcon style={{ color: Colors.TEXT }} /> Nuevo
                  </TextStyle>
                </ButtonNew>
                <MenuNew open={anchorEl} handleClose={handleClose} />
              </>
            )}
            <div onClick={() => history.push("/ventas")}>
              <TextStyle bold={700} type="h2" textAlign="end" margin="10px 0px">
                Ventas
              </TextStyle>
            </div>
            <div onClick={() => history.push("/compras")}>
              <TextStyle bold={700} type="h2" textAlign="end" margin="10px 0px">
                Compras
              </TextStyle>
            </div>
            <div onClick={() => history.push("/reportes")}>
              <TextStyle bold={700} type="h2" textAlign="end" margin="10px 0px">
                Reportes
              </TextStyle>
            </div>
          </Container>
        </Drawer>
      </Hidden>
      <ContainerLinks>
        {config.workspace && (
          <>
            <ButtonNew onClick={handleClick}>
              <AddDocumentIcon style={{ color: Colors.WHITE }} />
              <TextStyle bold={700} type="h3" color={Colors.WHITE}>
                Nuevo
              </TextStyle>
            </ButtonNew>
            <MenuNew open={anchorEl} handleClose={handleClose} />
          </>
        )}
        <div onClick={() => history.push("/ventas")}>
          <TextStyle bold={700} type="h3" color={Colors.WHITE}>
            Ventas
          </TextStyle>
        </div>
        <div onClick={() => history.push("/compras")}>
          <TextStyle bold={700} type="h3" color={Colors.WHITE}>
            Compras
          </TextStyle>
        </div>
        <div onClick={() => history.push("/reportes")}>
          <TextStyle bold={700} type="h3" color={Colors.WHITE}>
            Reportes
          </TextStyle>
        </div>
      </ContainerLinks>
      <ButtonNewUser onClick={handleClickUser}>
        <AccountCircle
          style={{ marginRight: 5, fontSize: 35, color: Colors.WHITE }}
        />
        <div>
          <TextStyle bold={700} color={Colors.WHITE} type="h3">
            {config.userName}
          </TextStyle>
          <TextStyle bold={400} color={Colors.WHITE} type="h4">
            {config.workspace !== null
              ? companies.find((c: CompanyInfo) => c.ruc === config.workspace)
                  .legal_name
              : "Registrar una empresa"}
          </TextStyle>
        </div>
        <ExpandMore style={{ marginLeft: 5, color: Colors.WHITE }} />
      </ButtonNewUser>
      <MenuUser
        open={anchorElUser}
        handleClose={handleCloseUser}
        handleRegisterNewCompany={handleRegisterNewCompany}
        handleOpenConfiguration={handleOpenConfiguration}
      />
      <RegisterCompanyModal
        isOpen={openRegisterCompanyModal}
        openWelcome={showIntroForNewCompany}
        close={handleCloseRegisterCompany}
      />
      <ConfigurationModal
        isOpen={openConfigModal}
        close={() => setOpenConfigModal(false)}
      />
    </ContainerHeader>
  );
};

export default Header;
