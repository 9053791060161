import { Colors, Sizes } from "src/infrastructure/static/colors";
import styled from "styled-components";

export const ContainerForm = styled.div`
  max-width: 800px;
  margin: auto;
  box-shadow: 0px 4px 8px #679b60;
  border-radius: 10px;
  margin-top: 50px;
  @media ${Sizes.TABLET} {
    box-shadow: none;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${Colors.PURPLE};
  margin-top: 20px;
  padding: 30px 20px;
`;
