import { userRepository } from "../../infrastructure/repositories/User.repository";
import {
  IUserForgotPassword,
  IUserLogin,
  IUserRegister,
  IUserVerification,
} from "../models/User";

const postUserLogin = async (data: IUserLogin) => {
  const response = await userRepository.postUserLogin(data);
  return response;
};

const postUserForgotPassword = async (data: IUserForgotPassword) => {
  const response = await userRepository.postUserForgotPassword(data);
  return response;
};

const postUserRegister = async (data: IUserRegister) => {
  const response = await userRepository.postUserCreate(data);
  return response;
};

const postUserVerify = async (data: IUserVerification) => {
  const response = await userRepository.postUserVerify(data);
  return response;
};

export const userService = {
  postUserLogin,
  postUserForgotPassword,
  postUserRegister,
  postUserVerify,
};
