import React from "react";
import { ButtonPrimary, ButtonSecondary } from "../button";
import TextStyle from "../textStyle";
import { ContainerButtons, ContainerTitle } from "./styled";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Colors } from "src/infrastructure/static/colors";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import FileDownload from "js-file-download";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { CompanyInfo } from "src/domain/models/Company";

const DownloadEmitted = () => {
  const dispatch = useDispatch();
  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const [downloadingExcel, setDownloadingExcel] =
    React.useState<boolean>(false);
  const [blockDownloadExcel, setBlockDownloadExcel] =
    React.useState<boolean>(false);
  const [showDialogConfirmExcel, setShowDialogConfirmExcel] =
    React.useState<boolean>(false);
  const [showDialogBackendExcel, setShowDialogBackendExcel] =
    React.useState<boolean>(false);
  const [disabledBulkDownload, setDisabledBulkDownload] =
    React.useState<boolean>(true);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [openDialogBulkDownload, setOpenDialogBulkDownload] =
    React.useState<boolean>(false);
  const [openDialogRequestProcess, setOpenDialogRequestProcess] =
    React.useState<boolean>(false);
  const [selectOptionPDF, setSelectOptionPDF] = React.useState<boolean>(false);
  const [selectOptionXML, setSelectOptionXML] = React.useState<boolean>(false);
  const [selectOptionCDR, setSelectOptionCDR] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDownloadExcel = async () => {
    if (config.emittedPageTotal <= 10000) {
      // 10 mil
      setDownloadingExcel(true);
      setBlockDownloadExcel(true);
      let { data } = await axios.get(
        `/api/emission/download/excel/${config.workspace}?dateOf=${
          config.emittedDateType
        }&startDate=${config.emittedStartDate}&endDate=${
          config.emittedEndDate
        }${
          config.emittedSerie
            ? `&serie=${config.emittedSerie}&seqInit=${config.emittedSequentialStart}&seqEnd=${config.emittedSequentialEnd}`
            : ""
        }${
          config.emittedDocumentType
            ? `&docType=${config.emittedDocumentType}`
            : ""
        }${
          config.emittedDocumentStatus
            ? `&docStatus=${
                config.emittedDocumentStatus.split(",").includes("1-3")
                  ? config.emittedDocumentStatus.replace("1-3", "1,3")
                  : config.emittedDocumentStatus
              }`
            : ""
        }${
          config.emittedClientId ? `&receiverId=${config.emittedClientId}` : ""
        }`,
        {
          responseType: "blob",
        }
      );
      FileDownload(
        new Blob([data]),
        `docs_emitidos_${config.workspace}_${config.emittedStartDate}_${config.emittedEndDate}.xlsx`
      );
      setDownloadingExcel(false);
      setTimeout(() => setBlockDownloadExcel(false), 3000);
    } else if (config.emittedPageTotal <= 30000) {
      // mostrar dialog de que son bastante y se puede demorar
      setShowDialogConfirmExcel(true);
    } else {
      // TODO: mostrar mensaje de que el reporte se le enviara mas adelante por email
      setShowDialogBackendExcel(true);
    }
  };

  const openPopOver = () => {
    setShowAlert(true);
  };
  const handleSelectbulkDownload = () => {
    setOpenDialogBulkDownload(true);
  };
  const handleCloseDialogBulkDownload = () => {
    setOpenDialogBulkDownload(false);
  };

  const handleCloseDialogRequestProcess = () => {
    setOpenDialogRequestProcess(false);
  };

  const generateBulkDownload = async () => {
    setLoading(true);
    const allDocument = await axios.get(
      `/api/emission/documents/all/${config.workspace}?dateOf=${
        config.emittedDateType
      }&startDate=${config.emittedStartDate}&endDate=${
        config.emittedEndDate
      }&receiverId=${config.emittedClientId ? config.emittedClientId : ""}${
        config.emittedSerie
          ? `&serie=${config.emittedSerie}&seqInit=${config.emittedSequentialStart}&seqEnd=${config.emittedSequentialEnd}`
          : ""
      }${
        config.emittedDocumentType
          ? `&docType=${config.emittedDocumentType}`
          : ""
      }${
        config.emittedDocumentStatus
          ? `&docStatus=${
              config.emittedDocumentStatus.split(",").includes("1-3")
                ? config.emittedDocumentStatus.replace("1-3", "1,3")
                : config.emittedDocumentStatus
            }`
          : ""
      }`
    );

    if (allDocument.data.result) {
      let body = allDocument.data.emittedDocs;
      let downloadLimit = await axios.get(`/api/bulkDownload/files/limit`);
      if (
        downloadLimit.data.result &&
        body.length <= downloadLimit.data.limit
      ) {
        let { data } = await axios.post(
          `/api/bulkDownload/emission/${config.workspace}/${selectOptionPDF}/${selectOptionXML}/${selectOptionCDR}`,
          body,
          {
            responseType: "blob",
          }
        );
        FileDownload(
          new Blob([data]),
          `DM-Doc-Emitidos-${config.workspace}.zip`
        );
        setLoading(false);
        handleCloseDialogBulkDownload();
      } else {
        setLoading(false);
        setOpenDialogRequestProcess(true);
        handleCloseDialogBulkDownload();
        let { data } = await axios.post(
          `/api/bulkDownload/emission/${config.workspace}/${selectOptionPDF}/${selectOptionXML}/${selectOptionCDR}`,
          body
        );
        if (data.result) {
          toast.info(
            "El processo de su ultima consultada de descarga masiva  ya fue generada",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      }
    }
  };

  React.useEffect(() => {
    setDisabledBulkDownload(true);
    if (companies.find((c: CompanyInfo) => c.ruc === config.workspace)) {
      if (
        companies.find((c: CompanyInfo) => c.ruc === config.workspace).customize
          .descargaMasiva === "YES"
      ) {
        setDisabledBulkDownload(false);
      }
    }
  }, [config.workspace, companies]);

  return (
    <ContainerTitle>
      <div>
        <TextStyle bold={700} type="h1" margin="10px 0px 0px 0px">
          Resultado de Documentos Emitidos
        </TextStyle>
      </div>
      <ContainerButtons>
        { !disabledBulkDownload && <ButtonSecondary
            border={Colors.GREEN}
            color={Colors.GREEN}
            onClick={
              disabledBulkDownload ? openPopOver : handleSelectbulkDownload
            }
            size="14px"
            
          >
            <GetAppIcon />
            Descarga masiva
          </ButtonSecondary>
        }
        <ButtonSecondary
          border={Colors.GREEN}
          color={Colors.GREEN}
          size="14px"
          disabled={blockDownloadExcel || config.emittedPageTotal === 0}
          onClick={handleDownloadExcel}
        >
          {downloadingExcel ? (
            <CircularProgress
              variant="indeterminate"
              size={14}
              style={{ marginRight: 5 }}
            />
          ) : (
            <GetAppIcon />
          )}
          Descargar reporte
        </ButtonSecondary>
      </ContainerButtons>
      <Dialog
        open={openDialogBulkDownload}
        onClose={handleCloseDialogBulkDownload}
      >
        <DialogTitle>Seleccione los tipos de archivos a descargar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormGroup
              row
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: Colors.primary }}
                    onChange={() => setSelectOptionPDF(!selectOptionPDF)}
                    checked={selectOptionPDF}
                  />
                }
                label="PDF"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: Colors.primary }}
                    onChange={() => setSelectOptionXML(!selectOptionXML)}
                    checked={selectOptionXML}
                  />
                }
                label="XML"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: Colors.primary }}
                    onChange={() => setSelectOptionCDR(!selectOptionCDR)}
                    checked={selectOptionCDR}
                  />
                }
                label="CDR"
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonSecondary
            onClick={handleCloseDialogBulkDownload}
            border={Colors.GREEN}
            color={Colors.GREEN}
            size="14px"
            width="100px"
          >
            Cancelar
          </ButtonSecondary>

          <ButtonPrimary
            onClick={() => {
              if (selectOptionPDF || selectOptionXML || selectOptionCDR) {
                generateBulkDownload();
              }
            }}
            disabled={loading}
            background={Colors.GREEN}
            color={Colors.WHITE}
            size="14px"
            width="130px"
          >
            {loading ? (
              <>
                <CircularProgress
                  variant="indeterminate"
                  size={14}
                  style={{ marginRight: 5 }}
                />
                Cargando...
              </>
            ) : (
              <>Confirmar</>
            )}
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogRequestProcess}
        onClose={handleCloseDialogRequestProcess}
      >
        <DialogTitle>SOLICITUD POR PROCESAR</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Puedes continuar usando el sistema de Ratifika mientras tu reporte
            se procesa. Te aparecerá una notificación cuando esté listo para
            descargar desde Mis Reportes
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </ContainerTitle>
  );
};

export default DownloadEmitted;
