import { Grid } from "@material-ui/core";
import React from "react";
import { getFormatDate, optionsTypeDocument } from "../../static/util";
import { useForm } from "../../hooks/useForm";
import { InputDate, InputNumber, InputText } from "../input";
import CustomSelect from "../select";
import TextStyle from "../textStyle";
import {
  Container,
  ContainerCard,
  ContainerInfoImage,
  ContainerLogo,
} from "./styled";
import CustomSwitch from "../switch";
import { comunService } from "../../../domain/services/Comun.service";
import { ButtonPrimary } from "../button";
import { Colors } from "../../static/colors";
import { validatorSearchDocument } from "src/infrastructure/static/validations";
import { ISearchDocument } from "src/domain/models/Comun";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Recaptcha from "../recaptcha";
import imgRucReceptor from "../../assets/imgs/RUCRECEPTOR.gif";
import imgRucEmitter from "../../assets/imgs/RUC_EMISOR.gif";
import imgCorrelative from "../../assets/imgs/CORRELATIVO.gif";
import imgSerie from "../../assets/imgs/SERIE.gif";
import imgTotal from "../../assets/imgs/TOTAL.gif";

const ConsultForm = () => {
  const history = useHistory();

  const [focusImage, setFocusImage] = React.useState("");
  const [loadingConsult, setLoadingConsult] = React.useState<boolean>(false);

  const {
    form,
    onChange,
    onChangeSelect,
    onChangeChecked,
    onCompleteData,
    onChangeValue,
  } = useForm({
    rucEmitter: "",
    hasContingency: false,
    typeDoc: "",
    serie: "",
    correlative: "",
    rucReceptor: "",
    totalAmount: "",
    emissionDate: new Date(),
    recaptcha: false,
  });

  const {
    form: formError,
    onChangeError,
    onReset: onResetError,
  } = useForm({
    rucEmitterError: false,
    rucEmitterErrorText: "",
    typeDocError: false,
    typeDocErrorText: "",
    serieError: false,
    serieErrorText: "",
    correlativeError: false,
    correlativeErrorText: "",
    rucReceptorError: false,
    rucReceptorErrorText: "",
    totalAmountError: false,
    totalAmountErrorText: "",
    emissionDateError: false,
    emissionDateErrorText: "",
    recaptchaError: false,
    recaptchaErrorText: "",
  });

  const [logoCompany, setLogoCompany] = React.useState<string>("");

  const onChangeRecaptcha = (value: any) => {
    onCompleteData({
      ...form,
      recaptcha: value,
    });
  };

  const getLogoCompany = async () => {
    try {
      const response = await comunService.getLogo(form.rucEmitter);
      setLogoCompany(response);
    } catch (err) {
      setLogoCompany("");
    }
  };

  const pushHistory = (data: ISearchDocument) => {
    if (data.rucReceptor === "") {
      history.push(
        `/consultar-resultado/${data.typeDoc}/${data.serie}/${data.correlative}/${data.totalAmount}/${data.rucEmitter}/${data.emissionDate}`
      );
    }
    if (data.rucReceptor) {
      history.push(
        `/consultar-resultado/${data.typeDoc}/${data.serie}/${data.correlative}/${data.totalAmount}/${data.rucEmitter}/${data.emissionDate}/${data.rucReceptor}`
      );
    }
  };

  const getSearchDocument = async () => {
    const validateSearchDocument = validatorSearchDocument(form, formError);
    if (validateSearchDocument) {
      onChangeError(validateSearchDocument);
    } else {
      onResetError();
      setLoadingConsult(true);
      try {
        const request = {
          ...form,
          emissionDate: getFormatDate(form.emissionDate),
        };
        const response = await comunService.getSearchDocument(request);
        if (response.data.file) {
          pushHistory(request);
        } else {
          toast.warn("Documento no encontrado ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (err) {
        console.log("err getSearchDocument", err);
        toast.error("Ocurrio un error, intentelo denuevo ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
    setLoadingConsult(false);
  };

  React.useEffect(() => {
    if (form.rucEmitter.length === 11) {
      getLogoCompany();
    }
  }, [form.rucEmitter]);

  React.useEffect(() => {
    onCompleteData({
      ...form,
      rucReceptor: "",
    });
    if (form.typeDoc === "09" || form.typeDoc === "20") {
      onCompleteData({
        ...form,
        totalAmount: "0",
      });
    }
  }, [form.typeDoc]);

  return (
    <>
      <Container>
        <TextStyle bold={700} type="h1">
          Consulta de Documentos
        </TextStyle>
        <ContainerCard>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <ContainerInfoImage>
                {focusImage === "" ? (
                  <TextStyle bold={700} type="h2" textAlign="center">
                    Ingrese los datos del comprobante
                  </TextStyle>
                ) : (
                  <img src={focusImage} alt="image_form" width="100%" />
                )}
              </ContainerInfoImage>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {logoCompany !== "" && (
                  <Grid item xs={12}>
                    <ContainerLogo>
                      <img src={logoCompany} alt="logo_company" width="150px" />
                    </ContainerLogo>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <InputNumber
                    name="rucEmitter"
                    onChange={onChange}
                    placeholder="Ruc Emisor"
                    value={form.rucEmitter}
                    maxLength={11}
                    error={formError.rucEmitterError}
                    helperText={formError.rucEmitterErrorText}
                    onFocus={() => setFocusImage(imgRucEmitter)}
                    onBlur={() => setFocusImage("")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomSelect
                    name="typeDoc"
                    value={form.typeDoc}
                    onChange={onChangeSelect}
                    placeholder="Tipo de documento"
                    options={optionsTypeDocument}
                    error={formError.typeDocError}
                    helperText={formError.typeDocErrorText}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomSwitch
                    checked={form.hasContingency}
                    name="hasContingency"
                    placeholder="¿Su comprobante es de contingencia?"
                    onChange={onChangeChecked}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputText
                    name="serie"
                    onChange={onChange}
                    placeholder="Serie"
                    value={form.serie}
                    maxLength={4}
                    error={formError.serieError}
                    helperText={formError.serieErrorText}
                    onFocus={() => setFocusImage(imgSerie)}
                    onBlur={() => setFocusImage("")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ContainerInfoImage>
                    <TextStyle bold={700} type="h1" textAlign="center">
                      -{" "}
                    </TextStyle>
                  </ContainerInfoImage>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputText
                    name="correlative"
                    onChange={onChange}
                    placeholder="Correlativo"
                    value={form.correlative}
                    maxLength={8}
                    error={formError.correlativeError}
                    helperText={formError.correlativeErrorText}
                    onFocus={() => setFocusImage(imgCorrelative)}
                    onBlur={() => setFocusImage("")}
                  />
                </Grid>
                {(form.typeDoc === "01" ||
                  form.typeDoc === "07" ||
                  form.typeDoc === "08") &&
                  form.serie.toUpperCase().charAt(0) === "F" &&
                  !form.hasContingency && (
                    <Grid item xs={12}>
                      <InputNumber
                        name="rucReceptor"
                        onChange={onChange}
                        placeholder="Ruc Receptor"
                        value={form.rucReceptor}
                        maxLength={11}
                        error={formError.rucEmitterError}
                        helperText={formError.rucEmitterErrorText}
                        onFocus={() => setFocusImage(imgRucReceptor)}
                        onBlur={() => setFocusImage("")}
                      />
                    </Grid>
                  )}
                {(form.typeDoc === "09" || form.typeDoc === "20") &&
                  !form.hasContingency && (
                    <Grid item xs={12}>
                      <InputNumber
                        name="rucReceptor"
                        onChange={onChange}
                        placeholder="Ruc Receptor"
                        value={form.rucReceptor}
                        maxLength={11}
                        error={formError.rucEmitterError}
                        helperText={formError.rucEmitterErrorText}
                        onFocus={() => setFocusImage(imgRucReceptor)}
                        onBlur={() => setFocusImage("")}
                      />
                    </Grid>
                  )}
                <Grid item xs={12} md={6}>
                  <InputText
                    name="totalAmount"
                    onChange={onChange}
                    placeholder="Monto total"
                    value={form.totalAmount}
                    error={formError.totalAmountError}
                    helperText={formError.totalAmountErrorText}
                    onFocus={() => setFocusImage(imgTotal)}
                    onBlur={() => setFocusImage("")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDate
                    name="emissionDate"
                    value={form.emissionDate}
                    placeholder="Fecha de emisión"
                    onChange={onChangeValue}
                    error={formError.emissionDateError}
                    helperText={formError.emissionDateErrorText}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Recaptcha
                    onChange={onChangeRecaptcha}
                    error={formError.recaptchaError}
                    helpertext={formError.recaptchaErrorText}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonPrimary
                    background={Colors.GREEN}
                    color={Colors.WHITE}
                    size="18px"
                    onClick={getSearchDocument}
                    disabled={loadingConsult}
                    loading={loadingConsult}
                  >
                    Consultar
                  </ButtonPrimary>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContainerCard>
      </Container>
    </>
  );
};

export default ConsultForm;
