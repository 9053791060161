import React from "react";
import ConsultForm from "../components/consultForm";
import PublicFooter from "../components/publicFooter";
import PublicHeader from "../components/publicHeader";
import { Colors } from "../static/colors";

const Consult = () => {
  return (
    <div style={{ position: "relative" }}>
      <PublicHeader color={Colors.GREEN} viewMenu={false} />
      <ConsultForm />
      <PublicFooter color={Colors.GREEN} />
    </div>
  );
};

export default Consult;
