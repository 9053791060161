import React, { useEffect, useState } from "react";
import { Typography, Input, IconButton, RadioGroup, FormControlLabel, Grid } from "@material-ui/core";
import { RTextField, RRadio } from "../../ratifikaElements";
import { DeleteTwoTone as DeleteIcon, Add } from "@material-ui/icons";
import { FormasPago, getCurrentDate, getCuoteNumber } from "../../../static/util";
import { getDate } from "date-fns";

interface Props {
  currency: string;
  total: number;
  cuotesChange: (cuotes: any[]) => void;
}


export const PaymentDetails: React.FC<Props> = ({ currency, total, cuotesChange }) => {
  const [formaPago, setFormaPago] = React.useState<string>(FormasPago.contado.label);
  const [montoPendiente, setMontoPendiente] = React.useState<number>(total);
  const [cuotes, setCuotes] = React.useState<any[]>([]);
  const [showAdd, setShowAdd] = React.useState<boolean>(false);
  const [rest, setRest] = React.useState<number>(0);
  //const [fecha, setFecha] = React.useState<Date>();

  useEffect(() => {
    setMontoPendiente(total);
  }, [total]);

  useEffect(() => {
    setCuotes([
      {
        cuota: "Cuota001",
        montoCuota: montoPendiente.toFixed(2),
        fechaCuota: getDate,


      },

    ]);

  }, [montoPendiente]);

  useEffect(() => {
    let r = getRest();
    setShowAdd(r > 0);
  }, [cuotes, montoPendiente]);

  useEffect(() => {
    if (cuotes.length) {
      let response: any = {};
      response["formaPago"] = formaPago;
      if (formaPago === FormasPago.credito.value) {
        response["pendientePago"] = montoPendiente.toFixed(2);
        response["cuotas"] = cuotes;
      }
      // emit cuote changes
      cuotesChange(response);
    }
  }, [cuotes, montoPendiente, formaPago]);

  const setCuoteValue = (index: number, value: number) => {
    let aux = JSON.parse(JSON.stringify(cuotes));
    aux[index].montoCuota = value.toFixed(2);
    let r = getRest();
    // validate max amount
    if (r >= 0) {
      setCuotes(aux);
      //console.log(aux);
    }
  };

  const setCuoteDate = (index: number, date: any) => {
    let aux = JSON.parse(JSON.stringify(cuotes));
    aux[index].fechaCuota = date;
    setCuotes(aux);
    //console.log(aux);
    //console.log(date);
  };

  const deleteCuote = (index: number) => {
    let aux = JSON.parse(JSON.stringify(cuotes));
    aux.splice(index, 1);
    setCuotes(aux);
    getRest();
  };

  const addCuote = () => {
    let aux = JSON.parse(JSON.stringify(cuotes));
    let rest = getRest();
    aux.push({
      cuota: getCuoteNumber(aux.length),
      montoCuota: rest > 0 ? rest.toFixed(2) : 0,
      fechaCuota: getCurrentDate(),
    });
    setCuotes(aux);
  };

  const getRest = () => {
    let aux = JSON.parse(JSON.stringify(cuotes));
    let r = montoPendiente;
    aux.forEach((cuote: any) => {
      r -= cuote.montoCuota;
    });
    setRest(r);
    return r;
  };

  return (
    <div style={{ marginTop: 10 }}>
      <Typography color="textSecondary" variant="caption">
        Forma de pago
      </Typography>
      <RadioGroup
        row
        value={formaPago}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFormaPago((event.target as HTMLInputElement).value);
        }}
        style={{ padding: 5 }}
      >
        <FormControlLabel
          value={FormasPago.contado.value}
          control={<RRadio color="primary" />}
          label={FormasPago.contado.label}
        />
        <FormControlLabel
          value={FormasPago.credito.value}
          control={<RRadio color="primary" />}
          label={FormasPago.credito.label}
        />
      </RadioGroup>
      {formaPago === FormasPago.credito.value ? (
        <div>
          <Typography color="textSecondary" variant="caption">
            Monto pendiente de pago ({currency})
          </Typography>
          <div className="clearfix" style={{ marginBottom: 10 }}></div>
          <Input
            autoComplete="off"
            type="number"
            style={{ clear: "both", marginRight: 10, maxWidth: 150 }}
            onChange={(e) => {
              let value = Number(e.target.value);
              if (value <= total) {
                setMontoPendiente(value);
              }
            }}
            value={montoPendiente}
            inputProps={{
              max: total,
            }}
          />
          <div className="clearfix" style={{ marginBottom: 15 }}></div>
          <Typography color="textSecondary" variant="caption">
            Cuotas de pago
          </Typography>
          {/* <div className="clearfix" style={{ marginBottom: 10 }}></div> */}
          {cuotes.length
            ? cuotes.map((cuote, index) => (
              <div
                key={cuote.cuota}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Grid container>

                  <Grid xs={12} sm={3} style={{ marginTop: "1rem" }}>

                    <Input
                      autoComplete="off"
                      type="number"
                      style={{ marginRight: 10 }}
                      onChange={(e) => {
                        let value = Number(e.target.value);
                        if (value <= total) {
                          setCuoteValue(index, value);
                        }
                      }}
                      value={cuote.montoCuota}
                      inputProps={{
                        max: total,
                      }}
                    />
                  </Grid>
                  <Grid xs={12} sm={9} style={{ marginTop: "1rem" }}>

                    <RTextField
                      id="date"
                      type="date"
                      defaultValue={cuote.fechaCuota}
                      InputLabelProps={{ shrink: true }}
                      style={{ marginRight: 10 }}
                      onChange={(e) => {
                        setCuoteDate(index, e.target.value);
                      }}
                    />
                    {(cuote.fechaCuota === "" || cuote.fechaCuota === undefined) ?
                      <Typography variant="body2"
                        color="error">
                        Seleccionar una fecha*
                      </Typography>
                      : ""
                    }
                  </Grid>
                </Grid>
                {cuotes.length > 1 ? (
                  <IconButton
                    style={{ width: 34 }}
                    size="small"
                    onClick={() => deleteCuote(index)}
                  >
                    <DeleteIcon fontSize="small" color="error" />
                  </IconButton>
                ) : null}
              </div>
            ))
            : null}
          {showAdd ? (
            <IconButton
              style={{ fontSize: 12, marginTop: 10 }}
              size="small"
              onClick={() => addCuote()}
            >
              <Add fontSize="small" color="inherit" /> Adicionar cuota
            </IconButton>
          ) : null}
          {showAdd ? (
            <div
              style={{
                color: "rgb(97, 26, 21)",
                fontSize: 12,
                background: "#FDECEA",
                padding: 10,
                borderRadius: 5,
                marginTop: 10,
              }}
            >
              Debe crear las cuotas necesarias para cubrir el{" "}
              <strong>
                monto pendiente ({currency} {rest.toFixed(2)})
              </strong>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
