import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { ButtonPrimary, ButtonSecondary } from "../button";
import { Colors } from "src/infrastructure/static/colors";

interface Props {
  open: boolean;
  title?: string;
  body?: string;
  acceptBtnText?: string;
  handleClose: () => void;
  handleAccept?: () => void;
  loading?: boolean;
}

const ConfirmDialog: React.FC<Props> = ({
  open,
  title,
  body,
  acceptBtnText,
  handleClose,
  handleAccept,
  loading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {body && (
        <DialogContent style={{ minWidth: 500 }}>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions style={{ padding: 20 }}>
        {handleAccept && (
          <ButtonPrimary
            background={Colors.GREEN}
            color={Colors.WHITE}
            onClick={handleAccept}
            size="14px"
            width="150px"
          >
            {loading ? (
              <CircularProgress size={14} />
            ) : (
              acceptBtnText || "Aceptar"
            )}
          </ButtonPrimary>
        )}
        <ButtonSecondary
          onClick={handleClose}
          border={Colors.GREEN}
          color={Colors.GREEN}
          size="14px"
          width="100px"
        >
          Cancelar
        </ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
