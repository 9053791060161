import React from "react";
import { Text } from "./styled";

export interface ITextStyleProps {
  bold: number;
  lineHeight?: number;
  color?: string;
  width?: number;
  letterSpacing?: number;
  position?: string;
  children: React.ReactNode;
  textAlign?: string;
  margin?: string;
  type?: string;
  size?: string;
  sizeMobile?: string;
}

const TextStyle: React.FC<ITextStyleProps> = ({
  bold,
  lineHeight,
  color,
  width,
  letterSpacing,
  position,
  children,
  textAlign,
  margin,
  type,
  size,
  sizeMobile,
}) => {
  return (
    <Text
      bold={bold}
      lineHeight={lineHeight}
      color={color}
      width={width}
      letterSpacing={letterSpacing}
      position={position}
      textAlign={textAlign}
      margin={margin}
      type={type}
      size={size}
      sizeMobile={sizeMobile}
    >
      {children}
    </Text>
  );
};

export default TextStyle;
