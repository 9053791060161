import {
  SET_UNADJUDICATED_DOCS,
  UnadjudicatedDocsActionTypes,
  UnadjudicatedDoc,
} from "../types/unadjudicatedDoc";

export function setUnadjudicatedDocs(payload: UnadjudicatedDoc[]): UnadjudicatedDocsActionTypes {
  return {
    type: SET_UNADJUDICATED_DOCS,
    payload,
  };
}
