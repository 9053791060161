import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import { Done as DoneIcon } from "@material-ui/icons";
import axios from "axios";
import documentSent from "../../imgs/documents.sent.png";
import { RButton, RButtonSecondary } from "../ratifikaElements";
import { fetch } from "../../store/fetch";
import { CompanyInfo } from "../../store/types/companies";
import MultiSelect from "react-select/creatable";
import { validateEmail } from "../../static/util";
import { Colors } from "../../static/colors";
import { ReceivedDoc } from "../../store/types/receivedDoc";

interface Props {
  open: boolean;
  document: ReceivedDoc;
  receiver: CompanyInfo;
  handleClose: () => void;
}

interface ErrorReport {
  title: string;
  message: string;
}

interface MultiSelectItem {
  label: string;
  value: string;
}
export const ResendDocumentEmail: React.FC<Props> = ({
  open,
  document,
  receiver,
  handleClose,
}) => {
  // const [availableEmails] = useState<Array<MultiSelectItem>>(
  //     document.receiver.email_address
  //       ? document.receiver.email_address.split(";").map((email) => {
  //         return { label: email, value: email };
  //       })
  //     : []
  // );
  const [selectedEmails, setSelectedEmails] = useState<Array<MultiSelectItem>>(
    []
  );
  const [errorMsgEmptyMail, setErrorMsgEmptyMail] = React.useState<
    string | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<ErrorReport | null>(null);
  const [copySent, setCopySent] = useState<boolean>(false);
  //   // consoles consoles consoles
  //   console.log("data", document)

  // console.log("here", selectedEmails)
  const handleMultiSelectChange = (emailList: any, action: any) => {
    if (action.action === "create-option") {
      if (!validateEmail(action.option.value)) {
        return;
      }
    }
    setSelectedEmails(emailList);
    if (errorMsgEmptyMail) setErrorMsgEmptyMail(null);
  };

  const sendDocumentCopy = async () => {
    if (selectedEmails.length === 0) {
      setErrorMsgEmptyMail(
        "Especifique al menos un destinatario para enviar la copia del documento"
      );
      return;
    } else {
      setLoading(true);
      let { data } = await axios.post("/api/reception/email/copy", {
        filename: document.file_name,
        id: document.id,
        emails: selectedEmails.map((e) => e.value),
      });
      setLoading(false);
      if (data.result) {
        setCopySent(true);
        fetch(["emission"]);
        setResponseError(null);
      } else {
        setResponseError({
          title: "Ha ocurrido un problema con el envío",
          message: data.msg,
        });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!copySent && (
        <DialogTitle id="alert-dialog-title">
          {`Enviar copia de documento: ${document.serie}-${document.number}`}
        </DialogTitle>
      )}
      <DialogContent style={{ minWidth: 500, minBlockSize: 150 }}>
        {copySent ? (
          <Slide direction="left" in={copySent}>
            <div>
              <Typography
                variant="h4"
                style={{
                  color: Colors.textOlive,
                  marginBottom: 20,
                }}
              >
                Copia enviada
              </Typography>
              {selectedEmails.length === 1 ? (
                <Typography
                  variant="body2"
                  style={{
                    color: Colors.textOlive,
                  }}
                >
                  Se ha enviado una copia del documento{" "}
                  <strong>
                    {document.serie}-{document.number}
                  </strong>{" "}
                  al destinatario: {selectedEmails[0].value}
                </Typography>
              ) : (
                <>
                  <Typography
                    variant="body2"
                    style={{
                      color: Colors.textOlive,
                    }}
                  >
                    Se ha enviado una copia del documento{" "}
                    <strong>
                      {document.serie}-{document.number}
                    </strong>{" "}
                    a los destinatarios:
                  </Typography>
                  <ul>
                    {selectedEmails.map((email) => (
                      <li
                        style={{
                          color: Colors.textOlive,
                          fontSize: "0.875rem",
                          lineHeight: 1.43,
                        }}
                      >
                        {email.value}
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <div style={{ textAlign: "center" }}>
                <img
                  src={documentSent}
                  style={{ height: 140 }}
                  alt="correo enviado"
                />
              </div>
            </div>
          </Slide>
        ) : (
          <div>
            {responseError && (
              <span style={{ color: "red" }}>
                {responseError.title} / {responseError.message}
              </span>
            )}
            {errorMsgEmptyMail && (
              <div style={{ marginBottom: 20 }}>
                <span style={{ color: "red" }}>{errorMsgEmptyMail}</span>
              </div>
            )}
            <DialogContentText id="alert-dialog-description">
              Destinatarios:
            </DialogContentText>
            <MultiSelect
              styles={{
                menu: (provided: any, state: any) => ({
                  ...provided,
                  width: state.selectProps.width,
                  marginTop: 1,
                }),
              }}
              placeholder="Agrega multiples destinatarios de email"
              isMulti
              // options={availableEmails}
              defaultValue={selectedEmails}
              value={selectedEmails}
              formatCreateLabel={(option) => (
                <div style={{ alignItems: "center", alignContent: "center" }}>
                  Agregar nuevo email: <strong>{option}</strong>{" "}
                  {validateEmail(option) && (
                    <DoneIcon
                      style={{ color: Colors.successful, fontSize: 14 }}
                    />
                  )}
                </div>
              )}
              noOptionsMessage={(option) =>
                "Agrega multiples destinatarios de email"
              }
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
              closeMenuOnSelect={true}
              onChange={handleMultiSelectChange}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "white",
                  primary: Colors.secundary,
                },
              })}
            />
            {/* {availableEmails.length > 0 && (
              <Typography
                style={{ marginTop: 5, fontStyle: "italic", fontSize: 10 }}
                color="textSecondary"
                variant="caption"
              >
                Para <strong>{document.receiver.legal_name}</strong>, se han enviado copias previas
                a: {availableEmails.map((e) => e.value).join(", ")}
              </Typography>
            )} */}
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        {!copySent && (
          <RButtonSecondary onClick={handleClose} color="primary">
            Cancelar
          </RButtonSecondary>
        )}

        <RButton
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={copySent ? handleClose : sendDocumentCopy}
        >
          {loading ? (
            <>
              <CircularProgress
                variant="indeterminate"
                size={14}
                style={{ marginRight: 5 }}
              />
              Enviando...
            </>
          ) : copySent ? (
            "Cerrar"
          ) : (
            "Enviar"
          )}
        </RButton>
      </DialogActions>
    </Dialog>
  );
};
