import { Configuration } from "../types/config";
import { ReceivedDoc } from "../types/receivedDoc";
import { UnadjudicatedDoc } from "../types/unadjudicatedDoc";
import { EmittedDoc } from "../types/emittedDoc";
import { CompanyInfo } from "../types/companies";
import { combineReducers } from "redux";
import configReducer from "./config";
import unadjudicatedDocsReducer from "./unadjudicatedDocs";
import emittedDocsReducer from "./emittedDocs";
import receivedDocsReducer from "./receivedDocs";
import companiesInfoReducer from "./companies";
import { Reporte } from "../types/report";

export interface AppState {
  config: Configuration;
  unadjudicatedDocs: UnadjudicatedDoc[];
  emittedDocs: EmittedDoc[];
  receivedDocs: ReceivedDoc[];
  companiesInfo: CompanyInfo[];
}

const reducers = combineReducers<AppState>({
  config: configReducer,
  unadjudicatedDocs: unadjudicatedDocsReducer,
  emittedDocs: emittedDocsReducer,
  receivedDocs: receivedDocsReducer,
  companiesInfo: companiesInfoReducer
});

export default reducers;
