import {
  Button,
  ButtonGroup,
  TextField,
  Input,
  Checkbox,
  Radio,
  Switch,
  FormControl,
  Step,
  Tab,
  Chip,
} from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "../../static/colors";

export const RTab = withStyles((theme: Theme) => ({
  root: {
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
    },
  },
}))(Tab);

export const RButton = withStyles((theme: Theme) => ({
  root: {
    color: "white",
    backgroundColor: Colors.primary,
    "&:hover": {
      backgroundColor: Colors.secundary,
    },
  },
}))(Button);

export const RButtonSecondary = withStyles((theme: Theme) => ({
  root: {
    color: Colors.secundary,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: Colors.primaryLigth,
    },
    "&.MuiButton-outlinedPrimary": {
      borderColor: Colors.primary,
    },
    "&.MuiButtonGroup-groupedOutlinedPrimary:hover": {
      borderColor: Colors.secundary,
    },
  },
}))(Button);

export const RButtonGroup = withStyles((theme: Theme) => ({
  root: {
    color: "white",
    backgroundColor: "transparent",
  },
}))(ButtonGroup);

export const RTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: Colors.secundary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: Colors.secundary,
    },
    "& .MuiOutlinedInput-root": {
      /* "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      }, */
      "&.Mui-focused fieldset": {
        borderColor: Colors.secundary,
      },
    },
  },
})(TextField);

export const RInput = withStyles({
  root: {
    "& .MuiInputBase-root .MuiInput-root .MuiInput-underline:after": {
      borderBottom: "2px solid #3f51b5",
    },
  },
})(Input);

export const RFormControl = withStyles({
  root: {
    "& label.Mui-focused": {
      color: Colors.secundary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: Colors.secundary,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: Colors.secundary,
      },
    },
  },
})(FormControl);

export const RCheckbox = withStyles({
  root: {
    "&$checked": {
      color: Colors.primary,
    },
  },
  checked: {},
})(Checkbox);

export const RRadio = withStyles({
  root: {
    "&$checked": {
      color: Colors.primary,
    },
  },
  checked: {},
})(Radio);

export const RSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: Colors.primary,
    },
    "&$checked + $track": {
      backgroundColor: Colors.primary,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const RStep = withStyles({
  root: {
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: Colors.primary,
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: Colors.secundary,
    },
  },
})(Step);

export const RChip = withStyles((theme: Theme) => ({
  root: {
    color: "white",
    backgroundColor: Colors.blueAlternative,
    "&:hover": {
      backgroundColor: Colors.blueAlternativeSecundary,
    },
    "&:focus": {
      backgroundColor: Colors.blueAlternative,
    },
  },
}))(Chip);
