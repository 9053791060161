import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { RButton, RButtonSecondary } from "../ratifikaElements";
import exceljs from "exceljs";
import fs from "file-saver";
import { timeout } from "../../static/util";
import axios from "axios";

interface Props {
  open: boolean;
  filterData: any;
  handleClose: () => void;
}

export const ExcelDialog: React.FC<Props> = ({ open, filterData, handleClose }) => {
  const [downloadingExcel, setDownloadingExcel] = React.useState<boolean>(false);
  const [downloadingPercent, setDownloadingPercent] = React.useState<number>(0);
  const [completedDownloadExcel, setCompletedDownloadExcel] = React.useState<boolean>(false);
  const [cancelDownloadExcel, setCancelDownloadExcel] = React.useState<boolean>(false);

  const downloadJsonReport = (iteration: number, totalIterations: number, batch: number) => {
    return new Promise(async (resolve, reject) => {
      if (cancelDownloadExcel) resolve([]);
      let { data } = await axios.get(
        `/api/emission/report/${filterData.workspace}?dateOf=${filterData.dateType}&startDate=${
          filterData.emissionStartDate
        }&endDate=${filterData.emissionEndDate}&startPage=${iteration * batch}&endPage=${
          (iteration + 1) * batch
        }${
          filterData.emittedFilterSerie
            ? `&serie=${filterData.emittedFilterSerie}&seqInit=${filterData.emittedFilterInitSeq}&seqEnd=${filterData.emittedFilterEndSeq}`
            : ""
        }${filterData.docType ? `&docType=${filterData.docType}` : ""}${
          filterData.docState
            ? `&docStatus=${
                filterData.docState.split(",").includes("1-3")
                  ? filterData.docState.replace("1-3", "1,3")
                  : filterData.docState
              }`
            : ""
        }`
      );
      //console.log("data", data);
      
      await timeout(1000 * iteration);
      const percent = Number(((iteration * 100) / totalIterations).toFixed());
      setDownloadingPercent(percent);
      resolve(data);
    });
  };

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    const batch = 500; // TODO: establecer batch
    let totalIterations = filterData.emittedPageTotal / batch;
    if (totalIterations < 1) totalIterations = 1;
    let threads = [];
    
    for (let i = 0; i < totalIterations; i++) {
      threads.push(downloadJsonReport(i, totalIterations, batch));
    }

    Promise.all(threads).then((resultRows: Array<any>) => {
      if (cancelDownloadExcel) {
        handleClose();
        return;
      }
      //console.log("resultRows",resultRows) 

      let rows: Array<any> = [];
      
      resultRows.forEach((result) => (rows = [...rows, ...result.rows]));
      //console.log(rows); // TODO: sort array by dates
      // Define columns
      const columns = [
        { header: "RUC EMISOR", key: "ruc_emisor", width: 13 },
        {
          header: "EMISOR",
          key: "nombre_emisor",
          width: rows[0] ? 40 /* rows[0].nombre_emisor.length * 1.2 */ : 32,
        },
        { header: "RUC CLIENTE", key: "ruc_cliente", width: 13 },
        { header: "CLIENTE", key: "nombre_cliente", width: 32 },
        { header: "TIPO DE DOCUMENTO", key: "tipo_documento", width: 20 },
        { header: "SERIE", key: "serie", width: 8 },
        { header: "CORRELATIVO", key: "correlativo", width: 14 },
        { header: "NOMBRE DE ARCHIVO", key: "nombre_archivo", width: 30 },
        { header: "ESTADO", key: "estado", width: 14 },
        { header: "ERROR SUNAT", key: "error_sunat", width: 14 },
        { header: "MONEDA", key: "moneda", width: 10 },
        { header: "SUBTOTAL", key: "subtotal", width: 10 },
        { header: "BASE IGV", key: "base_igv", width: 10 },
        { header: "IGV", key: "igv", width: 10 },
        { header: "BASE ISC", key: "base_isc", width: 10 },
        { header: "ISC", key: "total_isc", width: 10 },
        { header: "INAFECTO", key: "inafecto", width: 10 },
        { header: "EXONERADO", key: "exonerado", width: 10 },
        /* { header: "OTROS IMPUESTOS", key: "otros_impuestos", width: 10 }, */
        { header: "OTROS CARGOS", key: "otros_cargos", width: 10 },
        { header: "TOTAL", key: "total", width: 10 },
        { header: "FECHA EMISIÓN", key: "fecha_emision", width: 14 },
        { header: "FECHA VENCIMIENTO", key: "fecha_vencimiento", width: 20 },
        { header: "FECHA AUTORIZACIÓN", key: "fecha_autorizacion", width: 20 },
        { header: "FECHA SUBIDA", key: "fecha_subida", width: 20 },
        { header: "DOC REFERENCIA", key: "numero_ref", width: 15 },
      ];
      // Save excel file
      const workbook = new exceljs.Workbook();
      workbook.creator = "Ratifika";
      const worksheet = workbook.addWorksheet("Documentos enviados");
      worksheet.columns = columns;
      worksheet.addRows(rows);
      worksheet.getRow(1).fill = {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: "00000000" },
      };
      worksheet.getRow(1).font = { color: { argb: "FFFFFFFF" } };
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(
          blob,
          `docs_emitidos_${filterData.workspace}_${filterData.emissionStartDate}_${filterData.emissionEndDate}.xlsx`
        );
        setDownloadingExcel(false);
        setDownloadingPercent(0);
        setCompletedDownloadExcel(true);
      });
    });
  };

  const handleCancelDownloadEcel = () => {
    setCancelDownloadExcel(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Descarga de reporte excel</DialogTitle>

      <DialogContent style={{ minWidth: 500 }}>
        {!downloadingExcel && !completedDownloadExcel && (
          <DialogContentText id="alert-dialog-description">
            Ha seleccionado {filterData.emittedPageTotal} documentos para incluir en el reporte
            excel. El proceso pudiera tardar unos minutos.
          </DialogContentText>
        )}
        {downloadingExcel && (
          <div>
            <LinearProgress
              variant="buffer"
              value={downloadingPercent}
              valueBuffer={downloadingPercent + 10}
            />
            <DialogContentText id="alert-dialog-description">
              Descargando... {downloadingPercent}%
            </DialogContentText>
          </div>
        )}
        {completedDownloadExcel && (
          <DialogContentText id="alert-dialog-description">Descarga completada!</DialogContentText>
        )}
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        {/* TODO: cancelar descarga */}
        {!completedDownloadExcel && !downloadingExcel && (
          <RButtonSecondary
            onClick={downloadingExcel ? handleCancelDownloadEcel : handleClose}
            color="primary"
          >
            {cancelDownloadExcel ? "cancelando.." : "Cancelar"}
          </RButtonSecondary>
        )}
        {!downloadingExcel && (
          <RButton
            onClick={completedDownloadExcel ? handleClose : handleDownloadExcel}
            color="primary"
            variant="contained"
            autoFocus
          >
            {completedDownloadExcel ? "Cerrar" : "Descargar"}
          </RButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
