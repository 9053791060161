import React from "react";
import { DeleteForever } from "@material-ui/icons";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TablePagination,
  TableContainer,
  Paper,
} from "@material-ui/core";
import { NoteBox, NoteType } from "../noteBox";
import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getDocumentTypeDesc } from "src/infrastructure/static/util";
import { setUnadjudicatedDocs } from "src/domain/store/actions/unadjudicatedDocs";
import { UnadjudicatedDoc } from "src/domain/store/types/unadjudicatedDoc";

interface Column {
  id: "fecha" | "numero" | "tipo";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "fecha", label: "Fecha\u00a0emisión", minWidth: 80 },
  { id: "numero", label: "Número", minWidth: 150 },
  { id: "tipo", label: "Tipo", minWidth: 100 },
];

const DocItemList = (props: { doc: UnadjudicatedDoc }) => {
  const { doc } = props;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          {moment(doc.issue_date).calendar()}
        </TableCell>
        <TableCell align="left">{`${doc.serie}-${doc.number}`}</TableCell>
        <TableCell align="left">
          {getDocumentTypeDesc(doc.document_type)}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const UnadjudicatedDocs: React.FC<{}> = () => {
  const unadjudicatedDocs = useSelector(
    (state: any) => state.unadjudicatedDocs
  );
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchUnadjudicatedDocs = async () => {
    const { data } = await axios.get("/api/reception/unadjudicated");
    if (data.result) {
      dispatch(
        setUnadjudicatedDocs(data.unadjudicatedDocs as UnadjudicatedDoc[])
      );
    }
  };

  React.useEffect(() => {
    fetchUnadjudicatedDocs();
  }, []);

  return (
    <React.Fragment>
      {unadjudicatedDocs.length > 0 && (
        <div>
          <TableContainer
            component={Paper}
            style={{
              backgroundColor: "rgb(249 247 238)",
              borderStyle: "dashed",
              borderColor: "rgb(185 184 91)",
              borderWidth: "2px",
              marginTop: "1.5em",
            }}
          >
            {unadjudicatedDocs.length > 0 && (
              <NoteBox
                type={NoteType.Transparent}
                title="Documentos no adjudicados"
                body="Se han detectado documentos subidos desde su navegador, para poder adjudicárselos es
              necesario registrar y validar la empresa receptora."
              />
            )}
            <Table aria-label="collapsible table" /* size="small" */>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {unadjudicatedDocs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((doc: UnadjudicatedDoc) => {
                    return <DocItemList key={doc.id} doc={doc} />;
                  })}
              </TableBody>
            </Table>
            {unadjudicatedDocs.length > 5 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage="Filas por página"
                component="div"
                count={unadjudicatedDocs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        </div>
      )}
    </React.Fragment>
  );
};
