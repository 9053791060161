import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  InputLabel,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Input,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import {
  DeleteTwoTone as DeleteIcon,
  Send,
  ExpandMore,
  ExpandLess,
  CommentOutlined as NoteIcon,
  Done as DoneIcon,
} from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";
import { RTextField, RFormControl } from "../ratifikaElements";
import MultiSelect from "react-select/creatable";
import { NoteBox, NoteType } from "../noteBox";
import ConfirmDialog from "../confirmDialog";
import axios from "axios";
import { useSelector } from "react-redux";
import { CompanyInfo } from "../../../domain/store/types/companies";
import {
  ReceivedDoc,
  Product as OriginalProduct,
} from "../../../domain/store/types/receivedDoc";
import {
  getCurrencySymbol,
  numberWithCommas,
  NumeroALetras,
  parseDecimalNumber,
  incrementSequential,
  measureList,
  Measure,
  validateEmail,
  getDocumentTypeDesc,
  getUnidadMedida,
  getUnidadMedidaDescripcion,
  getCurrentDate,
  getAffectation,
} from "../../static/util";
import { Colors } from "../../static/colors";
import moment from "moment";
import { emissionService } from "src/domain/services/Emission.service";
import { ButtonPrimary, ButtonSecondary } from "../button";

interface Props {
  isOpen: boolean;
  refDocument: ReceivedDoc;
  close: () => void;
}

interface Product {
  code: string;
  measure: string;
  description: string;
  unit_price: number;
  unit_value: number;
  is_new?: boolean;
}

const igvPercent = 0.18;

interface ProductItem extends Product {
  count: number;
  price: number;
  value: number;
  measureSelected: Measure;
  taxType: string;
}

interface ErrorReport {
  title: string;
  message: string;
  details?: Array<string>;
}

interface MultiSelectItem {
  label: string;
  value: string;
}

const creditNoteReasons: Array<MultiSelectItem> = [
  { value: "01", label: "Intereses por mora" },
  { value: "02", label: "Aumento en el valor" },
  { value: "03", label: "Penalidades/ otros conceptos" },
  /*{ value: "11", label: "Ajustes de operaciones de exportación" },
  { value: "12", label: "Ajustes afectos al IVAP" },*/
];

export const NewDebitNoteReceivedModal: React.FC<Props> = ({
  isOpen,
  refDocument,
  close,
}) => {
  // Credit Note
  const [reason, setReason] = useState<string | null>(null);
  const [motives, setMotives] = useState<string | null>(null);
  const [hasReasonErrors, setHasReasonErrors] = useState<boolean>(false);
  const [hasMotivesErrors, setHasMotivesErrors] = useState<boolean>(false);
  // Buyer
  const [availableEmails, setAvailableEmails] = useState<
    Array<MultiSelectItem>
  >([]);
  const [selectedEmails, setSelectedEmails] = useState<Array<MultiSelectItem>>(
    []
  );

  // Products
  const [selectedItems, setSelectedItems] = useState<Array<ProductItem>>([]);
  const [disabledAll, setDisabledAll] = useState<boolean>(true);
  const [enableDescription, setEnableDescription] = useState<boolean>(false);
  const [enablePartial, setEnablePartial] = useState<boolean>(false);
  const [enableRemove, setEnableRemove] = useState<boolean>(false);
  const [notes, setNotes] = useState<Array<string>>([]);

  // Totals
  const [subtotal, setSubtotal] = useState<number>(0);
  const [operationsIGV, setOperationsIGV] = useState<number>(0);
  const [operationsINA, setOperationsINA] = useState<number>(0);
  const [operationsEXO, setOperationsEXO] = useState<number>(0);
  const [totalIGV, setTotalIGV] = useState<number>(0);
  const [totalToPay, setTotalToPay] = useState<number>(0);

  // Response
  const [responseError, setResponseError] = useState<ErrorReport | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [openAditional, setOpenAditional] = useState<boolean>(false);
  /*Consulta de datos Publicos */
  const [tipoCambio, setTipoCambio] = useState<number>(0);

  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const emitter: CompanyInfo = companies.find(
    (c: CompanyInfo) => c.ruc === config.workspace
  );

  const mapRefProducts = (
    itemsRemove: boolean = false,
    resetValues: boolean = false
  ) => {
    const refProducts = refDocument.products
      .sort((a, b) => a.line - b.line)
      .map((refProduct) => {
        let itemProduct: ProductItem = {
          code: refProduct.code,
          description: refProduct.description,
          unit_price: Number(!resetValues ? refProduct.unit_price : 0),
          unit_value: Number(!resetValues ? refProduct.unit_value : 0),
          count: Number(refProduct.quantity),
          price: Number(!resetValues ? refProduct.price : 0),
          value: Number(!resetValues ? refProduct.valor : 0),
          measure: refProduct.measure,
          measureSelected: {
            code: refProduct.measure,
            display: getUnidadMedida(refProduct.measure),
            description: getUnidadMedidaDescripcion(refProduct.measure),
          },
          taxType: getOriginalTaxType(refProduct),
        };
        return itemProduct;
      });

    setSelectedItems(refProducts);
    updateTotals(refProducts);
    if (itemsRemove && selectedItems.length > 1) {
      setEnableRemove(true);
    }
  };

  useEffect(() => {
    async function fetchEmailRecords() {
      const { data } = await axios.get<string>(
        `/api/contributor/emailRecords/${refDocument.emitter?refDocument.emitter.number:refDocument.emitter_company?refDocument.emitter_company.number:""}`
      );
      if (data) {
        setAvailableEmails(
          data.split(";").map((email) => {
            return { label: email, value: email };
          })
        );
      }
    }
    mapRefProducts();
    if (refDocument.emitter && refDocument.emitter.number !== "11111111") {
      // Se busca los emails solo si se especifico el receptor
      fetchEmailRecords();
    }
    if (refDocument.emitter_company && refDocument.emitter_company.number !== "11111111") {
      // Se busca los emails solo si se especifico el receptor
      fetchEmailRecords();
    }
    async function fetchTipoCambio() {
      const { data } = await axios.get(
        `/api/tipoCambio/day?fecha=${getCurrentDate()}`
      );
      setTipoCambio(data.sell);
    }
    fetchTipoCambio();
  }, []);

  /* Credit Note */
  const handleReasonChange = (event: React.ChangeEvent<{ value: any }>) => {
    setReason(event.target.value);
    if (hasReasonErrors) {
      setHasReasonErrors(false);
    }

    switch (event.target.value) {
      case "01": // "Descuento global"
        let intereses: ProductItem = {
          code: "",
          description: "Intereses por mora",
          unit_price: 0,
          unit_value: 0,
          count: 1,
          price: 0,
          value: 0,
          measure: "NIU",
          measureSelected: {
            code: "NIU",
            display: "UND",
            description: "Unidades",
          },
          taxType: "IGV",
        };
        setSelectedItems([intereses]);
        updateTotals([intereses]);
        setDisabledAll(false);
        setEnableDescription(false);
        break;

      case "02":
        mapRefProducts(true, true);
        setDisabledAll(true);
        setEnablePartial(true);
        setEnableDescription(false);
        break;

      case "03":
        let penalidad: ProductItem = {
          code: "",
          description: "Penalidades/ otros conceptos",
          unit_price: 0,
          unit_value: 0,
          count: 1,
          price: 0,
          value: 0,
          measure: "NIU",
          measureSelected: {
            code: "NIU",
            display: "UND",
            description: "Unidades",
          },
          taxType: "IGV",
        };
        setSelectedItems([penalidad]);
        updateTotals([penalidad]);
        setDisabledAll(false);
        setEnableDescription(false);
        break;

      default: // restablecer los productos originales
        mapRefProducts();
        setDisabledAll(true);
        setEnableDescription(false);
        setEnablePartial(false);
        break;
    }
  };

  const deleteItemClick = (index: any) => {
    if (selectedItems.length > 1) {
      let newArr = [...selectedItems];
      newArr.splice(index, 1);
      setSelectedItems(newArr);
      updateTotals(newArr);
    } else {
      setEnableRemove(false);
    }
  };

  /* Buyer info */
  const handleMultiSelectChange = (emailList: any, action: any) => {
    if (action.action === "create-option") {
      if (!validateEmail(action.option.value)) {
        return;
      }
    }
    setSelectedEmails(emailList);
  };

  /* Products */
  const getOriginalTaxType = (product: OriginalProduct) => {
    if (Number(product.igv_value) > 0) return "IGV";
    else if (product.other_taxes) {
      const other = JSON.parse(product.other_taxes)[0];
      if (other.tax === "30") return "INA";
      if (other.tax === "20") return "EXO";
      if (other.tax === "40") return "EXO";
    }
    return "IGV";
  };

  const updateTotals = (items: Array<ProductItem>) => {
    const calSubtotal = Number(
      items.reduce((n, { price }) => n + price, 0).toFixed(2)
    );
    setSubtotal(calSubtotal);
    const totalOpeIGV = Number(
      items
        .filter((i) => i.taxType === "IGV")
        .reduce((n, { price }) => n + price, 0)
        .toFixed(2)
    );
    const totalOpeINA = Number(
      items
        .filter((i) => i.taxType === "INA")
        .reduce((n, { price }) => n + price, 0)
        .toFixed(2)
    );
    const totalOpeEXO = Number(
      items
        .filter((i) => i.taxType === "EXO")
        .reduce((n, { price }) => n + price, 0)
        .toFixed(2)
    );
    const calTotalIGV = Number((totalOpeIGV * igvPercent).toFixed(2));
    setOperationsIGV(totalOpeIGV);
    setOperationsINA(totalOpeINA);
    setOperationsEXO(totalOpeEXO);
    setTotalIGV(calTotalIGV);
    setTotalToPay(Number(Number(calSubtotal + calTotalIGV).toFixed(2)));
  };

  const updateItemCountChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.target.value) < 0) return;
      let newArr = [...selectedItems];
      newArr[index].count = Number(e.target.value);
      newArr[index].price = Number(
        (newArr[index].unit_price * Number(e.target.value)).toFixed(2)
      );
      setSelectedItems(newArr);
      updateTotals(newArr);
    };

  const updateItemUnitPriceChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.target.value) < 0) return;
      let newArr = [...selectedItems];
      newArr[index].unit_price = Number(e.target.value);
      newArr[index].price = Number(
        (newArr[index].count * Number(e.target.value)).toFixed(2)
      );
      setSelectedItems(newArr);
      updateTotals(newArr);
    };

  const updateItemCodeChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].code = e.target.value;
      setSelectedItems(newArr);
    };

  const updateItemDescriptionChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].description = e.target.value;
      setSelectedItems(newArr);
    };

  const updateItemTaxChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...selectedItems];
    newArr[index].taxType = e.target.value as string;
    setSelectedItems(newArr);
    updateTotals(newArr);
  };

  /* End products */

  /* Notes */

  const deleteItemNoteClick = (index: any) => {
    let newArr = [...notes];
    newArr.splice(index, 1);
    setNotes(newArr);
  };

  const updateItemNoteChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...notes];
      newArr[index] = e.target.value;
      setNotes(newArr);
    };

  /* Procesamiento de la Nota de Débito */

  const generateJsonCreditNote = (serie: string, sequential: any) => {
    let taxesList: any = [];
    if (operationsIGV > 0) {
      taxesList = [
        ...taxesList,
        {
          codigoTributo: "1000",
          monto: totalIGV.toFixed(2),
          base: operationsIGV.toFixed(2),
        },
      ];
    }
    if (operationsINA > 0) {
      taxesList = [
        ...taxesList,
        {
          codigoTributo: "9998",
          monto: "0.00",
          base: operationsINA.toFixed(2),
        },
      ];
    }
    if (operationsEXO > 0) {
      taxesList = [
        ...taxesList,
        {
          codigoTributo: "9997",
          monto: "0.00",
          base: operationsEXO.toFixed(2),
        },
      ];
    }

    let tipo_cambio = null;
    if (refDocument.currency === "USD") {
      tipo_cambio = tipoCambio.toFixed(2);
    }
    return {
      ubl: "2.1",
      tipoComprobante: "08",
      serie: `${refDocument.document_type === "03" ? "B" : "F"}${serie}`,
      numeroComprobante: `${
        refDocument.document_type === "03"
          ? incrementSequential(sequential.B)
          : incrementSequential(sequential.F)
      }`,
      actualizarSecuencial: true,
      tipoNota: reason,
      sustentoNota: motives,
      tipoComprobanteAfectado: refDocument.document_type,
      serieComprobanteAfectado: refDocument.serie,
      numeroComprobanteAfectado: refDocument.number,
      fechaComprobanteAfectado: refDocument.issue_date,
      rucEmisor:  refDocument.emitter ?  refDocument.emitter.number : refDocument.emitter_company ? refDocument.emitter_company.number : "",
      fechaEmision: moment().format("YYYY-MM-DD"),
      horaEmision: moment().format("HH:mm:ss"),
      montoLetras: NumeroALetras(totalToPay),
      notas: notes
        .filter((n) => n.trim() !== "")
        .map((n) => {
          return { valor: n };
        }),
      moneda: refDocument.currency,
      tipoCambio: tipo_cambio,
      emisor: {
        numeroDocumento: emitter.ruc,
        tipoDocumento: "6",
        nombre: emitter.legal_name,
        direccion: {
          codigo: "0000",
          ubigeo: emitter.ubigeo,
          provincia: emitter.province,
          departament: emitter.department,
          distrito: emitter.district,
          direccionCompleta: emitter.address_line,
        },
      },
      receptor: {
        numeroDocumento: refDocument.emitter ? refDocument.emitter.number 
          : refDocument.emitter_company ? refDocument.emitter_company.number : "",
        tipoDocumento: refDocument.emitter ? refDocument.emitter.number_type
          : refDocument.emitter_company ? "6" : "",
        nombre: refDocument.emitter ? refDocument.emitter.legal_name
          : refDocument.emitter_company ? refDocument.emitter_company.legal_name : "",
        correo: selectedEmails.map((email) => email.value),
        direccion: {
          direccionCompleta: refDocument.emitter && refDocument.emitter.addresses ? refDocument.emitter.addresses[0].address_line: 
            refDocument.emitter_company ? refDocument.emitter_company.address_line : "",
          pais: "PE",
        },
      },
      totales: {
        totalVenta: totalToPay.toFixed(2),
        total: totalToPay.toFixed(2),
        subTotal: subtotal.toFixed(2),
        totalImpuestos: totalIGV.toFixed(2),
      },
      impuestos: taxesList,
      items: selectedItems.map((item, index) => {
        let itemTaxValue =
          item.taxType === "IGV"
            ? (item.price * igvPercent).toFixed(2)
            : "0.00";
        let itemUnitTaxValue =
          item.taxType === "IGV"
            ? (item.unit_price * igvPercent).toFixed(2)
            : "0.00";
        return {
          numeroItem: (index + 1).toString(),
          descripcion: item.description,
          codigo: item.code || "",
          unidadMedida: item.measureSelected.code,
          cantidad: item.count.toString(),
          precio: item.price.toFixed(2),
          valor: item.value.toFixed(2),
          precioReferencia: (
            Number(itemUnitTaxValue) + item.unit_price
          ).toFixed(2),
          precioUnitario: parseDecimalNumber(item.unit_price.toString()),
          valorUnitario: parseDecimalNumber(item.unit_value.toString()),
          totalImpuestos: itemTaxValue,
          impuestos: [
            {
              monto: itemTaxValue,
              base: item.price.toFixed(2),
              porcentaje:
                item.taxType === "IGV" ? (igvPercent * 100).toFixed(2) : "0.00",
              tipoAfectacion: getAffectation(item.taxType),
            },
          ],
        };
      }),
    };
  };

  const isValidateCreditNote = () => {
    let generalErros: Array<string> = [];
    let isReadyToSend = true;

    if (reason === null) {
      setHasReasonErrors(true);
      isReadyToSend = false;
    }
    if (motives === null || motives.trim() === "") {
      setHasMotivesErrors(true);
      isReadyToSend = false;
    }

    if (totalToPay === 0) {
      generalErros = [
        ...generalErros,
        "La Nota de Débito debe tener un valor mayor que cero",
      ];
    } else if (selectedItems.filter((item) => item.price === 0).length > 0) {
      generalErros = [
        ...generalErros,
        "La Nota de Débito contiene items con valor en cero",
      ];
    }

    if (generalErros.length > 0) {
      setResponseError({
        title: "Parece que faltan algunos detalles",
        message:
          "La Nota de Débito no se ha enviado aún, revisa qué está faltando:",
        details: generalErros,
      });
      isReadyToSend = false;
    } else if (responseError) {
      setResponseError(null);
    }

    return isReadyToSend;
  };

  const sendCreditNoteClick = async () => {
    if (isValidateCreditNote()) {
      setOpenConfirmDialog(true);
    }
  };

  const sendCreditNote = async () => {
    setOpenConfirmDialog(false);
    setLoading(true);

    const responseSequential = await axios.get(
      `/api/company/lastSequential/${emitter.ruc}/08`
    );
    if (!responseSequential.data.result) {
      setResponseError({
        title: "Ha ocurrido un problema",
        message: responseSequential.data.msg,
      });
      return;
    }
    //add new 17/01/2022
    const sequentialJson = JSON.parse(responseSequential.data.sequential);

    const json = generateJsonCreditNote(
      responseSequential.data.serie,
      sequentialJson
    );

    //console.log("json", json);

    let { data } = await axios.post("/api/emission/send", json);
    setLoading(false);
    if (data.procesado) {
      close();
      setTimeout(
        async () => await emissionService.getEmissionDocuments(),
        1000
      );
      // Otro dialog
    } else {
      setResponseError({
        title: "Ha ocurrido un problema con su Nota de Débito",
        message: data.mensaje,
      });
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        scroll={"body"}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>
          {"Nota de Débito"}
          <span style={{ fontSize: 15, marginLeft: 15 }}>
            / emitida por: {emitter.legal_name}
          </span>
          {responseError && (
            <Grid item xs={12} md={8} sm={10}>
              <NoteBox
                type={NoteType.Error}
                title={responseError.title}
                body={responseError.message}
                details={responseError.details}
              />
            </Grid>
          )}
        </DialogTitle>
        <DialogContent
          dividers={false}
          style={{ paddingRight: 10, paddingLeft: 10, minHeight: 600 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sm={7}>
              <div
                className="invoice-info-container"
                style={{ marginBottom: 10 }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                  color="textSecondary"
                  variant="caption"
                >
                  Documento afectado
                </Typography>
                <Grid container spacing={2} style={{ marginBottom: 15 }}>
                  <Grid item xs={6} md={6} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Número
                    </Typography>
                    <Typography variant="subtitle1">{`${refDocument.serie}-${refDocument.number}`}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Tipo de documento:
                    </Typography>
                    <Typography variant="subtitle1">
                      {getDocumentTypeDesc(refDocument.document_type)}
                    </Typography>
                  </Grid>
                </Grid>
                <RFormControl variant="outlined">
                  <InputLabel
                    id="district-select-label"
                    style={{ marginTop: -5 }}
                  >
                    Tipo de Nota *
                  </InputLabel>
                  <Select
                    labelId="district-select-label"
                    value={reason}
                    onChange={handleReasonChange}
                    label="Tipo de Nota"
                    style={{ height: 45, width: "60%" }}
                    error={hasReasonErrors}
                  >
                    {creditNoteReasons.map((reason: MultiSelectItem) => (
                      <MenuItem key={reason.value} value={reason.value}>
                        {reason.label}
                      </MenuItem>
                    ))}
                  </Select>
                </RFormControl>

                <RTextField
                  id="reasons"
                  label="Motivo o sustento de emisión"
                  variant="outlined"
                  required
                  autoComplete="off"
                  multiline
                  rows={2}
                  style={{ marginTop: 20, width: "60%" }}
                  helperText={
                    hasMotivesErrors
                      ? "Especifique los motivos de emisión de la Nota de Débito"
                      : ""
                  }
                  error={hasMotivesErrors}
                  value={motives}
                  onChange={(e) => {
                    setMotives(e.target.value);
                    if (hasMotivesErrors && e.target.value !== "") {
                      setHasMotivesErrors(false);
                    }
                  }}
                />
              </div>
              <div
                className="invoice-info-container"
                style={{ backgroundColor: "whitesmoke" }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                  color="textSecondary"
                  variant="caption"
                >
                  Datos del proveedor
                </Typography>
                <Grid container spacing={2} style={{ marginBottom: 1 }}>
                  <Grid item xs={6} md={6} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Nombre legal
                    </Typography>
                    <Typography variant="subtitle1">
                      {
                        refDocument.emitter
                        ? refDocument.emitter.legal_name
                        : refDocument.emitter_company
                        ? refDocument.emitter_company.legal_name
                        : ""
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {refDocument.emitter? refDocument.emitter.number_type === "6"
                        ? "RUC"
                        : "Identificación":
                        refDocument.emitter_company ? "RUC" : ""
                      }
                    </Typography>
                    <Typography variant="subtitle1">
                      {
                        refDocument.emitter
                        ? refDocument.emitter.number
                        : refDocument.emitter_company
                        ? refDocument.emitter_company.number
                        : ""
                      }
                    </Typography>
                  </Grid>
                </Grid>

                <Typography variant="subtitle2" color="textSecondary">
                  Dirección
                </Typography>
                <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
                  {refDocument.emitter && refDocument.emitter.addresses ? refDocument.emitter.addresses[0].address_line: 
                  refDocument.emitter_company ? refDocument.emitter_company.address_line : ""}
                </Typography>

                <Typography
                  style={{ marginBottom: 5 }}
                  color="textSecondary"
                  variant="caption"
                >
                  Destinatarios:
                </Typography>
                <MultiSelect
                  styles={{
                    menu: (provided: any, state: any) => ({
                      ...provided,
                      width: state.selectProps.width,
                      marginTop: 1,
                    }),
                  }}
                  placeholder="Agrega multiples destinatarios de email"
                  isMulti
                  options={availableEmails}
                  defaultValue={selectedEmails}
                  value={selectedEmails}
                  formatCreateLabel={(option) => (
                    <div
                      style={{ alignItems: "center", alignContent: "center" }}
                    >
                      Agregar nuevo email: <strong>{option}</strong>{" "}
                      {validateEmail(option) && (
                        <DoneIcon
                          style={{ color: Colors.successful, fontSize: 14 }}
                        />
                      )}
                    </div>
                  )}
                  noOptionsMessage={(option) =>
                    "Agrega multiples destinatarios de email"
                  }
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  onChange={handleMultiSelectChange}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "white",
                      primary: Colors.secundary,
                    },
                  })}
                />
                {availableEmails.length > 0 && (
                  <Typography
                    style={{ marginTop: 5, fontStyle: "italic", fontSize: 10 }}
                    color="textSecondary"
                    variant="caption"
                  >
                    Para <strong>{refDocument.emitter?refDocument.emitter.legal_name:refDocument.emitter_company?
                    refDocument.emitter_company.legal_name:""}</strong>, se
                    han enviado copias previas a:{" "}
                    {availableEmails.map((e) => e.value).join(", ")}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={1} md={2} sm={1} />
            <Grid item xs={12} md={4} sm={4} style={{ paddingRight: 30 }}>
              <Typography color="textSecondary" variant="caption">
                Totales
              </Typography>
              <table style={{ marginTop: 20 }} className="table table-clear">
                <tbody>
                  {(operationsINA > 0 || operationsEXO > 0) && (
                    <tr>
                      <td className="left">
                        <strong>Total Gravado</strong>
                      </td>
                      <td className="right">{`${getCurrencySymbol(
                        refDocument.currency
                      )} ${numberWithCommas(operationsIGV)}`}</td>
                    </tr>
                  )}
                  {operationsINA > 0 && (
                    <tr>
                      <td className="left">
                        <strong>Total Inafecto</strong>
                      </td>
                      <td className="right">{`${getCurrencySymbol(
                        refDocument.currency
                      )} ${numberWithCommas(operationsINA)}`}</td>
                    </tr>
                  )}
                  {operationsEXO > 0 && (
                    <tr>
                      <td className="left">
                        <strong>Total Exonerado</strong>
                      </td>
                      <td className="right">{`${getCurrencySymbol(
                        refDocument.currency
                      )} ${numberWithCommas(operationsEXO)}`}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="left">
                      <strong>Subtotal</strong>
                    </td>
                    <td className="right">{`${getCurrencySymbol(
                      refDocument.currency
                    )} ${numberWithCommas(subtotal)}`}</td>
                  </tr>
                  <tr>
                    <td className="left">
                      <strong>IGV</strong>
                    </td>
                    <td className="right">{`${getCurrencySymbol(
                      refDocument.currency
                    )} ${numberWithCommas(totalIGV)}`}</td>
                  </tr>
                  <tr>
                    <td className="left">
                      <strong>Total</strong>
                    </td>
                    <td className="right">
                      <strong>{`${getCurrencySymbol(
                        refDocument.currency
                      )} ${numberWithCommas(totalToPay)}`}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            {/* Items */}
            <Grid item xs={12} md={12} sm={12}>
              <div className="invoice-info-container">
                <Typography
                  style={{ marginBottom: 20 }}
                  color="textSecondary"
                  variant="caption"
                >
                  Items
                </Typography>
                {selectedItems.length > 0 && (
                  <Table
                    size="small"
                    aria-label="purchases"
                    style={{ marginTop: 30, marginBottom: 20 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Cantidad
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Código
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Descripción
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Valor unitario
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Afectación
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="right">
                          Precio
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedItems.map((product, index) => (
                        <TableRow
                          key={index}
                          style={{
                            borderColor: "transparent",
                            borderStyle: "solid",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ minWidth: 40, maxWidth: 40 }}
                          >
                            <div style={{ display: "flex" }}>
                              {enableRemove ? (
                                <Tooltip title="Remover Item" arrow>
                                  <IconButton
                                    style={{ marginRight: 10, width: 34 }}
                                    size="small"
                                    onClick={() => deleteItemClick(index)}
                                  >
                                    <DeleteIcon
                                      fontSize="small"
                                      color="error"
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : null}

                              <Input
                                autoComplete="off"
                                value={product.count}
                                onChange={updateItemCountChanged(index)}
                                type="number"
                                style={{ marginRight: 10, minWidth: 40 }}
                                disabled={disabledAll}
                              />
                              <Select
                                value={product.measureSelected.code}
                                disabled={true}
                              >
                                {measureList.map((m) => (
                                  <MenuItem key={m.code} value={m.code}>
                                    <Tooltip title={m.description}>
                                      <span>{m.display}</span>
                                    </Tooltip>
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </TableCell>
                          <TableCell style={{ minWidth: 50, maxWidth: 30 }}>
                            <Input
                              style={{ width: "100%" }}
                              autoComplete="off"
                              value={product.code}
                              onChange={updateItemCodeChanged(index)}
                              disabled={!enableDescription}
                            />
                          </TableCell>
                          <TableCell style={{ minWidth: 130, maxWidth: 500 }}>
                            <Input
                              style={{ width: "100%" }}
                              autoComplete="off"
                              error={product.description === ""}
                              value={product.description}
                              onChange={updateItemDescriptionChanged(index)}
                              disabled={!enableDescription}
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ minWidth: 40, maxWidth: 20 }}
                          >
                            <Input
                              style={{ width: "100%" }}
                              autoComplete="off"
                              onChange={updateItemUnitPriceChanged(index)}
                              value={product.unit_price}
                              type="number"
                              disabled={disabledAll && !enablePartial}
                            />
                          </TableCell>
                          <TableCell style={{ minWidth: 40, maxWidth: 40 }}>
                            <Select
                              value={product.taxType}
                              onChange={(e) => updateItemTaxChanged(index, e)}
                              disabled={disabledAll && !enablePartial}
                              style={{ width: "100%" }}
                            >
                              <MenuItem value="IGV">Gravado</MenuItem>
                              <MenuItem value="INA">Inafecto</MenuItem>
                              <MenuItem value="EXO">Exonerado</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ minWidth: 40, maxWidth: 40, fontSize: 16 }}
                          >
                            {numberWithCommas(product.price)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            </Grid>
            {/* Informacion adicional */}
            <Grid item xs={12} md={6} sm={6}>
              <Accordion
                className="aditional-info-container"
                expanded={openAditional}
                onChange={() => setOpenAditional(!openAditional)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  {!openAditional ? (
                    <ExpandMore fontSize="small" color="action" />
                  ) : (
                    <ExpandLess fontSize="small" color="action" />
                  )}

                  <Typography color="textSecondary" variant="caption">
                    Información adicional
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ width: "100%" }}>
                    <Button
                      variant="contained"
                      color="default"
                      disableElevation
                      onClick={() => setNotes([...notes, ""])}
                    >
                      <NoteIcon fontSize="small" style={{ marginRight: 5 }} />{" "}
                      Agregar nota
                    </Button>
                    {notes.map((note, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", marginTop: 20 }}
                      >
                        <Tooltip title="Remover Nota">
                          <IconButton
                            style={{ marginRight: 10, width: 40 }}
                            size="small"
                            onClick={() => deleteItemNoteClick(index)}
                          >
                            <DeleteIcon fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                        <RTextField
                          variant="outlined"
                          autoFocus
                          autoComplete="off"
                          size="small"
                          style={{ width: "100%" }}
                          onChange={updateItemNoteChanged(index)}
                          value={note}
                        />
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <ButtonSecondary
            border={Colors.GREEN}
            color={Colors.GREEN}
            onClick={close}
            size="14px"
            disabled={loading}
            width="100px"
          >
            Cancelar
          </ButtonSecondary>
          <ButtonPrimary
            background={Colors.GREEN}
            color={Colors.WHITE}
            disabled={loading}
            onClick={sendCreditNoteClick}
            size="14px"
            width="100px"
          >
            {loading ? (
              <>
                <CircularProgress
                  variant="indeterminate"
                  size={14}
                  style={{ marginRight: 5 }}
                />
                Enviando...
              </>
            ) : (
              <>
                <Send fontSize="small" style={{ marginRight: 5 }} />
                Enviar
              </>
            )}
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title="¿Ha completado su Nota de Débito?"
        body="Se enviará el documento a la SUNAT"
        acceptBtnText="Confirmar"
        open={openConfirmDialog}
        handleClose={() => setOpenConfirmDialog(false)}
        handleAccept={sendCreditNote}
      />
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
