import React from "react";
import Header from "../components/header";
import NewInvoice from "../components/newInvoice";

const NewInvoicePage = () => {
  return (
    <div>
      <Header />
      <NewInvoice />
    </div>
  );
};

export default NewInvoicePage;
