import React, { useEffect } from "react";
import { Typography, InputAdornment, FormControlLabel, FormGroup, Grid } from "@material-ui/core";
import { RTextField, RSwitch } from "../ratifikaElements";
import Autosuggest from "react-autosuggest";
import { detractionList, Detraction } from "../../static/util";

interface Props {
  currency: string;
  total: number;
  detractionAcctNumber: string;
  updateDetraction: (detraction: any) => void;
  tipoCambio: number;
}

export const DetractionDetails: React.FC<Props> = ({
  currency,
  total,
  detractionAcctNumber,
  updateDetraction,
  tipoCambio
}) => {
  const [enableDetraction, setEnableDetraction] = React.useState<boolean>(false);
  const [availableDetractions] = React.useState<Array<Detraction>>(detractionList);
  const [autocompleteDetraction, setAutocompleteDetraction] = React.useState<Array<Detraction>>([]);
  const [detractionKey, setDetractionKey] = React.useState<string>("");
  const [selectedCode, setSelectedCode] = React.useState<string>("");
  const [detractionPercent, setDetractionPercent] = React.useState<number>(0);
  const [detractionTotal, setDetractionTotal] = React.useState<number>(0);
  const [detractionTotalUSD, setDetractionTotalUSD] = React.useState<number>(0);
  const [baseTotal, setBaseTotal] = React.useState<number>(total);

 
  
  useEffect(() => {
   
    setBaseTotal(total);
    if (selectedCode !== "") {
      let totalDetraction = (total * (detractionPercent / 100)).toFixed(2);
      let totalDetractionUSD

      if(currency==="$"){
        totalDetractionUSD=(total * (detractionPercent / 100) *tipoCambio).toFixed(2)
        setDetractionTotalUSD(Number(totalDetractionUSD));

      }
      else if(currency==="S/"){
        totalDetractionUSD=totalDetraction
        setDetractionTotal(Number(totalDetraction));
      }
     
      updateDetraction({
        code: selectedCode,
        percent: detractionPercent,
        total: totalDetraction,
        total_USD:totalDetractionUSD
      });
    }

  }, [currency,total]);

 

  const onSwitchChange = () => {
    if (enableDetraction) {
      updateDetraction(null);
    } else if (selectedCode !== "") {
      
      
      updateDetraction({
        code: selectedCode,
        percent: detractionPercent,
        total: detractionTotal,
        total_USD:detractionTotalUSD
      });
    }
    setEnableDetraction(!enableDetraction);
  };

  const onSuggestionsFetchRequested = async (param: any) => {
    setAutocompleteDetraction(await getDetractionSuggestions(param.value));
  };

  const getDetractionSuggestions = async (value: string): Promise<Detraction[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results = [];
    results =
      inputLength === 0
        ? []
        : availableDetractions
            .filter((dtr) => dtr.description.toLowerCase().includes(inputValue.toLowerCase()))
            .sort((a, b) => {
              return a.description.indexOf(inputValue) - b.description.indexOf(inputValue);
            });

    return results;
  };

  const onSuggestionsClearRequested = () => {
    setAutocompleteDetraction([]);
  };

  const getSuggestionValue = (suggestion: Detraction) => {
    setDetractionPercent(suggestion.percent);
    const totalDetraction = (baseTotal * (suggestion.percent / 100)).toFixed(2);
    setDetractionTotal(Number(totalDetraction));
      
    ///detraccion en dolares
    const totalDetractionUSD=((baseTotal * (suggestion.percent / 100)) *tipoCambio).toFixed(2);
    setDetractionTotalUSD(Number(totalDetractionUSD));
    ///
    setSelectedCode(suggestion.code);
    updateDetraction({
      code: suggestion.code,
      percent: suggestion.percent,
      total: totalDetraction,
      total_USD:totalDetractionUSD
    });
    return suggestion.description;
  };

  const renderSuggestion = (suggestion: Detraction, { query }: any) => {
    const suggestionText = suggestion.description;
    const indexOfSuggestion = suggestionText.toLowerCase().indexOf(query.toLowerCase());
    const substring = suggestionText.substring(indexOfSuggestion, indexOfSuggestion + query.length);
    const highlight = suggestionText.replace(substring, `<strong>${substring}</strong>`);
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
      </div>
    );
  };

  

  return (
    <div style={{ marginTop: 10 }}>
      <Typography color="textSecondary" variant="caption">
        Detracciones
      </Typography>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="end"
          control={<RSwitch checked={enableDetraction} onChange={onSwitchChange} />}
          label="Operación Sujeta al SPOT"
          labelPlacement="end"
        />
      </FormGroup>
      {enableDetraction ? (
        <div style={{ marginBottom: 25 }}>
          <div className="clearfix" style={{ marginBottom: 20 }}></div>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid item xs={12} md={4} sm={6} style={{ display: "flex", flexDirection: "column" }}>
              <Autosuggest
                suggestions={autocompleteDetraction}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                  placeholder: "Bienes y servicios sujetos a detracciones",
                  value: detractionKey,
                  onChange: (
                    event: React.FormEvent<any>,
                    { newValue, method }: Autosuggest.ChangeEvent
                  ) => {
                    setDetractionKey(newValue);
                  },
                  ref: React.createRef<HTMLInputElement>(),
                }}
              />
            </Grid>
            <Grid item>
              <RTextField
                variant="outlined"
                size="small"
                autoComplete="off"
                type="number"
                label="Porcentaje detracción"
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                value={detractionPercent}
                style={{ width: 170 }}
                onChange={(e) => {
                  setDetractionPercent(Number(e.target.value));
                  setDetractionTotal(
                    Number((baseTotal * (Number(e.target.value) / 100)).toFixed(2))
                  );
                  updateDetraction({
                    code: selectedCode,
                    percent: Number(e.target.value),
                    total: Number((baseTotal * (Number(e.target.value) / 100)).toFixed(2)),
                    totalDetractionUSD:Number(total * (detractionPercent / 100) *tipoCambio).toFixed(2)
                  });
                }}
              />
            </Grid>
            <Grid item>
              <RTextField
                variant="outlined"
                size="small"
                autoComplete="off"
                label="Detracción"
                type="number"
                InputProps={{
                  readOnly: false,
                  startAdornment: <InputAdornment position="start">S/</InputAdornment>,
                }}
                value={currency==='S/'?detractionTotal:detractionTotalUSD}                
                onChange={(e)=>{
                  setDetractionTotal(
                    Number(e.target.value)
                  )
                  updateDetraction({
                    code: selectedCode,
                    percent: detractionPercent,
                    total: Number(e.target.value).toFixed(2),
                  });
                } }
                style={{ width: 170 }}
              />
            </Grid>

            <Grid item>
              <RTextField
                variant="outlined"
                size="small"
                autoComplete="off"
                label="Número de cuenta"
                InputProps={{ readOnly: true }}
                value={detractionAcctNumber}
                style={{ width: 170 }}
              />
            </Grid>
          </Grid>
          {/* <table style={{ marginTop: 20 }} className="table table-clear">
            <tbody>
              <tr>
                <td className="left">
                  <strong>Total detración</strong>
                </td>
                <td className="right">
                  <strong>S/ 0.00</strong>
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>
      ) : null}
    </div>
  );
};
