import axios from "axios";
import { ISearchDocument } from "../../domain/models/Comun";
import { PATH } from "../http/Path";

export const comunRepository = {
  getLogo: async (company: string | number) => {
    const response = await axios.get(`${PATH.COMUN_LOGO}/${company}`);
    return response;
  },
  getSearchDocument: async (data: ISearchDocument) => {
    const response = await axios.get(
      `${PATH.COMUN_SEARCH_DOCUMENT}?document_type=${data.typeDoc}
      &serie=${data.serie}&correlativo=${data.correlative}
      &total_value=${data.totalAmount}&ruc_emisor=${data.rucEmitter}
      &emission_date=${data.emissionDate}
      ${
        data.rucReceptor !== "" && data.rucReceptor
          ? `&ruc_receptor=${data.rucReceptor}`
          : ""
      }`
    );
    return response;
  },
  downloadXML: async (id: number) => {
    const response = await axios.get(`${PATH.COMUN_DOWNLOAD_XML}/${id}`, {
      responseType: "blob",
    });
    return response;
  },
  downloadCDR: async (id: number) => {
    const response = await axios.get(`${PATH.COMUN_DOWNLOAD_CDR}/${id}`, {
      responseType: "blob",
    });
    return response;
  },
  downloadPDF: async (data: ISearchDocument) => {
    const response = await axios.get(
      `${PATH.COMUN_DOWNLOAD_PDF}?document_type=${data.typeDoc}&serie=${
        data.serie
      }&correlativo=${data.correlative}&total_value=${
        data.totalAmount
      }&ruc_emisor=${data.rucEmitter}&emission_date=${data.emissionDate}${
        data.rucReceptor !== "" && data.rucReceptor
          ? `&ruc_receptor=${data.rucReceptor}`
          : ""
      }`,
      {
        responseType: "blob",
      }
    );
    return response;
  },
  getDocumentsCustomer: async (params: string) => {
    const response = await axios.get(
      `${PATH.COMUN_DOCUMENTS_CUSTOMER}/${params}`
    );
    return response;
  },
  getDocumentsSupplier: async (params: string) => {
    const response = await axios.get(
      `${PATH.COMUN_DOCUMENTS_SUPPLIER}/${params}`
    );
    return response;
  },
};
