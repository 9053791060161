import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Tabs,
} from "@material-ui/core";
import React from "react";
import { Colors } from "src/infrastructure/static/colors";
import { ButtonPrimary, ButtonSecondary } from "../button";
import TextStyle from "../textStyle";
import TitleFilter from "../titleFilter";
import { ContainerLayout, Sidebar, ToggleButton } from "./styled";
import { useHistory } from "react-router-dom";
import { CompanyInfo } from "src/store/types/companies";
import { RTab } from "../ratifikaElements";
import { useSelector } from "react-redux";
import {
  ArrowBack,
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  ExpandLess,
  ExpandMore,
  Menu,
} from "@material-ui/icons";
import { CompanyInformation } from "../companyInformation";
import CustomListItem from "./customListItem";
import { Route, Switch, Redirect } from "react-router-dom";
import { sizeSidebar } from "src/static/sizeSidebar";

interface ILayoutFilterProps {
  type: string;
  children: React.ReactNode;
  title: string;
  componentLoading: any;
  onTabChange: (newValue: number) => void;
}

interface Contributor {
  number_type: string;
  number: string;
  legal_name: string;
  id: number;
}

const LayoutSetting: React.FC<ILayoutFilterProps> = ({
  title,
  type,
  children,
  componentLoading: ComponentLoading,
  onTabChange,
}) => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const companies = useSelector((state: any) => state.companiesInfo);
  const [isOpen, setIsOpen] = React.useState(true);
  const [openItemMenu, setOpenItemMenu] = React.useState(true);
  const [sizeSidebarWidth, setSizeSidebarWidth] = React.useState(sizeSidebar.with);
  const history = useHistory();

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
    setSizeSidebarWidth("");
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    onTabChange(newValue);
    // setCompanyData(companies[newValue]);
  };
  const changeWorkSpaces = (newValue: number) => {
    setValue(newValue);
    onTabChange(newValue);
    history.push("/configuracion/misdatos")
    // setCompanyData(companies[newValue]);
  };
  
  const handleClick = () => {
    setOpenItemMenu(!openItemMenu);
  };

  function a11yProps(index: any) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <div style={{ display: "flex", }}>
      {/* <Grid container> */}
{/* <Grid item sm={3}> */}
<Box 
        style={{
          width: isOpen?"300px": "-100px",
          flexShrink: 0,
          // overflow:"hidden",
        // transition:"width 0.3s"
        }}>

      
          
      <Sidebar isOpen={isOpen} >
        <List>
          {/* <ListItem button onClick={handleClick}>
            <ListItemText
              primary="WORK SPACES"
              style={{ color: Colors.GREEN }}
            />
            {openItemMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openItemMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ marginLeft: 8 }}>
              <ListItem
                button
                onClick={() => history.push("/configuracion/misdatos")}
              >
                <ListItemText primary="Eximio" />
              </ListItem>
            </List>
          </Collapse> */}
          <CustomListItem primary="WORK SPACES">
            {companies.map((company: CompanyInfo, index: number)=>(
              <ListItem
              button
              onClick={()=>changeWorkSpaces(index)}
              // selected
            >
              <ListItemText primary={company.legal_name} />
            </ListItem>
            ))}
            
          </CustomListItem>
          <CustomListItem primary="MARKETPLACE">
              <ListItem
              button
              onClick={() => history.push("/configuracion/integraciones")}
            >
              <ListItemText primary="INTEGRATIONS" />
            </ListItem>
            
          </CustomListItem>
        </List>
        {/* <div style={{ padding: "20px" }}>
          <MenuItem onClick={() => history.push("/configuracion/misdatos")}>
            Mis Datos
          </MenuItem>
          <MenuItem
            onClick={() => history.push("/configuracion/integraciones")}
          >
            Integraciones
          </MenuItem>
        </div> */}
        <ToggleButton onClick={handleToggleSidebar} isOpen={isOpen}>
          {isOpen ? <ArrowBackIos /> : <ArrowForwardIos />}
        </ToggleButton>
      </Sidebar>
      </Box>
      {/* </Grid> */}
      {/* <Grid item sm={isOpen? 9:12}> */}

      {/* <div style={{display:"flow-root", flexDirection:"row",justifyContent:"center", alignItems:"center"}}> */}

      {/* <Box style={{
          flexGrow: 1,
          padding: 3,
          width: `calc(100% - ${sizeSidebar.with})`,
          minHeight: "100vh",
          
        }}>
        Hola mundo jeje
      </Box> */}

      {/* <Box marginLeft={0} style={{backgroundColor:"red"}}>
        Hola mundo jeje
      </Box> */}
        {/* <CompanyInformation activeIndex={1}/> */}
      {/* </div> */}
      {/* </Grid>
      </Grid> */}
      {/* {children} */}
      
      <Box >
          <Tabs
            orientation={"horizontal"}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            {companies.map((company: CompanyInfo, index: number) => (
              <RTab
                label={company.legal_name}
                
                style={{ textAlign: "left", justifyContent: "flex-start" }}
                {...a11yProps(index)}
                key={index}
              />
            ))}
          </Tabs>
          {children}
          
        
      </Box>
    </div>
  );
};

export default LayoutSetting;
