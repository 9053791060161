import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { RButtonSecondary } from "../ratifikaElements";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const PrivacyPolicy: React.FC<Props> = ({ isOpen, close }) => {
  return (
    <Dialog open={isOpen} onClose={close} scroll="paper">
      <DialogTitle>Política de Privacidad</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText tabIndex={-1}>
          <Typography variant="body2" gutterBottom>
            Actualizado el 2022-06-16
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO. ("nosotros", "nuestro" o "nos") se compromete a proteger su
            privacidad. Esta Política de privacidad explica cómo RATIFIKA.
            recopila, usa y divulga su información personal. Esta Política de
            privacidad se aplica a nuestro sitio web, https://ratifika.com.pe y
            https://eximio.com.pe , y sus subdominios asociados (colectivamente,
            nuestro "Servicio") junto con nuestra aplicación, RATIFIKA. Al
            acceder o utilizar nuestro Servicio, usted indica que ha leído,
            comprendido y aceptado nuestra recopilación, almacenamiento, uso y
            divulgación de su información personal como se describe en esta
            Política de privacidad y en nuestros Términos de servicio.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Definiciones y Términos Clave
          </Typography>
          <Typography variant="body2" gutterBottom>
            Para ayudar a explicar las cosas de la manera más clara posible en
            esta Política de privacidad, cada vez que se hace referencia a
            cualquiera de estos términos, se definen estrictamente como:
          </Typography>
          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              Cookie: pequeña cantidad de datos generados por un sitio web y
              guardados por su navegador web. Se utiliza para identificar su
              navegador, proporcionar análisis, recordar información sobre
              usted, como su preferencia de idioma o información de inicio de
              sesión.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Compañía: cuando esta política menciona "Compañía", "nosotros",
              "nos" o "nuestro", se refiere a EXIMIO S.A.C, con domicilio fiscal
              en: CAL.26 MZA. L2 LOTE. 42 URB. EL PINAR LIMA - LIMA - COMAS que
              es responsable de su información en virtud de esta Política de
              privacidad.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Plataforma: sitio web de Internet, aplicación web o aplicación
              digital de cara al público de RATIFIKA
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              País: donde se encuentra EXIMIO S.A.C. o los propietarios /
              fundadores de RATIFIKA en este caso es Perú.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Cliente: se refiere a la empresa, organización o persona que se
              registra para utilizar el Servicio RATIFIKA para gestionar las
              relaciones con sus consumidores o usuarios del servicio.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Dispositivo: cualquier dispositivo conectado a Internet, como un
              teléfono, tablet, computadora o cualquier otro dispositivo que se
              pueda usar para visitar RATIFIKA y usar los servicios.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Dirección IP: a cada dispositivo conectado a Internet se le asigna
              un número conocido como dirección de protocolo de Internet (IP).
              Estos números generalmente se asignan en bloques geográficos. A
              menudo, se puede utilizar una dirección IP para identificar la
              ubicación desde la que un dispositivo se conecta a Internet.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Personal: se refiere a aquellas personas que son empleadas por
              EXIMIO o están bajo contrato para realizar un servicio en nombre
              de una de las partes.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Datos personales: cualquier información que directa,
              indirectamente o en conexión con otra información, incluido un
              número de identificación personal, permita la identificación de
              una persona física.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Servicio: se refiere al servicio brindado por EXIMIO como se
              describe en los términos relativos (si están disponibles) y en
              esta plataforma.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Terceros: se refiere a anunciantes, patrocinadores de concursos,
              socios promocionales y de marketing, y aliados estrategicos que
              brindan nuestro contenido o cuyos productos o servicios que
              creemos que pueden interesarle.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Sitio web: el sitio de RATIFIKA, al que se puede acceder a través
              de esta URL: https://ratifika.com.pe.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Usted: una persona o entidad que está registrada con RATIFIKA para
              utilizar los Servicios.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
            Openpay: Entidad encargada de procesar pagos electrónicos a través
              de RATIFIKA y de uso exclusivo como pasarela de pagos.
            </Typography>
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Qué información recopilamos?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Recopilamos información suya cuando visita nuestra plataforma, se
            registra en nuestro sitio, realiza un pedido, se suscribe a nuestro
            boletín, responde a una encuesta o completa un formulario.
          </Typography>
          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              Nombre / nombre de usuario
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Números de teléfono
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Correos electrónicos
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Direcciones de correo
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Títulos de trabajo
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Direcciones de facturación
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Números de tarjetas de débito / crédito
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Contraseña
            </Typography>
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Cómo usamos la información que recopilamos?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Cualquiera de la información que recopilamos de usted puede usarse
            de una de las siguientes maneras:
          </Typography>
          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              Para personalizar su experiencia (su información nos ayuda a
              responder mejor a sus necesidades individuales)
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Para mejorar nuestra plataforma (nos esforzamos continuamente por
              mejorar lo que ofrece nuestra plataforma en función de la
              información y los comentarios que recibimos de usted)
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Para mejorar el servicio al cliente (su información nos ayuda a
              responder de manera más efectiva a sus solicitudes de servicio al
              cliente y necesidades de soporte)
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Para procesar transacciones de pago electrónico en alianza con
              OPENPAY
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Para administrar un concurso, promoción, encuesta u otra
              característica del sitio
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Para enviar correos electrónicos periódicos
            </Typography>
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Cuándo usa RATIFIKA la información del cliente de terceros?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Recibimos información de terceros cuando se comunica con nosotros.
            Por ejemplo, cuando nos envía su dirección de correo electrónico
            para mostrar interés en convertirse en usuario de RATIFIKAu otros
            productos de EXIMIO, recibimos información de Openpay que brinda
            servicios automáticos de detección de fraude a RATIFIKA.
            Ocasionalmente, también recopilamos información que se pone a
            disposición del público en los sitios web de redes sociales. Puede
            controlar la cantidad de información que los sitios web de redes
            sociales hacen pública visitando estos sitios web y cambiando su
            configuración de privacidad.
          </Typography>
          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Compartimos la información que recopilamos con terceros?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podemos compartir la información que recopilamos, tanto personal
            como no personal, con terceros como anunciantes, patrocinadores de
            concursos, socios promocionales y de marketing, y otros que
            proporcionan nuestro contenido o cuyos productos o servicios creemos
            que pueden interesarle. También podemos compartirlo con nuestras
            compañías afiliadas y socios comerciales actuales y futuros, y si
            estamos involucrados en una fusión, venta de activos u otra
            reorganización comercial, también podemos compartir o transferir su
            información personal y no personal a nuestros sucesores en interés.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podemos contratar proveedores de servicios de terceros de confianza
            para que realicen funciones y nos brinden servicios, como el
            alojamiento y el mantenimiento de nuestros servidores y la
            plataforma, almacenamiento y administración de bases de datos,
            administración de correo electrónico, marketing de almacenamiento,
            procesamiento de tarjetas de crédito, servicio y cumplimiento de
            pedidos de productos y servicios que puede comprar a través de la
            plataforma. Es probable que compartamos su información personal, y
            posiblemente alguna información no personal, con estos terceros para
            permitirles realizar estos servicios para nosotros y para usted.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podemos compartir partes de los datos de nuestro archivo de
            registro, incluidas las direcciones IP, con fines analíticos con
            terceros, como socios de análisis web, desarrolladores de
            aplicaciones y redes publicitarias. Si se comparte su dirección IP,
            se puede utilizar para estimar la ubicación general y otros datos
            tecnológicos, como la velocidad de conexión, si ha visitado la
            plataforma en una ubicación compartida y el tipo de dispositivo
            utilizado para visitar la plataforma. Pueden agregar información
            sobre nuestra publicidad y lo que ve en la plataforma y luego
            proporcionar auditorías, investigaciones e informes para nosotros y
            nuestros anunciantes.
          </Typography>
          <Typography variant="body2" gutterBottom>
            También podemos divulgar información personal y no personal sobre
            usted al gobierno, a funcionarios encargados de hacer cumplir la ley
            o a terceros privados, según consideremos, a nuestro exclusivo
            criterio, necesario o apropiado para responder a reclamos, procesos
            legales (incluidas citaciones), para proteger nuestra derechos e
            intereses o los de un tercero, la seguridad del público o de
            cualquier persona, para prevenir o detener cualquier actividad
            ilegal, poco ética o legalmente procesable, o para cumplir con las
            órdenes judiciales, leyes, reglas y regulaciones aplicables.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Dónde y cuándo se recopila la información de los clientes y
            usuarios finales?
          </Typography>
          <Typography variant="body2" gutterBottom>
            RATIFIKA recopilará la información personal que nos envíe. También
            podemos recibir información personal sobre usted de terceros como se
            describe anteriormente.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Podría transferirse mi información a otros países?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Estamos incorporados en Perú. La información recopilada a través de
            nuestro sitio web, a través de interacciones directas con usted o
            del uso de nuestros servicios de ayuda puede transferirse de vez en
            cuando a nuestras oficinas o personal, o a terceros, ubicados en
            todo el mundo, y puede verse y alojarse en cualquier lugar de el
            mundo, incluidos los países que pueden no tener leyes de aplicación
            general que regulen el uso y la transferencia de dichos datos. En la
            mayor medida permitida por la ley aplicable, al utilizar cualquiera
            de los anteriores, usted acepta voluntariamente la transferencia
            transfronteriza y el alojamiento de dicha información.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿La información recopilada a través del Servicio RATIFIKA es segura?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Tomamos precauciones para proteger la seguridad de su información.
            Contamos con procedimientos físicos, electrónicos y administrativos
            para ayudar a salvaguardar, prevenir el acceso no autorizado,
            mantener la seguridad de los datos y usar correctamente su
            información. Sin embargo, ni las personas ni los sistemas de
            seguridad son infalibles, incluidos los sistemas de cifrado. Además,
            las personas pueden cometer delitos intencionales, cometer errores o
            no seguir las políticas. Por lo tanto, aunque hacemos todos los
            esfuerzos razonables para proteger su información personal, no
            podemos garantizar su seguridad absoluta. Si la ley aplicable impone
            algún deber irrenunciable de proteger su información personal, usted
            acepta que la mala conducta intencional serán los estándares
            utilizados para medir nuestro cumplimiento con ese deber.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Puedo actualizar o corregir mi información?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Los derechos que tienes para solicitar actualizaciones o
            correcciones de la información que recopila RATIFIKA dependen de tu
            relación con EXIMIO. El personal puede actualizar o corregir su
            información según se detalla en nuestras políticas de empleo
            internas de la empresa.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Los clientes tienen derecho a solicitar la restricción de ciertos
            usos y divulgaciones de información de identificación personal de la
            siguiente manera. Puede comunicarse con nosotros para (1) actualizar
            o corregir su información de identificación personal, (2) cambiar
            sus preferencias con respecto a las comunicaciones y otra
            información que recibe de nosotros, o (3) eliminar la información de
            identificación personal que se mantiene sobre usted en nuestro
            sistema (sujeto al siguiente párrafo), cancelando su cuenta. Dichas
            actualizaciones, correcciones, cambios y eliminaciones no tendrán
            ningún efecto sobre otra información que mantenemos o información
            que hayamos proporcionado a terceros de acuerdo con esta Política de
            privacidad antes de dicha actualización, corrección, cambio o
            eliminación. Para proteger su privacidad y seguridad, podemos tomar
            medidas razonables (como solicitar una contraseña única) para
            verificar su identidad antes de otorgarle acceso a su perfil o hacer
            correcciones. Usted es responsable de mantener en secreto su
            contraseña única y la información de su cuenta en todo momento.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Debe tener en cuenta que tecnológicamente no es posible eliminar
            todos y cada uno de los registros de la información que nos ha
            proporcionado de nuestro sistema. La necesidad de realizar copias de
            seguridad de nuestros sistemas para proteger la información de
            pérdidas involuntarias significa que puede existir una copia de su
            información en una forma que no se pueda borrar y que será difícil o
            imposible de localizar para nosotros. Inmediatamente después de
            recibir su solicitud, toda la información personal almacenada en las
            bases de datos que usamos activamente y otros medios fácilmente
            buscables se actualizará, corregirá, cambiará o eliminará, según
            corresponda, tan pronto como y en la medida en que sea razonable y
            técnicamente posible.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si es un usuario final y desea actualizar, eliminar o recibir
            cualquier información que tengamos sobre usted, puede hacerlo
            poniéndose en contacto con la organización de la que es cliente.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Personal
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si es un trabajador o solicitante de EXIMIO, recopilamos la
            información que nos proporciona voluntariamente. Usamos la
            información recopilada con fines de recursos humanos para
            administrar los beneficios a los trabajadores y seleccionar a los
            solicitantes.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Puede comunicarse con nosotros para (1) actualizar o corregir su
            información, (2) cambiar sus preferencias con respecto a las
            comunicaciones y otra información que reciba de nosotros, o (3)
            recibir un registro de la información que tenemos relacionada con
            usted. Dichas actualizaciones, correcciones, cambios y eliminaciones
            no tendrán ningún efecto sobre otra información que mantenemos o
            información que hayamos proporcionado a terceros de acuerdo con esta
            Política de privacidad antes de dicha actualización, corrección,
            cambio o eliminación.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Venta de Negocio
          </Typography>
          <Typography variant="body2" gutterBottom>
            Nos reservamos el derecho de transferir información a un tercero en
            el caso de una venta, fusión u otra transferencia de todos o
            sustancialmente todos los activos de RATIFIKA o cualquiera de sus
            afiliadas corporativas (como se define en este documento), o la
            porción de RATIFIKA o cualquiera de sus Afiliadas corporativas con
            las que se relaciona el Servicio, o en el caso de que discontinuemos
            nuestro negocio o presentemos una petición o hayamos presentado una
            petición contra nosotros en caso de quiebra, reorganización o
            procedimiento similar, siempre que el el tercero acepte adherirse a
            los términos de esta Política de privacidad.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Afiliados
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podemos divulgar información (incluida la información personal)
            sobre usted a nuestros afiliados corporativos. Para los propósitos
            de esta Política de Privacidad, "Afiliado Corporativo" significa
            cualquier persona o entidad que directa o indirectamente controla,
            está controlada por o está bajo control común con EXIMIO, ya sea por
            propiedad o de otra manera. Cualquier información relacionada con
            usted que proporcionemos a nuestros afiliados corporativos será
            tratada por dichos afiliados corporativos de acuerdo con los
            términos de esta política de privacidad.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Cuánto tiempo conservamos su información?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Conservamos su información solo mientras la necesitemos para
            proporcionarle RATIFIKA y cumplir con los propósitos descritos en
            esta política. Este también es el caso de cualquier persona con la
            que compartamos su información y que lleve a cabo servicios en
            nuestro nombre. Cuando ya no necesitemos usar su información y no
            sea necesario que la conservemos para cumplir con nuestras
            obligaciones legales o reglamentarias, la eliminaremos de nuestros
            sistemas o la despersonalizaremos para que no podamos identificarlo.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Cómo protegemos su información?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Implementamos una variedad de medidas de seguridad para mantener la
            seguridad de su información personal cuando realiza un pedido,
            ingresa, envía o accede a su información personal. Ofrecemos el uso
            de un servidor seguro. Toda la información confidencial / crediticia
            suministrada se transmite a través de la tecnología Secure Socket
            Layer (SSL) y luego se encripta en nuestra base de datos de
            proveedores de pasarela de pago solo para que sea accesible por
            aquellos autorizados con derechos especiales de acceso a dichos
            sistemas, y deben mantener la información confidencial. Después de
            una transacción, su información privada (tarjetas de crédito,
            números de seguro social, finanzas, etc.) nunca se archiva. Sin
            embargo, no podemos garantizar la seguridad absoluta de la
            información que transmita a RATIFIKA ni garantizar que su
            información en el servicio no sea accedida, divulgada, alterada o
            destruida por una infracción de cualquiera de nuestras condiciones
            físicas, salvaguardias técnicas o de gestión.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Ley que Rige
          </Typography>
          <Typography variant="body2" gutterBottom>
            Esta Política de privacidad se rige por las leyes de Perú sin tener
            en cuenta su disposición sobre conflicto de leyes. Usted acepta la
            jurisdicción exclusiva de los tribunales en relación con cualquier
            acción o disputa que surja entre las partes en virtud de esta
            Política de privacidad o en relación con ella, excepto aquellas
            personas que puedan tener derecho a presentar reclamaciones en
            virtud del Escudo de privacidad o el marco suizo-estadounidense.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Tu consentimiento
          </Typography>
          <Typography variant="body2" gutterBottom>
            Hemos actualizado nuestra Política de privacidad para brindarle
            total transparencia sobre lo que se establece cuando visita nuestro
            sitio y cómo se utiliza. Al utilizar nuestra plataforma, registrar
            una cuenta o realizar una compra, por la presente acepta nuestra
            Política de privacidad y acepta sus términos.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Enlaces a otros Sitios Web
          </Typography>
          <Typography variant="body2" gutterBottom>
            Esta Política de privacidad se aplica solo a los Servicios. Los
            Servicios pueden contener enlaces a otros sitios web que RATIFIKA no
            opera ni controla. No somos responsables por el contenido, la
            precisión o las opiniones expresadas en dichos sitios web, y dichos
            sitios web no son investigados, monitoreados o verificados por
            nuestra precisión o integridad. Recuerde que cuando utiliza un
            enlace para ir desde los Servicios a otro sitio web, nuestra
            Política de privacidad deja de estar en vigor. Su navegación e
            interacción en cualquier otro sitio web, incluidos aquellos que
            tienen un enlace en nuestra plataforma, están sujetos a las propias
            reglas y políticas de ese sitio web. Dichos terceros pueden utilizar
            sus propias cookies u otros métodos para recopilar información sobre
            usted.
          </Typography>
          <Typography variant="body2" gutterBottom>
            RATIFIKA no asumirá ninguna responsabilidad por los hipervínculos a
            sitios web o servicios de terceros incluidos en la plataforma. Desde
            nuestra plataforma, puede visitar otros sitios web siguiendo los
            hipervínculos a dichos sitios externos. Si bien nos esforzamos por
            proporcionar solo enlaces de calidad a sitios web útiles y éticos,
            no tenemos control sobre el contenido y la naturaleza de estos
            sitios. Estos enlaces a otros sitios web no implican una
            recomendación para todo el contenido que se encuentra en estos
            sitios. Los propietarios del sitio y el contenido pueden cambiar sin
            previo aviso y pueden ocurrir antes de que tengamos la oportunidad
            de eliminar un enlace que puede haber fallado.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Cookies
          </Typography>
          <Typography variant="body2" gutterBottom>
            RATIFIKA utiliza "cookies" para identificar las áreas de nuestro
            sitio web que ha visitado. Una cookie es una pequeña porción de
            datos que su navegador web almacena en su computadora o dispositivo
            móvil. Usamos cookies para mejorar el rendimiento y la funcionalidad
            de nuestra plataforma, pero no son esenciales para su uso. Sin
            embargo, sin estas cookies, es posible que ciertas funciones, como
            los videos, no estén disponibles o se le solicitará que ingrese sus
            datos de inicio de sesión cada vez que visite la plataforma, ya que
            no podríamos recordar que había iniciado sesión anteriormente. La
            mayoría de los navegadores web se pueden configurar para desactivar
            el uso de cookies. Sin embargo, si desactiva las cookies, es posible
            que no pueda acceder a la funcionalidad de nuestro sitio web
            correctamente o en absoluto. Nunca colocamos información de
            identificación personal en cookies.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Publicidad
          </Typography>
          <Typography variant="body2" gutterBottom>
            Esta plataforma puede contener anuncios de terceros y enlaces a
            sitios de terceros. EXIMIO no hace ninguna representación en cuanto
            a la exactitud o idoneidad de la información contenida en esos
            anuncios o sitios y no acepta ninguna responsabilidad u obligación
            por la conducta o el contenido de esos anuncios y sitios y las
            ofertas realizadas por los terceros.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Cookies para Publicidad
          </Typography>
          <Typography variant="body2" gutterBottom>
            Estas cookies recopilan información a lo largo del tiempo sobre su
            actividad en línea en la plataforma y otros servicios en línea para
            hacer que los anuncios en línea sean más relevantes y efectivos para
            usted. Esto se conoce como publicidad basada en intereses. También
            realizan funciones como evitar que el mismo anuncio vuelva a
            aparecer continuamente y garantizar que los anuncios se muestren
            correctamente a los anunciantes. Sin cookies, es muy difícil para un
            anunciante llegar a su audiencia o saber cuántos anuncios se
            mostraron y cuántos clics recibieron.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Servicios de Remarketing
          </Typography>
          <Typography variant="body2" gutterBottom>
            Usamos servicios de remarketing. ¿Qué es el remarketing? En
            marketing digital, el remarketing (o retargeting) es la práctica de
            publicar anuncios en Internet a personas que ya han visitado su
            sitio web. Permite que su empresa parezca que está "siguiendo" a las
            personas en Internet mediante la publicación de anuncios en los
            sitios web y las plataformas que más utilizan.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Detalles del Pago
          </Typography>
          <Typography variant="body2" gutterBottom>
            Con respecto a cualquier tarjeta de crédito u otros detalles de
            procesamiento de pagos que nos haya proporcionado, nos comprometemos
            a que esta información confidencial se almacene de la manera más
            segura posible.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Privacidad de los Niños
          </Typography>
          <Typography variant="body2" gutterBottom>
            No nos dirigimos a ninguna persona menor de 13 años. No recopilamos
            información de identificación personal de ninguna persona menor de
            13 años. Si usted es padre o tutor y sabe que su hijo nos ha
            proporcionado Datos personales, comuníquese con Nos. Si nos damos
            cuenta de que hemos recopilado datos personales de cualquier persona
            menor de 13 años sin la verificación del consentimiento de los
            padres, tomamos medidas para eliminar esa información de nuestros
            servidores.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Cambios en nuestra Política de Privacidad
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podemos cambiar nuestro Servicio y nuestras políticas, y es posible
            que debamos realizar cambios en esta Política de privacidad para que
            reflejen con precisión nuestro Servicio y nuestras políticas. A
            menos que la ley exija lo contrario, le notificaremos (por ejemplo,
            a través de nuestro Servicio) antes de realizar cambios en esta
            Política de privacidad y le daremos la oportunidad de revisarlos
            antes de que entren en vigencia. Luego, si continúa utilizando el
            Servicio, estará sujeto a la Política de privacidad actualizada. Si
            no desea aceptar esta o cualquier Política de privacidad
            actualizada, puede eliminar su cuenta.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Servicios de terceros
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podemos mostrar, incluir o poner a disposición contenido de terceros
            (incluidos datos, información, aplicaciones y otros servicios de
            productos) o proporcionar enlaces a sitios web o servicios de
            terceros ("Servicios de terceros").
          </Typography>
          <Typography variant="body2" gutterBottom>
            Usted reconoce y acepta que EXIMIO no será responsable de ningún
            Servicio de terceros, incluida su precisión, integridad,
            puntualidad, validez, cumplimiento de los derechos de autor,
            legalidad, decencia, calidad o cualquier otro aspecto de los mismos.
            RATIFIKA no asume ni tendrá ninguna obligación o responsabilidad
            ante usted o cualquier otra persona o entidad por los Servicios de
            terceros.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Los Servicios de terceros y los enlaces a los mismos se brindan
            únicamente para su conveniencia y usted accede a ellos y los usa
            completamente bajo su propio riesgo y sujeto a los términos y
            condiciones de dichos terceros.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Facebook Pixel
          </Typography>
          <Typography variant="body2" gutterBottom>
            Facebook pixel es una herramienta de análisis que le permite medir
            la efectividad de su publicidad al comprender las acciones que las
            personas realizan en su sitio web. Puede utilizar el píxel para:
            Asegurarse de que sus anuncios se muestren a las personas adecuadas.
            Facebook pixel puede recopilar información de su dispositivo cuando
            utiliza el servicio. Facebook pixel recopila información que se
            guarda de acuerdo con su Política de privacidad.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Tecnologías de Seguimiento
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Información sobre el Reglamento general de protección de datos
            (RGPD)
          </Typography>
          <Typography variant="body2" gutterBottom>
            Es posible que recopilemos y utilicemos información suya si
            pertenece al Espacio Económico Europeo (EEE), y en esta sección de
            nuestra Política de privacidad vamos a explicar exactamente cómo y
            por qué se recopilan estos datos, y cómo los mantenemos bajo
            protección contra la replicación o el uso incorrecto.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Qué es RGPD?
          </Typography>
          <Typography variant="body2" gutterBottom>
            RGPD es una ley de privacidad y protección de datos en toda la UE
            que regula cómo las empresas protegen los datos de los residentes de
            la UE y mejora el control que los residentes de la UE tienen sobre
            sus datos personales.
          </Typography>
          <Typography variant="body2" gutterBottom>
            El RGPD es relevante para cualquier empresa que opere a nivel
            mundial y no solo para las empresas con sede en la UE y los
            residentes de la UE. Los datos de nuestros clientes son importantes
            independientemente de dónde se encuentren, por lo que hemos
            implementado controles RGPD como nuestro estándar de referencia para
            todas nuestras operaciones en todo el mundo.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Qué son los Datos Personales?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Cualquier dato que se relacione con un individuo identificable o
            identificable. El RGPD cubre un amplio espectro de información que
            podría usarse por sí sola o en combinación con otras piezas de
            información para identificar a una persona. Los datos personales van
            más allá del nombre o la dirección de correo electrónico de una
            persona. Algunos ejemplos incluyen información financiera, opiniones
            políticas, datos genéticos, datos biométricos, direcciones IP,
            dirección física, orientación sexual y origen étnico.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Los principios de protección de datos incluyen requisitos tales
            como:
          </Typography>
          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              Los datos personales recopilados deben procesarse de manera justa,
              legal y transparente y solo deben usarse de la manera que una
              persona esperaría razonablemente.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Los datos personales solo deben recopilarse para cumplir con un
              propósito específico y solo deben usarse para ese propósito. Las
              organizaciones deben especificar por qué necesitan los datos
              personales cuando los recopilan.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Los datos personales no deben conservarse más tiempo del necesario
              para cumplir con su propósito.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Las personas cubiertas por el RGPD tienen derecho a acceder a sus
              propios datos personales. También pueden solicitar una copia de
              sus datos y que sus datos se actualicen, eliminen, restrinjan o
              muevan a otra organización.
            </Typography>
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            ¿Por qué es importante el RGPD?
          </Typography>
          <Typography variant="body2" gutterBottom>
            RGPD agrega algunos requisitos nuevos con respecto a cómo las
            empresas deben proteger los datos personales de las personas que
            recopilan y procesan. También aumenta las apuestas para el
            cumplimiento al aumentar la aplicación e imponer mayores multas por
            incumplimiento. Más allá de estos hechos, es simplemente lo
            correcto. En EXIMIO creemos firmemente que la privacidad de sus
            datos es muy importante y ya contamos con prácticas sólidas de
            seguridad y privacidad que van más allá de los requisitos de esta
            nueva regulación.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Derechos individuales del interesado: acceso, portabilidad y
            eliminación de datos
          </Typography>
          <Typography variant="body2" gutterBottom>
            Estamos comprometidos a ayudar a nuestros clientes a cumplir con los
            requisitos de derechos de los sujetos de datos de RGPD. EXIMIO
            procesa o almacena todos los datos personales en proveedores que
            cumplen con DPA y han sido examinados por completo. Almacenamos
            todas las conversaciones y los datos personales durante un máximo de
            6 años, a menos que se elimine su cuenta. En cuyo caso, eliminamos
            todos los datos de acuerdo con nuestros Términos de servicio y
            Política de privacidad, pero no los conservaremos por más de 60
            días.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Somos conscientes de que si trabaja con clientes de la UE, debe
            poder brindarles la capacidad de acceder, actualizar, recuperar y
            eliminar datos personales. Hemos sido configurados como autoservicio
            desde el principio y siempre le hemos dado acceso a sus datos y a
            los datos de sus clientes. Nuestro equipo de atención al cliente
            está aquí para que responda cualquier pregunta que pueda tener sobre
            cómo trabajar con la API.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Contáctenos
          </Typography>
          <Typography variant="body2" gutterBottom>
            No dude en contactarnos si tiene alguna pregunta.
          </Typography>
          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              A través de correo electrónico: apoyo@eximio.com.pe
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              A través del número de teléfono: +51 (01) 480 1790
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              A través de este enlace: https://eximio.com.pe
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              A través de esta dirección: CAL.26 MZA. L2 LOTE. 42 URB. EL PINAR
              LIMA - LIMA - COMAS
            </Typography>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <RButtonSecondary onClick={close} color="primary">
          Cerrar
        </RButtonSecondary>
      </DialogActions>
    </Dialog>
  );
};
