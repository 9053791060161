import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { ButtonSecondary } from "../button";
import { Colors } from "../../static/colors";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const TermsConditions: React.FC<Props> = ({ isOpen, close }) => {
  return (
    <Dialog open={isOpen} onClose={close} scroll="paper">
      <DialogTitle>Términos y Condiciones</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText tabIndex={-1}>
          <Typography variant="body2" gutterBottom>
            Actualizado el 2022-06-16
          </Typography>
          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Términos generales
          </Typography>
          <Typography variant="body2" gutterBottom>
            Al acceder y realizar un comprobante con RATIFIKA, usted confirma
            que está de acuerdo y sujeto a los términos de servicio contenidos
            en los Términos y condiciones que se describen a continuación. Estos
            términos se aplican a todo el sitio web y a cualquier correo
            electrónico u otro tipo de comunicación entre usted y EXIMIO.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Bajo ninguna circunstancia el equipo de EXIMIO será responsable de
            ningún daño directo, indirecto, especial, incidental o consecuente,
            que incluye, entre otros, la pérdida de datos o ganancias que surjan
            del uso o la incapacidad de usar, los materiales de este sitio,
            incluso si el equipo de EXIMIO o un representante autorizado han
            sido informados de la posibilidad de tales daños. Si su uso de
            materiales de este sitio resulta en la necesidad de servicio,
            reparación o corrección de equipos o datos, usted asume los costos
            de los mismos.
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO no será responsable de ningún resultado que pueda ocurrir
            durante el curso del uso de nuestros recursos. Nos reservamos el
            derecho de cambiar los precios y revisar la política de uso de
            recursos en cualquier momento.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Licencia
          </Typography>
          <Typography variant="body2" gutterBottom>
            RATIFIKA le otorga una licencia revocable, no exclusiva,
            intransferible y limitada para descargar, instalar y usar la
            plataforma estrictamente de acuerdo con los términos de este
            Acuerdo.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Estos Términos y condiciones son un contrato entre usted y EXIMIO
            (referidos en estos Términos y condiciones como "EXIMIO",
            "nosotros", o "nuestro"), el proveedor del sitio web RATIFIKA y los
            servicios accesibles desde el sitio web de RATIFIKA (que se
            denominan colectivamente en estos Términos y condiciones como el
            "Servicio de RATIFIKA").
          </Typography>
          <Typography variant="body2" gutterBottom>
            Usted acepta estar sujeto a estos Términos y condiciones. Si no está
            de acuerdo con estos Términos y condiciones, no utilice el Servicio
            RATIFIKA. En estos Términos y condiciones, "usted" se refiere tanto
            a usted como individuo como a la entidad que representa. Si viola
            cualquiera de estos Términos y condiciones, nos reservamos el
            derecho de cancelar su cuenta o bloquear el acceso a su cuenta sin
            previo aviso.
          </Typography>
          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Definiciones y términos clave
          </Typography>
          <Typography variant="body2" gutterBottom>
            Para ayudar a explicar las cosas de la manera más clara posible en
            estos Términos y Condiciones, cada vez que se hace referencia a
            cualquiera de estos términos, se definen estrictamente como:
          </Typography>

          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              Cookie: pequeña cantidad de datos generados por un sitio web y
              guardados por su navegador web. Se utiliza para identificar su
              navegador, proporcionar análisis, recordar información sobre
              usted, como su preferencia de idioma o información de inicio de
              sesión.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Compañía: cuando estos términos mencionan "Compañía", "nosotros",
              "nos" o "nuestro", se refiere a EXIMIO S.A.C., CAL.26 MZA. L2
              LOTE. 42 URB. EL PINAR LIMA - LIMA - COMAS que es responsable de
              su información en virtud de estos Términos y Condiciones.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Plataforma: sitio web de Internet, aplicación web o aplicación
              digital de cara al público de RATIFIKA
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              País: donde se encuentra EXIMIO o los propietarios / fundadores de
              RATIFIKA en este caso es Perú.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Dispositivo: cualquier dispositivo conectado a Internet, como un
              teléfono, tablet, computadora o cualquier otro dispositivo que se
              pueda usar para visitar RATIFIKA y usar los servicios.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Servicio: se refiere al servicio brindado por EXIMIO como se
              describe en los términos relativos (si están disponibles) y en
              esta plataforma.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Terceros: se refiere a anunciantes, patrocinadores de concursos,
              socios promocionales y de marketing, y otros que brindan nuestro
              contenido o cuyos productos o servicios que creemos que pueden
              interesarle.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Sitio web: el sitio de RATIFIKA, al que se puede acceder a través
              de esta URL: https://ratifika.com.pe.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Usted: una persona o entidad que está registrada con EXIMIO para
              utilizar los Servicios.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Openpay: Entidad encargada de procesar pagos electrónicos a través
              de RATIFIKA y de uso exclusivo como pasarela de pagos.
            </Typography>
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Restricciones
          </Typography>
          <Typography variant="body2" gutterBottom>
            Usted acepta no hacerlo y no permitirá que otros:
          </Typography>
          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              Licenciar, vender, alquilar, arrendar, asignar, distribuir,
              transmitir, alojar, subcontratar, divulgar o explotar
              comercialmente la plataforma o poner la plataforma a disposición
              de terceros.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Modificar, realizar trabajos derivados, desensamblar, descifrar,
              realizar una compilación inversa o realizar ingeniería inversa de
              cualquier parte de la plataforma.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Eliminar, alterar u ocultar cualquier aviso de propiedad (incluido
              cualquier aviso de derechos de autor o marca registrada) de sus
              afiliados, socios, proveedores o licenciatarios de la plataforma.
            </Typography>
          </Typography>
          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Pago
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si se registra en cualquiera de nuestros planes de pago recurrente,
            acepta pagar todas las tarifas o cargos a su cuenta por el Servicio
            de acuerdo con las tarifas, los cargos y los términos de facturación
            vigentes en el momento en que cada tarifa o cargo vence y se paga. A
            menos que se indique lo contrario en un formulario de pedido, debe
            proporcionar a RATIFIKA una tarjeta de crédito válida (Visa,
            MasterCard o cualquier otro emisor aceptado por nosotros)
            ("Proveedor de pagos") como condición para registrarse en el plan
            Premium. Su acuerdo de Proveedor de pagos rige el uso de la cuenta
            de tarjeta de crédito designada, y debe consultar ese acuerdo y no
            estos Términos para determinar sus derechos y responsabilidades con
            respecto a su Proveedor de pagos. Al proporcionar a RATIFIKA su
            número de tarjeta de crédito y la información de pago asociada,
            acepta que EXIMIO está autorizado para verificar la información de
            inmediato y, posteriormente, facturar a su cuenta todas las tarifas
            y cargos adeudados y pagaderos a EXIMIO a continuación y que no se
            requiere ningún aviso o consentimiento adicional. Acepta notificar
            inmediatamente a EXIMIO de cualquier cambio en su dirección de
            facturación o en la tarjeta de crédito utilizada para el pago a
            continuación. EXIMIO se reserva el derecho en cualquier momento de
            cambiar sus precios y métodos de facturación, ya sea inmediatamente
            después de la publicación en nuestro Sitio o por correo electrónico
            al administrador de su organización.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Cualquier honorario de abogado, costos judiciales u otros costos
            incurridos en el cobro de cantidades no disputadas en mora serán
            responsabilidad suya y serán pagados por usted.
          </Typography>
          <Typography variant="body2" gutterBottom>
            No existirá ningún contrato entre usted y EXIMIO para el Servicio
            hasta que EXIMIO acepte su pedido mediante un correo electrónico de
            confirmación, mensaje SMS / MMS u otro medio de comunicación
            apropiado.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Usted es responsable de las tarifas de terceros en las que pueda
            incurrir al utilizar el Servicio.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Política de Devolución y Reembolso
          </Typography>
          <Typography variant="body2" gutterBottom>
            Gracias por comprar en RATIFIKA. Apreciamos el hecho de que le guste
            comprar las cosas que construimos. También queremos asegurarnos de
            que tenga una experiencia gratificante mientras explora, evalúa y
            compra nuestros productos.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Al igual que con cualquier experiencia de compra, existen términos y
            condiciones que se aplican a las transacciones en RATIFIKA. Seremos
            tan breves como lo permitan nuestros abogados. Lo principal que debe
            recordar es que al realizar un pedido o realizar una compra en
            RATIFIKA, acepta los términos junto con la Política de privacidad de
            RATIFIKA.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si por alguna razón no está completamente satisfecho con algún bien
            o servicio que le brindamos, no dude en contactarnos y discutiremos
            cualquiera de los problemas que esté atravesando con nuestro
            producto.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Tus sugerencias
          </Typography>
          <Typography variant="body2" gutterBottom>
            Cualquier, comentario, idea, mejora o sugerencia (colectivamente,
            "Sugerencias") que usted proporcione a EXIMIO con respecto a la
            plataforma RATIFIKA seguirá siendo propiedad única y exclusiva de
            EXIMIO.
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO tendrá la libertad de usar, copiar, modificar, publicar o
            redistribuir las Sugerencias para cualquier propósito y de cualquier
            manera sin ningún crédito o compensación para usted.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Tu consentimiento
          </Typography>
          <Typography variant="body2" gutterBottom>
            Hemos actualizado nuestros Términos y condiciones para brindarle
            total transparencia sobre lo que se establece cuando visita nuestro
            sitio y cómo se utiliza. Al utilizar nuestra plataforma, registrar
            una cuenta o realizar una compra, por la presente acepta nuestros
            Términos y condiciones.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Enlaces a otros Sitios Web
          </Typography>
          <Typography variant="body2" gutterBottom>
            Estos Términos y Condiciones se aplican solo a los Servicios. Los
            Servicios pueden contener enlaces a otros sitios web que RATIFIKA no
            opera ni controla. No somos responsables por el contenido, la
            precisión o las opiniones expresadas en dichos sitios web, y dichos
            sitios web no son investigados, monitoreados o verificados por
            nuestra precisión o integridad. Recuerde que cuando utiliza un
            enlace para ir de los Servicios a otro sitio web, nuestros Términos
            y condiciones dejan de estar vigentes. Su navegación e interacción
            en cualquier otro sitio web, incluidos aquellos que tienen un enlace
            en nuestra plataforma, están sujetos a las propias reglas y
            políticas de ese sitio web. Dichos terceros pueden utilizar sus
            propias cookies u otros métodos para recopilar información sobre
            usted.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Cookies
          </Typography>
          <Typography variant="body2" gutterBottom>
            RATIFIKA utiliza "cookies" para identificar las áreas de nuestro
            sitio web que ha visitado. Una cookie es una pequeña porción de
            datos que su navegador web almacena en su computadora o dispositivo
            móvil. Usamos cookies para mejorar el rendimiento y la funcionalidad
            de nuestra plataforma, pero no son esenciales para su uso. Sin
            embargo, sin estas cookies, es posible que ciertas funciones, como
            los videos, no estén disponibles o se le solicitará que ingrese sus
            datos de inicio de sesión cada vez que visite la plataforma, ya que
            no podríamos recordar que había iniciado sesión anteriormente. La
            mayoría de los navegadores web se pueden configurar para desactivar
            el uso de cookies. Sin embargo, si desactiva las cookies, es posible
            que no pueda acceder a la funcionalidad de nuestro sitio web
            correctamente o en absoluto. Nunca colocamos información de
            identificación personal en cookies.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Cambios en nuestros Términos y Condiciones
          </Typography>
          <Typography variant="body2" gutterBottom>
            Usted reconoce y acepta que EXIMIO puede dejar de brindarle (de
            forma permanente o temporal) el Servicio (o cualquier función dentro
            del Servicio) a usted o a los usuarios en general, a discreción
            exclusiva de EXIMIO, sin previo aviso. Puede dejar de utilizar el
            Servicio en cualquier momento. No es necesario que informe
            específicamente a RATIFIKA cuando deje de usar el Servicio, salv
            exista un contrato corporativo de por medio que obligue a notificar
            previamente, además de asumir los cargos o penalidades que
            impliquen. Usted reconoce y acepta que si RATIFIKA deshabilita el
            acceso a su cuenta, es posible que no pueda acceder al Servicio, los
            detalles de su cuenta o cualquier archivo u otro material contenido
            en su cuenta.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si decidimos cambiar nuestros Términos y condiciones, publicaremos
            esos cambios en esta página y / o actualizaremos la fecha de
            modificación de los Términos y condiciones a continuación.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Modificaciones a nuestra plataforma
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO se reserva el derecho de modificar, suspender o interrumpir,
            temporal o permanentemente, la plataforma o cualquier servicio al
            que se conecte, con o sin previo aviso y sin responsabilidad ante
            usted, salvo exista un contrato corporativo de por medio que
            implique el fiel cumplimiento de los acuerdos pactados con EXIMIO.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Actualizaciones a nuestra plataforma
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO puede, de vez en cuando, proporcionar mejoras a las
            características / funcionalidad de la plataforma RATIFIKA, que
            pueden incluir parches, corrección de errores, actualizaciones,
            mejoras y otras modificaciones ("Actualizaciones").
          </Typography>
          <Typography variant="body2" gutterBottom>
            Las actualizaciones pueden modificar o eliminar ciertas
            características y / o funcionalidades de la plataforma. Usted acepta
            que RATIFIKA no tiene la obligación de (i) proporcionar
            Actualizaciones, o (ii) continuar proporcionándole o habilitando
            características y / o funcionalidades particulares de la plataforma,
            a exepción de aquellas que implique la regulación de alguna norma
            legal dispuesta por las entidades gubernamentales u otra afines al
            servicio.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Además, acepta que todas las Actualizaciones (i) se considerarán una
            parte integral de la plataforma y (ii) estarán sujetas a los
            términos y condiciones de este Acuerdo.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Servicios de Terceros
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podemos mostrar, incluir o poner a disposición contenido de terceros
            (incluidos datos, información, aplicaciones y otros servicios de
            productos) o proporcionar enlaces a sitios web o servicios de
            terceros ("Servicios de terceros").
          </Typography>
          <Typography variant="body2" gutterBottom>
            Usted reconoce y acepta que EXIMIO no será responsable de ningún
            Servicio de terceros, incluida su precisión, integridad,
            puntualidad, validez, cumplimiento de los derechos de autor,
            legalidad, decencia, calidad o cualquier otro aspecto de los mismos.
            EXIMIO no asume ni tendrá ninguna obligación o responsabilidad ante
            usted o cualquier otra persona o entidad por los Servicios de
            terceros.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Los Servicios de terceros y los enlaces a los mismos se brindan
            únicamente para su conveniencia y usted accede a ellos y los usa
            completamente bajo su propio riesgo y sujeto a los términos y
            condiciones de dichos terceros.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Duración y Terminación
          </Typography>
          <Typography variant="body2" gutterBottom>
            Este Acuerdo permanecerá en vigor hasta que usted o EXIMIO lo
            rescindan.
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO puede, a su entera discreción, en cualquier momento y por
            cualquier motivo o sin él, suspender o rescindir este Acuerdo con o
            sin previo aviso, salvo exista un contrato corporativo de por medio
            que implique el fiel cumplimiento de los acuerdos pactados con
            EXIMIO.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Este Acuerdo terminará inmediatamente, sin previo aviso de EXIMIO,
            en caso de que usted no cumpla con alguna de las disposiciones de
            este Acuerdo. Usted también puede rescindir este Acuerdo eliminando
            la plataforma y todas las copias del mismo de su computadora.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Tras la rescisión de este Acuerdo, deberá dejar de utilizar la
            plataforma y eliminar todas las copias de la plataforma de su
            computadora.
          </Typography>
          <Typography variant="body2" gutterBottom>
            La rescisión de este Acuerdo no limitará ninguno de los derechos o
            recursos de EXIMIO por ley o en equidad en caso de incumplimiento
            por su parte (durante la vigencia de este Acuerdo) de cualquiera de
            sus obligaciones en virtud del presente Acuerdo.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Aviso de infracción de Derechos de Autor
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si usted es propietario de los derechos de autor o el agente de
            dicho propietario y cree que cualquier material de nuestra
            plataforma constituye una infracción de sus derechos de autor,
            comuníquese con nosotros y proporcione la siguiente información: (a)
            una firma física o electrónica del propietario de los derechos de
            autor o una persona autorizada para actuar en su nombre; (b)
            identificación del material que se alega infringe; (c) su
            información de contacto, incluida su dirección, número de teléfono y
            un correo electrónico; (d) una declaración suya de que cree de buena
            fe que el uso del material no está autorizado por los propietarios
            de los derechos de autor; y (e) la declaración de que la información
            en la notificación es precisa y, bajo pena de perjurio, usted está
            autorizado a actuar en nombre del propietario.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Indemnización
          </Typography>
          <Typography variant="body2" gutterBottom>
            Usted acepta indemnizar y eximir de responsabilidad a EXIMIO y a sus
            empresas matrices, subsidiarias, afiliadas, funcionarios, empleados,
            agentes, socios y otorgantes de licencias (si corresponde) de
            cualquier reclamo o demanda, incluidos los honorarios razonables de
            abogados, debido a que surja de su: (a) uso de la plataforma; (b)
            violación de este Acuerdo o cualquier ley o reglamento; o (c)
            violación de cualquier derecho de un tercero.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Sin garantías
          </Typography>
          <Typography variant="body2" gutterBottom>
            La plataforma se le proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD"
            y con todas las fallas y defectos sin garantía de ningún tipo. En la
            medida máxima permitida por la ley aplicable, EXIMIO, en su propio
            nombre y en nombre de sus afiliados y sus respectivos licenciantes y
            proveedores de servicios, renuncia expresamente a todas las
            garantías, ya sean expresas, implícitas, legales o de otro tipo, con
            con respecto a la plataforma, incluidas todas las garantías
            implícitas de comerciabilidad, idoneidad para un propósito
            particular, título y no infracción, y garantías que puedan surgir
            del curso del trato, el curso del desempeño, el uso o la práctica
            comercial. Sin limitación a lo anterior, EXIMIO no ofrece garantía
            ni compromiso, y no hace ninguna representación de ningún tipo de
            que la plataforma cumplirá con sus requisitos, logrará los
            resultados previstos, será compatible o funcionará con cualquier
            otro software, sitios web, sistemas o servicios, operen sin
            interrupciones, cumplan con los estándares de rendimiento o
            confiabilidad o que no tengan errores o que cualquier error o
            defecto puede o será corregido.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Sin limitar lo anterior, ni EXIMIO ni ningún proveedor de EXIMIO
            hace ninguna representación o garantía de ningún tipo, expresa o
            implícita: (i) en cuanto al funcionamiento o disponibilidad de la
            plataforma, o la información, contenido y materiales o productos
            incluidos en el mismo; (ii) que la plataforma será ininterrumpida o
            libre de errores; (iii) en cuanto a la precisión, confiabilidad o
            vigencia de cualquier información o contenido proporcionado a través
            de la plataforma; o (iv) que la plataforma, sus servidores, el
            contenido o los correos electrónicos enviados desde o en nombre de
            RATIFIKA están libres de virus, scripts, troyanos, gusanos, malware,
            bombas de tiempo u otros componentes nocivos.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Algunas jurisdicciones no permiten la exclusión o las limitaciones
            de las garantías implícitas o las limitaciones de los derechos
            legales aplicables de un consumidor, por lo que algunas o todas las
            exclusiones y limitaciones anteriores pueden no aplicarse en su
            caso.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Limitación de Responsabilidad
          </Typography>
          <Typography variant="body2" gutterBottom>
            Sin perjuicio de los daños en los que pueda incurrir, la
            responsabilidad total de EXIMIO y cualquiera de sus proveedores en
            virtud de cualquier disposición de este Acuerdo y su recurso
            exclusivo por todo lo anterior se limitará al monto realmente pagado
            por usted para la plataforma.
          </Typography>
          <Typography variant="body2" gutterBottom>
            En la máxima medida permitida por la ley aplicable, en ningún caso
            EXIMIO o sus proveedores serán responsables de ningún daño especial,
            incidental, indirecto o consecuente de ningún tipo (incluidos, entre
            otros, daños por lucro cesante, por pérdida de datos u otra
            información, por interrupción del negocio, por lesiones personales,
            por la pérdida de privacidad que surja de alguna manera relacionada
            con el uso o la imposibilidad de usar la plataforma, software de
            terceros y / o - hardware de terceros utilizado con la plataforma, o
            de otro modo en relación con cualquier disposición de este Acuerdo),
            incluso si EXIMIO o cualquier proveedor ha sido informado de la
            posibilidad de tales daños e incluso si el recurso no cumple con su
            propósito esencial.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Algunos estados / jurisdicciones no permiten la exclusión o
            limitación de daños incidentales o consecuentes, por lo que es
            posible que la limitación o exclusión anterior no se aplique en su
            caso, salvo exista un contrato corporativo de por medio que implique
            el fiel cumplimiento de los acuerdos pactados con EXIMIO y de las
            rsponsabilidades y garantias pactadas para los servicios
            corporativos.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Divisibilidad
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si alguna disposición de este Acuerdo se considera inaplicable o
            inválida, dicha disposición se cambiará e interpretará para lograr
            los objetivos de dicha disposición en la mayor medida posible según
            la ley aplicable y las disposiciones restantes continuarán en pleno
            vigor y efecto.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Este Acuerdo, junto con la Política de privacidad y cualquier otro
            aviso legal publicado por EXIMIO en los Servicios, constituirá el
            acuerdo completo entre usted y EXIMIO con respecto a los Servicios.
            Si alguna disposición de este Acuerdo es considerada inválida por un
            tribunal de jurisdicción competente, la invalidez de dicha
            disposición no afectará la validez de las disposiciones restantes de
            este Acuerdo, que permanecerán en pleno vigor y efecto. Ninguna
            renuncia a cualquier término de este Acuerdo se considerará una
            renuncia adicional o continua de dicho término o cualquier otro
            término, y el hecho de que EXIMIO no haga valer ningún derecho o
            disposición en virtud de este Acuerdo no constituirá una renuncia a
            dicho derecho. o provisión. USTED Y EXIMIO ACEPTAN QUE CUALQUIER
            CAUSA DE ACCIÓN QUE SURJA O ESTÉ RELACIONADA CON LOS SERVICIOS DEBE
            COMENZAR DENTRO DE UN (1) AÑO DESPUÉS DE QUE LA CAUSA DE ACCIÓN SE
            ACUERDA. DE LO CONTRARIO, DICHA CAUSA DE ACCIÓN ESTÁ PERMANENTEMENTE
            PROHIBIDA.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Renuncia
          </Typography>
          <Typography variant="body2" gutterBottom>
            Salvo lo dispuesto en el presente, el hecho de no ejercer un derecho
            o exigir el cumplimiento de una obligación en virtud de este Acuerdo
            no afectará la capacidad de una de las partes para ejercer dicho
            derecho o requerir dicho cumplimiento en cualquier momento
            posterior, ni constituirá la renuncia a una infracción. cualquier
            incumplimiento posterior.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Ninguna falla en el ejercicio, ni demora en el ejercicio, por parte
            de cualquiera de las partes, de cualquier derecho o poder bajo este
            Acuerdo operará como una renuncia a ese derecho o poder. El
            ejercicio único o parcial de cualquier derecho o poder en virtud de
            este Acuerdo tampoco impedirá el ejercicio posterior de ese o
            cualquier otro derecho otorgado en este documento. En caso de
            conflicto entre este Acuerdo y cualquier compra u otros términos
            aplicables, regirán los términos de este Acuerdo.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Enmiendas a este Acuerdo
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO se reserva el derecho, a su entera discreción, de modificar o
            reemplazar este Acuerdo en cualquier momento. Si una revisión es
            importante, proporcionaremos un aviso de al menos 30 días antes de
            que entren en vigencia los nuevos términos. Lo que constituye un
            cambio material se determinará a nuestro exclusivo criterio.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si continúa accediendo o utilizando nuestra plataforma después de
            que las revisiones entren en vigencia, usted acepta estar sujeto a
            los términos revisados. Si no está de acuerdo con los nuevos
            términos, ya no está autorizado para usar RATIFIKA.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Acuerdo completo
          </Typography>
          <Typography variant="body2" gutterBottom>
            El Acuerdo constituye el acuerdo completo entre usted y EXIMIO con
            respecto a su uso de la plataforma RATIFIKA.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Es posible que esté sujeto a términos y condiciones adicionales
            detallados en los contratos corporativos que se aplican cuando usa o
            compra otros servicios de RATIFIKA, que EXIMIO le proporcionará en
            el momento de dicho uso o compra.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Actualizaciones de nuestros Términos
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podemos cambiar nuestro Servicio y nuestras políticas, y es posible
            que debamos realizar cambios en estos Términos para que reflejen con
            precisión nuestro Servicio y nuestras políticas. A menos que la ley
            exija lo contrario, le notificaremos (por ejemplo, a través de
            nuestro Servicio) antes de realizar cambios en estos Términos y le
            daremos la oportunidad de revisarlos antes de que entren en
            vigencia. Luego, si continúa utilizando el Servicio, estará sujeto a
            los Términos actualizados. Si no desea aceptar estos o alguno de los
            Términos actualizados, puede eliminar su cuenta.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Propiedad intelectual
          </Typography>
          <Typography variant="body2" gutterBottom>
            La plataforma y todo su contenido, características y funcionalidad
            (que incluyen, entre otros, toda la información, software, texto,
            pantallas, imágenes, video y audio, y el diseño, selección y
            disposición de los mismos), son propiedad de EXIMIO, sus
            licenciantes u otros proveedores de dicho material y están
            protegidos por Perú y leyes internacionales de derechos de autor,
            marcas registradas, patentes, secretos comerciales y otras leyes de
            propiedad intelectual o derechos de propiedad. El material no puede
            ser copiado, modificado, reproducido, descargado o distribuido de
            ninguna manera, en su totalidad o en parte, sin el permiso previo
            expreso por escrito de EXIMIO, a menos que y excepto que se indique
            expresamente en estos Términos y Condiciones. Se prohíbe cualquier
            uso no autorizado del material.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Acuerdo de Arbitraje
          </Typography>
          <Typography variant="body2" gutterBottom>
            Esta sección se aplica a cualquier disputa, EXCEPTO QUE NO INCLUYE
            UNA DISPUTA RELACIONADA CON RECLAMOS POR RECURSOS INJUNTIVOS O
            EQUITATIVOS CON RESPECTO A LA EJECUCIÓN O VALIDEZ DE SUS DERECHOS DE
            PROPIEDAD INTELECTUAL O DE EXIMIO. El término "disputa" significa
            cualquier disputa, acción u otra controversia entre usted y EXIMIO
            en relación con los Servicios o este acuerdo, ya sea en contrato,
            garantía, agravio, estatuto, regulación, ordenanza o cualquier otra
            base legal o equitativa. "Disputa" tendrá el significado más amplio
            posible permitido por la ley.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Aviso de Disputa
          </Typography>
          <Typography variant="body2" gutterBottom>
            En el caso de una disputa, usted o EXIMIO deben darle al otro un
            Aviso de Disputa, que es una declaración escrita que establece el
            nombre, la dirección y la información de contacto de la parte que la
            proporcionó, los hechos que dieron lugar a la disputa y la
            reparación solicitada. Debe enviar cualquier Aviso de disputa por
            correo electrónico a: apoyo@eximio.com.pe. EXIMIO le enviará
            cualquier Aviso de disputa por correo a su dirección si la tenemos,
            o de otra manera a su dirección de correo electrónico. Usted y
            EXIMIO intentarán resolver cualquier disputa mediante una
            negociación informal dentro de los sesenta (60) días a partir de la
            fecha en que se envíe la Notificación de disputa. Después de sesenta
            (60) días, usted o EXIMIO pueden comenzar el arbitraje.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Arbitraje Obligatorio
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si usted y EXIMIO no resuelven ninguna disputa mediante una
            negociación informal, cualquier otro esfuerzo para resolver la
            disputa se llevará a cabo exclusivamente mediante arbitraje
            vinculante como se describe en esta sección. Está renunciando al
            derecho de litigar (o participar como parte o miembro de la clase)
            todas las disputas en la corte ante un juez o jurado. La disputa se
            resolverá mediante arbitraje vinculante de acuerdo con las reglas de
            arbitraje comercial de la Asociación Americana de Arbitraje.
            Cualquiera de las partes puede buscar medidas cautelares
            provisionales o preliminares de cualquier tribunal de jurisdicción
            competente, según sea necesario para proteger los derechos o la
            propiedad de las partes en espera de la finalización del arbitraje.
            Todos y cada uno de los costos, honorarios y gastos legales,
            contables y de otro tipo en los que incurra la parte ganadora
            correrán a cargo de la parte no ganadora.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Envíos y Privacidad
          </Typography>
          <Typography variant="body2" gutterBottom>
            En el caso de que envíe o publique ideas, sugerencias creativas,
            diseños, fotografías, información, anuncios, datos o propuestas,
            incluidas ideas para productos, servicios, funciones, tecnologías o
            promociones nuevos o mejorados, acepta expresamente que dichos
            envíos se realizarán automáticamente. será tratado como no
            confidencial y no propietario y se convertirá en propiedad exclusiva
            de EXIMIO sin ningún tipo de compensación o crédito para usted.
            EXIMIO y sus afiliados no tendrán obligaciones con respecto a dichos
            envíos o publicaciones y pueden usar las ideas contenidas en dichos
            envíos o publicaciones para cualquier propósito en cualquier medio a
            perpetuidad, incluyendo, pero no limitado a desarrollo, fabricación,
            y comercializar productos y servicios utilizando tales ideas.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Promociones
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO puede, de vez en cuando, incluir concursos, promociones,
            sorteos u otras actividades ("Promociones") que requieren que envíe
            material o información sobre usted. Tenga en cuenta que todas las
            promociones pueden regirse por reglas independientes que pueden
            contener ciertos requisitos de elegibilidad, como restricciones de
            edad y ubicación geográfica. Usted es responsable de leer todas las
            reglas de Promociones para determinar si es elegible para participar
            o no. Si participa en alguna Promoción, acepta cumplir con todas las
            Reglas de promociones.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Es posible que se apliquen términos y condiciones adicionales a las
            compras de bienes o servicios a través de los Servicios, cuyos
            términos y condiciones forman parte de este Acuerdo mediante esta
            referencia.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Errores Tipográficos
          </Typography>
          <Typography variant="body2" gutterBottom>
            En el caso de que un producto y / o servicio se enumere a un precio
            incorrecto o con información incorrecta debido a un error
            tipográfico, tendremos el derecho de rechazar o cancelar cualquier
            pedido realizado para el producto y / o servicio enumerado al precio
            incorrecto. Tendremos derecho a rechazar o cancelar cualquier
            pedido, ya sea que se haya confirmado o no y se haya cargado a su
            tarjeta de crédito. Si su tarjeta de crédito ya ha sido cargada por
            la compra y su pedido es cancelado, emitiremos inmediatamente un
            crédito a su cuenta de tarjeta de crédito u otra cuenta de pago por
            el monto del cargo.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Diverso
          </Typography>
          <Typography variant="body2" gutterBottom>
            Si por alguna razón un tribunal de jurisdicción competente determina
            que alguna disposición o parte de estos Términos y condiciones no se
            puede hacer cumplir, el resto de estos Términos y condiciones
            continuará en pleno vigor y efecto. Cualquier renuncia a cualquier
            disposición de estos Términos y condiciones será efectiva solo si
            está por escrito y firmada por un representante autorizado de
            EXIMIO. EXIMIO tendrá derecho a una medida cautelar u otra
            compensación equitativa (sin la obligación de depositar ninguna
            fianza o garantía) en caso de incumplimiento anticipado por su
            parte. EXIMIO opera y controla el Servicio RATIFIKA desde sus
            oficinas en Perú. El Servicio no está destinado a ser distribuido ni
            utilizado por ninguna persona o entidad en ninguna jurisdicción o
            país donde dicha distribución o uso sea contrario a la ley o
            regulación. En consecuencia, las personas que eligen acceder al
            Servicio RATIFIKA desde otras ubicaciones lo hacen por iniciativa
            propia y son las únicas responsables del cumplimiento de las leyes
            locales, en la medida en que las leyes locales sean aplicables.
            Estos Términos y condiciones (que incluyen e incorporan la Política
            de privacidad de RATIFIKA) contienen el entendimiento completo y
            reemplazan todos los entendimientos previos entre usted y EXIMIO con
            respecto a su tema, y ​​usted no puede cambiarlos ni modificarlos.
            Los títulos de las secciones que se utilizan en este Acuerdo son
            solo por conveniencia y no se les dará ninguna importancia legal.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Descargo de Responsabilidad
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO no es responsable de ningún contenido, código o cualquier
            otra imprecisión.
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO no ofrece garantías.
          </Typography>
          <Typography variant="body2" gutterBottom>
            En ningún caso EXIMIO será responsable de ningún daño especial,
            directo, indirecto, consecuente o incidental o de cualquier daño, ya
            sea en una acción contractual, negligencia u otro agravio, que surja
            de o en conexión con el uso del Servicio o el contenido del
            Servicio. EXIMIO se reserva el derecho de realizar adiciones,
            eliminaciones o modificaciones al contenido del Servicio en
            cualquier momento sin previo aviso.
          </Typography>
          <Typography variant="body2" gutterBottom>
            El Servicio RATIFIKA y su contenido se proporcionan "tal cual" y
            "según esté disponible" sin ninguna garantía o representación de
            ningún tipo, ya sea expresa o implícita. EXIMIO es un distribuidor y
            no un editor del contenido proporcionado por terceros; como tal,
            EXIMIO no ejerce ningún control editorial sobre dicho contenido y no
            ofrece ninguna garantía o representación en cuanto a la precisión,
            confiabilidad o vigencia de cualquier información, contenido,
            servicio o mercancía proporcionada o accesible a través del Servicio
            RATIFIKA. Sin limitar lo anterior, EXIMIO renuncia específicamente a
            todas las garantías y representaciones en cualquier contenido
            transmitido en conexión con el Servicio RATIFIKA o en sitios que
            pueden aparecer como enlaces en el Servicio RATIFIKA, o en los
            productos proporcionados como parte o en relación con el Servicio
            RATIFIKA, incluidas, entre otras, las garantías de comerciabilidad,
            idoneidad para un propósito particular o no infracción de derechos
            de terceros. Ningún consejo oral o información escrita proporcionada
            por EXIMIO o cualquiera de sus afiliados, empleados, funcionarios,
            directores, agentes o similares creará una garantía. La información
            sobre precios y disponibilidad está sujeta a cambios sin previo
            aviso. Sin limitar lo anterior, EXIMIO no garantiza que el Servicio
            de RATIFIKA sea ininterrumpido, sin corrupción, oportuno o sin
            errores.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Descargo de Responsabilidad de Copyright
          </Typography>
          <Typography variant="body2" gutterBottom>
            Todos los derechos de propiedad intelectual relacionados con estos
            materiales pertenecen a EXIMIO. No se permite la copia, distribución
            y cualquier otro uso de estos materiales sin el permiso por escrito
            de EXIMIO, excepto y solo en la medida en que se disponga lo
            contrario en las regulaciones de la ley obligatoria (como el derecho
            a citar), a menos que se indique lo contrario ciertos materiales.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Divulgación de Afiliados
          </Typography>
          <Typography variant="body2" gutterBottom>
            RATIFIKA Y EXIMIO tiene enlaces de afiliados y en esta sección del
            Descargo de responsabilidad abordaremos cómo usamos esos enlaces de
            afiliados de otros sitios web / empresas y productos. Estos "enlaces
            de afiliados" son URL específicas que contienen la identificación o
            el nombre de usuario del afiliado.
          </Typography>
          <Typography variant="body2" gutterBottom>
            De acuerdo con las pautas de la FTC, asuma lo siguiente sobre los
            enlaces y publicaciones en este sitio:
          </Typography>
          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              Todos o cualquiera de los enlaces de RATIFIKA son enlaces de
              afiliados de los cuales recibimos una pequeña comisión por las
              ventas de ciertos artículos, pero el precio es el mismo para ti. A
              medida que RATIFIKA ha crecido, también lo han hecho los costos
              asociados con su funcionamiento y mantenimiento, y los enlaces de
              afiliados son una forma en que ayudamos a compensar estos costos.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              Si publicamos un enlace de afiliado a un producto, es algo que
              usamos, apoyamos y recomendaríamos personalmente sin un enlace de
              afiliado.
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              A menos que se indique lo contrario, todas las reseñas son de
              artículos que hemos comprado y no se nos paga ni compensa de
              ninguna manera.
            </Typography>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podríamos participar en programas de afiliados como:
          </Typography>
          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              Otros
            </Typography>
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Divulgación de Información Financiera
          </Typography>
          <Typography variant="body2" gutterBottom>
            Las ventas brutas de mercancías son una métrica no GAAP. Lo usamos
            para expresar la demanda total en todos nuestros sitios web y
            tiendas. Este número mide el valor en dólares o soles, según
            corresponda, del pedido realizado en el año antes de acumularse para
            ciertos artículos, como devoluciones, e ignora ciertos cortes de
            tiempo que son requeridos por GAAP para propósitos de reconocimiento
            de ingresos. Si fuéramos una empresa pública, tendríamos que
            conciliar las ventas brutas de mercancías con la métrica GAAP más
            cercana (ventas netas), pero actualmente somos una empresa privada,
            por lo que el número de ventas brutas de mercancías debería verse
            como un número interesante que queremos compartir con nuestros
            amigos.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Este documento contiene declaraciones prospectivas que involucran
            riesgos e incertidumbres, así como suposiciones que, si alguna vez
            se materializan o resultan incorrectas, podrían causar que los
            resultados difieran materialmente de los expresados ​​o implícitos
            en las declaraciones y suposiciones prospectivas. Estos riesgos e
            incertidumbres incluyen, pero no se limitan a, el riesgo de
            desaceleración económica, el riesgo de compra excesiva o
            insuficiente, el riesgo de que los consumidores no compren en línea
            en nuestro sitio web al ritmo que esperábamos, el riesgo de escasez
            de proveedores, el riesgo de competencia nueva o creciente, el
            riesgo de desastres naturales o de otro tipo que afecten nuestro
            cumplimiento de operaciones históricas o servidores web, y el riesgo
            de que el mundo en general llegue a su fin. Todas las declaraciones
            que no sean declaraciones de hechos históricos son declaraciones que
            podrían considerarse declaraciones a futuro, incluidas declaraciones
            de expectativas o creencias; y cualquier declaración de supuestos
            subyacentes a cualquiera de los anteriores. EXIMIO no asume ninguna
            obligación y no tiene la intención de actualizar estas declaraciones
            a futuro.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Divulgación de Publicidad
          </Typography>
          <Typography variant="body2" gutterBottom>
            Esta plataforma puede contener anuncios de terceros y enlaces a
            sitios de terceros. EXIMIO no hace ninguna representación en cuanto
            a la exactitud o idoneidad de la información contenida en esos
            anuncios o sitios y no acepta ninguna responsabilidad u obligación
            por la conducta o el contenido de esos anuncios y sitios y las
            ofertas realizadas por los terceros.
          </Typography>
          <Typography variant="body2" gutterBottom>
            La publicidad mantiene a EXIMIO y a muchos de los sitios web y
            servicios que usted utiliza de forma gratuita. Trabajamos arduamente
            para asegurarnos de que los anuncios sean seguros, discretos y lo
            más relevantes posible.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Los anuncios de terceros y los enlaces a otros sitios donde se
            anuncian bienes o servicios no son respaldos ni recomendaciones de
            RATIFIKA de los sitios, bienes o servicios de terceros. RATIFIKA no
            asume ninguna responsabilidad por el contenido de los anuncios, las
            promesas hechas o la calidad / confiabilidad de los productos o
            servicios ofrecidos en todos los anuncios.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Divulgación de Testimonios
          </Typography>
          <Typography variant="body2" gutterBottom>
            Los testimonios proporcionados en esta plataforma son opiniones de
            quienes los proporcionan. No se debe confiar en la información
            proporcionada en los testimonios para predecir resultados en su
            situación específica. Los resultados que experimente dependerán de
            muchos factores, incluidos, entre otros, su nivel de responsabilidad
            personal, compromiso y habilidades, además de aquellos factores que
            usted y / o EXIMIO quizás no puedan anticipar.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Daremos testimonios honestos a nuestros visitantes
            independientemente de cualquier descuento. Cualquier producto o
            servicio que probamos son experiencias individuales, que reflejan
            experiencias de la vida real. Los testimonios pueden mostrarse en
            audio, texto o video y no son necesariamente representativos de
            todos aquellos que usarán nuestros productos y / o servicios.
          </Typography>
          <Typography variant="body2" gutterBottom>
            EXIMIO no garantiza los mismos resultados que los testimonios
            proporcionados en nuestra plataforma. Los testimonios presentados en
            EXIMIO son aplicables a las personas que los escriben y pueden no
            ser indicativos del éxito futuro de otras personas.
          </Typography>
          <Typography variant="body2" gutterBottom>
            No dude en ponerse en contacto con nosotros si desea obtener más
            información sobre testimonios, descuentos o cualquiera de los
            productos / servicios que revisamos.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Su consentimiento
          </Typography>
          <Typography variant="body2" gutterBottom>
            Hemos actualizado nuestro Descargo de Responsabilidad para brindarle
            total transparencia sobre lo que se establece cuando visita nuestro
            sitio y cómo se utiliza. Al utilizar nuestra plataforma, registrar
            una cuenta o realizar una compra, por la presente acepta nuestro
            Descargo de Responsabilidad y acepta sus términos.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Cambios en nuestro Descargo de Responsabilidad
          </Typography>
          <Typography variant="body2" gutterBottom>
            Podemos actualizar, enmendar o realizar cambios en este documento
            para que reflejen con precisión nuestro Servicio y nuestras
            políticas. A menos que la ley exija lo contrario, esos cambios se
            publicarán de manera destacada aquí. Luego, si continúa utilizando
            el Servicio, estará sujeto al Descargo de responsabilidad
            actualizado. Si no desea aceptar este o cualquier descargo de
            responsabilidad actualizado, puede eliminar su cuenta.
          </Typography>

          <Typography variant="h6" style={{ marginTop: 20 }} gutterBottom>
            Contáctenos
          </Typography>
          <Typography variant="body2" gutterBottom>
            No dude en contactarnos si tiene alguna pregunta.
          </Typography>
          <Typography variant="body2" gutterBottom component="ul">
            <Typography variant="body2" gutterBottom component="li">
              A través de correo electrónico: apoyo@eximio.com.pe
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              A través del número de teléfono: +51 (01) 480 1790
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              A través de este enlace: https://eximio.com.pe
            </Typography>
            <Typography variant="body2" gutterBottom component="li">
              A través de esta dirección: CAL.26 MZA. L2 LOTE. 42 URB. EL PINAR
              LIMA - LIMA - COMAS
            </Typography>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary
          border={Colors.GREEN}
          color={Colors.GREEN}
          size="14px"
          onClick={close}
          width="20%"
        >
          Cerrar
        </ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
};
