export const PATH = {
  AUTH_GOOGLE: `/api/auth/google`,
  USER_LOGIN: "/api/user/login",
  USER_RESET_PASSWORD: "/api/user/resetPassword",
  USER_CREATE: "/api/user/create",
  USER_VERIFY: "/api/user/verify",
  COMUN_LOGO: "/api/logo/company",
  COMUN_SEARCH_DOCUMENT: "/api/search/document/pdf/info",
  COMUN_DOWNLOAD_XML: "/api/receiver/download/xml",
  COMUN_DOWNLOAD_PDF: "/api/download/document/pdf",
  COMUN_DOWNLOAD_CDR: "/api/receiver/download/cdr",
  COMUN_DOCUMENTS_CUSTOMER: "/api/documents/customer",
  COMUN_DOCUMENTS_SUPPLIER: "/api/documents/supplier",
  COMPANY_INFO: "/api/company/info",
  COMPANY_INFOLIST: "/api/company/infoList",
  PAYMENT_VOUCHER: "/api/payment/voucher",
  PAYMENT_VOUCHER_PDF: "/api/payment/voucher/pdf",
  PAYMENT_INVOICE: "/api/payment/invoice",
  PAYMENT_RECEIPT_PDF: "/api/receipt/payment/pdf",
  REPORTS_GENERAL: "/api/general/report",
  REPORTS_DOWNLOAD_EMISSION: "/api/report/download/emission",
  REPORTS_DOWNLOAD_FINANCIAL: "/api/report/download/financial",
  REPORTS_DOWNLOAD_EMAILS: "/api/reports/download/emails",
  REPORTS_DOWNLOAD_TXT: "/api/emission/download/txt",
  RECEPTION_DOCUMENTS: "/api/reception/documents",
  EMISSION_DOCUMENTS: "/api/emission/documents",
};
