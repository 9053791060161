import React, { useEffect } from "react";
import { LocationOn } from "@material-ui/icons";
import {
  InputLabel,
  CircularProgress,
  TextField,
  Paper,
  Card,
  CardContent,
  Grid,
  Box,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Colors } from "../../static/colors";
import { direcciones } from "../../static/ubigeo";
import { isValidTelephoneNumber } from "../../static/util";
import { CompanyInfo } from "../../../domain/store/types/companies";
import ConfirmDialog from "../confirmDialog";
import axios from "axios";
import { store } from "../../../domain/store/configureStore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextStyle from "../textStyle";
import { InputNumber, InputText } from "../input";
import { ButtonPrimary } from "../button";
import CustomSwitch from "../switch";

interface Props {
    activeIndex: number;
}
interface Ubicacion {
  code: string;
  departamento: string;
  provincia: string;
  distrito: string;
}
export const CompanyInformation: React.FC<Props> = ({activeIndex}) => {
  const [companyRUC, setCompanyRUC] = React.useState("");
  const [legalName, setLegalName] = React.useState<string>("");
  const [tradeName, setTradeName] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [addressLine, setAddressLine] = React.useState<string>("");
  const [errorMsgLegalName, setErrorMsgLegalName] = React.useState<
    string | null
  >(null);
  const [errorMsgTradeName, setErrorMsgTradeName] = React.useState<
    string | null
  >(null);
  const [errorMsgPhone, setErrorMsgPhone] = React.useState<string | null>(null);
  const [errorMsgAddressLine, setErrorMsgAddressLine] = React.useState<
    string | null
  >(null);
  const [hasLegalNameErrors, setHasLegalNameErrors] = React.useState(false);
  const [hasTradeNameErrors, setHasTradeNameErrors] = React.useState(false);
  const [hasPhoneErrors, setHasPhoneErrors] = React.useState(false);
  const [hasAddressLineErrors, setHasAddressLineErrors] = React.useState(false);
  const [successMsgTryingInfoUpdate, setSuccessMsgTryingInfoUpdate] =
    React.useState<string | null>(null);
  const [errorMsgTryingInfoUpdate, setErrorMsgTryingInfoUpdate] =
    React.useState<string | null>(null);
  const [successMsgTryingInfoSettingUpdate, setSuccessMsgTryingInfoSettingUpdate] =
    React.useState<string | null>(null);
  const [errorMsgTryingInfoSettingUpdate, setErrorMsgTryingInfoSettingUpdate] =
    React.useState<string | null>(null);
  const [openConfirmDialogInfo, setOpenConfirmDialogInfo] =
    React.useState<boolean>(false);
  const [loadingInfo, setLoadingInfo] = React.useState<boolean>(false);
  const [loadingConfigInfo, setLoadingConfigInfo] = React.useState<boolean>(false);
  const [direccionUbigeo, setDireccionUbigeo] = React.useState<
    string | undefined
  >("");
  const [selectUbigeo, setSelectUbigeo] = React.useState<Ubicacion | null>(
    null
  );
  const [switchDiscountPerItem, setSwitchDiscountPerItem] =
   React.useState<boolean>(false);
   const [switchGlobalDiscount, setSwitchGlobalDiscount] =
   React.useState<boolean>(false);
   const [switchAdvances, setSwitchAdvances] =
   React.useState<boolean>(false);
   const [openConfirmDialogConfigInfo, setOpenConfirmDialogConfigInfo] =
   React.useState<boolean>(false);
   

  const companies = useSelector((state: any) => state.companiesInfo);

  const saveInfo = async () => {
    if (!loadingInfo) {
      // close confirm dialog
      setOpenConfirmDialogInfo(false);
      // set loading
      setLoadingInfo(true);
      // clear messages status
      clearMsgs();

      if (
        checkLegalName() &&
        checkTradeName() &&
        checkPhoneNumber() &&
        checkAddressLine() &&
        direccionUbigeo
      ) {
        const body: any = {
          ruc: companyRUC,
          legal_name: legalName,
          trade_name: tradeName,
          phone_number: phoneNumber,
          address: addressLine,
          ubigeo: direccionUbigeo,
        };
        let { data } = await axios.post("/api/company/update", body);
        if (data.result) {
          let company: CompanyInfo = companies.find(
            (c: CompanyInfo) => c.ruc === body.ruc
          );
          company.legal_name = body.legal_name;
          company.trade_name = body.trade_name;
          company.phone_number = body.phone_number;
          company.address_line = body.address;
          company.ubigeo = body.ubigeo;
          store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
          setSuccessMsgTryingInfoUpdate("Información actualizada");
        } else {
          setErrorMsgTryingInfoUpdate(
            `Ha ocurrido un error inesperado: ${data.msg}`
          );
        }
      } else {
        setErrorMsgTryingInfoUpdate(
          "Debe llenear todos los campos correctamente"
        );
      }

      setLoadingInfo(false);
    }
  };

  const setCompanyData = (company: CompanyInfo) => {
    // clear messages status
    clearMsgs();
    // companyRUC
    setCompanyRUC(company.ruc.toString());
    // legal name
    setLegalName(company.legal_name);
    // trade name
    setTradeName(company.trade_name);
    // phone number
    setPhoneNumber(company.phone_number);
    // address
    setAddressLine(company.address_line);

    const codeUbigeo = direcciones.find((e) => e.code === company.ubigeo);
    if (codeUbigeo) {
      setSelectUbigeo(codeUbigeo);
      setDireccionUbigeo(codeUbigeo.code);
    }
    if(company.company_setting){
      // discount per item 
      setSwitchDiscountPerItem(company.company_setting["discounts_items"]);
      setSwitchGlobalDiscount(company.company_setting["discounts_total"]);
      setSwitchAdvances(company.company_setting["prepaid_search"]);
    }
    else{
      setSwitchDiscountPerItem(false);
      setSwitchGlobalDiscount(false);
      setSwitchAdvances(false);
    }
  };

  const checkLegalName = () => {
    // clear messages status
    clearMsgs();
    if (legalName.trim() === "" || legalName.trim().length <= 3) {
      setHasLegalNameErrors(true);
      setErrorMsgLegalName("Debes especificar un Nombre Legal correcto");
      return false;
    } else {
      setErrorMsgLegalName(null);
      setHasLegalNameErrors(false);
      return true;
    }
  };

  const checkTradeName = () => {
    // clear messages status
    clearMsgs();
    if (tradeName.trim() === "" || tradeName.trim().length <= 3) {
      setHasTradeNameErrors(true);
      setErrorMsgTradeName("Debes especificar un Nombre Comercial correcto");
      return false;
    } else {
      setErrorMsgTradeName(null);
      setHasTradeNameErrors(false);
      return true;
    }
  };

  const checkPhoneNumber = () => {
    // clear messages status
    clearMsgs();
    if (phoneNumber.trim() === "" || !isValidTelephoneNumber(phoneNumber)) {
      setHasPhoneErrors(true);
      setErrorMsgPhone("Debes especificar un número de contacto correcto");
      return false;
    } else {
      setErrorMsgPhone(null);
      setHasPhoneErrors(false);
      return true;
    }
  };

  const checkAddressLine = () => {
    // clear messages status
    clearMsgs();
    if (addressLine.trim() === "" || addressLine.trim().length <= 3) {
      setHasAddressLineErrors(true);
      setErrorMsgAddressLine("Debes especificar un Domicilio fiscal correcto");
      return false;
    } else {
      setErrorMsgAddressLine(null);
      setHasAddressLineErrors(false);
      return true;
    }
  };

  const clearMsgs = () => {
    setSuccessMsgTryingInfoUpdate("");
    setErrorMsgTryingInfoUpdate("");
    setSuccessMsgTryingInfoSettingUpdate("");
    setErrorMsgTryingInfoSettingUpdate("");
  };

  const handleChangeSwitchDiscountPerItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchDiscountPerItem(event.target.checked);
  }

  const handleChangeSwitchGlobalDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchGlobalDiscount(event.target.checked);
  }

  const handleChangeSwitchAdvances = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchAdvances(event.target.checked);
  }

  const saveConfigInfo = async () =>{
    if(!loadingConfigInfo){
      setOpenConfirmDialogConfigInfo(false);
      setLoadingConfigInfo(true);
      clearMsgs();
      const body: any = {
        ruc: companyRUC,
        discount_per_item: switchDiscountPerItem,
        global_discount: switchGlobalDiscount,
        advances: switchAdvances
      };
      let { data } = await axios.post("/api/company/setting/update", body);
      if (data.result) {
        let company: CompanyInfo = companies.find(
          (c: CompanyInfo) => c.ruc === body.ruc
        );
        company.company_setting = {
            "discounts_items": body.discount_per_item,
            "discounts_total": body.global_discount,
            "prepaid_search": body.advances,
        }
        store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
        setSuccessMsgTryingInfoSettingUpdate("Información actualizada");
      } else {
        setErrorMsgTryingInfoSettingUpdate(
          `Ha ocurrido un error inesperado: ${data.msg}`
        );
      }
      setLoadingConfigInfo(false);
    }
  }

  useEffect(() => {
    const current_company = companies[activeIndex];
    if (current_company) {
        setCompanyData(current_company);
    }
  },[activeIndex]);

  return (
    <div>
        {companies.map((company: CompanyInfo, index: number)=>(
            <TabPanel value={activeIndex} index={index} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Card
                      variant="outlined"
                      className="cardDireccion"
                      style={{
                      display: "flex",
                      flexDirection: "column",
                      }}
                  >
                      <CardContent>
                      <TextStyle bold={600} type="h2">
                          Informaci&oacute;n de la empresa
                      </TextStyle>
                      <br />
                      <TextStyle bold={400} type="h4">
                          Agrega los datos requeridos para la facturación
                      </TextStyle>
                      <InputText
                          value={legalName}
                          name="legalName"
                          placeholder="Nombre legal"
                          helperText={errorMsgLegalName}
                          error={hasLegalNameErrors}
                          onChange={(e) => {
                          setLegalName(e.target.value);
                          checkLegalName();
                          }}
                      />
                      <InputText
                          value={tradeName}
                          name="tradeName"
                          onChange={(e) => {
                          setTradeName(e.target.value);
                          checkTradeName();
                          }}
                          placeholder="Nombre comercial"
                          helperText={
                          errorMsgTradeName ||
                          "Es el nombre que usaremos para presentarte ante tus clientes"
                          }
                          error={hasTradeNameErrors}
                      />
                      <InputNumber
                          value={phoneNumber}
                          name="phoneNumber"
                          onChange={(e) => {
                          setPhoneNumber(e.target.value);
                          checkPhoneNumber();
                          }}
                          placeholder="Teléfono de contacto"
                          helperText={errorMsgPhone}
                          error={hasPhoneErrors}
                          maxLength={9}
                      />
                      <InputText
                          value={addressLine}
                          name="addressLine"
                          onChange={(e) => {
                          setAddressLine(e.target.value);
                          checkAddressLine();
                          }}
                          placeholder="Domicilio fiscal"
                          helperText={errorMsgAddressLine}
                          error={hasAddressLineErrors}
                      />
                      <div style={{ paddingBottom: "10px" }}>
                          <Autocomplete
                          id="combo-box"
                          options={direcciones}
                          getOptionLabel={(option) =>
                              `${option.departamento.toUpperCase()}-${option.provincia.toUpperCase()}-${option.distrito.toUpperCase()}`
                          }
                          fullWidth={true}
                          popupIcon={<LocationOn />}
                          renderInput={(params) => (
                              <TextField
                              {...params}
                              label="Ubigeo"
                              placeholder="Buscar..."
                              variant="outlined"
                              />
                          )}
                          renderOption={(option) => (
                              <React.Fragment>
                              <Paper style={{ width: "100%" }}>
                                  <div style={{ padding: "5px" }}>
                                  {option.departamento.toUpperCase()}-
                                  {option.provincia.toUpperCase()}-
                                  {option.distrito.toUpperCase()}
                                  <div>
                                      <small style={{ color: "#777" }}>
                                      {option.code}
                                      </small>
                                  </div>
                                  </div>
                              </Paper>
                              </React.Fragment>
                          )}
                          PaperComponent={({ children }) => (
                              <Card style={{ background: "#f3f3f3" }}>
                              <CardContent>{children}</CardContent>
                              </Card>
                          )}
                          size="small"
                          value={selectUbigeo}
                          onChange={(event, value) => {
                              setSelectUbigeo(value);
                              setDireccionUbigeo(value?.code);
                          }}
                          />
                      </div>
                      <div
                          style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          }}
                      >
                          {(errorMsgTryingInfoUpdate !== "" ||
                          successMsgTryingInfoUpdate !== "") && (
                          <InputLabel
                              style={{
                              color:
                                  errorMsgTryingInfoUpdate !== ""
                                  ? Colors.RED
                                  : Colors.GREEN,
                              fontSize: "0.75rem",
                              marginLeft: 14,
                              marginTop: 8,
                              marginRight: 10,
                              }}
                          >
                              {errorMsgTryingInfoUpdate}
                              {successMsgTryingInfoUpdate}
                          </InputLabel>
                          )}
                          <ButtonPrimary
                          onClick={() => {
                              if (
                              checkLegalName() &&
                              checkTradeName() &&
                              checkPhoneNumber() &&
                              checkAddressLine() &&
                              direccionUbigeo
                              ) {
                              setOpenConfirmDialogInfo(true);
                              }
                          }}
                          background={Colors.GREEN}
                          color={Colors.WHITE}
                          size="14px"
                          width="100px"
                          disabled={loadingInfo}
                          >
                          {!loadingInfo ? (
                              "Guardar"
                          ) : (
                              <CircularProgress
                              variant="indeterminate"
                              size={14}
                              style={{ marginRight: 5, color: Colors.WHITE }}
                              />
                          )}
                          </ButtonPrimary>
                      </div>
                      </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Card
                    variant="outlined"
                    className="cardDireccion"
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    }}
                >
                    <CardContent>
                    <TextStyle bold={600} type="h2">
                        Configuración de la empresa
                    </TextStyle>
                    <br />
                    
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CustomSwitch
                    checked={switchDiscountPerItem}
                    name="switchDiscountPerItem"
                    placeholder="HABILITAR DESCUENTOS POR ITEM"
                    onChange={handleChangeSwitchDiscountPerItem}
                    selectHandle={false}
                  />
                </div>
                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CustomSwitch
                    checked={switchGlobalDiscount}
                    name="switchGlobalDiscount"
                    placeholder="HABILITAR DESCUENTO GLOBAL"
                    onChange={handleChangeSwitchGlobalDiscount}
                    selectHandle={false}
                  />
                </div> */}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CustomSwitch
                    checked={switchAdvances}
                    name="switchAdvances"
                    placeholder="HABILITAR ANTICIPOS"
                    onChange={handleChangeSwitchAdvances}
                    selectHandle={false}
                  />
                </div>
                    
                <div style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          }}
                          >
                {(errorMsgTryingInfoSettingUpdate !== "" ||
                          successMsgTryingInfoSettingUpdate !== "") && (
                          <InputLabel
                              style={{
                              color:
                                  errorMsgTryingInfoSettingUpdate !== ""
                                  ? Colors.RED
                                  : Colors.GREEN,
                              fontSize: "0.75rem",
                              marginLeft: 14,
                              marginTop: 8,
                              marginRight: 10,
                              }}
                          >
                              {errorMsgTryingInfoSettingUpdate}
                              {successMsgTryingInfoSettingUpdate}
                          </InputLabel>
                          )}
                        <ButtonPrimary
                        onClick={() => {
                            setOpenConfirmDialogConfigInfo(true);
                        }}
                        background={Colors.GREEN}
                        color={Colors.WHITE}
                        size="14px"
                        width="100px"
                        disabled={loadingInfo}
                        >
                        {!loadingConfigInfo ? (
                            "Guardar"
                        ) : (
                            <CircularProgress
                            variant="indeterminate"
                            size={14}
                            style={{ marginRight: 5, color: Colors.WHITE }}
                            />
                        )}
                        </ButtonPrimary>
                    </div>
                    </CardContent>
                </Card>
                </Grid>  
              </Grid>
            </TabPanel>
        ))
        }

      <ConfirmDialog
        title="¿Desea actualizar esta información?"
        body="Los datos asociados a esta empresa serán modificados"
        acceptBtnText="Confirmar"
        open={openConfirmDialogInfo}
        handleClose={() => setOpenConfirmDialogInfo(false)}
        handleAccept={saveInfo}
      />
      <ConfirmDialog
        title="¿Desea actualizar la configuración?"
        body="La configuración asociados a esta empresa serán modificadas"
        acceptBtnText="Confirmar"
        open={openConfirmDialogConfigInfo}
        handleClose={() => setOpenConfirmDialogConfigInfo(false)}
        handleAccept={saveConfigInfo}
      />
    </div>
  );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        key={value}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
