import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogTitle,
} from "@material-ui/core";
import {
  EmailTwoTone as SentIcon,
  EmailTwoTone as RecivedIcon,
  DraftsTwoTone as ReadIcon,
  CancelScheduleSendTwoTone as UnsentIcon,
} from "@material-ui/icons";
import { RButton } from "../ratifikaElements";
import { EmailState } from "../../store/types/emittedDoc";
import moment from "moment";
import { getEmailStatusDesc } from "../../static/util";
import { Colors } from "../../static/colors";

interface Props {
  open: boolean;
  emailList: EmailState[];
  handleClose: () => void;
}

export const EmailHistory: React.FC<Props> = ({ open, emailList, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{`Historial de enviados`}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Estado</strong>
                </TableCell>
                <TableCell>
                  <strong>Dirección</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Fecha</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emailList
                .sort((a, b) => {
                  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                })
                .map((mail) => (
                  <TableRow key={`${mail.email}${mail.created_at}`}>
                    <TableCell component="th" scope="row">
                      <div
                        style={{
                          justifyContent: "flex-start",
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        {mail.state === 1 && (
                          <SentIcon
                            style={{
                              fontSize: 16,
                              color: Colors.pending,
                              marginRight: 5,
                              marginTop: 2,
                            }}
                          />
                        )}
                        {mail.state === 2 && (
                          <RecivedIcon
                            style={{
                              fontSize: 16,
                              color: Colors.successful,
                              marginRight: 5,
                              marginTop: 2,
                            }}
                          />
                        )}
                        {mail.state === 3 && (
                          <ReadIcon
                            style={{
                              fontSize: 16,
                              color: Colors.successful,
                              marginRight: 5,
                              marginTop: 2,
                            }}
                          />
                        )}
                        {[-1, -2].includes(mail.state) && (
                          <UnsentIcon
                            style={{
                              fontSize: 16,
                              color: Colors.alert,
                              marginRight: 5,
                              marginTop: 2,
                            }}
                          />
                        )}{" "}
                        {getEmailStatusDesc(mail.state)}
                      </div>
                    </TableCell>
                    <TableCell>{mail.email}</TableCell>
                    <TableCell align="right">
                      {moment(mail.created_at).format("DD/MM/YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <RButton variant="contained" color="primary" onClick={handleClose}>
          Cerrar
        </RButton>
      </DialogActions>
    </Dialog>
  );
};
