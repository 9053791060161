import axios from "axios";
import { PATH } from "../http/Path";

export const companyRepository = {
  getCompanyInfo: async (company: string) => {
    const response = await axios.get(`${PATH.COMPANY_INFO}/${company}`);
    return response;
  },
  getCompanyInfoList: async () => {
    const response = await axios.get(`${PATH.COMPANY_INFOLIST}`);
    return response;
  },
};
