import { withStyles } from "@material-ui/core";
import { Colors, Sizes } from "src/infrastructure/static/colors";
import styled from "styled-components";
import MuiAccordion from "@material-ui/core/Accordion";

export const ContainerFilter = styled.div`
  min-height: 90vh;
  height: 100%;
  border-right: 1px solid ${Colors.PURPLE};
  padding: 20px 0px;

  @media ${Sizes.TABLET} {
    min-height: auto;
    height: auto;
    border-right: none;
  }
`;

export const ContainerLayout = styled.div`
  /* padding: 50px 20px; */
  height: 100%;
`;

export const Accordion = withStyles({
  root: {
    boxShadow: "none",
    padding: 0,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);
