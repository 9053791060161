import React from "react";
import DownloadReception from "../downloadReception";
import { ReceivedDocList } from "../receivedDocList";
import { UnadjudicatedDocs } from "../unadjudicatedDocs";

const DashboardReception = () => {
  return (
    <div>
      <DownloadReception />
      <ReceivedDocList />
      <UnadjudicatedDocs />
    </div>
  );
};

export default DashboardReception;
