import React from "react";
import FormNewGuieRemission from "../components/formNewGuieRemission";
import Header from "../components/header";

const NewGuieRemission = () => {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <Header />
      <FormNewGuieRemission />
    </div>
  );
};

export default NewGuieRemission;
