import React, { useState, useEffect } from "react";
import {
  Grid,
  Slide,
  Link,
  Typography,
  Card,
  CardContent,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { RButton, RFormControl } from "../../components/ratifikaElements";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import logo from "../assets/imgs/ratifikaLogo.svg";
import expired from "../assets/imgs/expired.png";
import successful from "../assets/imgs/password.png";
import { Colors } from "../static/colors";
import axios from "axios";

export const ResetPassword: React.FC<{ token: string }> = ({ token }) => {
  const [validToken, setValidToken] = useState<boolean | null>(null);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorPasswordMsg, setErrorPasswordMsg] = useState<string | null>(null);
  const [passwordSet, setPasswordSet] = useState<boolean | null>(null);

  useEffect(() => {
    async function validateToken() {
      const { data } = await axios.post(`/api/user/validateTokenPassword/${token}`);
      setValidToken(data.result);
    }
    validateToken();
  }, []);

  const handleResetPassword = async () => {
    if (password.length < 8) {
      setErrorPasswordMsg("Debe usar una contraseña válida, al menos 8 caracteres");
      return;
    }
    const { data } = await axios.post("/api/user/setNewPassword/", {
      token,
      newPassword: password,
    });
    if (data.result) {
      setPasswordSet(true);
    } else {
      setValidToken(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: "10px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img width="100px" src={logo} alt="Ratifika" />
      </div>
      <div style={styles.contentBody}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sm={2}></Grid>
          <Grid item xs={12} md={4} sm={6} style={{ padding: 25, paddingTop: 60 }}>
            <Card>
              {validToken === true && passwordSet !== true && (
                <CardContent>
                  <Typography
                    variant="h6"
                    style={{
                      fontFamily: "Corbel",
                      color: Colors.textOlive,
                    }}
                  >
                    Ingresa tu nueva contraseña
                  </Typography>
                  <RFormControl
                    variant="outlined"
                    style={{ marginTop: 15, marginBottom: 6, width: "100%" }}
                  >
                    <InputLabel
                      htmlFor="outlined-password"
                      style={{ paddingRight: 10, backgroundColor: "white" }}
                    >
                      Nueva contraseña *
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-password"
                      required
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(event.target.value);
                        if (errorPasswordMsg !== null) {
                          if (event.target.value.length >= 8) {
                            setErrorPasswordMsg(null);
                          }
                        }
                      }}
                      error={errorPasswordMsg !== null}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                              event.preventDefault()
                            }
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </RFormControl>

                  {errorPasswordMsg !== null && (
                    <InputLabel style={{ color: "#f44336", fontSize: "0.75rem", marginLeft: 14 }}>
                      {errorPasswordMsg}
                    </InputLabel>
                  )}

                  <RButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: 10, marginTop: 35 }}
                    onClick={handleResetPassword}
                  >
                    Restablecer contraseña
                  </RButton>
                </CardContent>
              )}
              {validToken === false && (
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      color: Colors.textOlive,
                    }}
                  >
                    Sección expirada
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: Colors.textOlive,
                    }}
                  >
                    Parece que no se ha podido validar su solicitud, por favor vuelva a solicitar
                    una nueva contraseña
                  </Typography>
                  <div style={{ textAlign: "center", padding: 20 }}>
                    <img style={{ width: "100px" }} src={expired} alt="Expirada" />
                  </div>
                  <Link href="/" variant="body2">
                    {"Ir a la página principal"}
                  </Link>
                </CardContent>
              )}
              {passwordSet === true && (
                <Slide in={passwordSet} direction="left">
                  <CardContent>
                    <Typography
                      variant="h5"
                      style={{
                        color: Colors.textOlive,
                      }}
                    >
                      Contraseña actualizada
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: Colors.textOlive,
                      }}
                    >
                      Puedes iniciar sesión con tu nueva contraseña
                    </Typography>
                    <div style={{ textAlign: "center" }}>
                      <img style={{ width: "200px" }} src={successful} alt="Exito" />
                    </div>

                    <Link href="/" variant="body2">
                      {"Ir a la página principal"}
                    </Link>
                  </CardContent>
                </Slide>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sm={2}></Grid>
        </Grid>
      </div>
    </>
  );
};

let styles = {
  contentBody: {
    padding: "1em",
  },
};

export default ResetPassword