import axios from "axios";
import {
  IUserForgotPassword,
  IUserLogin,
  IUserRegister,
  IUserVerification,
} from "../../domain/models/User";
import { PATH } from "../http/Path";

export const userRepository = {
  postUserLogin: async (data: IUserLogin) => {
    const response = axios.post(PATH.USER_LOGIN, data);
    return response;
  },
  postUserForgotPassword: async (data: IUserForgotPassword) => {
    const response = axios.post(PATH.USER_RESET_PASSWORD, {
      email: data.emailForgotPassword,
    });
    return response;
  },
  postUserCreate: async (data: IUserRegister) => {
    const response = axios.post(PATH.USER_CREATE, {
      ...data,
      phone_number: data.phone,
    });
    return response;
  },
  postUserVerify: async (data: IUserVerification) => {
    const response = axios.post(PATH.USER_VERIFY, data);
    return response;
  },
};
