import { AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";
import { subDays } from "date-fns";
import moment from "moment";
import React from "react";
import { Colors } from "src/infrastructure/static/colors";
import { ButtonPrimary, ButtonSecondary } from "../button";
import TextStyle from "../textStyle";
import TitleFilter from "../titleFilter";
import { Accordion, ContainerFilter, ContainerLayout } from "./styled";
import {
  ArrowRightAlt as ArrowDateIcon,
  Today as DateRangeIcon,
  ExpandMore,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import CustomCheckbox from "../checkbox";
import { InputNumber, InputText } from "../input";
import { useForm } from "src/infrastructure/hooks/useForm";
import { setConfigParam } from "src/domain/store/actions/config";
import { emissionService } from "src/domain/services/Emission.service";
import { receptionService } from "src/domain/services/Reception.service";
import { reportsService } from "src/domain/services/Reports.service";
import ModalDateRange from "../modalDateRange";
import { comunService } from "src/domain/services/Comun.service";

interface ILayoutFilterProps {
  type: string;
  children: React.ReactNode;
  title: string;
  componentLoading: any;
}

interface Contributor {
  number_type: string;
  number: string;
  legal_name: string;
  id: number;
}

const LayoutFilter: React.FC<ILayoutFilterProps> = ({
  title,
  type,
  children,
  componentLoading: ComponentLoading,
}) => {
  const dispatch = useDispatch();
  const config = useSelector((state: any) => state.config);

  const [showLoading, setShowLoading] = React.useState(false);
  const [openDateRange, setOpenDateRange] = React.useState(false);

  const { form: formNumeral, onChange: onChangeNumeral } = useForm({
    filterSerie: config[`${type}Serie`] || "",
    filterSequentialStart: config[`${type}SequentialStart`] || "",
    filterSequentialEnd: config[`${type}SequentialEnd`] || "",
  });

  const filterDocumentType = config[`${type}DocumentType`]
    ? config[`${type}DocumentType`].split(",")
    : [];

  const [documentTypes, setDocumentsTypes] = React.useState({
    invoice: filterDocumentType.includes("01"),
    ticket: filterDocumentType.includes("03"),
    creditNote: filterDocumentType.includes("07"),
    debitNote: filterDocumentType.includes("08"),
    guie: filterDocumentType.includes("09"),
    retention: filterDocumentType.includes("20"),
    perception: filterDocumentType.includes("40"),
  });

  const filterDocumentStatus = config[`${type}DocumentStatus`]
    ? config[`${type}DocumentStatus`].replace("1-3", "1,3").split(",")
    : [];

  const [documentStatus, setDocumentStatus] = React.useState({
    authorized:
      filterDocumentStatus.includes("1") || filterDocumentStatus.includes("3"),
    canceled: filterDocumentStatus.includes("2"),
    pendant: filterDocumentStatus.includes("0"),
    wrong: filterDocumentStatus.includes("-1"),
    rejected: filterDocumentStatus.includes("-2"),
  });

  const [availableCustomers, setAvailableCustomers] = React.useState<
    Array<Contributor>
  >([]);

  const [autocompleteCustomers, setAutocompleteCustomers] = React.useState<
    Array<Contributor>
  >([]);

  const [buyerKey, setBuyerKey] = React.useState<string>(
    config[`${type}ClientName`] || ""
  );

  const [name, setName] = React.useState<string | null>(
    config[`${type}ClientName`] || ""
  );

  const [filterClientId, setfilterClientId] = React.useState<number | null>(
    config[`${type}ClientId`] || null
  );

  const [dateRange, setDateRange] = React.useState([
    {
      startDate: subDays(new Date(), 1),
      endDate: subDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const handleCheckDocumentType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDocumentsTypes({
      ...documentTypes,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCheckDocumentStatus = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDocumentStatus({
      ...documentStatus,
      [event.target.name]: event.target.checked,
    });
  };

  const getCustomersSuggestions = async (
    value: string
  ): Promise<Contributor[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results = [];

    if (Number.isInteger(Number(inputValue))) {
      // posiblemente sea un RUC
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter(
                (contrib) => contrib.number.slice(0, inputLength) === inputValue
              )
              .sort((a, b) => {
                return (
                  a.number.indexOf(inputValue) - b.number.indexOf(inputValue)
                );
              });
    } else {
      // Posiblemente sea un nombre legal
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter((contrib) =>
                contrib.legal_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
              .sort((a, b) => {
                return (
                  a.legal_name.indexOf(inputValue) -
                  b.legal_name.indexOf(inputValue)
                );
              });
    }

    return results;
  };

  const onSuggestionsFetchRequested = async (param: any) => {
    setAutocompleteCustomers(await getCustomersSuggestions(param.value));
  };

  const onSuggestionsClearRequested = async () => {
    setAutocompleteCustomers([]);
    await handlerCustomers();
  };

  const renderSuggestion = (suggestion: Contributor, { query }: any) => {
    const handleNumber = Number.isInteger(Number(query));
    const suggestionText = handleNumber
      ? suggestion.number
      : suggestion.legal_name;

    const indexOfSuggestion = suggestionText
      .toLowerCase()
      .indexOf(query.toLowerCase());
    const substring = suggestionText.substring(
      indexOfSuggestion,
      indexOfSuggestion + query.length
    );
    const highlight = suggestionText.replace(
      substring,
      `<strong ${
        handleNumber ? `style="font-size: 11px"` : ""
      }>${substring}</strong>`
    );
    return (
      <div>
        {!handleNumber ? (
          <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
        ) : (
          <span style={{ fontSize: 11 }}>{suggestion.legal_name}</span>
        )}
        <br></br>
        {handleNumber ? (
          <span
            style={{ fontSize: 11 }}
            dangerouslySetInnerHTML={{ __html: highlight }}
          ></span>
        ) : (
          <strong style={{ fontSize: 11 }}>{suggestion.number}</strong>
        )}
      </div>
    );
  };

  const getSuggestionValue = (suggestion: Contributor) => {
    setfilterClientId(suggestion.id);
    setName(suggestion.legal_name);
    return suggestion.legal_name || suggestion.number;
  };

  const handlerCustomers = async () => {
    if (type === "reports") {
      const response = await comunService.getDocumentsCustomerReport();
      setAvailableCustomers(response);
    }
    if (type === "emitted") {
      const response = await comunService.getDocumentsCustomerEmitted();
      setAvailableCustomers(response);
    }
    if (type === "reception") {
      const response = await comunService.getDocumentsSupplierReception();
      setAvailableCustomers(response);
    }
  };

  const handlerChangeFilter = async () => {
    dispatch(setConfigParam(`${type}Serie`, formNumeral.filterSerie));
    dispatch(
      setConfigParam(
        `${type}SequentialStart`,
        formNumeral.filterSequentialStart
      )
    );
    dispatch(
      setConfigParam(`${type}SequentialEnd`, formNumeral.filterSequentialEnd)
    );

    let filteredDocTypes = "";
    if (documentTypes.invoice) {
      filteredDocTypes = "01";
    }
    if (documentTypes.ticket) {
      filteredDocTypes += filteredDocTypes ? ",03" : "03";
    }
    if (documentTypes.creditNote) {
      filteredDocTypes += filteredDocTypes ? ",07" : "07";
    }
    if (documentTypes.debitNote) {
      filteredDocTypes += filteredDocTypes ? ",08" : "08";
    }
    if (documentTypes.guie) {
      filteredDocTypes += filteredDocTypes ? ",09" : "09";
    }
    if (documentTypes.retention) {
      filteredDocTypes += filteredDocTypes ? ",20" : "20";
    }
    if (documentTypes.perception) {
      filteredDocTypes += filteredDocTypes ? ",40" : "40";
    }

    dispatch(setConfigParam(`${type}DocumentType`, filteredDocTypes));

    let filteredDocStates = "";
    if (documentStatus.authorized) {
      filteredDocStates = "1-3";
    }
    if (documentStatus.canceled) {
      filteredDocStates += filteredDocStates ? ",2" : "2";
    }
    if (documentStatus.pendant) {
      filteredDocStates += filteredDocStates ? ",0" : "0";
    }
    if (documentStatus.wrong) {
      filteredDocStates += filteredDocStates ? ",-1" : "-1";
    }
    if (documentStatus.rejected) {
      filteredDocStates += filteredDocStates ? ",-2" : "-2";
    }
    dispatch(setConfigParam(`${type}DocumentStatus`, filteredDocStates));
    dispatch(setConfigParam(`${type}ClientId`, filterClientId));
    dispatch(setConfigParam(`${type}ClientName`, name));
  };

  const handlerFilter = async () => {
    setShowLoading(true);
    handlerChangeFilter();

    if (type === "reports") {
      await reportsService.getReportsGeneral();
    }
    if (type === "emitted") {
      await emissionService.getEmissionDocuments();
    }
    if (type === "reception") {
      await receptionService.getReceptionDocuments();
    }

    setShowLoading(false);
  };

  React.useEffect(() => {
    handlerFilter();
    handlerCustomers();
  }, [config.workspace]);

  React.useEffect(() => {
    handlerChangeFilter();
  }, [formNumeral, documentTypes, documentStatus, filterClientId, name]);

  return (
    <ContainerLayout>
      <Grid container>
        <Grid item xs={12} md={3}>
          <ContainerFilter>
            <TitleFilter title={title} />
            <div style={{ padding: "20px" }}>
              <TextStyle bold={400} type="h4">
                Filtar por fechas
              </TextStyle>
              <ButtonPrimary
                background={Colors.GREEN}
                width="100%"
                size="20px"
                color={Colors.WHITE}
                onClick={() => setOpenDateRange(true)}
              >
                <DateRangeIcon
                  style={{
                    fontSize: 18,
                    paddingRight: 5,
                    color: "white",
                  }}
                />
                <TextStyle color={Colors.WHITE} type="h4" bold={400}>
                  {moment(config[`${type}StartDate`])
                    .locale("es")
                    .format("MMM DD")}
                </TextStyle>
                <ArrowDateIcon
                  style={{
                    fontSize: 22,
                    paddingLeft: 5,
                    paddingRight: 5,
                    color: "white",
                  }}
                />
                <TextStyle color={Colors.WHITE} type="h4" bold={400}>
                  {moment(config[`${type}EndDate`])
                    .locale("es")
                    .format("MMM DD")}
                </TextStyle>
              </ButtonPrimary>
              <TextStyle bold={400} type="h4">
                Filtrar por{" "}
                {type === "emitted" || type === "reports"
                  ? "cliente"
                  : "proveedor"}
              </TextStyle>
              <Autosuggest
                suggestions={autocompleteCustomers}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                  placeholder: "  Ruc, DNI o nombre",
                  value: buyerKey,
                  onChange: (
                    event: React.FormEvent<any>,
                    { newValue, method }: Autosuggest.ChangeEvent
                  ) => {
                    setBuyerKey(newValue);
                    if (newValue === "") {
                      setfilterClientId(null);
                      setName(null);
                    }
                  },
                  ref: React.createRef<HTMLInputElement>(),
                }}
              />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="paneltipodocumento-content"
                  id="paneltipodocumento-header"
                  style={{ padding: 0 }}
                >
                  <TextStyle bold={400} type="h4">
                    Filtrar por tipo de documento
                  </TextStyle>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="invoice"
                    onChange={handleCheckDocumentType}
                    value={documentTypes.invoice}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Factura
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="ticket"
                    onChange={handleCheckDocumentType}
                    value={documentTypes.ticket}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Boleta
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="creditNote"
                    onChange={handleCheckDocumentType}
                    value={documentTypes.creditNote}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Nota de Crédito
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="debitNote"
                    onChange={handleCheckDocumentType}
                    value={documentTypes.debitNote}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Nota de Débito
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="guie"
                    onChange={handleCheckDocumentType}
                    value={documentTypes.guie}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Guias de remisión remitente
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="retention"
                    onChange={handleCheckDocumentType}
                    value={documentTypes.retention}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Comprobante de retención
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="perception"
                    onChange={handleCheckDocumentType}
                    value={documentTypes.perception}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Comprobante de percepción
                      </TextStyle>
                    )}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panelestadodocumento-content"
                  id="panelestadodocumento-header"
                  style={{ padding: 0 }}
                >
                  <TextStyle bold={400} type="h4">
                    Filtrar por estado de documento
                  </TextStyle>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="authorized"
                    onChange={handleCheckDocumentStatus}
                    value={documentStatus.authorized}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Autorizado
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="canceled"
                    onChange={handleCheckDocumentStatus}
                    value={documentStatus.canceled}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Anulado
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="wrong"
                    onChange={handleCheckDocumentStatus}
                    value={documentStatus.wrong}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Erróneo
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="rejected"
                    onChange={handleCheckDocumentStatus}
                    value={documentStatus.rejected}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Rechazado
                      </TextStyle>
                    )}
                  />
                  <CustomCheckbox
                    color={Colors.GREEN}
                    name="pendant"
                    onChange={handleCheckDocumentStatus}
                    value={documentStatus.pendant}
                    margin={"0px"}
                    renderText={() => (
                      <TextStyle type="h3" bold={400}>
                        Pendiente
                      </TextStyle>
                    )}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panelnumeral-content"
                  id="panelnumeral-header"
                  style={{ padding: 0 }}
                >
                  <TextStyle bold={400} type="h4">
                    Filtrar por numeral
                  </TextStyle>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputText
                    name="filterSerie"
                    onChange={onChangeNumeral}
                    placeholder="Serie"
                    value={formNumeral.filterSerie}
                    maxLength={4}
                  />
                  <InputNumber
                    name="filterSequentialStart"
                    onChange={onChangeNumeral}
                    placeholder="Secuencial Inicio"
                    value={formNumeral.filterSequentialStart}
                    maxLength={8}
                  />
                  <InputNumber
                    name="filterSequentialEnd"
                    onChange={onChangeNumeral}
                    placeholder="Secuencial Final"
                    value={formNumeral.filterSequentialEnd}
                    maxLength={8}
                  />
                </AccordionDetails>
              </Accordion>
              <ButtonSecondary
                border={Colors.GREEN}
                color={Colors.GREEN}
                width="100%"
                size="20px"
                onClick={handlerFilter}
              >
                Filtrar
              </ButtonSecondary>
            </div>
          </ContainerFilter>
        </Grid>
        <Grid item xs={12} md={9} style={{ padding: 20 }}>
          {showLoading ? <ComponentLoading /> : children}
        </Grid>
      </Grid>
      <ModalDateRange
        open={openDateRange}
        handleClose={() => setOpenDateRange(false)}
        type={type}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </ContainerLayout>
  );
};

export default LayoutFilter;
