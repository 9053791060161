import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "src/infrastructure/static/colors";
import { ButtonPrimary, ButtonSecondary } from "../button";
import TextStyle from "../textStyle";
import { ContainerButtons, ContainerTitle } from "./styled";
import FileDownload from "js-file-download";
import { CompanyInfo } from "src/domain/models/Company";
import { toast } from "react-toastify";
import GetAppIcon from "@material-ui/icons/GetApp";
import { UploadReceivedDoc } from "../uploadReceivedDoc";

const DownloadReception = () => {
  const dispatch = useDispatch();
  const companies = useSelector((state: any) => state.companiesInfo);
  const config = useSelector((state: any) => state.config);

  const [disabledBulkDownload, setDisabledBulkDownload] =
    React.useState<boolean>(true);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [openDialogBulkDownload, setOpenDialogBulkDownload] =
    React.useState<boolean>(false);
  const [selectOptionPDF, setSelectOptionPDF] = React.useState<boolean>(false);
  const [selectOptionXML, setSelectOptionXML] = React.useState<boolean>(false);
  const [selectOptionCDR, setSelectOptionCDR] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openDialogRequestProcess, setOpenDialogRequestProcess] =
    React.useState<boolean>(false);
  const [isOpenUploadXML, setIsOpenUploadXML] = React.useState(false);

  const openPopOver = () => {
    setShowAlert(true);
  };

  const handleSelectbulkDownload = () => {
    setOpenDialogBulkDownload(true);
  };

  const handleCloseDialogBulkDownload = () => {
    setOpenDialogBulkDownload(false);
  };

  const handleCloseDialogRequestProcess = () => {
    setOpenDialogRequestProcess(false);
  };

  const generateBulkDownload = async () => {
    setLoading(true);
    const allDocument = await axios.get(
      `/api/reception/documents/all/${config.workspace}?dateOf=${
        config.receptionDateType
      }&startDate=${config.receptionStartDate}&endDate=${
        config.receptionEndDate
      }${
        config.receptionSerie
          ? `&serie=${config.receptionSerie}&seqInit=${config.receptionSequentialStart}&seqEnd=${config.receptionSequentialEnd}`
          : ""
      }${
        config.receptionClientId
          ? `&proveedorId=${config.receptionClientId}`
          : ""
      }${
        config.receptionDocumentType
          ? `&docType=${config.receptionDocumentType}`
          : ""
      }${
        config.receptionDocumentStatus
          ? `&docStatus=${
              config.receptionDocumentStatus.split(",").includes("1-3")
                ? config.receptionDocumentStatus.replace("1-3", "1,3")
                : config.receptionDocumentStatus
            }`
          : ""
      }`
    );

    if (allDocument.data.result) {
      let body = allDocument.data.receivedDocs;
      let downloadLimit = await axios.get(`/api/bulkDownload/files/limit`);
      if (
        downloadLimit.data.result &&
        body.length <= downloadLimit.data.limit
      ) {
        let { data } = await axios.post(
          `/api/bulkDownload/reception/${config.workspace}/${selectOptionPDF}/${selectOptionXML}/${selectOptionCDR}`,
          body,
          {
            responseType: "blob",
          }
        );
        FileDownload(
          new Blob([data]),
          `DM-Doc-Recibidos-${config.workspace}.zip`
        );
        setLoading(false);
        handleCloseDialogBulkDownload();
      } else {
        setLoading(false);
        setOpenDialogRequestProcess(true);
        handleCloseDialogBulkDownload();
        let { data } = await axios.post(
          `/api/bulkDownload/reception/${config.workspace}/${selectOptionPDF}/${selectOptionXML}/${selectOptionCDR}`,
          body
        );
        if (data.result) {
          toast.info(
            "El processo de su ultima consultada de descarga masiva  ya fue generada"
          );
        }
      }
    }
  };

  React.useEffect(() => {
    setDisabledBulkDownload(true);
    if (companies.find((c: CompanyInfo) => c.ruc === config.workspace)) {
      if (
        companies.find((c: CompanyInfo) => c.ruc === config.workspace).customize
          .descargaMasiva === "YES"
      ) {
        setDisabledBulkDownload(false);
      }
    }
  }, [config.workspace, companies]);

  return (
    <ContainerTitle>
      <div>
        <TextStyle bold={700} type="h1" margin="10px 0px 0px 0px">
          Resultado de Documentos Recibidos
        </TextStyle>
      </div>
      <ContainerButtons>
        { !disabledBulkDownload && <ButtonSecondary
          border={Colors.GREEN}
          color={Colors.GREEN}
          onClick={
            disabledBulkDownload ? openPopOver : handleSelectbulkDownload
          }
          size="14px"
        >
          <GetAppIcon />
          Desacarga masiva
        </ButtonSecondary>}
        <ButtonSecondary
          border={Colors.GREEN}
          color={Colors.GREEN}
          disabled={!config.workspace}
          onClick={() => setIsOpenUploadXML(true)}
          size="14px"
        >
          Subir Documento
        </ButtonSecondary>
      </ContainerButtons>
      <Dialog
        open={openDialogBulkDownload}
        onClose={handleCloseDialogBulkDownload}
      >
        <DialogTitle>Seleccione los tipos de archivos a descargar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormGroup
              row
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: Colors.primary }}
                    onChange={() => setSelectOptionPDF(!selectOptionPDF)}
                    checked={selectOptionPDF}
                  />
                }
                label="PDF"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: Colors.primary }}
                    onChange={() => setSelectOptionXML(!selectOptionXML)}
                    checked={selectOptionXML}
                  />
                }
                label="XML"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: Colors.primary }}
                    onChange={() => setSelectOptionCDR(!selectOptionCDR)}
                    checked={selectOptionCDR}
                  />
                }
                label="CDR"
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonSecondary
            border={Colors.GREEN}
            color={Colors.GREEN}
            onClick={handleCloseDialogBulkDownload}
            size="14px"
            width="100px"
          >
            Cancelar
          </ButtonSecondary>
          <ButtonPrimary
            background={Colors.GREEN}
            color={Colors.WHITE}
            onClick={() => {
              if (selectOptionPDF || selectOptionXML || selectOptionCDR) {
                generateBulkDownload();
              }
            }}
            disabled={loading}
            size="14px"
            width="130px"
          >
            {loading ? (
              <>
                <CircularProgress
                  variant="indeterminate"
                  size={14}
                  style={{ marginRight: 5 }}
                />
                Cargando...
              </>
            ) : (
              <>Confirmar</>
            )}
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogRequestProcess}
        onClose={handleCloseDialogRequestProcess}
      >
        <DialogTitle>SOLICITUD POR PROCESAR</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Puedes continuar usando el sistema de Ratifika mientras tu reporte
            se procesa. Te aparecerá una notificación cuando esté listo para
            descargar desde Mis Reportes
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <UploadReceivedDoc
        key={Date.now() + 25}
        title="Carga manual de XML"
        subtitle="Agrega los documentos donde figures como receptor"
        isOpen={isOpenUploadXML}
        closeForm={() => setIsOpenUploadXML(!isOpenUploadXML)}
      />
    </ContainerTitle>
  );
};

export default DownloadReception;
