export interface UnadjudicatedDoc {
  id: number;
  serie: string;
  number: string;
  document_type: string;
  issue_date: string;
}

export const SET_UNADJUDICATED_DOCS = "SET_UNADJUDICATED_DOCS";
export const RESET_UNADJUDICATED_DOCS = "RESET_UNADJUDICATED_DOCS";

interface setUnadjudicatedDocsAction {
  type: typeof SET_UNADJUDICATED_DOCS;
  payload: UnadjudicatedDoc[];
}

interface ResetUnadjudicatedDocsAction {
  type: typeof RESET_UNADJUDICATED_DOCS;
  payload: {};
}

export type UnadjudicatedDocsActionTypes =
  | setUnadjudicatedDocsAction
  | ResetUnadjudicatedDocsAction;
