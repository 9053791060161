import React from "react";
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Colors } from "src/infrastructure/static/colors";

interface CustomListItemProps {
primary: string;
children?: React.ReactNode;
}

const CustomListItem: React.FC<CustomListItemProps> = ({ primary, children }) => {
    const [openItemMenu, setOpenItemMenu] = React.useState(false);
  
    const handleClick = () => {
      setOpenItemMenu(!openItemMenu);
    };

    React.useEffect(() => {
        // Al montar el componente, intenta cargar el estado desde localStorage
        const storedState = localStorage.getItem(primary);
        if (storedState) {
          setOpenItemMenu(JSON.parse(storedState));
        }
      }, [primary]);

      React.useEffect(() => {
        // Al cambiar el estado, guárdalo en localStorage
        localStorage.setItem(primary, JSON.stringify(openItemMenu));
      }, [primary, openItemMenu]);
  
    return (
      <>
        <ListItem button onClick={handleClick}>
          <ListItemText primary={primary} style={{ color: Colors.GREEN }} />
          {openItemMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openItemMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: 8 }}>
            {children}
          </List>
        </Collapse>
      </>
    );
  };

  export default CustomListItem;