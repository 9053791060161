import { PrintTwoTone, TouchApp } from "@material-ui/icons";
import React from "react";
import { toast } from "react-toastify";
import { IEmittedDoc } from "src/domain/models/Emitted";
import { comunService } from "src/domain/services/Comun.service";
import { Colors } from "src/infrastructure/static/colors";
import { statusPayment } from "src/infrastructure/static/util";
import { ButtonSecondary } from "../button";
import ModalPayment from "../modalPayment";
import { ContainerDownload } from "./styled";
import FileDownload from "js-file-download";
import { ISearchDocument } from "src/domain/models/Comun";
import { paymentService } from "src/domain/services/Payment.service";

interface IDownloadFileProps {
  infoFile: IEmittedDoc;
  companyInfo: any;
  dataConsult: ISearchDocument;
  getData: () => void;
}

const DownloadFile: React.FC<IDownloadFileProps> = ({
  infoFile,
  companyInfo,
  dataConsult,
  getData,
}) => {
  const [openModalPayment, setOpenModalPayment] = React.useState(false);
  const [loadingDownload, setLoadingDownload] = React.useState({
    xml: false,
    cdr: false,
    pdf: false,
    print: false,
    payment: false,
    voucher: false,
  });

  const handleLoadingFile = (name: string, value: boolean) => {
    setLoadingDownload({
      ...loadingDownload,
      [name]: value,
    });
  };

  const downloadXML = async () => {
    handleLoadingFile("xml", true);
    const response = await comunService.downloadXML(infoFile.id);
    if (response.data) {
      FileDownload(new Blob([response.data]), `${infoFile.file_name}.zip`);
    } else {
      toast.warn("No se encontró el XML de este Documento", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    handleLoadingFile("xml", false);
  };

  const downloadPDF = async () => {
    handleLoadingFile("pdf", true);
    const response = await comunService.downloadPDF(dataConsult);
    if (response.data.size > 700) {
      FileDownload(new Blob([response.data]), `${infoFile.file_name}.pdf`);
    }
    handleLoadingFile("pdf", false);
  };

  const downloadCDR = async () => {
    handleLoadingFile("cdr", true);
    const response = await comunService.downloadCDR(infoFile.id);
    if (response.data) {
      FileDownload(new Blob([response.data]), `CDR_${infoFile.file_name}.zip`);
    } else {
      toast.warn("No se encontró el CDR de este Documento", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    handleLoadingFile("cdr", false);
  };

  const printVoucher = async () => {
    handleLoadingFile("print", true);
    const response = await comunService.getSearchDocument(dataConsult);
    if (response.data.result) {
      const content: any = await comunService.generatePrintableVoucher(
        infoFile,
        ""
      );
      let winPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0"
      );
      winPrint?.document.write(content);
      winPrint?.document.close();
      winPrint?.focus();
      winPrint?.print();
      winPrint?.close();
    }
    handleLoadingFile("print", false);
  };

  const paymentVoucher = async () => {
    handleLoadingFile("voucher", true);
    let { data } = await paymentService.paymentVoucher(infoFile.file_name);
    if (data.result) {
      const { jsonCargo } = data;
      jsonCargo.logo = companyInfo.logo_path;
      jsonCargo.legal_name = companyInfo.legal_name;
      let res = await paymentService.paymentVoucherPDF(jsonCargo);
      if (res.data.size > 700) {
        //Create a Blob from the PDF Stream
        const file = new Blob([res.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const pdfWindow = window.open();
        if (pdfWindow) {
          pdfWindow.location.href = fileURL;
        }
        handleLoadingFile("voucher", false);
      }
    } else {
      handleLoadingFile("voucher", false);
      toast.error("El voucher de pago no pudo ser generado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const validateOpenPay = () => {
    if (paymentService.hasCredentialsOpenPay(companyInfo, infoFile)) {
      setOpenModalPayment(true);
    } else {
      toast.error(
        "Las credenciales de Openpay aún no han sido configuradas para este tipo de moneda, por favor comuniquese con la empresa para agregar las credenciales en la configuración.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  return (
    <>
      <ContainerDownload>
        <ButtonSecondary
          onClick={() => downloadXML()}
          border={Colors.GREEN}
          color={Colors.GREEN}
          size="14px"
          disabled={loadingDownload.xml}
          loading={loadingDownload.xml}
        >
          XML
        </ButtonSecondary>
        {["1", "2", "3", "-2"].includes(infoFile.state) && (
          <ButtonSecondary
            onClick={downloadCDR}
            border={Colors.GREEN}
            color={Colors.GREEN}
            size="14px"
            disabled={loadingDownload.cdr}
            loading={loadingDownload.cdr}
          >
            CDR
          </ButtonSecondary>
        )}
        <ButtonSecondary
          onClick={downloadPDF}
          border={Colors.GREEN}
          color={Colors.GREEN}
          size="14px"
          disabled={loadingDownload.pdf}
          loading={loadingDownload.pdf}
        >
          PDF
        </ButtonSecondary>
        <ButtonSecondary
          onClick={printVoucher}
          border={Colors.GREEN}
          color={Colors.GREEN}
          size="14px"
          disabled={loadingDownload.print}
          loading={loadingDownload.print}
        >
          <PrintTwoTone
            style={{
              fontSize: 17,
              color: Colors.iconOlive,
            }}
          />
        </ButtonSecondary>
        {["1", "3"].includes(infoFile.state) &&
          ["01", "03", "07", "08"].includes(infoFile.document_type) &&
          JSON.parse(companyInfo?.customize).openPay === "YES" && (
            <>
              {infoFile.payment ? (
                <>
                  {infoFile.payment.status === "pagado" ? (
                    <></>
                  ) : statusPayment(
                      infoFile.payment.status,
                      infoFile.payment.expiration_date
                    ) === "expirado" ? (
                    <ButtonSecondary
                      border={Colors.GREEN}
                      color={Colors.GREEN}
                      size="14px"
                      onClick={validateOpenPay}
                    >
                      <TouchApp
                        style={{
                          fontSize: 20,
                          color: Colors.alert,
                        }}
                      />
                      Pagar
                    </ButtonSecondary>
                  ) : (
                    statusPayment(
                      infoFile.payment.status,
                      infoFile.payment.expiration_date
                    ) === "pendiente" && (
                      <ButtonSecondary
                        border={Colors.GREEN}
                        color={Colors.GREEN}
                        size="14px"
                        onClick={validateOpenPay}
                      >
                        <TouchApp
                          style={{
                            fontSize: 20,
                            color: Colors.pending,
                          }}
                        />
                        Pagar
                      </ButtonSecondary>
                    )
                  )}
                </>
              ) : (
                <ButtonSecondary
                  border={Colors.GREEN}
                  color={Colors.GREEN}
                  size="14px"
                  onClick={validateOpenPay}
                >
                  <TouchApp
                    style={{
                      fontSize: 20,
                      color: Colors.iconOlive,
                    }}
                  />
                  Pagar
                </ButtonSecondary>
              )}
            </>
          )}
        {infoFile.payment &&
          infoFile.payment.status === "pagado" &&
          infoFile.payment.method_payment !== null &&
          infoFile.payment.method_payment === "card" && (
            <ButtonSecondary
              border={Colors.GREEN}
              color={Colors.GREEN}
              size="14px"
              onClick={paymentVoucher}
              disabled={loadingDownload.voucher}
              loading={loadingDownload.voucher}
            >
              Voucher de pago
            </ButtonSecondary>
          )}
        <ModalPayment
          open={openModalPayment}
          onClose={() => setOpenModalPayment(false)}
          infoFile={infoFile}
          companyInfo={companyInfo}
          getData={getData}
        />
      </ContainerDownload>
    </>
  );
};

export default DownloadFile;
