import { Sizes, Colors } from "src/infrastructure/static/colors";
import styled from "styled-components";

export const ButtonClose = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ContainerDownload = styled.div`
  display: flex;
  justify-content: space-around;
  button {
    margin: 10px;
  }
  @media ${Sizes.TABLET} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0px;
  @media ${Sizes.TABLET} {
    justify-content: center;
  }
`;

export const ContainerResult = styled.div`
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0px 4px 8px #679b60;
  border-radius: 10px;

  @media ${Sizes.TABLET} {
    box-shadow: none;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${Colors.PURPLE};
  margin-top: 20px;
  padding: 30px 20px;
`;
