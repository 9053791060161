import {
  getCreditNoteReason,
  getCurrencySymbol,
  getDocumentTypeDesc,
  getUnidadMedida,
  NumeroALetras,
} from "src/infrastructure/static/util";
import { comunRepository } from "../../infrastructure/repositories/Comun.repository";
import { ISearchDocument } from "../models/Comun";
import { IEmittedDoc } from "../models/Emitted";
import logoRatifikaBN from "../../assets/logoRatifikaBN.png";
import moment from "moment";
import { store } from "../store/configureStore";

const getLogo = async (company: string | number) => {
  const response = await comunRepository.getLogo(company);
  if (response.data.result) {
    return response.data.logo;
  } else {
    return "";
  }
};

const getSearchDocument = async (data: ISearchDocument) => {
  const response = await comunRepository.getSearchDocument(data);
  return response;
};

const downloadXML = async (id: number) => {
  const response = await comunRepository.downloadXML(id);
  return response;
};

const downloadPDF = async (data: ISearchDocument) => {
  const response = await comunRepository.downloadPDF(data);
  return response;
};

const downloadCDR = async (id: number) => {
  const response = await comunRepository.downloadCDR(id);
  return response;
};

const generatePrintableVoucher = async (docInfo: IEmittedDoc, qr: string) => {
  let logoVoucher = await getLogo(docInfo.emitter_company.number);
  let receiver_full_address =
    docInfo.receiver.addresses !== null
      ? docInfo.receiver.addresses.filter((a: any) => a.default === true)[0]
      : docInfo.receiver.addresses[0];

  let html = `<!DOCTYPE html>
<html>
   <head>
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
   <style>
      * {
         font-size: 11px;
         font-family: Arial, Helvetica, sans-serif;
      }

      td,
      th,
      tr,
      table {
         border-top: 1px solid rgb(150, 146, 146);
         border-collapse: collapse;
      }

      .centrado {
         text-align: center;
         align-content: center;
      }

      .ticket {
         width: 250px;
         max-width: 300px;
      }

      img {
         max-width: inherit;
         width: inherit;
      }

      .ratifika {
         max-width: 130px;
         width: 130px;
         margin-left: auto;
         margin-right: auto;
         display: block;
      }
   </style>
   </head>
   <body>
    <div class="ticket" style="text-align: left;">
      <table style="width: 100%;">
        <tbody>
          <tr>
            <td style="width: 100%;">
            <div class="ticket" style="text-align: left;">
                ${
                  logoVoucher.data
                    ? `<img
                    style="display: block; margin-left: auto; margin-right: auto;"
                    src="${logoVoucher.data.logo}"
                    alt="Logotipo" />`
                    : ""
                }
              <p class="centrado" style="text-align: center;">
              ${
                docInfo.emitter_company
                  ? `${docInfo.emitter_company.legal_name.toLocaleUpperCase()}`
                  : `${docInfo.emitter.legal_name.toLocaleUpperCase()}`
              }
              </p>
              <p class="centrado" style="text-align: center;">
                  <strong>RUC:
                    ${
                      docInfo.emitter_company
                        ? docInfo.emitter_company.number
                        : docInfo.emitter.number
                    }
                  </strong>
                  <br />
                  Direci&oacute;n: ${
                    docInfo.emitter_company
                      ? `${docInfo.emitter_company.address_line}`
                      : `${docInfo.emitter.addresses[0].address_line}`
                  }
                  <br />
                  ${
                    docInfo.emitter_company
                      ? `${docInfo.emitter_company.province},&nbsp;${docInfo.emitter_company.department}&nbsp;-&nbsp;${docInfo.emitter_company.district}&nbsp;<br />TELF:&nbsp;${docInfo.emitter_company.phone_number}`
                      : ""
                  }
              </p>
              <h1 style="text-align: center;"><strong>
                  ${getDocumentTypeDesc(
                    docInfo.document_type
                  ).toLocaleUpperCase()}
                  ELECTR&Oacute;NICA <br />${docInfo.serie}-${
    docInfo.number
  } </strong>
              </h1>
              <p class="centrado" style="text-align: left;">
                <strong>RUC: 
                  ${docInfo.receiver ? docInfo.receiver.number : ""}
                </strong>
                        <br /><strong>
                        ${docInfo.receiver ? docInfo.receiver.legal_name : ""}
                        </strong>
                        <br />Direccion:  
                            ${`${receiver_full_address.address_line},&nbsp;<br />${receiver_full_address.province},${receiver_full_address.district}&nbsp;-&nbsp;${receiver_full_address.department}`}
                        <br />Emisi&oacute;n: ${moment(
                          docInfo.issue_date
                        ).format("DD/MM/YYYY hh:mm A")} 
                        ${
                          docInfo.due_date
                            ? `<br />Vencimiento: ${moment(
                                docInfo.due_date
                              ).format("DD/MM/YYYY")}`
                            : ""
                        }   
                        <br />Moneda : ${
                          docInfo.currency === "PEN" ? "SOLES" : "DOLARES"
                        }</p>
                        ${
                          docInfo.document_type
                            ? docInfo.document_type === "07" ||
                              docInfo.document_type === "08"
                              ? `<p><strong>Documento afectado:</strong> ${
                                  docInfo.note
                                    ? docInfo.note.ref_serie_number
                                    : ""
                                } <br /><strong>Tipo de Nota de
                            Cr&eacute;dito:</strong>  ${
                              docInfo.note
                                ? getCreditNoteReason(
                                    docInfo.note.response_code
                                  )
                                : ""
                            } <br /><strong>Motivo o
                            sustento:</strong> ${
                              docInfo.note ? docInfo.note.description : ""
                            }</p>`
                              : ""
                            : ""
                        }
                     
                            <table style="width: 250px; max-width: 300px;">
                        <thead>
                           <tr>
                              <th style="text-align: center;"><strong>CANT</strong></th>
                              <th style="text-align: center;"><strong>PRODUCTO</strong></th>
                              <th style="text-align: center;"><strong>V. Unit.</strong></th>
                              <th style="text-align: center;"><strong>V. VENTA</strong></th>
                           </tr>
                        </thead>
                        <tbody>
                        ${docInfo.products.map((item) => {
                          return `
                          <tr>
                            <td>${parseInt(`${item.quantity}`).toFixed(
                              3
                            )}&nbsp;${getUnidadMedida(item.measure)}
                            </td>
                            <td style="text-align: left;">${
                              item.description
                            }</td>
                            <td>&nbsp;&nbsp;${parseFloat(
                              `${item.unit_price}`
                            ).toFixed(2)}</td>
                            <td>&nbsp;&nbsp;${
                              item.unit_price > 0 ? item.price : "0.00"
                            }</td>
                          </tr>
                          `;
                        })}
                           
                        </tbody>
                     </table>
                    <p>${NumeroALetras(docInfo.total_value)}</p>
      
                    ${
                      docInfo.notes
                        ? docInfo.notes
                            .split(";?")
                            .map((note) => {
                              return `<p ">
                              <span style="font-size: 11pt; font-family: 'Calibri Light', sans-serif;">&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;${note}</span>
                            </p>`;
                            })
                            .join("")
                        : ""
                    }
                    <br />
                     <table style="width: 250px; max-width: 300px; height: 198px;">
                        <tbody>
                          ${
                            docInfo.base_igv > 0
                              ? `
                              <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>Gravado:</strong></td>
                              <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                                docInfo.currency
                              )}</td>
                              <td style="width: 69.95px; height: 18px;">${
                                docInfo.base_igv
                              }</td>
                              <td style="width: 0px; height: 198px;" rowspan="10">&nbsp;</td>
                              <td style="width: 150px; height: 198px;" rowspan="10">
                                <div style="display: flex; justify-content: center; align-items: center;">
                                ${
                                  qr
                                    ? `<img style="display: block; margin-left: auto; margin-right: auto;"
                                src="${qr}"
                                alt="QR" width="120" height="100"/>`
                                    : ""
                                }
                                </div>
                                <p class="centrado" style="text-align: center;">Autorizado mediante resoluci&oacute;n
                                    <br />N&ordm; 034-005-0006805/SUNAT<br />Consulte este documento en:</p>
                              </td>
                              </tr>
                            `
                              : `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"></td>
                            <td style="width: 19.6px; height: 18px;"></td>
                            <td style="width: 69.95px; height: 18px;"></td>
                              <td style="width: 0px; height: 198px;" rowspan="10">&nbsp;</td>
                              <td style="width: 150px; height: 198px;" rowspan="10">
                                <div style="display: flex; justify-content: center; align-items: center;">
                                ${
                                  qr
                                    ? `<img style="display: block; margin-left: auto; margin-right: auto;"
                                src="${qr}"
                                alt="QR" width="120" height="100"/>`
                                    : ""
                                }
                                </div>
                                <p class="centrado" style="text-align: center;">Autorizado mediante resoluci&oacute;n
                                    <br />N&ordm; 034-005-0006805/SUNAT<br />Consulte este documento en:</p>
                              </td>
                              </tr>
                            `
                          }
                           ${
                             docInfo.base_unaffected > 0
                               ? `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"><strong>Inafecto:</strong></td>
                            <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 18px;">${
                              docInfo.base_unaffected
                            }</td>
                            </tr>
                           `
                               : ""
                           }
                           ${
                             docInfo.base_exemption > 0
                               ? `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"><strong>Exonerado:</strong></td>
                            <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 18px;">${
                              docInfo.base_exemption
                            }</td>
                            </tr>
                           `
                               : ""
                           }
                           
                           <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>Subtotal:</strong></td>
                              <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                                docInfo.currency
                              )} </td>
                              <td style="width: 69.95px; height: 18px;">${
                                docInfo.subtotal
                              }</td>
                           </tr>
                           <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>IGV:</strong></td>
                              <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                                docInfo.currency
                              )} </td>
                              <td style="width: 69.95px; height: 18px;">${
                                docInfo.total_igv
                              }</td>
                           </tr>
                           ${
                             docInfo.total_isc > 0
                               ? `
                              <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>ISC:</strong></td>
                              <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                                docInfo.currency
                              )} </td>
                              <td style="width: 69.95px; height: 18px;">${
                                docInfo.total_isc
                              }</td>
                              </tr>
                            `
                               : ""
                           }
                           ${
                             docInfo.other_charges > 0
                               ? `
                            <tr style="height: 36px;">
                            <td style="width: 96.6375px; height: 36px;"><strong>Otros Cargos:</strong></td>
                            <td style="width: 19.6px; height: 36px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 36px;">${
                              docInfo.other_charges
                            }</td>
                            </tr>
                           `
                               : ""
                           }
                           ${
                             docInfo.total_discount > 0
                               ? `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"><strong>Descuentos:</strong></td>
                            <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 18px;">-${
                              docInfo.total_discount
                            }</td>
                            </tr>   
                           `
                               : ""
                           }
                           ${
                             docInfo.prepaid_amount > 0
                               ? `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"><strong>Anticipo:</strong></td>
                            <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 18px;">-${
                              docInfo.prepaid_amount
                            }</td>
                            </tr>   
                           `
                               : ""
                           }
                           
                           <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>Total:</strong></td>
                              <td style="width: 19.6px; height: 18px;"><strong>${getCurrencySymbol(
                                docInfo.currency
                              )}</strong></td>
                              <td style="width: 69.95px; height: 18px;"><strong>${
                                docInfo.total_value
                              }</strong></td>
                           </tr>
                        </tbody>
                     </table>

                     <img class="ratifika"
                        src="${logoRatifikaBN}" alt="RATIFIKA"
                        width="50px" />
                     <h1 style="text-align: center;">RATIFIKA.COM.PE<br />innovaci&oacute;n y tecnolog&iacute;a de
                        Eximio</h1>  
            </div> 
            </tr>
         </tbody>
      </table>
   </div>
</body>
  </html>`;

  return html;
};

const getDocumentsCustomerReport = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}?dateOf=${
    config.reportsDateType
  }&startDate=${config.reportsStartDate}&endDate=${config.reportsEndDate}${
    config.reportsSerie
      ? `&serie=${config.reportsSerie}&seqInit=${config.reportsSequentialStart}&seqEnd=${config.reportsSequentialEnd}`
      : ""
  }${
    config.reportsDocumentType ? `&docType=${config.reportsDocumentType}` : ""
  }${
    config.reportsDocumentStatus
      ? `&docStatus=${
          config.reportsDocumentStatus.split(",").includes("1-3")
            ? config.reportsDocumentStatus.replace("1-3", "1,3")
            : config.reportsDocumentStatus
        }`
      : ""
  }`;
  const response = await comunRepository.getDocumentsCustomer(params);
  return response.data;
};

const getDocumentsCustomerEmitted = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}?dateOf=${
    config.emittedDateType
  }&startDate=${config.emittedStartDate}&endDate=${config.emittedEndDate}${
    config.emittedSerie
      ? `&serie=${config.emittedSerie}&seqInit=${config.emittedSequentialStart}&seqEnd=${config.emittedSequentialEnd}`
      : ""
  }${
    config.emittedDocumentType ? `&docType=${config.emittedDocumentType}` : ""
  }${
    config.emittedDocumentStatus
      ? `&docStatus=${
          config.emittedDocumentStatus.split(",").includes("1-3")
            ? config.emittedDocumentStatus.replace("1-3", "1,3")
            : config.emittedDocumentStatus
        }`
      : ""
  }`;
  const response = await comunRepository.getDocumentsCustomer(params);
  return response.data;
};

const getDocumentsSupplierReception = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}?dateOf=${
    config.receptionDateType
  }&startDate=${config.receptionStartDate}&endDate=${config.receptionEndDate}${
    config.receptionSerie
      ? `&serie=${config.receptionSerie}&seqInit=${config.receptionSequentialStart}&seqEnd=${config.receptionSequentialEnd}`
      : ""
  }${
    config.receptionDocumentType
      ? `&docType=${config.receptionDocumentType}`
      : ""
  }${
    config.receptionDocumentStatus
      ? `&docStatus=${
          config.receptionDocumentStatus.split(",").includes("1-3")
            ? config.receptionDocumentStatus.replace("1-3", "1,3")
            : config.receptionDocumentStatus
        }`
      : ""
  }`;
  const response = await comunRepository.getDocumentsSupplier(params);
  return response.data;
};

export const comunService = {
  getLogo,
  getSearchDocument,
  downloadXML,
  downloadPDF,
  generatePrintableVoucher,
  downloadCDR,
  getDocumentsCustomerReport,
  getDocumentsSupplierReception,
  getDocumentsCustomerEmitted,
};
