import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

interface ICheckBoxProps {
  color: string;
}

interface ICheckBoxContainerProps {
  margin?: string;
}

export const ContarinerCheckbox = styled.div<ICheckBoxContainerProps>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin || "10px 0px"};
`;

export const useStyles = makeStyles((props: ICheckBoxProps) => ({
  customCheckbox: {
    "& .MuiCheckbox-root": {
      color: (props: ICheckBoxProps) => `${props.color} !important`,
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked:hover": {
      backgroundColor: "transparent",
    },
  },
  root: {
    "&$checked": {
      color: (props: ICheckBoxProps) => props.color,
    },
  },
}));
