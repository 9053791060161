import React from "react";
import PublicHeader from "../components/publicHeader";
import Publicity from "../components/publicity";
import imgpublicity1 from "../assets/imgs/img_publi1.svg";
import imgpublicity2 from "../assets/imgs/img_publi2.svg";
import imgpublicity3 from "../assets/imgs/img_publi3.svg";
import imgpublicity4 from "../assets/imgs/img_publi4.svg";
import imgpublicity5 from "../assets/imgs/img_publi5.svg";
import imgpublicity6 from "../assets/imgs/img_publi6.svg";
import imgpublicity7 from "../assets/imgs/img_publi7.svg";
import ValidationTool from "../components/validationTool";
import PublicFooter from "../components/publicFooter";

const Login = () => {
  const ItemsPublicity = [
    {
      background: "#F1F4F7",
      title: "Seguridad e integridad garantizada",
      img: imgpublicity1,
      colorLogo: "#736D6D",
    },
    {
      background: "#95C0BE",
      title: "Seguridad e integridad garantizada",
      img: imgpublicity2,
      colorLogo: "#000000",
    },
    {
      background: "#E5B4AE",
      title: "Seguridad e integridad garantizada",
      img: imgpublicity3,
      colorLogo: "#000000",
    },
    {
      background: "#EAC083",
      title: "Seguridad e integridad garantizada",
      img: imgpublicity4,
      colorLogo: "#000000",
    },
    {
      background: "#95BD90",
      title: "Seguridad e integridad garantizada",
      img: imgpublicity5,
      colorLogo: "#000000",
    },
    {
      background: "#80BFBA",
      title: "Seguridad e integridad garantizada",
      img: imgpublicity6,
      colorLogo: "#000000",
    },
    {
      background: "#9CC3EF",
      title: "Seguridad e integridad garantizada",
      img: imgpublicity7,
      colorLogo: "#736D6D",
    },
  ];

  const [publicitySelected, setPublicitySelected] = React.useState(0);

  const handleChangePublicity = (swiperSelected: any) => {
    setPublicitySelected(swiperSelected.realIndex);
  };

  return (
    <div style={{ position: "relative" }}>
      <PublicHeader
        color={ItemsPublicity[publicitySelected].colorLogo}
        viewMenu
      />
      <ValidationTool
        title="Herramienta de validación"
        subtitle="Verifica tus comprobantes electrónicos en SUNAT"
      />
      <Publicity
        items={ItemsPublicity}
        onChangeSlider={handleChangePublicity}
      />
      <PublicFooter color={ItemsPublicity[publicitySelected].colorLogo} />
    </div>
  );
};

export default Login;
