export const departaments = [
  { number: "01", name: "Amazonas" },
  { number: "02", name: "Ancash" },
  { number: "03", name: "Apurimac" },
  { number: "04", name: "Arequipa" },
  { number: "05", name: "Ayacucho" },
  { number: "06", name: "Cajamarca" },
  { number: "07", name: "Callao" },
  { number: "08", name: "Cusco" },
  { number: "09", name: "Huancavelica" },
  { number: "10", name: "Huanuco" },
  { number: "11", name: "Ica" },
  { number: "12", name: "Junin" },
  { number: "13", name: "La Libertad" },
  { number: "14", name: "Lambayeque" },
  { number: "15", name: "Lima" },
  { number: "16", name: "Loreto" },
  { number: "17", name: "Madre De Dios" },
  { number: "18", name: "Moquegua" },
  { number: "19", name: "Pasco" },
  { number: "20", name: "Piura" },
  { number: "21", name: "Puno" },
  { number: "22", name: "San Martin" },
  { number: "23", name: "Tacna" },
  { number: "24", name: "Tumbes" },
  { number: "25", name: "Ucayali" },
];

export const provinces = [
  { number: "0101", name: "Chachapoyas", departamento: "01" },
  { number: "0102", name: "Bagua", departamento: "01" },
  { number: "0103", name: "Bongara", departamento: "01" },
  { number: "0104", name: "Condorcanqui", departamento: "01" },
  { number: "0105", name: "Luya", departamento: "01" },
  { number: "0106", name: "Rodriguez de Mendoza", departamento: "01" },
  { number: "0107", name: "Utcubamba", departamento: "01" },
  { number: "0201", name: "Huaraz", departamento: "02" },
  { number: "0202", name: "Aija", departamento: "02" },
  { number: "0203", name: "Antonio Raymondi", departamento: "02" },
  { number: "0204", name: "Asuncion", departamento: "02" },
  { number: "0205", name: "Bolognesi", departamento: "02" },
  { number: "0206", name: "Carhuaz", departamento: "02" },
  { number: "0207", name: "Carlos Fermin Fitzcarrald", departamento: "02" },
  { number: "0208", name: "Casma", departamento: "02" },
  { number: "0209", name: "Corongo", departamento: "02" },
  { number: "0210", name: "Huari", departamento: "02" },
  { number: "0211", name: "Huarmey", departamento: "02" },
  { number: "0212", name: "Huaylas", departamento: "02" },
  { number: "0213", name: "Mariscal Luzuriaga", departamento: "02" },
  { number: "0214", name: "Ocros", departamento: "02" },
  { number: "0215", name: "Pallasca", departamento: "02" },
  { number: "0216", name: "Pomabamba", departamento: "02" },
  { number: "0217", name: "Recuay", departamento: "02" },
  { number: "0218", name: "Santa", departamento: "02" },
  { number: "0219", name: "Sihuas", departamento: "02" },
  { number: "0220", name: "Yungay", departamento: "02" },
  { number: "0301", name: "Abancay", departamento: "03" },
  { number: "0302", name: "Andahuaylas", departamento: "03" },
  { number: "0303", name: "Antabamba", departamento: "03" },
  { number: "0304", name: "Aymaraes", departamento: "03" },
  { number: "0305", name: "Cotabambas", departamento: "03" },
  { number: "0306", name: "Chincheros", departamento: "03" },
  { number: "0307", name: "Grau", departamento: "03" },
  { number: "0401", name: "Arequipa", departamento: "04" },
  { number: "0402", name: "Camana", departamento: "04" },
  { number: "0403", name: "Caraveli", departamento: "04" },
  { number: "0404", name: "Castilla", departamento: "04" },
  { number: "0405", name: "Caylloma", departamento: "04" },
  { number: "0406", name: "Condesuyos", departamento: "04" },
  { number: "0407", name: "Islay", departamento: "04" },
  { number: "0408", name: "La Union", departamento: "04" },
  { number: "0501", name: "Huamanga", departamento: "05" },
  { number: "0502", name: "Cangallo", departamento: "05" },
  { number: "0503", name: "Huanca Sancos", departamento: "05" },
  { number: "0504", name: "Huanta", departamento: "05" },
  { number: "0505", name: "La Mar", departamento: "05" },
  { number: "0506", name: "Lucanas", departamento: "05" },
  { number: "0507", name: "Parinacochas", departamento: "05" },
  { number: "0508", name: "Paucar del Sara Sara", departamento: "05" },
  { number: "0509", name: "Sucre", departamento: "05" },
  { number: "0510", name: "Victor Fajardo", departamento: "05" },
  { number: "0511", name: "Vilcas Huaman", departamento: "05" },
  { number: "0601", name: "Cajamarca", departamento: "06" },
  { number: "0602", name: "Cajabamba", departamento: "06" },
  { number: "0603", name: "Celendin", departamento: "06" },
  { number: "0604", name: "Chota", departamento: "06" },
  { number: "0605", name: "Contumaza", departamento: "06" },
  { number: "0606", name: "Cutervo", departamento: "06" },
  { number: "0607", name: "Hualgayoc", departamento: "06" },
  { number: "0608", name: "Jaen", departamento: "06" },
  { number: "0609", name: "San Ignacio", departamento: "06" },
  { number: "0610", name: "San Marcos", departamento: "06" },
  { number: "0611", name: "San Miguel", departamento: "06" },
  { number: "0612", name: "San Pablo", departamento: "06" },
  { number: "0613", name: "Santa Cruz", departamento: "06" },
  { number: "0701", name: "Callao", departamento: "07" },
  { number: "0801", name: "Cusco", departamento: "08" },
  { number: "0802", name: "Acomayo", departamento: "08" },
  { number: "0803", name: "Anta", departamento: "08" },
  { number: "0804", name: "Calca", departamento: "08" },
  { number: "0805", name: "Canas", departamento: "08" },
  { number: "0806", name: "Canchis", departamento: "08" },
  { number: "0807", name: "Chumbivilcas", departamento: "08" },
  { number: "0808", name: "Espinar", departamento: "08" },
  { number: "0809", name: "La Convencion", departamento: "08" },
  { number: "0810", name: "Paruro", departamento: "08" },
  { number: "0811", name: "Paucartambo", departamento: "08" },
  { number: "0812", name: "Quispicanchi", departamento: "08" },
  { number: "0813", name: "Urubamba", departamento: "08" },
  { number: "0901", name: "Huancavelica", departamento: "09" },
  { number: "0902", name: "Acobamba", departamento: "09" },
  { number: "0903", name: "Angaraes", departamento: "09" },
  { number: "0904", name: "Castrovirreyna", departamento: "09" },
  { number: "0905", name: "Churcampa", departamento: "09" },
  { number: "0906", name: "Huaytara", departamento: "09" },
  { number: "0907", name: "Tayacaja", departamento: "09" },
  { number: "1001", name: "Huanuco", departamento: "10" },
  { number: "1002", name: "Ambo", departamento: "10" },
  { number: "1003", name: "Dos de Mayo", departamento: "10" },
  { number: "1004", name: "Huacaybamba", departamento: "10" },
  { number: "1005", name: "Huamalies", departamento: "10" },
  { number: "1006", name: "Leoncio Prado", departamento: "10" },
  { number: "1007", name: "Marañon", departamento: "10" },
  { number: "1008", name: "Pachitea", departamento: "10" },
  { number: "1009", name: "Puerto Inca", departamento: "10" },
  { number: "1010", name: "Lauricocha", departamento: "10" },
  { number: "1011", name: "Yarowilca", departamento: "10" },
  { number: "1101", name: "Ica", departamento: "11" },
  { number: "1102", name: "Chincha", departamento: "11" },
  { number: "1103", name: "Nazca", departamento: "11" },
  { number: "1104", name: "Palpa", departamento: "11" },
  { number: "1105", name: "Pisco", departamento: "11" },
  { number: "1201", name: "Huancayo", departamento: "12" },
  { number: "1202", name: "Concepcion", departamento: "12" },
  { number: "1203", name: "Chanchamayo", departamento: "12" },
  { number: "1204", name: "Jauja", departamento: "12" },
  { number: "1205", name: "Junin", departamento: "12" },
  { number: "1206", name: "Satipo", departamento: "12" },
  { number: "1207", name: "Tarma", departamento: "12" },
  { number: "1208", name: "Yauli", departamento: "12" },
  { number: "1209", name: "Chupaca", departamento: "12" },
  { number: "1301", name: "Trujillo", departamento: "13" },
  { number: "1302", name: "Ascope", departamento: "13" },
  { number: "1303", name: "Bolivar", departamento: "13" },
  { number: "1304", name: "Chepen", departamento: "13" },
  { number: "1305", name: "Julcan", departamento: "13" },
  { number: "1306", name: "Otuzco", departamento: "13" },
  { number: "1307", name: "Pacasmayo", departamento: "13" },
  { number: "1308", name: "Pataz", departamento: "13" },
  { number: "1309", name: "Sanchez Carrion", departamento: "13" },
  { number: "1310", name: "Santiago de Chuco", departamento: "13" },
  { number: "1311", name: "Gran Chimu", departamento: "13" },
  { number: "1312", name: "Viru", departamento: "13" },
  { number: "1401", name: "Chiclayo", departamento: "14" },
  { number: "1402", name: "Ferreñafe", departamento: "14" },
  { number: "1403", name: "Lambayeque", departamento: "14" },
  { number: "1501", name: "Lima", departamento: "15" },
  { number: "1502", name: "Barranca", departamento: "15" },
  { number: "1503", name: "Cajatambo", departamento: "15" },
  { number: "1504", name: "Canta", departamento: "15" },
  { number: "1505", name: "Cañete", departamento: "15" },
  { number: "1506", name: "Huaral", departamento: "15" },
  { number: "1507", name: "Huarochiri", departamento: "15" },
  { number: "1508", name: "Huaura", departamento: "15" },
  { number: "1509", name: "Oyon", departamento: "15" },
  { number: "1510", name: "Yauyos", departamento: "15" },
  { number: "1601", name: "Maynas", departamento: "16" },
  { number: "1602", name: "Alto Amazonas", departamento: "16" },
  { number: "1603", name: "Loreto", departamento: "16" },
  { number: "1604", name: "Mariscal Ramon Castilla", departamento: "16" },
  { number: "1605", name: "Requena", departamento: "16" },
  { number: "1606", name: "Ucayali", departamento: "16" },
  { number: "1607", name: "Datem del Marañon", departamento: "16" },
  { number: "1701", name: "Tambopata", departamento: "17" },
  { number: "1702", name: "Manu", departamento: "17" },
  { number: "1703", name: "Tahuamanu", departamento: "17" },
  { number: "1801", name: "Mariscal Nieto", departamento: "18" },
  { number: "1802", name: "General Sanchez Cerro", departamento: "18" },
  { number: "1803", name: "Ilo", departamento: "18" },
  { number: "1901", name: "Pasco", departamento: "19" },
  { number: "1902", name: "Daniel Alcides Carrion", departamento: "19" },
  { number: "1903", name: "Oxapampa", departamento: "19" },
  { number: "2001", name: "Piura", departamento: "20" },
  { number: "2002", name: "Ayabaca", departamento: "20" },
  { number: "2003", name: "Huancabamba", departamento: "20" },
  { number: "2004", name: "Morropon", departamento: "20" },
  { number: "2005", name: "Paita", departamento: "20" },
  { number: "2006", name: "Sullana", departamento: "20" },
  { number: "2007", name: "Talara", departamento: "20" },
  { number: "2008", name: "Sechura", departamento: "20" },
  { number: "2101", name: "Puno", departamento: "21" },
  { number: "2102", name: "Azangaro", departamento: "21" },
  { number: "2103", name: "Carabaya", departamento: "21" },
  { number: "2104", name: "Chucuito", departamento: "21" },
  { number: "2105", name: "El Collao", departamento: "21" },
  { number: "2106", name: "Huancane", departamento: "21" },
  { number: "2107", name: "Lampa", departamento: "21" },
  { number: "2108", name: "Melgar", departamento: "21" },
  { number: "2109", name: "Moho", departamento: "21" },
  { number: "2110", name: "San Antonio de Putina", departamento: "21" },
  { number: "2111", name: "San Roman", departamento: "21" },
  { number: "2112", name: "Sandia", departamento: "21" },
  { number: "2113", name: "Yunguyo", departamento: "21" },
  { number: "2201", name: "Moyobamba", departamento: "22" },
  { number: "2202", name: "Bellavista", departamento: "22" },
  { number: "2203", name: "El Dorado", departamento: "22" },
  { number: "2204", name: "Huallaga", departamento: "22" },
  { number: "2205", name: "Lamas", departamento: "22" },
  { number: "2206", name: "Mariscal Caceres", departamento: "22" },
  { number: "2207", name: "Picota", departamento: "22" },
  { number: "2208", name: "Rioja", departamento: "22" },
  { number: "2209", name: "San Martin", departamento: "22" },
  { number: "2210", name: "Tocache", departamento: "22" },
  { number: "2301", name: "Tacna", departamento: "23" },
  { number: "2302", name: "Candarave", departamento: "23" },
  { number: "2303", name: "Jorge Basadre", departamento: "23" },
  { number: "2304", name: "Tarata", departamento: "23" },
  { number: "2401", name: "Tumbes", departamento: "24" },
  { number: "2402", name: "Contralmirante Villar", departamento: "24" },
  { number: "2403", name: "Zarumilla", departamento: "24" },
  { number: "2501", name: "Coronel Portillo", departamento: "25" },
  { number: "2502", name: "Atalaya", departamento: "25" },
  { number: "2503", name: "Padre Abad", departamento: "25" },
  { number: "2504", name: "Purus", departamento: "25" },
];

export const ubigeos = [
  { number: "010101", name: "Chachapoyas", provincia: "0101" },
  { number: "010102", name: "Asuncion", provincia: "0101" },
  { number: "010103", name: "Balsas", provincia: "0101" },
  { number: "010104", name: "Cheto", provincia: "0101" },
  { number: "010105", name: "Chiliquin", provincia: "0101" },
  { number: "010106", name: "Chuquibamba", provincia: "0101" },
  { number: "010107", name: "Granada", provincia: "0101" },
  { number: "010108", name: "Huancas", provincia: "0101" },
  { number: "010109", name: "La Jalca", provincia: "0101" },
  { number: "010110", name: "Leimebamba", provincia: "0101" },
  { number: "010111", name: "Levanto", provincia: "0101" },
  { number: "010112", name: "Magdalena", provincia: "0101" },
  { number: "010113", name: "Mariscal Castilla", provincia: "0101" },
  { number: "010114", name: "Molinopampa", provincia: "0101" },
  { number: "010115", name: "Montevideo", provincia: "0101" },
  { number: "010116", name: "Olleros", provincia: "0101" },
  { number: "010117", name: "Quinjalca", provincia: "0101" },
  { number: "010118", name: "San Francisco de Daguas", provincia: "0101" },
  { number: "010119", name: "San Isidro de Maino", provincia: "0101" },
  { number: "010120", name: "Soloco", provincia: "0101" },
  { number: "010121", name: "Sonche", provincia: "0101" },
  { number: "010201", name: "Bagua", provincia: "0102" },
  { number: "010202", name: "Aramango", provincia: "0102" },
  { number: "010203", name: "Copallin", provincia: "0102" },
  { number: "010204", name: "El Parco", provincia: "0102" },
  { number: "010205", name: "Imaza", provincia: "0102" },
  { number: "010206", name: "La Peca", provincia: "0102" },
  { number: "010301", name: "Jumbilla", provincia: "0103" },
  { number: "010302", name: "Chisquilla", provincia: "0103" },
  { number: "010303", name: "Churuja", provincia: "0103" },
  { number: "010304", name: "Corosha", provincia: "0103" },
  { number: "010305", name: "Cuispes", provincia: "0103" },
  { number: "010306", name: "Florida", provincia: "0103" },
  { number: "010307", name: "Jazan", provincia: "0103" },
  { number: "010308", name: "Recta", provincia: "0103" },
  { number: "010309", name: "San Carlos", provincia: "0103" },
  { number: "010310", name: "Shipasbamba", provincia: "0103" },
  { number: "010311", name: "Valera", provincia: "0103" },
  { number: "010312", name: "Yambrasbamba", provincia: "0103" },
  { number: "010401", name: "Nieva", provincia: "0104" },
  { number: "010402", name: "El Cenepa", provincia: "0104" },
  { number: "010403", name: "Rio Santiago", provincia: "0104" },
  { number: "010501", name: "Lamud", provincia: "0105" },
  { number: "010502", name: "Camporredondo", provincia: "0105" },
  { number: "010503", name: "Cocabamba", provincia: "0105" },
  { number: "010504", name: "Colcamar", provincia: "0105" },
  { number: "010505", name: "Conila", provincia: "0105" },
  { number: "010506", name: "Inguilpata", provincia: "0105" },
  { number: "010507", name: "Longuita", provincia: "0105" },
  { number: "010508", name: "Lonya Chico", provincia: "0105" },
  { number: "010509", name: "Luya", provincia: "0105" },
  { number: "010510", name: "Luya Viejo", provincia: "0105" },
  { number: "010511", name: "Maria", provincia: "0105" },
  { number: "010512", name: "Ocalli", provincia: "0105" },
  { number: "010513", name: "Ocumal", provincia: "0105" },
  { number: "010514", name: "Pisuquia", provincia: "0105" },
  { number: "010515", name: "Providencia", provincia: "0105" },
  { number: "010516", name: "San Cristobal", provincia: "0105" },
  { number: "010517", name: "San Francisco del Yeso", provincia: "0105" },
  { number: "010518", name: "San Jeronimo", provincia: "0105" },
  { number: "010519", name: "San Juan de Lopecancha", provincia: "0105" },
  { number: "010520", name: "Santa Catalina", provincia: "0105" },
  { number: "010521", name: "Santo Tomas", provincia: "0105" },
  { number: "010522", name: "Tingo", provincia: "0105" },
  { number: "010523", name: "Trita", provincia: "0105" },
  { number: "010601", name: "San Nicolas", provincia: "0106" },
  { number: "010602", name: "Chirimoto", provincia: "0106" },
  { number: "010603", name: "Cochamal", provincia: "0106" },
  { number: "010604", name: "Huambo", provincia: "0106" },
  { number: "010605", name: "Limabamba", provincia: "0106" },
  { number: "010606", name: "Longar", provincia: "0106" },
  { number: "010607", name: "Mariscal Benavides", provincia: "0106" },
  { number: "010608", name: "Milpuc", provincia: "0106" },
  { number: "010609", name: "Omia", provincia: "0106" },
  { number: "010610", name: "Santa Rosa", provincia: "0106" },
  { number: "010611", name: "Totora", provincia: "0106" },
  { number: "010612", name: "Vista Alegre", provincia: "0106" },
  { number: "010701", name: "Bagua Grande", provincia: "0107" },
  { number: "010702", name: "Cajaruro", provincia: "0107" },
  { number: "010703", name: "Cumba", provincia: "0107" },
  { number: "010704", name: "El Milagro", provincia: "0107" },
  { number: "010705", name: "Jamalca", provincia: "0107" },
  { number: "010706", name: "Lonya Grande", provincia: "0107" },
  { number: "010707", name: "Yamon", provincia: "0107" },
  { number: "020101", name: "Huaraz", provincia: "0201" },
  { number: "020102", name: "Cochabamba", provincia: "0201" },
  { number: "020103", name: "Colcabamba", provincia: "0201" },
  { number: "020104", name: "Huanchay", provincia: "0201" },
  { number: "020105", name: "Independencia", provincia: "0201" },
  { number: "020106", name: "Jangas", provincia: "0201" },
  { number: "020107", name: "La Libertad", provincia: "0201" },
  { number: "020108", name: "Olleros", provincia: "0201" },
  { number: "020109", name: "Pampas", provincia: "0201" },
  { number: "020110", name: "Pariacoto", provincia: "0201" },
  { number: "020111", name: "Pira", provincia: "0201" },
  { number: "020112", name: "Tarica", provincia: "0201" },
  { number: "020201", name: "Aija", provincia: "0202" },
  { number: "020202", name: "Coris", provincia: "0202" },
  { number: "020203", name: "Huacllan", provincia: "0202" },
  { number: "020204", name: "La Merced", provincia: "0202" },
  { number: "020205", name: "Succha", provincia: "0202" },
  { number: "020301", name: "Llamellin", provincia: "0203" },
  { number: "020302", name: "Aczo", provincia: "0203" },
  { number: "020303", name: "Chaccho", provincia: "0203" },
  { number: "020304", name: "Chingas", provincia: "0203" },
  { number: "020305", name: "Mirgas", provincia: "0203" },
  { number: "020306", name: "San Juan de Rontoy", provincia: "0203" },
  { number: "020401", name: "Chacas", provincia: "0204" },
  { number: "020402", name: "Acochaca", provincia: "0204" },
  { number: "020501", name: "Chiquian", provincia: "0205" },
  { number: "020502", name: "Abelardo Pardo Lezameta", provincia: "0205" },
  { number: "020503", name: "Antonio Raymondi", provincia: "0205" },
  { number: "020504", name: "Aquia", provincia: "0205" },
  { number: "020505", name: "Cajacay", provincia: "0205" },
  { number: "020506", name: "Canis", provincia: "0205" },
  { number: "020507", name: "Colquioc", provincia: "0205" },
  { number: "020508", name: "Huallanca", provincia: "0205" },
  { number: "020509", name: "Huasta", provincia: "0205" },
  { number: "020510", name: "Huayllacayan", provincia: "0205" },
  { number: "020511", name: "La Primavera", provincia: "0205" },
  { number: "020512", name: "Mangas", provincia: "0205" },
  { number: "020513", name: "Pacllon", provincia: "0205" },
  { number: "020514", name: "San Miguel de Corpanqui", provincia: "0205" },
  { number: "020515", name: "Ticllos", provincia: "0205" },
  { number: "020601", name: "Carhuaz", provincia: "0206" },
  { number: "020602", name: "Acopampa", provincia: "0206" },
  { number: "020603", name: "Amashca", provincia: "0206" },
  { number: "020604", name: "Anta", provincia: "0206" },
  { number: "020605", name: "Ataquero", provincia: "0206" },
  { number: "020606", name: "Marcara", provincia: "0206" },
  { number: "020607", name: "Pariahuanca", provincia: "0206" },
  { number: "020608", name: "San Miguel de Aco", provincia: "0206" },
  { number: "020609", name: "Shilla", provincia: "0206" },
  { number: "020610", name: "Tinco", provincia: "0206" },
  { number: "020611", name: "Yungar", provincia: "0206" },
  { number: "020701", name: "San Luis", provincia: "0207" },
  { number: "020702", name: "San Nicolas", provincia: "0207" },
  { number: "020703", name: "Yauya", provincia: "0207" },
  { number: "020801", name: "Casma", provincia: "0208" },
  { number: "020802", name: "Buena Vista Alta", provincia: "0208" },
  { number: "020803", name: "Comandante Noel", provincia: "0208" },
  { number: "020804", name: "Yautan", provincia: "0208" },
  { number: "020901", name: "Corongo", provincia: "0209" },
  { number: "020902", name: "Aco", provincia: "0209" },
  { number: "020903", name: "Bambas", provincia: "0209" },
  { number: "020904", name: "Cusca", provincia: "0209" },
  { number: "020905", name: "La Pampa", provincia: "0209" },
  { number: "020906", name: "Yanac", provincia: "0209" },
  { number: "020907", name: "Yupan", provincia: "0209" },
  { number: "021001", name: "Huari", provincia: "0210" },
  { number: "021002", name: "Anra", provincia: "0210" },
  { number: "021003", name: "Cajay", provincia: "0210" },
  { number: "021004", name: "Chavin de Huantar", provincia: "0210" },
  { number: "021005", name: "Huacachi", provincia: "0210" },
  { number: "021006", name: "Huacchis", provincia: "0210" },
  { number: "021007", name: "Huachis", provincia: "0210" },
  { number: "021008", name: "Huantar", provincia: "0210" },
  { number: "021009", name: "Masin", provincia: "0210" },
  { number: "021010", name: "Paucas", provincia: "0210" },
  { number: "021011", name: "Ponto", provincia: "0210" },
  { number: "021012", name: "Rahuapampa", provincia: "0210" },
  { number: "021013", name: "Rapayan", provincia: "0210" },
  { number: "021014", name: "San Marcos", provincia: "0210" },
  { number: "021015", name: "San Pedro de Chana", provincia: "0210" },
  { number: "021016", name: "Uco", provincia: "0210" },
  { number: "021101", name: "Huarmey", provincia: "0211" },
  { number: "021102", name: "Cochapeti", provincia: "0211" },
  { number: "021103", name: "Culebras", provincia: "0211" },
  { number: "021104", name: "Huayan", provincia: "0211" },
  { number: "021105", name: "Malvas", provincia: "0211" },
  { number: "021201", name: "Caraz", provincia: "0212" },
  { number: "021202", name: "Huallanca", provincia: "0212" },
  { number: "021203", name: "Huata", provincia: "0212" },
  { number: "021204", name: "Huaylas", provincia: "0212" },
  { number: "021205", name: "Mato", provincia: "0212" },
  { number: "021206", name: "Pamparomas", provincia: "0212" },
  { number: "021207", name: "Pueblo Libre / 1", provincia: "0212" },
  { number: "021208", name: "Santa Cruz", provincia: "0212" },
  { number: "021209", name: "Santo Toribio", provincia: "0212" },
  { number: "021210", name: "Yuracmarca", provincia: "0212" },
  { number: "021301", name: "Piscobamba", provincia: "0213" },
  { number: "021302", name: "Casca", provincia: "0213" },
  { number: "021303", name: "Eleazar Guzman Barron", provincia: "0213" },
  { number: "021304", name: "Fidel Olivas Escudero", provincia: "0213" },
  { number: "021305", name: "Llama", provincia: "0213" },
  { number: "021306", name: "Llumpa", provincia: "0213" },
  { number: "021307", name: "Lucma", provincia: "0213" },
  { number: "021308", name: "Musga", provincia: "0213" },
  { number: "021401", name: "Ocros", provincia: "0214" },
  { number: "021402", name: "Acas", provincia: "0214" },
  { number: "021403", name: "Cajamarquilla", provincia: "0214" },
  { number: "021404", name: "Carhuapampa", provincia: "0214" },
  { number: "021405", name: "Cochas", provincia: "0214" },
  { number: "021406", name: "Congas", provincia: "0214" },
  { number: "021407", name: "Llipa", provincia: "0214" },
  { number: "021408", name: "San Cristobal de Rajan", provincia: "0214" },
  { number: "021409", name: "San Pedro", provincia: "0214" },
  { number: "021410", name: "Santiago de Chilcas", provincia: "0214" },
  { number: "021501", name: "Cabana", provincia: "0215" },
  { number: "021502", name: "Bolognesi", provincia: "0215" },
  { number: "021503", name: "Conchucos", provincia: "0215" },
  { number: "021504", name: "Huacaschuque", provincia: "0215" },
  { number: "021505", name: "Huandoval", provincia: "0215" },
  { number: "021506", name: "Lacabamba", provincia: "0215" },
  { number: "021507", name: "Llapo", provincia: "0215" },
  { number: "021508", name: "Pallasca", provincia: "0215" },
  { number: "021509", name: "Pampas", provincia: "0215" },
  { number: "021510", name: "Santa Rosa", provincia: "0215" },
  { number: "021511", name: "Tauca", provincia: "0215" },
  { number: "021601", name: "Pomabamba", provincia: "0216" },
  { number: "021602", name: "Huayllan", provincia: "0216" },
  { number: "021603", name: "Parobamba", provincia: "0216" },
  { number: "021604", name: "Quinuabamba", provincia: "0216" },
  { number: "021701", name: "Recuay", provincia: "0217" },
  { number: "021702", name: "Catac", provincia: "0217" },
  { number: "021703", name: "Cotaparaco", provincia: "0217" },
  { number: "021704", name: "Huayllapampa", provincia: "0217" },
  { number: "021705", name: "Llacllin", provincia: "0217" },
  { number: "021706", name: "Marca", provincia: "0217" },
  { number: "021707", name: "Pampas Chico", provincia: "0217" },
  { number: "021708", name: "Pararin", provincia: "0217" },
  { number: "021709", name: "Tapacocha", provincia: "0217" },
  { number: "021710", name: "Ticapampa", provincia: "0217" },
  { number: "021801", name: "Chimbote", provincia: "0218" },
  { number: "021802", name: "Caceres del Peru", provincia: "0218" },
  { number: "021803", name: "Coishco", provincia: "0218" },
  { number: "021804", name: "Macate", provincia: "0218" },
  { number: "021805", name: "Moro", provincia: "0218" },
  { number: "021806", name: "Nepeña", provincia: "0218" },
  { number: "021807", name: "Samanco", provincia: "0218" },
  { number: "021808", name: "Santa", provincia: "0218" },
  { number: "021809", name: "Nuevo Chimbote", provincia: "0218" },
  { number: "021901", name: "Sihuas", provincia: "0219" },
  { number: "021902", name: "Acobamba", provincia: "0219" },
  { number: "021903", name: "Alfonso Ugarte", provincia: "0219" },
  { number: "021904", name: "Cashapampa", provincia: "0219" },
  { number: "021905", name: "Chingalpo", provincia: "0219" },
  { number: "021906", name: "Huayllabamba", provincia: "0219" },
  { number: "021907", name: "Quiches", provincia: "0219" },
  { number: "021908", name: "Ragash", provincia: "0219" },
  { number: "021909", name: "San Juan", provincia: "0219" },
  { number: "021910", name: "Sicsibamba", provincia: "0219" },
  { number: "022001", name: "Yungay", provincia: "0220" },
  { number: "022002", name: "Cascapara", provincia: "0220" },
  { number: "022003", name: "Mancos", provincia: "0220" },
  { number: "022004", name: "Matacoto", provincia: "0220" },
  { number: "022005", name: "Quillo", provincia: "0220" },
  { number: "022006", name: "Ranrahirca", provincia: "0220" },
  { number: "022007", name: "Shupluy", provincia: "0220" },
  { number: "022008", name: "Yanama", provincia: "0220" },
  { number: "030101", name: "Abancay", provincia: "0301" },
  { number: "030102", name: "Chacoche", provincia: "0301" },
  { number: "030103", name: "Circa", provincia: "0301" },
  { number: "030104", name: "Curahuasi", provincia: "0301" },
  { number: "030105", name: "Huanipaca", provincia: "0301" },
  { number: "030106", name: "Lambrama", provincia: "0301" },
  { number: "030107", name: "Pichirhua", provincia: "0301" },
  { number: "030108", name: "San Pedro de Cachora", provincia: "0301" },
  { number: "030109", name: "Tamburco", provincia: "0301" },
  { number: "030201", name: "Andahuaylas", provincia: "0302" },
  { number: "030202", name: "Andarapa", provincia: "0302" },
  { number: "030203", name: "Chiara", provincia: "0302" },
  { number: "030204", name: "Huancarama", provincia: "0302" },
  { number: "030205", name: "Huancaray", provincia: "0302" },
  { number: "030206", name: "Huayana", provincia: "0302" },
  { number: "030207", name: "Kishuara", provincia: "0302" },
  { number: "030208", name: "Pacobamba", provincia: "0302" },
  { number: "030209", name: "Pacucha", provincia: "0302" },
  { number: "030210", name: "Pampachiri", provincia: "0302" },
  { number: "030211", name: "Pomacocha", provincia: "0302" },
  { number: "030212", name: "San Antonio de Cachi", provincia: "0302" },
  { number: "030213", name: "San Jeronimo", provincia: "0302" },
  { number: "030214", name: "San Miguel de Chaccrampa", provincia: "0302" },
  { number: "030215", name: "Santa Maria de Chicmo", provincia: "0302" },
  { number: "030216", name: "Talavera", provincia: "0302" },
  { number: "030217", name: "Tumay Huaraca", provincia: "0302" },
  { number: "030218", name: "Turpo", provincia: "0302" },
  { number: "030219", name: "Kaquiabamba", provincia: "0302" },
  { number: "030301", name: "Antabamba", provincia: "0303" },
  { number: "030302", name: "El Oro", provincia: "0303" },
  { number: "030303", name: "Huaquirca", provincia: "0303" },
  { number: "030304", name: "Juan Espinoza Medrano", provincia: "0303" },
  { number: "030305", name: "Oropesa", provincia: "0303" },
  { number: "030306", name: "Pachaconas", provincia: "0303" },
  { number: "030307", name: "Sabaino", provincia: "0303" },
  { number: "030401", name: "Chalhuanca", provincia: "0304" },
  { number: "030402", name: "Capaya", provincia: "0304" },
  { number: "030403", name: "Caraybamba", provincia: "0304" },
  { number: "030404", name: "Chapimarca", provincia: "0304" },
  { number: "030405", name: "Colcabamba", provincia: "0304" },
  { number: "030406", name: "Cotaruse", provincia: "0304" },
  { number: "030407", name: "Huayllo", provincia: "0304" },
  { number: "030408", name: "Justo Apu Sahuaraura", provincia: "0304" },
  { number: "030409", name: "Lucre", provincia: "0304" },
  { number: "030410", name: "Pocohuanca", provincia: "0304" },
  { number: "030411", name: "San Juan de Chacña", provincia: "0304" },
  { number: "030412", name: "Sañayca", provincia: "0304" },
  { number: "030413", name: "Soraya", provincia: "0304" },
  { number: "030414", name: "Tapairihua", provincia: "0304" },
  { number: "030415", name: "Tintay", provincia: "0304" },
  { number: "030416", name: "Toraya", provincia: "0304" },
  { number: "030417", name: "Yanaca", provincia: "0304" },
  { number: "030501", name: "Tambobamba", provincia: "0305" },
  { number: "030502", name: "Cotabambas", provincia: "0305" },
  { number: "030503", name: "Coyllurqui", provincia: "0305" },
  { number: "030504", name: "Haquira", provincia: "0305" },
  { number: "030505", name: "Mara", provincia: "0305" },
  { number: "030506", name: "Challhuahuacho", provincia: "0305" },
  { number: "030601", name: "Chincheros", provincia: "0306" },
  { number: "030602", name: "Anco-Huallo", provincia: "0306" },
  { number: "030603", name: "Cocharcas", provincia: "0306" },
  { number: "030604", name: "Huaccana", provincia: "0306" },
  { number: "030605", name: "Ocobamba", provincia: "0306" },
  { number: "030606", name: "Ongoy", provincia: "0306" },
  { number: "030607", name: "Uranmarca", provincia: "0306" },
  { number: "030608", name: "Ranracancha", provincia: "0306" },
  { number: "030701", name: "Chuquibambilla", provincia: "0307" },
  { number: "030702", name: "Curpahuasi", provincia: "0307" },
  { number: "030703", name: "Gamarra", provincia: "0307" },
  { number: "030704", name: "Huayllati", provincia: "0307" },
  { number: "030705", name: "Mamara", provincia: "0307" },
  { number: "030706", name: "Micaela Bastidas", provincia: "0307" },
  { number: "030707", name: "Pataypampa", provincia: "0307" },
  { number: "030708", name: "Progreso", provincia: "0307" },
  { number: "030709", name: "San Antonio", provincia: "0307" },
  { number: "030710", name: "Santa Rosa", provincia: "0307" },
  { number: "030711", name: "Turpay", provincia: "0307" },
  { number: "030712", name: "Vilcabamba", provincia: "0307" },
  { number: "030713", name: "Virundo", provincia: "0307" },
  { number: "030714", name: "Curasco", provincia: "0307" },
  { number: "040101", name: "Arequipa", provincia: "0401" },
  { number: "040102", name: "Alto Selva Alegre", provincia: "0401" },
  { number: "040103", name: "Cayma", provincia: "0401" },
  { number: "040104", name: "Cerro Colorado", provincia: "0401" },
  { number: "040105", name: "Characato", provincia: "0401" },
  { number: "040106", name: "Chiguata", provincia: "0401" },
  { number: "040107", name: "Jacobo Hunter", provincia: "0401" },
  { number: "040108", name: "La Joya", provincia: "0401" },
  { number: "040109", name: "Mariano Melgar", provincia: "0401" },
  { number: "040110", name: "Miraflores", provincia: "0401" },
  { number: "040111", name: "Mollebaya", provincia: "0401" },
  { number: "040112", name: "Paucarpata", provincia: "0401" },
  { number: "040113", name: "Pocsi", provincia: "0401" },
  { number: "040114", name: "Polobaya", provincia: "0401" },
  { number: "040115", name: "Quequeña", provincia: "0401" },
  { number: "040116", name: "Sabandia", provincia: "0401" },
  { number: "040117", name: "Sachaca", provincia: "0401" },
  { number: "040118", name: "San Juan de Siguas /1", provincia: "0401" },
  { number: "040119", name: "San Juan de Tarucani", provincia: "0401" },
  { number: "040120", name: "Santa Isabel de Siguas", provincia: "0401" },
  { number: "040121", name: "Santa Rita de Siguas", provincia: "0401" },
  { number: "040122", name: "Socabaya", provincia: "0401" },
  { number: "040123", name: "Tiabaya", provincia: "0401" },
  { number: "040124", name: "Uchumayo", provincia: "0401" },
  { number: "040125", name: "Vitor", provincia: "0401" },
  { number: "040126", name: "Yanahuara", provincia: "0401" },
  { number: "040127", name: "Yarabamba", provincia: "0401" },
  { number: "040128", name: "Yura", provincia: "0401" },
  {
    number: "040129",
    name: "Jose Luis Bustamante Y Rivero",
    provincia: "0401",
  },
  { number: "040201", name: "Camana", provincia: "0402" },
  { number: "040202", name: "Jose Maria Quimper", provincia: "0402" },
  { number: "040203", name: "Mariano Nicolas Valcarcel", provincia: "0402" },
  { number: "040204", name: "Mariscal Caceres", provincia: "0402" },
  { number: "040205", name: "Nicolas de Pierola", provincia: "0402" },
  { number: "040206", name: "Ocoña", provincia: "0402" },
  { number: "040207", name: "Quilca", provincia: "0402" },
  { number: "040208", name: "Samuel Pastor", provincia: "0402" },
  { number: "040301", name: "Caraveli", provincia: "0403" },
  { number: "040302", name: "Acari", provincia: "0403" },
  { number: "040303", name: "Atico", provincia: "0403" },
  { number: "040304", name: "Atiquipa", provincia: "0403" },
  { number: "040305", name: "Bella Union", provincia: "0403" },
  { number: "040306", name: "Cahuacho", provincia: "0403" },
  { number: "040307", name: "Chala", provincia: "0403" },
  { number: "040308", name: "Chaparra", provincia: "0403" },
  { number: "040309", name: "Huanuhuanu", provincia: "0403" },
  { number: "040310", name: "Jaqui", provincia: "0403" },
  { number: "040311", name: "Lomas", provincia: "0403" },
  { number: "040312", name: "Quicacha", provincia: "0403" },
  { number: "040313", name: "Yauca", provincia: "0403" },
  { number: "040401", name: "Aplao", provincia: "0404" },
  { number: "040402", name: "Andagua", provincia: "0404" },
  { number: "040403", name: "Ayo", provincia: "0404" },
  { number: "040404", name: "Chachas", provincia: "0404" },
  { number: "040405", name: "Chilcaymarca", provincia: "0404" },
  { number: "040406", name: "Choco", provincia: "0404" },
  { number: "040407", name: "Huancarqui", provincia: "0404" },
  { number: "040408", name: "Machaguay", provincia: "0404" },
  { number: "040409", name: "Orcopampa", provincia: "0404" },
  { number: "040410", name: "Pampacolca", provincia: "0404" },
  { number: "040411", name: "Tipan", provincia: "0404" },
  { number: "040412", name: "Uñon", provincia: "0404" },
  { number: "040413", name: "Uraca", provincia: "0404" },
  { number: "040414", name: "Viraco", provincia: "0404" },
  { number: "040501", name: "Chivay", provincia: "0405" },
  { number: "040502", name: "Achoma", provincia: "0405" },
  { number: "040503", name: "Cabanaconde", provincia: "0405" },
  { number: "040504", name: "Callalli", provincia: "0405" },
  { number: "040505", name: "Caylloma", provincia: "0405" },
  { number: "040506", name: "Coporaque", provincia: "0405" },
  { number: "040507", name: "Huambo", provincia: "0405" },
  { number: "040508", name: "Huanca", provincia: "0405" },
  { number: "040509", name: "Ichupampa", provincia: "0405" },
  { number: "040510", name: "Lari", provincia: "0405" },
  { number: "040511", name: "Lluta", provincia: "0405" },
  { number: "040512", name: "Maca", provincia: "0405" },
  { number: "040513", name: "Madrigal", provincia: "0405" },
  { number: "040514", name: "San Antonio de Chuca 2/", provincia: "0405" },
  { number: "040515", name: "Sibayo", provincia: "0405" },
  { number: "040516", name: "Tapay", provincia: "0405" },
  { number: "040517", name: "Tisco", provincia: "0405" },
  { number: "040518", name: "Tuti", provincia: "0405" },
  { number: "040519", name: "Yanque", provincia: "0405" },
  { number: "040520", name: "Majes", provincia: "0405" },
  { number: "040601", name: "Chuquibamba", provincia: "0406" },
  { number: "040602", name: "Andaray", provincia: "0406" },
  { number: "040603", name: "Cayarani", provincia: "0406" },
  { number: "040604", name: "Chichas", provincia: "0406" },
  { number: "040605", name: "Iray", provincia: "0406" },
  { number: "040606", name: "Rio Grande", provincia: "0406" },
  { number: "040607", name: "Salamanca", provincia: "0406" },
  { number: "040608", name: "Yanaquihua", provincia: "0406" },
  { number: "040701", name: "Mollendo", provincia: "0407" },
  { number: "040702", name: "Cocachacra", provincia: "0407" },
  { number: "040703", name: "Dean Valdivia", provincia: "0407" },
  { number: "040704", name: "Islay", provincia: "0407" },
  { number: "040705", name: "Mejia", provincia: "0407" },
  { number: "040706", name: "Punta de Bombon", provincia: "0407" },
  { number: "040801", name: "Cotahuasi", provincia: "0408" },
  { number: "040802", name: "Alca", provincia: "0408" },
  { number: "040803", name: "Charcana", provincia: "0408" },
  { number: "040804", name: "Huaynacotas", provincia: "0408" },
  { number: "040805", name: "Pampamarca", provincia: "0408" },
  { number: "040806", name: "Puyca", provincia: "0408" },
  { number: "040807", name: "Quechualla", provincia: "0408" },
  { number: "040808", name: "Sayla", provincia: "0408" },
  { number: "040809", name: "Tauria", provincia: "0408" },
  { number: "040810", name: "Tomepampa", provincia: "0408" },
  { number: "040811", name: "Toro", provincia: "0408" },
  { number: "050101", name: "Ayacucho", provincia: "0501" },
  { number: "050102", name: "Acocro", provincia: "0501" },
  { number: "050103", name: "Acos Vinchos", provincia: "0501" },
  { number: "050104", name: "Carmen Alto", provincia: "0501" },
  { number: "050105", name: "Chiara", provincia: "0501" },
  { number: "050106", name: "Ocros", provincia: "0501" },
  { number: "050107", name: "Pacaycasa", provincia: "0501" },
  { number: "050108", name: "Quinua", provincia: "0501" },
  { number: "050109", name: "San Jose de Ticllas", provincia: "0501" },
  { number: "050110", name: "San Juan Bautista", provincia: "0501" },
  { number: "050111", name: "Santiago de Pischa", provincia: "0501" },
  { number: "050112", name: "Socos", provincia: "0501" },
  { number: "050113", name: "Tambillo", provincia: "0501" },
  { number: "050114", name: "Vinchos", provincia: "0501" },
  { number: "050115", name: "Jesus Nazareno", provincia: "0501" },
  { number: "050201", name: "Cangallo", provincia: "0502" },
  { number: "050202", name: "Chuschi", provincia: "0502" },
  { number: "050203", name: "Los Morochucos", provincia: "0502" },
  { number: "050204", name: "Maria Parado de Bellido", provincia: "0502" },
  { number: "050205", name: "Paras", provincia: "0502" },
  { number: "050206", name: "Totos", provincia: "0502" },
  { number: "050301", name: "Sancos", provincia: "0503" },
  { number: "050302", name: "Carapo", provincia: "0503" },
  { number: "050303", name: "Sacsamarca", provincia: "0503" },
  { number: "050304", name: "Santiago de Lucanamarca", provincia: "0503" },
  { number: "050401", name: "Huanta", provincia: "0504" },
  { number: "050402", name: "Ayahuanco", provincia: "0504" },
  { number: "050403", name: "Huamanguilla", provincia: "0504" },
  { number: "050404", name: "Iguain", provincia: "0504" },
  { number: "050405", name: "Luricocha", provincia: "0504" },
  { number: "050406", name: "Santillana", provincia: "0504" },
  { number: "050407", name: "Sivia", provincia: "0504" },
  { number: "050408", name: "Llochegua", provincia: "0504" },
  { number: "050501", name: "San Miguel", provincia: "0505" },
  { number: "050502", name: "Anco", provincia: "0505" },
  { number: "050503", name: "Ayna", provincia: "0505" },
  { number: "050504", name: "Chilcas", provincia: "0505" },
  { number: "050505", name: "Chungui", provincia: "0505" },
  { number: "050506", name: "Luis Carranza", provincia: "0505" },
  { number: "050507", name: "Santa Rosa", provincia: "0505" },
  { number: "050508", name: "Tambo", provincia: "0505" },
  { number: "050601", name: "Puquio", provincia: "0506" },
  { number: "050602", name: "Aucara", provincia: "0506" },
  { number: "050603", name: "Cabana", provincia: "0506" },
  { number: "050604", name: "Carmen Salcedo", provincia: "0506" },
  { number: "050605", name: "Chaviña", provincia: "0506" },
  { number: "050606", name: "Chipao", provincia: "0506" },
  { number: "050607", name: "Huac-Huas", provincia: "0506" },
  { number: "050608", name: "Laramate", provincia: "0506" },
  { number: "050609", name: "Leoncio Prado", provincia: "0506" },
  { number: "050610", name: "Llauta", provincia: "0506" },
  { number: "050611", name: "Lucanas", provincia: "0506" },
  { number: "050612", name: "Ocaña", provincia: "0506" },
  { number: "050613", name: "Otoca", provincia: "0506" },
  { number: "050614", name: "Saisa", provincia: "0506" },
  { number: "050615", name: "San Cristobal", provincia: "0506" },
  { number: "050616", name: "San Juan", provincia: "0506" },
  { number: "050617", name: "San Pedro", provincia: "0506" },
  { number: "050618", name: "San Pedro de Palco", provincia: "0506" },
  { number: "050619", name: "Sancos", provincia: "0506" },
  { number: "050620", name: "Santa Ana de Huaycahuacho", provincia: "0506" },
  { number: "050621", name: "Santa Lucia", provincia: "0506" },
  { number: "050701", name: "Coracora", provincia: "0507" },
  { number: "050702", name: "Chumpi", provincia: "0507" },
  { number: "050703", name: "Coronel Castañeda", provincia: "0507" },
  { number: "050704", name: "Pacapausa", provincia: "0507" },
  { number: "050705", name: "Pullo", provincia: "0507" },
  { number: "050706", name: "Puyusca", provincia: "0507" },
  { number: "050707", name: "San Francisco de Ravacayco", provincia: "0507" },
  { number: "050708", name: "Upahuacho", provincia: "0507" },
  { number: "050801", name: "Pausa", provincia: "0508" },
  { number: "050802", name: "Colta", provincia: "0508" },
  { number: "050803", name: "Corculla", provincia: "0508" },
  { number: "050804", name: "Lampa", provincia: "0508" },
  { number: "050805", name: "Marcabamba", provincia: "0508" },
  { number: "050806", name: "Oyolo", provincia: "0508" },
  { number: "050807", name: "Pararca", provincia: "0508" },
  { number: "050808", name: "San Javier de Alpabamba", provincia: "0508" },
  { number: "050809", name: "San Jose de Ushua", provincia: "0508" },
  { number: "050810", name: "Sara Sara", provincia: "0508" },
  { number: "050901", name: "Querobamba", provincia: "0509" },
  { number: "050902", name: "Belen", provincia: "0509" },
  { number: "050903", name: "Chalcos", provincia: "0509" },
  { number: "050904", name: "Chilcayoc", provincia: "0509" },
  { number: "050905", name: "Huacaña", provincia: "0509" },
  { number: "050906", name: "Morcolla", provincia: "0509" },
  { number: "050907", name: "Paico", provincia: "0509" },
  { number: "050908", name: "San Pedro de Larcay", provincia: "0509" },
  { number: "050909", name: "San Salvador de Quije", provincia: "0509" },
  { number: "050910", name: "Santiago de Paucaray", provincia: "0509" },
  { number: "050911", name: "Soras", provincia: "0509" },
  { number: "051001", name: "Huancapi", provincia: "0510" },
  { number: "051002", name: "Alcamenca", provincia: "0510" },
  { number: "051003", name: "Apongo", provincia: "0510" },
  { number: "051004", name: "Asquipata", provincia: "0510" },
  { number: "051005", name: "Canaria", provincia: "0510" },
  { number: "051006", name: "Cayara", provincia: "0510" },
  { number: "051007", name: "Colca", provincia: "0510" },
  { number: "051008", name: "Huamanquiquia", provincia: "0510" },
  { number: "051009", name: "Huancaraylla", provincia: "0510" },
  { number: "051010", name: "Huaya", provincia: "0510" },
  { number: "051011", name: "Sarhua", provincia: "0510" },
  { number: "051012", name: "Vilcanchos", provincia: "0510" },
  { number: "051101", name: "Vilcas Huaman", provincia: "0511" },
  { number: "051102", name: "Accomarca", provincia: "0511" },
  { number: "051103", name: "Carhuanca", provincia: "0511" },
  { number: "051104", name: "Concepcion", provincia: "0511" },
  { number: "051105", name: "Huambalpa", provincia: "0511" },
  { number: "051106", name: "Independencia /1", provincia: "0511" },
  { number: "051107", name: "Saurama", provincia: "0511" },
  { number: "051108", name: "Vischongo", provincia: "0511" },
  { number: "060101", name: "Cajamarca", provincia: "0601" },
  { number: "060102", name: "Asuncion", provincia: "0601" },
  { number: "060103", name: "Chetilla", provincia: "0601" },
  { number: "060104", name: "Cospan", provincia: "0601" },
  { number: "060105", name: "Encañada", provincia: "0601" },
  { number: "060106", name: "Jesus", provincia: "0601" },
  { number: "060107", name: "Llacanora", provincia: "0601" },
  { number: "060108", name: "Los Baños del Inca", provincia: "0601" },
  { number: "060109", name: "Magdalena", provincia: "0601" },
  { number: "060110", name: "Matara", provincia: "0601" },
  { number: "060111", name: "Namora", provincia: "0601" },
  { number: "060112", name: "San Juan", provincia: "0601" },
  { number: "060201", name: "Cajabamba", provincia: "0602" },
  { number: "060202", name: "Cachachi", provincia: "0602" },
  { number: "060203", name: "Condebamba", provincia: "0602" },
  { number: "060204", name: "Sitacocha", provincia: "0602" },
  { number: "060301", name: "Celendin", provincia: "0603" },
  { number: "060302", name: "Chumuch", provincia: "0603" },
  { number: "060303", name: "Cortegana", provincia: "0603" },
  { number: "060304", name: "Huasmin", provincia: "0603" },
  { number: "060305", name: "Jorge Chavez", provincia: "0603" },
  { number: "060306", name: "Jose Galvez", provincia: "0603" },
  { number: "060307", name: "Miguel Iglesias", provincia: "0603" },
  { number: "060308", name: "Oxamarca", provincia: "0603" },
  { number: "060309", name: "Sorochuco", provincia: "0603" },
  { number: "060310", name: "Sucre", provincia: "0603" },
  { number: "060311", name: "Utco", provincia: "0603" },
  { number: "060312", name: "La Libertad de Pallan", provincia: "0603" },
  { number: "060401", name: "Chota", provincia: "0604" },
  { number: "060402", name: "Anguia", provincia: "0604" },
  { number: "060403", name: "Chadin", provincia: "0604" },
  { number: "060404", name: "Chiguirip", provincia: "0604" },
  { number: "060405", name: "Chimban", provincia: "0604" },
  { number: "060406", name: "Choropampa", provincia: "0604" },
  { number: "060407", name: "Cochabamba", provincia: "0604" },
  { number: "060408", name: "Conchan", provincia: "0604" },
  { number: "060409", name: "Huambos", provincia: "0604" },
  { number: "060410", name: "Lajas", provincia: "0604" },
  { number: "060411", name: "Llama", provincia: "0604" },
  { number: "060412", name: "Miracosta", provincia: "0604" },
  { number: "060413", name: "Paccha", provincia: "0604" },
  { number: "060414", name: "Pion", provincia: "0604" },
  { number: "060415", name: "Querocoto", provincia: "0604" },
  { number: "060416", name: "San Juan de Licupis", provincia: "0604" },
  { number: "060417", name: "Tacabamba", provincia: "0604" },
  { number: "060418", name: "Tocmoche", provincia: "0604" },
  { number: "060419", name: "Chalamarca", provincia: "0604" },
  { number: "060501", name: "Contumaza", provincia: "0605" },
  { number: "060502", name: "Chilete", provincia: "0605" },
  { number: "060503", name: "Cupisnique", provincia: "0605" },
  { number: "060504", name: "Guzmango", provincia: "0605" },
  { number: "060505", name: "San Benito", provincia: "0605" },
  { number: "060506", name: "Santa Cruz de Toled", provincia: "0605" },
  { number: "060507", name: "Tantarica", provincia: "0605" },
  { number: "060508", name: "Yonan", provincia: "0605" },
  { number: "060601", name: "Cutervo", provincia: "0606" },
  { number: "060602", name: "Callayuc", provincia: "0606" },
  { number: "060603", name: "Choros", provincia: "0606" },
  { number: "060604", name: "Cujillo", provincia: "0606" },
  { number: "060605", name: "La Ramada", provincia: "0606" },
  { number: "060606", name: "Pimpingos", provincia: "0606" },
  { number: "060607", name: "Querocotillo", provincia: "0606" },
  { number: "060608", name: "San Andres de Cutervo", provincia: "0606" },
  { number: "060609", name: "San Juan de Cutervo", provincia: "0606" },
  { number: "060610", name: "San Luis de Lucma", provincia: "0606" },
  { number: "060611", name: "Santa Cruz", provincia: "0606" },
  { number: "060612", name: "Santo Domingo de la Capilla", provincia: "0606" },
  { number: "060613", name: "Santo Tomas", provincia: "0606" },
  { number: "060614", name: "Socota", provincia: "0606" },
  { number: "060615", name: "Toribio Casanova", provincia: "0606" },
  { number: "060701", name: "Bambamarca", provincia: "0607" },
  { number: "060702", name: "Chugur", provincia: "0607" },
  { number: "060703", name: "Hualgayoc", provincia: "0607" },
  { number: "060801", name: "Jaen", provincia: "0608" },
  { number: "060802", name: "Bellavista", provincia: "0608" },
  { number: "060803", name: "Chontali", provincia: "0608" },
  { number: "060804", name: "Colasay", provincia: "0608" },
  { number: "060805", name: "Huabal", provincia: "0608" },
  { number: "060806", name: "Las Pirias", provincia: "0608" },
  { number: "060807", name: "Pomahuaca", provincia: "0608" },
  { number: "060808", name: "Pucara", provincia: "0608" },
  { number: "060809", name: "Sallique", provincia: "0608" },
  { number: "060810", name: "San Felipe", provincia: "0608" },
  { number: "060811", name: "San Jose del Alto", provincia: "0608" },
  { number: "060812", name: "Santa Rosa", provincia: "0608" },
  { number: "060901", name: "San Ignacio", provincia: "0609" },
  { number: "060902", name: "Chirinos", provincia: "0609" },
  { number: "060903", name: "Huarango", provincia: "0609" },
  { number: "060904", name: "La Coipa", provincia: "0609" },
  { number: "060905", name: "Namballe", provincia: "0609" },
  { number: "060906", name: "San Jose de Lourdes", provincia: "0609" },
  { number: "060907", name: "Tabaconas", provincia: "0609" },
  { number: "061001", name: "Pedro Galvez", provincia: "0610" },
  { number: "061002", name: "Chancay", provincia: "0610" },
  { number: "061003", name: "Eduardo Villanueva", provincia: "0610" },
  { number: "061004", name: "Gregorio Pita", provincia: "0610" },
  { number: "061005", name: "Ichocan", provincia: "0610" },
  { number: "061006", name: "Jose Manuel Quiroz", provincia: "0610" },
  { number: "061007", name: "Jose Sabogal", provincia: "0610" },
  { number: "061101", name: "San Miguel", provincia: "0611" },
  { number: "061102", name: "Bolivar", provincia: "0611" },
  { number: "061103", name: "Calquis", provincia: "0611" },
  { number: "061104", name: "Catilluc", provincia: "0611" },
  { number: "061105", name: "El Prado", provincia: "0611" },
  { number: "061106", name: "La Florida", provincia: "0611" },
  { number: "061107", name: "Llapa", provincia: "0611" },
  { number: "061108", name: "Nanchoc", provincia: "0611" },
  { number: "061109", name: "Niepos", provincia: "0611" },
  { number: "061110", name: "San Gregorio", provincia: "0611" },
  { number: "061111", name: "San Silvestre de Cochan", provincia: "0611" },
  { number: "061112", name: "Tongod", provincia: "0611" },
  { number: "061113", name: "Union Agua Blanca", provincia: "0611" },
  { number: "061201", name: "San Pablo", provincia: "0612" },
  { number: "061202", name: "San Bernardino", provincia: "0612" },
  { number: "061203", name: "San Luis", provincia: "0612" },
  { number: "061204", name: "Tumbaden", provincia: "0612" },
  { number: "061301", name: "Santa Cruz", provincia: "0613" },
  { number: "061302", name: "Andabamba", provincia: "0613" },
  { number: "061303", name: "Catache", provincia: "0613" },
  { number: "061304", name: "Chancaybaños", provincia: "0613" },
  { number: "061305", name: "La Esperanza", provincia: "0613" },
  { number: "061306", name: "Ninabamba", provincia: "0613" },
  { number: "061307", name: "Pulan", provincia: "0613" },
  { number: "061308", name: "Saucepampa", provincia: "0613" },
  { number: "061309", name: "Sexi", provincia: "0613" },
  { number: "061310", name: "Uticyacu", provincia: "0613" },
  { number: "061311", name: "Yauyucan", provincia: "0613" },
  { number: "070101", name: "Callao", provincia: "0701" },
  { number: "070102", name: "Bellavista", provincia: "0701" },
  { number: "070103", name: "Carmen de la Legua Reynoso", provincia: "0701" },
  { number: "070104", name: "La Perla", provincia: "0701" },
  { number: "070105", name: "La Punta", provincia: "0701" },
  { number: "070106", name: "Ventanilla", provincia: "0701" },
  { number: "080101", name: "Cusco", provincia: "0801" },
  { number: "080102", name: "Ccorca", provincia: "0801" },
  { number: "080103", name: "Poroy", provincia: "0801" },
  { number: "080104", name: "San Jeronimo", provincia: "0801" },
  { number: "080105", name: "San Sebastian", provincia: "0801" },
  { number: "080106", name: "Santiago", provincia: "0801" },
  { number: "080107", name: "Saylla", provincia: "0801" },
  { number: "080108", name: "Wanchaq", provincia: "0801" },
  { number: "080201", name: "Acomayo", provincia: "0802" },
  { number: "080202", name: "Acopia", provincia: "0802" },
  { number: "080203", name: "Acos", provincia: "0802" },
  { number: "080204", name: "Mosoc Llacta", provincia: "0802" },
  { number: "080205", name: "Pomacanchi", provincia: "0802" },
  { number: "080206", name: "Rondocan", provincia: "0802" },
  { number: "080207", name: "Sangarara", provincia: "0802" },
  { number: "080301", name: "Anta", provincia: "0803" },
  { number: "080302", name: "Ancahuasi", provincia: "0803" },
  { number: "080303", name: "Cachimayo", provincia: "0803" },
  { number: "080304", name: "Chinchaypujio", provincia: "0803" },
  { number: "080305", name: "Huarocondo", provincia: "0803" },
  { number: "080306", name: "Limatambo", provincia: "0803" },
  { number: "080307", name: "Mollepata", provincia: "0803" },
  { number: "080308", name: "Pucyura", provincia: "0803" },
  { number: "080309", name: "Zurite", provincia: "0803" },
  { number: "080401", name: "Calca", provincia: "0804" },
  { number: "080402", name: "Coya", provincia: "0804" },
  { number: "080403", name: "Lamay", provincia: "0804" },
  { number: "080404", name: "Lares", provincia: "0804" },
  { number: "080405", name: "Pisac", provincia: "0804" },
  { number: "080406", name: "San Salvador", provincia: "0804" },
  { number: "080407", name: "Taray", provincia: "0804" },
  { number: "080408", name: "Yanatile", provincia: "0804" },
  { number: "080501", name: "Yanaoca", provincia: "0805" },
  { number: "080502", name: "Checca", provincia: "0805" },
  { number: "080503", name: "Kunturkanki", provincia: "0805" },
  { number: "080504", name: "Langui", provincia: "0805" },
  { number: "080505", name: "Layo", provincia: "0805" },
  { number: "080506", name: "Pampamarca", provincia: "0805" },
  { number: "080507", name: "Quehue", provincia: "0805" },
  { number: "080508", name: "Tupac Amaru", provincia: "0805" },
  { number: "080601", name: "Sicuani", provincia: "0806" },
  { number: "080602", name: "Checacupe", provincia: "0806" },
  { number: "080603", name: "Combapata", provincia: "0806" },
  { number: "080604", name: "Marangani", provincia: "0806" },
  { number: "080605", name: "Pitumarca", provincia: "0806" },
  { number: "080606", name: "San Pablo", provincia: "0806" },
  { number: "080607", name: "San Pedro", provincia: "0806" },
  { number: "080608", name: "Tinta", provincia: "0806" },
  { number: "080701", name: "Santo Tomas", provincia: "0807" },
  { number: "080702", name: "Capacmarca", provincia: "0807" },
  { number: "080703", name: "Chamaca", provincia: "0807" },
  { number: "080704", name: "Colquemarca", provincia: "0807" },
  { number: "080705", name: "Livitaca", provincia: "0807" },
  { number: "080706", name: "Llusco", provincia: "0807" },
  { number: "080707", name: "Quiñota", provincia: "0807" },
  { number: "080708", name: "Velille", provincia: "0807" },
  { number: "080801", name: "Espinar", provincia: "0808" },
  { number: "080802", name: "Condoroma", provincia: "0808" },
  { number: "080803", name: "Coporaque", provincia: "0808" },
  { number: "080804", name: "Ocoruro", provincia: "0808" },
  { number: "080805", name: "Pallpata", provincia: "0808" },
  { number: "080806", name: "Pichigua", provincia: "0808" },
  { number: "080807", name: "Suyckutambo 3/", provincia: "0808" },
  { number: "080808", name: "Alto Pichigua", provincia: "0808" },
  { number: "080901", name: "Santa Ana", provincia: "0809" },
  { number: "080902", name: "Echarate", provincia: "0809" },
  { number: "080903", name: "Huayopata /1", provincia: "0809" },
  { number: "080904", name: "Maranura", provincia: "0809" },
  { number: "080905", name: "Ocobamba /2", provincia: "0809" },
  { number: "080906", name: "Quellouno", provincia: "0809" },
  { number: "080907", name: "Kimbiri", provincia: "0809" },
  { number: "080908", name: "Santa Teresa", provincia: "0809" },
  { number: "080909", name: "Vilcabamba", provincia: "0809" },
  { number: "080910", name: "Pichari", provincia: "0809" },
  { number: "081001", name: "Paruro", provincia: "0810" },
  { number: "081002", name: "Accha", provincia: "0810" },
  { number: "081003", name: "Ccapi", provincia: "0810" },
  { number: "081004", name: "Colcha", provincia: "0810" },
  { number: "081005", name: "Huanoquite", provincia: "0810" },
  { number: "081006", name: "Omacha", provincia: "0810" },
  { number: "081007", name: "Paccaritambo", provincia: "0810" },
  { number: "081008", name: "Pillpinto", provincia: "0810" },
  { number: "081009", name: "Yaurisque", provincia: "0810" },
  { number: "081101", name: "Paucartambo", provincia: "0811" },
  { number: "081102", name: "Caicay", provincia: "0811" },
  { number: "081103", name: "Challabamba", provincia: "0811" },
  { number: "081104", name: "Colquepata", provincia: "0811" },
  { number: "081105", name: "Huancarani", provincia: "0811" },
  { number: "081106", name: "Kosñipata", provincia: "0811" },
  { number: "081201", name: "Urcos", provincia: "0812" },
  { number: "081202", name: "Andahuaylillas", provincia: "0812" },
  { number: "081203", name: "Camanti", provincia: "0812" },
  { number: "081204", name: "Ccarhuayo", provincia: "0812" },
  { number: "081205", name: "Ccatca", provincia: "0812" },
  { number: "081206", name: "Cusipata", provincia: "0812" },
  { number: "081207", name: "Huaro", provincia: "0812" },
  { number: "081208", name: "Lucre", provincia: "0812" },
  { number: "081209", name: "Marcapata", provincia: "0812" },
  { number: "081210", name: "Ocongate", provincia: "0812" },
  { number: "081211", name: "Oropesa", provincia: "0812" },
  { number: "081212", name: "Quiquijana", provincia: "0812" },
  { number: "081301", name: "Urubamba", provincia: "0813" },
  { number: "081302", name: "Chinchero", provincia: "0813" },
  { number: "081303", name: "Huayllabamba", provincia: "0813" },
  { number: "081304", name: "Machupicchu", provincia: "0813" },
  { number: "081305", name: "Maras", provincia: "0813" },
  { number: "081306", name: "Ollantaytambo", provincia: "0813" },
  { number: "081307", name: "Yucay", provincia: "0813" },
  { number: "090101", name: "Huancavelica", provincia: "0901" },
  { number: "090102", name: "Acobambilla", provincia: "0901" },
  { number: "090103", name: "Acoria", provincia: "0901" },
  { number: "090104", name: "Conayca", provincia: "0901" },
  { number: "090105", name: "Cuenca", provincia: "0901" },
  { number: "090106", name: "Huachocolpa", provincia: "0901" },
  { number: "090107", name: "Huayllahuara", provincia: "0901" },
  { number: "090108", name: "Izcuchaca", provincia: "0901" },
  { number: "090109", name: "Laria", provincia: "0901" },
  { number: "090110", name: "Manta", provincia: "0901" },
  { number: "090111", name: "Mariscal Caceres", provincia: "0901" },
  { number: "090112", name: "Moya", provincia: "0901" },
  { number: "090113", name: "Nuevo Occoro", provincia: "0901" },
  { number: "090114", name: "Palca", provincia: "0901" },
  { number: "090115", name: "Pilchaca", provincia: "0901" },
  { number: "090116", name: "Vilca", provincia: "0901" },
  { number: "090117", name: "Yauli", provincia: "0901" },
  { number: "090118", name: "Ascension", provincia: "0901" },
  { number: "090119", name: "Huando", provincia: "0901" },
  { number: "090201", name: "Acobamba", provincia: "0902" },
  { number: "090202", name: "Andabamba", provincia: "0902" },
  { number: "090203", name: "Anta", provincia: "0902" },
  { number: "090204", name: "Caja", provincia: "0902" },
  { number: "090205", name: "Marcas", provincia: "0902" },
  { number: "090206", name: "Paucara", provincia: "0902" },
  { number: "090207", name: "Pomacocha", provincia: "0902" },
  { number: "090208", name: "Rosario", provincia: "0902" },
  { number: "090301", name: "Lircay", provincia: "0903" },
  { number: "090302", name: "Anchonga", provincia: "0903" },
  { number: "090303", name: "Callanmarca", provincia: "0903" },
  { number: "090304", name: "Ccochaccasa", provincia: "0903" },
  { number: "090305", name: "Chincho", provincia: "0903" },
  { number: "090306", name: "Congalla", provincia: "0903" },
  { number: "090307", name: "Huanca-Huanca", provincia: "0903" },
  { number: "090308", name: "Huayllay Grande", provincia: "0903" },
  { number: "090309", name: "Julcamarca", provincia: "0903" },
  { number: "090310", name: "San Antonio de Antaparco", provincia: "0903" },
  { number: "090311", name: "Santo Tomas de Pata", provincia: "0903" },
  { number: "090312", name: "Secclla", provincia: "0903" },
  { number: "090401", name: "Castrovirreyna", provincia: "0904" },
  { number: "090402", name: "Arma", provincia: "0904" },
  { number: "090403", name: "Aurahua", provincia: "0904" },
  { number: "090404", name: "Capillas", provincia: "0904" },
  { number: "090405", name: "Chupamarca", provincia: "0904" },
  { number: "090406", name: "Cocas", provincia: "0904" },
  { number: "090407", name: "Huachos", provincia: "0904" },
  { number: "090408", name: "Huamatambo", provincia: "0904" },
  { number: "090409", name: "Mollepampa", provincia: "0904" },
  { number: "090410", name: "San Juan", provincia: "0904" },
  { number: "090411", name: "Santa Ana", provincia: "0904" },
  { number: "090412", name: "Tantara", provincia: "0904" },
  { number: "090413", name: "Ticrapo", provincia: "0904" },
  { number: "090501", name: "Churcampa", provincia: "0905" },
  { number: "090502", name: "Anco", provincia: "0905" },
  { number: "090503", name: "Chinchihuasi", provincia: "0905" },
  { number: "090504", name: "El Carmen", provincia: "0905" },
  { number: "090505", name: "La Merced", provincia: "0905" },
  { number: "090506", name: "Locroja", provincia: "0905" },
  { number: "090507", name: "Paucarbamba", provincia: "0905" },
  { number: "090508", name: "San Miguel de Mayocc", provincia: "0905" },
  { number: "090509", name: "San Pedro de Coris", provincia: "0905" },
  { number: "090510", name: "Pachamarca", provincia: "0905" },
  { number: "090601", name: "Huaytara", provincia: "0906" },
  { number: "090602", name: "Ayavi", provincia: "0906" },
  { number: "090603", name: "Cordova", provincia: "0906" },
  { number: "090604", name: "Huayacundo Arma", provincia: "0906" },
  { number: "090605", name: "Laramarca", provincia: "0906" },
  { number: "090606", name: "Ocoyo", provincia: "0906" },
  { number: "090607", name: "Pilpichaca", provincia: "0906" },
  { number: "090608", name: "Querco", provincia: "0906" },
  { number: "090609", name: "Quito-Arma", provincia: "0906" },
  { number: "090610", name: "San Antonio de Cusicancha", provincia: "0906" },
  { number: "090611", name: "San Francisco de Sangayaico", provincia: "0906" },
  { number: "090612", name: "San Isidro", provincia: "0906" },
  { number: "090613", name: "Santiago de Chocorvos", provincia: "0906" },
  { number: "090614", name: "Santiago de Quirahuara", provincia: "0906" },
  { number: "090615", name: "Santo Domingo de Capillas", provincia: "0906" },
  { number: "090616", name: "Tambo", provincia: "0906" },
  { number: "090701", name: "Pampas", provincia: "0907" },
  { number: "090702", name: "Acostambo", provincia: "0907" },
  { number: "090703", name: "Acraquia", provincia: "0907" },
  { number: "090704", name: "Ahuaycha", provincia: "0907" },
  { number: "090705", name: "Colcabamba", provincia: "0907" },
  { number: "090706", name: "Daniel Hernandez", provincia: "0907" },
  { number: "090707", name: "Huachocolpa", provincia: "0907" },
  { number: "090709", name: "Huaribamba", provincia: "0907" },
  { number: "090710", name: "Ñahuimpuquio", provincia: "0907" },
  { number: "090711", name: "Pazos", provincia: "0907" },
  { number: "090713", name: "Quishuar", provincia: "0907" },
  { number: "090714", name: "Salcabamba", provincia: "0907" },
  { number: "090715", name: "Salcahuasi", provincia: "0907" },
  { number: "090716", name: "San Marcos de Rocchac", provincia: "0907" },
  { number: "090717", name: "Surcubamba", provincia: "0907" },
  { number: "090718", name: "Tintay Puncu", provincia: "0907" },
  { number: "100101", name: "Huanuco", provincia: "1001" },
  { number: "100102", name: "Amarilis", provincia: "1001" },
  { number: "100103", name: "Chinchao", provincia: "1001" },
  { number: "100104", name: "Churubamba", provincia: "1001" },
  { number: "100105", name: "Margos", provincia: "1001" },
  { number: "100106", name: "Quisqui", provincia: "1001" },
  { number: "100107", name: "San Francisco de Cayran", provincia: "1001" },
  { number: "100108", name: "San Pedro de Chaulan", provincia: "1001" },
  { number: "100109", name: "Santa Maria del Valle", provincia: "1001" },
  { number: "100110", name: "Yarumayo", provincia: "1001" },
  { number: "100111", name: "Pillco Marca", provincia: "1001" },
  { number: "100201", name: "Ambo", provincia: "1002" },
  { number: "100202", name: "Cayna", provincia: "1002" },
  { number: "100203", name: "Colpas", provincia: "1002" },
  { number: "100204", name: "Conchamarca", provincia: "1002" },
  { number: "100205", name: "Huacar", provincia: "1002" },
  { number: "100206", name: "San Francisco", provincia: "1002" },
  { number: "100207", name: "San Rafael", provincia: "1002" },
  { number: "100208", name: "Tomay Kichwa", provincia: "1002" },
  { number: "100301", name: "La Union", provincia: "1003" },
  { number: "100307", name: "Chuquis", provincia: "1003" },
  { number: "100311", name: "Marias", provincia: "1003" },
  { number: "100313", name: "Pachas", provincia: "1003" },
  { number: "100316", name: "Quivilla", provincia: "1003" },
  { number: "100317", name: "Ripan", provincia: "1003" },
  { number: "100321", name: "Shunqui", provincia: "1003" },
  { number: "100322", name: "Sillapata", provincia: "1003" },
  { number: "100323", name: "Yanas", provincia: "1003" },
  { number: "100401", name: "Huacaybamba", provincia: "1004" },
  { number: "100402", name: "Canchabamba", provincia: "1004" },
  { number: "100403", name: "Cochabamba", provincia: "1004" },
  { number: "100404", name: "Pinra", provincia: "1004" },
  { number: "100501", name: "Llata", provincia: "1005" },
  { number: "100502", name: "Arancay", provincia: "1005" },
  { number: "100503", name: "Chavin de Pariarca", provincia: "1005" },
  { number: "100504", name: "Jacas Grande", provincia: "1005" },
  { number: "100505", name: "Jircan", provincia: "1005" },
  { number: "100506", name: "Miraflores", provincia: "1005" },
  { number: "100507", name: "Monzon", provincia: "1005" },
  { number: "100508", name: "Punchao", provincia: "1005" },
  { number: "100509", name: "Puños", provincia: "1005" },
  { number: "100510", name: "Singa", provincia: "1005" },
  { number: "100511", name: "Tantamayo", provincia: "1005" },
  { number: "100601", name: "Rupa-Rupa", provincia: "1006" },
  { number: "100602", name: "Daniel Alomia Robles", provincia: "1006" },
  { number: "100603", name: "Hermilio Valdizan", provincia: "1006" },
  { number: "100604", name: "Jose Crespo Y Castillo", provincia: "1006" },
  { number: "100605", name: "Luyando 1/", provincia: "1006" },
  { number: "100606", name: "Mariano Damaso Beraun", provincia: "1006" },
  { number: "100701", name: "Huacrachuco", provincia: "1007" },
  { number: "100702", name: "Cholon", provincia: "1007" },
  { number: "100703", name: "San Buenaventura", provincia: "1007" },
  { number: "100801", name: "Panao", provincia: "1008" },
  { number: "100802", name: "Chaglla", provincia: "1008" },
  { number: "100803", name: "Molino", provincia: "1008" },
  { number: "100804", name: "Umari", provincia: "1008" },
  { number: "100901", name: "Puerto Inca", provincia: "1009" },
  { number: "100902", name: "Codo del Pozuzo", provincia: "1009" },
  { number: "100903", name: "Honoria", provincia: "1009" },
  { number: "100904", name: "Tournavista", provincia: "1009" },
  { number: "100905", name: "Yuyapichis", provincia: "1009" },
  { number: "101001", name: "Jesus", provincia: "1010" },
  { number: "101002", name: "Baños", provincia: "1010" },
  { number: "101003", name: "Jivia", provincia: "1010" },
  { number: "101004", name: "Queropalca", provincia: "1010" },
  { number: "101005", name: "Rondos", provincia: "1010" },
  { number: "101006", name: "San Francisco de Asis", provincia: "1010" },
  { number: "101007", name: "San Miguel de Cauri", provincia: "1010" },
  { number: "101101", name: "Chavinillo", provincia: "1011" },
  { number: "101102", name: "Cahuac", provincia: "1011" },
  { number: "101103", name: "Chacabamba", provincia: "1011" },
  { number: "101104", name: "Aparicio Pomares", provincia: "1011" },
  { number: "101105", name: "Jacas Chico", provincia: "1011" },
  { number: "101106", name: "Obas", provincia: "1011" },
  { number: "101107", name: "Pampamarca", provincia: "1011" },
  { number: "101108", name: "Choras", provincia: "1011" },
  { number: "110101", name: "Ica", provincia: "1101" },
  { number: "110102", name: "La Tinguiña", provincia: "1101" },
  { number: "110103", name: "Los Aquijes", provincia: "1101" },
  { number: "110104", name: "Ocucaje", provincia: "1101" },
  { number: "110105", name: "Pachacutec", provincia: "1101" },
  { number: "110106", name: "Parcona", provincia: "1101" },
  { number: "110107", name: "Pueblo Nuevo", provincia: "1101" },
  { number: "110108", name: "Salas", provincia: "1101" },
  { number: "110109", name: "San Jose de los Molinos", provincia: "1101" },
  { number: "110110", name: "San Juan Bautista", provincia: "1101" },
  { number: "110111", name: "Santiago", provincia: "1101" },
  { number: "110112", name: "Subtanjalla", provincia: "1101" },
  { number: "110113", name: "Tate", provincia: "1101" },
  { number: "110114", name: "Yauca del Rosario 1/", provincia: "1101" },
  { number: "110201", name: "Chincha Alta", provincia: "1102" },
  { number: "110202", name: "Alto Laran", provincia: "1102" },
  { number: "110203", name: "Chavin", provincia: "1102" },
  { number: "110204", name: "Chincha Baja", provincia: "1102" },
  { number: "110205", name: "El Carmen", provincia: "1102" },
  { number: "110206", name: "Grocio Prado", provincia: "1102" },
  { number: "110207", name: "Pueblo Nuevo", provincia: "1102" },
  { number: "110208", name: "San Juan de Yanac", provincia: "1102" },
  { number: "110209", name: "San Pedro de Huacarpana", provincia: "1102" },
  { number: "110210", name: "Sunampe", provincia: "1102" },
  { number: "110211", name: "Tambo de Mora", provincia: "1102" },
  { number: "110301", name: "Nazca", provincia: "1103" },
  { number: "110302", name: "Changuillo", provincia: "1103" },
  { number: "110303", name: "El Ingenio", provincia: "1103" },
  { number: "110304", name: "Marcona", provincia: "1103" },
  { number: "110305", name: "Vista Alegre", provincia: "1103" },
  { number: "110401", name: "Palpa", provincia: "1104" },
  { number: "110402", name: "Llipata", provincia: "1104" },
  { number: "110403", name: "Rio Grande", provincia: "1104" },
  { number: "110404", name: "Santa Cruz", provincia: "1104" },
  { number: "110405", name: "Tibillo", provincia: "1104" },
  { number: "110501", name: "Pisco", provincia: "1105" },
  { number: "110502", name: "Huancano", provincia: "1105" },
  { number: "110503", name: "Humay", provincia: "1105" },
  { number: "110504", name: "Independencia", provincia: "1105" },
  { number: "110505", name: "Paracas", provincia: "1105" },
  { number: "110506", name: "San Andres", provincia: "1105" },
  { number: "110507", name: "San Clemente", provincia: "1105" },
  { number: "110508", name: "Tupac Amaru Inca", provincia: "1105" },
  { number: "120101", name: "Huancayo", provincia: "1201" },
  { number: "120104", name: "Carhuacallanga", provincia: "1201" },
  { number: "120105", name: "Chacapampa", provincia: "1201" },
  { number: "120106", name: "Chicche", provincia: "1201" },
  { number: "120107", name: "Chilca", provincia: "1201" },
  { number: "120108", name: "Chongos Alto", provincia: "1201" },
  { number: "120111", name: "Chupuro", provincia: "1201" },
  { number: "120112", name: "Colca", provincia: "1201" },
  { number: "120113", name: "Cullhuas", provincia: "1201" },
  { number: "120114", name: "El Tambo", provincia: "1201" },
  { number: "120116", name: "Huacrapuquio", provincia: "1201" },
  { number: "120117", name: "Hualhuas", provincia: "1201" },
  { number: "120119", name: "Huancan", provincia: "1201" },
  { number: "120120", name: "Huasicancha", provincia: "1201" },
  { number: "120121", name: "Huayucachi", provincia: "1201" },
  { number: "120122", name: "Ingenio", provincia: "1201" },
  { number: "120124", name: "Pariahuanca 1/", provincia: "1201" },
  { number: "120125", name: "Pilcomayo", provincia: "1201" },
  { number: "120126", name: "Pucara", provincia: "1201" },
  { number: "120127", name: "Quichuay", provincia: "1201" },
  { number: "120128", name: "Quilcas", provincia: "1201" },
  { number: "120129", name: "San Agustin", provincia: "1201" },
  { number: "120130", name: "San Jeronimo de Tunan", provincia: "1201" },
  { number: "120132", name: "Saño", provincia: "1201" },
  { number: "120133", name: "Sapallanga", provincia: "1201" },
  { number: "120134", name: "Sicaya", provincia: "1201" },
  { number: "120135", name: "Santo Domingo de Acobamba", provincia: "1201" },
  { number: "120136", name: "Viques", provincia: "1201" },
  { number: "120201", name: "Concepcion", provincia: "1202" },
  { number: "120202", name: "Aco", provincia: "1202" },
  { number: "120203", name: "Andamarca", provincia: "1202" },
  { number: "120204", name: "Chambara", provincia: "1202" },
  { number: "120205", name: "Cochas", provincia: "1202" },
  { number: "120206", name: "Comas", provincia: "1202" },
  { number: "120207", name: "Heroinas Toledo", provincia: "1202" },
  { number: "120208", name: "Manzanares", provincia: "1202" },
  { number: "120209", name: "Mariscal Castilla", provincia: "1202" },
  { number: "120210", name: "Matahuasi", provincia: "1202" },
  { number: "120211", name: "Mito", provincia: "1202" },
  { number: "120212", name: "Nueve de Julio", provincia: "1202" },
  { number: "120213", name: "Orcotuna", provincia: "1202" },
  { number: "120214", name: "San Jose de Quero", provincia: "1202" },
  { number: "120215", name: "Santa Rosa de Ocopa", provincia: "1202" },
  { number: "120301", name: "Chanchamayo", provincia: "1203" },
  { number: "120302", name: "Perene", provincia: "1203" },
  { number: "120303", name: "Pichanaqui", provincia: "1203" },
  { number: "120304", name: "San Luis de Shuaro", provincia: "1203" },
  { number: "120305", name: "San Ramon", provincia: "1203" },
  { number: "120306", name: "Vitoc", provincia: "1203" },
  { number: "120401", name: "Jauja", provincia: "1204" },
  { number: "120402", name: "Acolla", provincia: "1204" },
  { number: "120403", name: "Apata", provincia: "1204" },
  { number: "120404", name: "Ataura", provincia: "1204" },
  { number: "120405", name: "Canchayllo", provincia: "1204" },
  { number: "120406", name: "Curicaca", provincia: "1204" },
  { number: "120407", name: "El Mantaro", provincia: "1204" },
  { number: "120408", name: "Huamali", provincia: "1204" },
  { number: "120409", name: "Huaripampa", provincia: "1204" },
  { number: "120410", name: "Huertas", provincia: "1204" },
  { number: "120411", name: "Janjaillo", provincia: "1204" },
  { number: "120412", name: "Julcan", provincia: "1204" },
  { number: "120413", name: "Leonor Ordoñez", provincia: "1204" },
  { number: "120414", name: "Llocllapampa", provincia: "1204" },
  { number: "120415", name: "Marco", provincia: "1204" },
  { number: "120416", name: "Masma", provincia: "1204" },
  { number: "120417", name: "Masma Chicche", provincia: "1204" },
  { number: "120418", name: "Molinos", provincia: "1204" },
  { number: "120419", name: "Monobamba", provincia: "1204" },
  { number: "120420", name: "Muqui", provincia: "1204" },
  { number: "120421", name: "Muquiyauyo", provincia: "1204" },
  { number: "120422", name: "Paca", provincia: "1204" },
  { number: "120423", name: "Paccha", provincia: "1204" },
  { number: "120424", name: "Pancan", provincia: "1204" },
  { number: "120425", name: "Parco", provincia: "1204" },
  { number: "120426", name: "Pomacancha", provincia: "1204" },
  { number: "120427", name: "Ricran", provincia: "1204" },
  { number: "120428", name: "San Lorenzo", provincia: "1204" },
  { number: "120429", name: "San Pedro de Chunan", provincia: "1204" },
  { number: "120430", name: "Sausa", provincia: "1204" },
  { number: "120431", name: "Sincos", provincia: "1204" },
  { number: "120432", name: "Tunan Marca", provincia: "1204" },
  { number: "120433", name: "Yauli", provincia: "1204" },
  { number: "120434", name: "Yauyos", provincia: "1204" },
  { number: "120501", name: "Junin", provincia: "1205" },
  { number: "120502", name: "Carhuamayo", provincia: "1205" },
  { number: "120503", name: "Ondores", provincia: "1205" },
  { number: "120504", name: "Ulcumayo", provincia: "1205" },
  { number: "120601", name: "Satipo", provincia: "1206" },
  { number: "120602", name: "Coviriali", provincia: "1206" },
  { number: "120603", name: "Llaylla", provincia: "1206" },
  { number: "120604", name: "Mazamari", provincia: "1206" },
  { number: "120605", name: "Pampa Hermosa", provincia: "1206" },
  { number: "120606", name: "Pangoa", provincia: "1206" },
  { number: "120607", name: "Rio Negro", provincia: "1206" },
  { number: "120608", name: "Rio Tambo", provincia: "1206" },
  { number: "120701", name: "Tarma", provincia: "1207" },
  { number: "120702", name: "Acobamba", provincia: "1207" },
  { number: "120703", name: "Huaricolca", provincia: "1207" },
  { number: "120704", name: "Huasahuasi", provincia: "1207" },
  { number: "120705", name: "La Union", provincia: "1207" },
  { number: "120706", name: "Palca", provincia: "1207" },
  { number: "120707", name: "Palcamayo", provincia: "1207" },
  { number: "120708", name: "San Pedro de Cajas", provincia: "1207" },
  { number: "120709", name: "Tapo", provincia: "1207" },
  { number: "120801", name: "La Oroya", provincia: "1208" },
  { number: "120802", name: "Chacapalpa", provincia: "1208" },
  { number: "120803", name: "Huay-Huay", provincia: "1208" },
  { number: "120804", name: "Marcapomacocha", provincia: "1208" },
  { number: "120805", name: "Morococha", provincia: "1208" },
  { number: "120806", name: "Paccha", provincia: "1208" },
  { number: "120807", name: "Santa Barbara de Carhuacayan", provincia: "1208" },
  { number: "120808", name: "Santa Rosa de Sacco", provincia: "1208" },
  { number: "120809", name: "Suitucancha", provincia: "1208" },
  { number: "120810", name: "Yauli", provincia: "1208" },
  { number: "120901", name: "Chupaca", provincia: "1209" },
  { number: "120902", name: "Ahuac", provincia: "1209" },
  { number: "120903", name: "Chongos Bajo", provincia: "1209" },
  { number: "120904", name: "Huachac", provincia: "1209" },
  { number: "120905", name: "Huamancaca Chico", provincia: "1209" },
  { number: "120906", name: "San Juan de Iscos", provincia: "1209" },
  { number: "120907", name: "San Juan de Jarpa", provincia: "1209" },
  { number: "120908", name: "Tres de Diciembre", provincia: "1209" },
  { number: "120909", name: "Yanacancha", provincia: "1209" },
  { number: "130101", name: "Trujillo", provincia: "1301" },
  { number: "130102", name: "El Porvenir", provincia: "1301" },
  { number: "130103", name: "Florencia de Mora", provincia: "1301" },
  { number: "130104", name: "Huanchaco", provincia: "1301" },
  { number: "130105", name: "La Esperanza", provincia: "1301" },
  { number: "130106", name: "Laredo", provincia: "1301" },
  { number: "130107", name: "Moche", provincia: "1301" },
  { number: "130108", name: "Poroto", provincia: "1301" },
  { number: "130109", name: "Salaverry", provincia: "1301" },
  { number: "130110", name: "Simbal", provincia: "1301" },
  { number: "130111", name: "Victor Larco Herrera", provincia: "1301" },
  { number: "130201", name: "Ascope", provincia: "1302" },
  { number: "130202", name: "Chicama", provincia: "1302" },
  { number: "130203", name: "Chocope", provincia: "1302" },
  { number: "130204", name: "Magdalena de Cao", provincia: "1302" },
  { number: "130205", name: "Paijan", provincia: "1302" },
  { number: "130206", name: "Razuri", provincia: "1302" },
  { number: "130207", name: "Santiago de Cao", provincia: "1302" },
  { number: "130208", name: "Casa Grande", provincia: "1302" },
  { number: "130301", name: "Bolivar", provincia: "1303" },
  { number: "130302", name: "Bambamarca", provincia: "1303" },
  { number: "130303", name: "Condormarca /1", provincia: "1303" },
  { number: "130304", name: "Longotea", provincia: "1303" },
  { number: "130305", name: "Uchumarca", provincia: "1303" },
  { number: "130306", name: "Ucuncha", provincia: "1303" },
  { number: "130401", name: "Chepen", provincia: "1304" },
  { number: "130402", name: "Pacanga", provincia: "1304" },
  { number: "130403", name: "Pueblo Nuevo", provincia: "1304" },
  { number: "130501", name: "Julcan", provincia: "1305" },
  { number: "130502", name: "Calamarca", provincia: "1305" },
  { number: "130503", name: "Carabamba", provincia: "1305" },
  { number: "130504", name: "Huaso", provincia: "1305" },
  { number: "130601", name: "Otuzco", provincia: "1306" },
  { number: "130602", name: "Agallpampa", provincia: "1306" },
  { number: "130604", name: "Charat", provincia: "1306" },
  { number: "130605", name: "Huaranchal", provincia: "1306" },
  { number: "130606", name: "La Cuesta", provincia: "1306" },
  { number: "130608", name: "Mache", provincia: "1306" },
  { number: "130610", name: "Paranday", provincia: "1306" },
  { number: "130611", name: "Salpo", provincia: "1306" },
  { number: "130613", name: "Sinsicap", provincia: "1306" },
  { number: "130614", name: "Usquil", provincia: "1306" },
  { number: "130701", name: "San Pedro de Lloc", provincia: "1307" },
  { number: "130702", name: "Guadalupe", provincia: "1307" },
  { number: "130703", name: "Jequetepeque", provincia: "1307" },
  { number: "130704", name: "Pacasmayo", provincia: "1307" },
  { number: "130705", name: "San Jose", provincia: "1307" },
  { number: "130801", name: "Tayabamba", provincia: "1308" },
  { number: "130802", name: "Buldibuyo", provincia: "1308" },
  { number: "130803", name: "Chillia", provincia: "1308" },
  { number: "130804", name: "Huancaspata", provincia: "1308" },
  { number: "130805", name: "Huaylillas", provincia: "1308" },
  { number: "130806", name: "Huayo", provincia: "1308" },
  { number: "130807", name: "Ongon", provincia: "1308" },
  { number: "130808", name: "Parcoy", provincia: "1308" },
  { number: "130809", name: "Pataz", provincia: "1308" },
  { number: "130810", name: "Pias", provincia: "1308" },
  { number: "130811", name: "Santiago de Challas", provincia: "1308" },
  { number: "130812", name: "Taurija", provincia: "1308" },
  { number: "130813", name: "Urpay", provincia: "1308" },
  { number: "130901", name: "Huamachuco", provincia: "1309" },
  { number: "130902", name: "Chugay", provincia: "1309" },
  { number: "130903", name: "Cochorco", provincia: "1309" },
  { number: "130904", name: "Curgos", provincia: "1309" },
  { number: "130905", name: "Marcabal", provincia: "1309" },
  { number: "130906", name: "Sanagoran", provincia: "1309" },
  { number: "130907", name: "Sarin", provincia: "1309" },
  { number: "130908", name: "Sartimbamba", provincia: "1309" },
  { number: "131001", name: "Santiago de Chuco", provincia: "1310" },
  { number: "131002", name: "Angasmarca", provincia: "1310" },
  { number: "131003", name: "Cachicadan", provincia: "1310" },
  { number: "131004", name: "Mollebamba", provincia: "1310" },
  { number: "131005", name: "Mollepata", provincia: "1310" },
  { number: "131006", name: "Quiruvilca", provincia: "1310" },
  { number: "131007", name: "Santa Cruz de Chuca", provincia: "1310" },
  { number: "131008", name: "Sitabamba", provincia: "1310" },
  { number: "131101", name: "Cascas", provincia: "1311" },
  { number: "131102", name: "Lucma", provincia: "1311" },
  { number: "131103", name: "Compin", provincia: "1311" },
  { number: "131104", name: "Sayapullo", provincia: "1311" },
  { number: "131201", name: "Viru", provincia: "1312" },
  { number: "131202", name: "Chao", provincia: "1312" },
  { number: "131203", name: "Guadalupito", provincia: "1312" },
  { number: "140101", name: "Chiclayo", provincia: "1401" },
  { number: "140102", name: "Chongoyape", provincia: "1401" },
  { number: "140103", name: "Eten", provincia: "1401" },
  { number: "140104", name: "Eten Puerto", provincia: "1401" },
  { number: "140105", name: "Jose Leonardo Ortiz", provincia: "1401" },
  { number: "140106", name: "La Victoria", provincia: "1401" },
  { number: "140107", name: "Lagunas", provincia: "1401" },
  { number: "140108", name: "Monsefu", provincia: "1401" },
  { number: "140109", name: "Nueva Arica", provincia: "1401" },
  { number: "140110", name: "Oyotun", provincia: "1401" },
  { number: "140111", name: "Picsi", provincia: "1401" },
  { number: "140112", name: "Pimentel", provincia: "1401" },
  { number: "140113", name: "Reque", provincia: "1401" },
  { number: "140114", name: "Santa Rosa", provincia: "1401" },
  { number: "140115", name: "Saña", provincia: "1401" },
  { number: "140116", name: "Cayalti", provincia: "1401" },
  { number: "140117", name: "Patapo", provincia: "1401" },
  { number: "140118", name: "Pomalca", provincia: "1401" },
  { number: "140119", name: "Pucala", provincia: "1401" },
  { number: "140120", name: "Tuman", provincia: "1401" },
  { number: "140201", name: "Ferreñafe", provincia: "1402" },
  { number: "140202", name: "Cañaris", provincia: "1402" },
  { number: "140203", name: "Incahuasi", provincia: "1402" },
  { number: "140204", name: "Manuel Antonio Mesones Muro", provincia: "1402" },
  { number: "140205", name: "Pitipo", provincia: "1402" },
  { number: "140206", name: "Pueblo Nuevo", provincia: "1402" },
  { number: "140301", name: "Lambayeque", provincia: "1403" },
  { number: "140302", name: "Chochope", provincia: "1403" },
  { number: "140303", name: "Illimo", provincia: "1403" },
  { number: "140304", name: "Jayanca", provincia: "1403" },
  { number: "140305", name: "Mochumi", provincia: "1403" },
  { number: "140306", name: "Morrope", provincia: "1403" },
  { number: "140307", name: "Motupe", provincia: "1403" },
  { number: "140308", name: "Olmos", provincia: "1403" },
  { number: "140309", name: "Pacora", provincia: "1403" },
  { number: "140310", name: "Salas", provincia: "1403" },
  { number: "140311", name: "San Jose", provincia: "1403" },
  { number: "140312", name: "Tucume", provincia: "1403" },
  { number: "150101", name: "Lima", provincia: "1501" },
  { number: "150102", name: "Ancon", provincia: "1501" },
  { number: "150103", name: "Ate", provincia: "1501" },
  { number: "150104", name: "Barranco", provincia: "1501" },
  { number: "150105", name: "Breña", provincia: "1501" },
  { number: "150106", name: "Carabayllo", provincia: "1501" },
  { number: "150107", name: "Chaclacayo", provincia: "1501" },
  { number: "150108", name: "Chorrillos", provincia: "1501" },
  { number: "150109", name: "Cieneguilla", provincia: "1501" },
  { number: "150110", name: "Comas", provincia: "1501" },
  { number: "150111", name: "El Agustino", provincia: "1501" },
  { number: "150112", name: "Independencia", provincia: "1501" },
  { number: "150113", name: "Jesus Maria", provincia: "1501" },
  { number: "150114", name: "La Molina", provincia: "1501" },
  { number: "150115", name: "La Victoria", provincia: "1501" },
  { number: "150116", name: "Lince", provincia: "1501" },
  { number: "150117", name: "Los Olivos", provincia: "1501" },
  { number: "150118", name: "Lurigancho", provincia: "1501" },
  { number: "150119", name: "Lurin", provincia: "1501" },
  { number: "150120", name: "Magdalena del Mar", provincia: "1501" },
  { number: "150121", name: "Pueblo Libre", provincia: "1501" },
  { number: "150122", name: "Miraflores", provincia: "1501" },
  { number: "150123", name: "Pachacamac", provincia: "1501" },
  { number: "150124", name: "Pucusana", provincia: "1501" },
  { number: "150125", name: "Puente Piedra", provincia: "1501" },
  { number: "150126", name: "Punta Hermosa", provincia: "1501" },
  { number: "150127", name: "Punta Negra", provincia: "1501" },
  { number: "150128", name: "Rimac", provincia: "1501" },
  { number: "150129", name: "San Bartolo", provincia: "1501" },
  { number: "150130", name: "San Borja", provincia: "1501" },
  { number: "150131", name: "San Isidro", provincia: "1501" },
  { number: "150132", name: "San Juan de Lurigancho", provincia: "1501" },
  { number: "150133", name: "San Juan de Miraflores", provincia: "1501" },
  { number: "150134", name: "San Luis", provincia: "1501" },
  { number: "150135", name: "San Martin de Porres", provincia: "1501" },
  { number: "150136", name: "San Miguel", provincia: "1501" },
  { number: "150137", name: "Santa Anita", provincia: "1501" },
  { number: "150138", name: "Santa Maria del Mar", provincia: "1501" },
  { number: "150139", name: "Santa Rosa", provincia: "1501" },
  { number: "150140", name: "Santiago de Surco", provincia: "1501" },
  { number: "150141", name: "Surquillo", provincia: "1501" },
  { number: "150142", name: "Villa El Salvador", provincia: "1501" },
  { number: "150143", name: "Villa Maria del Triunfo", provincia: "1501" },
  { number: "150201", name: "Barranca", provincia: "1502" },
  { number: "150202", name: "Paramonga", provincia: "1502" },
  { number: "150203", name: "Pativilca", provincia: "1502" },
  { number: "150204", name: "Supe", provincia: "1502" },
  { number: "150205", name: "Supe Puerto", provincia: "1502" },
  { number: "150301", name: "Cajatambo", provincia: "1503" },
  { number: "150302", name: "Copa", provincia: "1503" },
  { number: "150303", name: "Gorgor", provincia: "1503" },
  { number: "150304", name: "Huancapon", provincia: "1503" },
  { number: "150305", name: "Manas", provincia: "1503" },
  { number: "150401", name: "Canta", provincia: "1504" },
  { number: "150402", name: "Arahuay", provincia: "1504" },
  { number: "150403", name: "Huamantanga", provincia: "1504" },
  { number: "150404", name: "Huaros", provincia: "1504" },
  { number: "150405", name: "Lachaqui", provincia: "1504" },
  { number: "150406", name: "San Buenaventura", provincia: "1504" },
  { number: "150407", name: "Santa Rosa de Quives", provincia: "1504" },
  { number: "150501", name: "San Vicente de Cañete", provincia: "1505" },
  { number: "150502", name: "Asia", provincia: "1505" },
  { number: "150503", name: "Calango", provincia: "1505" },
  { number: "150504", name: "Cerro Azul", provincia: "1505" },
  { number: "150505", name: "Chilca", provincia: "1505" },
  { number: "150506", name: "Coayllo", provincia: "1505" },
  { number: "150507", name: "Imperial", provincia: "1505" },
  { number: "150508", name: "Lunahuana", provincia: "1505" },
  { number: "150509", name: "Mala", provincia: "1505" },
  { number: "150510", name: "Nuevo Imperial", provincia: "1505" },
  { number: "150511", name: "Pacaran", provincia: "1505" },
  { number: "150512", name: "Quilmana", provincia: "1505" },
  { number: "150513", name: "San Antonio", provincia: "1505" },
  { number: "150514", name: "San Luis", provincia: "1505" },
  { number: "150515", name: "Santa Cruz de Flores", provincia: "1505" },
  { number: "150516", name: "Zuñiga", provincia: "1505" },
  { number: "150601", name: "Huaral", provincia: "1506" },
  { number: "150602", name: "Atavillos Alto", provincia: "1506" },
  { number: "150603", name: "Atavillos Bajo", provincia: "1506" },
  { number: "150604", name: "Aucallama", provincia: "1506" },
  { number: "150605", name: "Chancay", provincia: "1506" },
  { number: "150606", name: "Ihuari", provincia: "1506" },
  { number: "150607", name: "Lampian", provincia: "1506" },
  { number: "150608", name: "Pacaraos", provincia: "1506" },
  { number: "150609", name: "San Miguel de Acos", provincia: "1506" },
  { number: "150610", name: "Santa Cruz de Andamarca", provincia: "1506" },
  { number: "150611", name: "Sumbilca", provincia: "1506" },
  { number: "150612", name: "Veintisiete de Noviembre", provincia: "1506" },
  { number: "150701", name: "Matucana", provincia: "1507" },
  { number: "150702", name: "Antioquia", provincia: "1507" },
  { number: "150703", name: "Callahuanca", provincia: "1507" },
  { number: "150704", name: "Carampoma", provincia: "1507" },
  { number: "150705", name: "Chicla", provincia: "1507" },
  { number: "150706", name: "Cuenca", provincia: "1507" },
  { number: "150707", name: "Huachupampa", provincia: "1507" },
  { number: "150708", name: "Huanza", provincia: "1507" },
  { number: "150709", name: "Huarochiri", provincia: "1507" },
  { number: "150710", name: "Lahuaytambo", provincia: "1507" },
  { number: "150711", name: "Langa", provincia: "1507" },
  { number: "150712", name: "Laraos", provincia: "1507" },
  { number: "150713", name: "Mariatana", provincia: "1507" },
  { number: "150714", name: "Ricardo Palma", provincia: "1507" },
  { number: "150715", name: "San Andres de Tupicocha", provincia: "1507" },
  { number: "150716", name: "San Antonio", provincia: "1507" },
  { number: "150717", name: "San Bartolome", provincia: "1507" },
  { number: "150718", name: "San Damian", provincia: "1507" },
  { number: "150719", name: "San Juan de Iris", provincia: "1507" },
  { number: "150720", name: "San Juan de Tantaranche", provincia: "1507" },
  { number: "150721", name: "San Lorenzo de Quinti", provincia: "1507" },
  { number: "150722", name: "San Mateo", provincia: "1507" },
  { number: "150723", name: "San Mateo de Otao", provincia: "1507" },
  { number: "150724", name: "San Pedro de Casta", provincia: "1507" },
  { number: "150725", name: "San Pedro de Huancayre", provincia: "1507" },
  { number: "150726", name: "Sangallaya", provincia: "1507" },
  { number: "150727", name: "Santa Cruz de Cocachacra", provincia: "1507" },
  { number: "150728", name: "Santa Eulalia", provincia: "1507" },
  { number: "150729", name: "Santiago de Anchucaya", provincia: "1507" },
  { number: "150730", name: "Santiago de Tuna", provincia: "1507" },
  { number: "150731", name: "Santo Domingo de los Olleros", provincia: "1507" },
  { number: "150732", name: "Surco", provincia: "1507" },
  { number: "150801", name: "Huacho", provincia: "1508" },
  { number: "150802", name: "Ambar", provincia: "1508" },
  { number: "150803", name: "Caleta de Carquin", provincia: "1508" },
  { number: "150804", name: "Checras", provincia: "1508" },
  { number: "150805", name: "Hualmay", provincia: "1508" },
  { number: "150806", name: "Huaura", provincia: "1508" },
  { number: "150807", name: "Leoncio Prado", provincia: "1508" },
  { number: "150808", name: "Paccho", provincia: "1508" },
  { number: "150809", name: "Santa Leonor", provincia: "1508" },
  { number: "150810", name: "Santa Maria", provincia: "1508" },
  { number: "150811", name: "Sayan", provincia: "1508" },
  { number: "150812", name: "Vegueta", provincia: "1508" },
  { number: "150901", name: "Oyon", provincia: "1509" },
  { number: "150902", name: "Andajes", provincia: "1509" },
  { number: "150903", name: "Caujul", provincia: "1509" },
  { number: "150904", name: "Cochamarca", provincia: "1509" },
  { number: "150905", name: "Navan", provincia: "1509" },
  { number: "150906", name: "Pachangara", provincia: "1509" },
  { number: "151001", name: "Yauyos", provincia: "1510" },
  { number: "151002", name: "Alis", provincia: "1510" },
  { number: "151003", name: "Allauca", provincia: "1510" },
  { number: "151004", name: "Ayaviri", provincia: "1510" },
  { number: "151005", name: "Azangaro", provincia: "1510" },
  { number: "151006", name: "Cacra", provincia: "1510" },
  { number: "151007", name: "Carania", provincia: "1510" },
  { number: "151008", name: "Catahuasi", provincia: "1510" },
  { number: "151009", name: "Chocos", provincia: "1510" },
  { number: "151010", name: "Cochas", provincia: "1510" },
  { number: "151011", name: "Colonia", provincia: "1510" },
  { number: "151012", name: "Hongos", provincia: "1510" },
  { number: "151013", name: "Huampara", provincia: "1510" },
  { number: "151014", name: "Huancaya", provincia: "1510" },
  { number: "151015", name: "Huangascar", provincia: "1510" },
  { number: "151016", name: "Huantan", provincia: "1510" },
  { number: "151017", name: "Huañec", provincia: "1510" },
  { number: "151018", name: "Laraos", provincia: "1510" },
  { number: "151019", name: "Lincha", provincia: "1510" },
  { number: "151020", name: "Madean", provincia: "1510" },
  { number: "151021", name: "Miraflores", provincia: "1510" },
  { number: "151022", name: "Omas", provincia: "1510" },
  { number: "151023", name: "Putinza", provincia: "1510" },
  { number: "151024", name: "Quinches", provincia: "1510" },
  { number: "151025", name: "Quinocay", provincia: "1510" },
  { number: "151026", name: "San Joaquin", provincia: "1510" },
  { number: "151027", name: "San Pedro de Pilas", provincia: "1510" },
  { number: "151028", name: "Tanta", provincia: "1510" },
  { number: "151029", name: "Tauripampa", provincia: "1510" },
  { number: "151030", name: "Tomas", provincia: "1510" },
  { number: "151031", name: "Tupe", provincia: "1510" },
  { number: "151032", name: "Viñac", provincia: "1510" },
  { number: "151033", name: "Vitis", provincia: "1510" },
  { number: "160101", name: "Iquitos", provincia: "1601" },
  { number: "160102", name: "Alto Nanay", provincia: "1601" },
  { number: "160103", name: "Fernando Lores", provincia: "1601" },
  { number: "160104", name: "Indiana", provincia: "1601" },
  { number: "160105", name: "Las Amazonas", provincia: "1601" },
  { number: "160106", name: "Mazan", provincia: "1601" },
  { number: "160107", name: "Napo", provincia: "1601" },
  { number: "160108", name: "Punchana", provincia: "1601" },
  { number: "160109", name: "Putumayo", provincia: "1601" },
  { number: "160110", name: "Torres Causana", provincia: "1601" },
  { number: "160112", name: "Belen", provincia: "1601" },
  { number: "160113", name: "San Juan Bautista", provincia: "1601" },
  { number: "160114", name: "Teniente Manuel Clavero", provincia: "1601" },
  { number: "160201", name: "Yurimaguas", provincia: "1602" },
  { number: "160202", name: "Balsapuerto", provincia: "1602" },
  { number: "160205", name: "Jeberos", provincia: "1602" },
  { number: "160206", name: "Lagunas", provincia: "1602" },
  { number: "160210", name: "Santa Cruz", provincia: "1602" },
  { number: "160211", name: "Teniente Cesar Lopez Rojas", provincia: "1602" },
  { number: "160301", name: "Nauta", provincia: "1603" },
  { number: "160302", name: "Parinari", provincia: "1603" },
  { number: "160303", name: "Tigre", provincia: "1603" },
  { number: "160304", name: "Trompeteros", provincia: "1603" },
  { number: "160305", name: "Urarinas", provincia: "1603" },
  { number: "160401", name: "Ramon Castilla", provincia: "1604" },
  { number: "160402", name: "Pebas", provincia: "1604" },
  { number: "160403", name: "Yavari /1", provincia: "1604" },
  { number: "160404", name: "San Pablo", provincia: "1604" },
  { number: "160501", name: "Requena", provincia: "1605" },
  { number: "160502", name: "Alto Tapiche", provincia: "1605" },
  { number: "160503", name: "Capelo", provincia: "1605" },
  { number: "160504", name: "Emilio San Martin", provincia: "1605" },
  { number: "160505", name: "Maquia", provincia: "1605" },
  { number: "160506", name: "Puinahua", provincia: "1605" },
  { number: "160507", name: "Saquena", provincia: "1605" },
  { number: "160508", name: "Soplin", provincia: "1605" },
  { number: "160509", name: "Tapiche", provincia: "1605" },
  { number: "160510", name: "Jenaro Herrera", provincia: "1605" },
  { number: "160511", name: "Yaquerana", provincia: "1605" },
  { number: "160601", name: "Contamana", provincia: "1606" },
  { number: "160602", name: "Inahuaya", provincia: "1606" },
  { number: "160603", name: "Padre Marquez", provincia: "1606" },
  { number: "160604", name: "Pampa Hermosa", provincia: "1606" },
  { number: "160605", name: "Sarayacu", provincia: "1606" },
  { number: "160606", name: "Vargas Guerra", provincia: "1606" },
  { number: "160701", name: "Barranca", provincia: "1607" },
  { number: "160702", name: "Cahuapanas", provincia: "1607" },
  { number: "160703", name: "Manseriche", provincia: "1607" },
  { number: "160704", name: "Morona", provincia: "1607" },
  { number: "160705", name: "Pastaza", provincia: "1607" },
  { number: "160706", name: "Andoas", provincia: "1607" },
  { number: "170101", name: "Tambopata", provincia: "1701" },
  { number: "170102", name: "Inambari", provincia: "1701" },
  { number: "170103", name: "Las Piedras", provincia: "1701" },
  { number: "170104", name: "Laberinto", provincia: "1701" },
  { number: "170201", name: "Manu", provincia: "1702" },
  { number: "170202", name: "Fitzcarrald", provincia: "1702" },
  { number: "170203", name: "Madre de Dios", provincia: "1702" },
  { number: "170204", name: "Huepetuhe", provincia: "1702" },
  { number: "170301", name: "Iñapari", provincia: "1703" },
  { number: "170302", name: "Iberia", provincia: "1703" },
  { number: "170303", name: "Tahuamanu", provincia: "1703" },
  { number: "180101", name: "Moquegua", provincia: "1801" },
  { number: "180102", name: "Carumas", provincia: "1801" },
  { number: "180103", name: "Cuchumbaya", provincia: "1801" },
  { number: "180104", name: "Samegua", provincia: "1801" },
  { number: "180105", name: "San Cristobal", provincia: "1801" },
  { number: "180106", name: "Torata", provincia: "1801" },
  { number: "180201", name: "Omate", provincia: "1802" },
  { number: "180202", name: "Chojata", provincia: "1802" },
  { number: "180203", name: "Coalaque", provincia: "1802" },
  { number: "180204", name: "Ichuña", provincia: "1802" },
  { number: "180205", name: "La Capilla", provincia: "1802" },
  { number: "180206", name: "Lloque", provincia: "1802" },
  { number: "180207", name: "Matalaque", provincia: "1802" },
  { number: "180208", name: "Puquina", provincia: "1802" },
  { number: "180209", name: "Quinistaquillas", provincia: "1802" },
  { number: "180210", name: "Ubinas", provincia: "1802" },
  { number: "180211", name: "Yunga", provincia: "1802" },
  { number: "180301", name: "Ilo", provincia: "1803" },
  { number: "180302", name: "El Algarrobal", provincia: "1803" },
  { number: "180303", name: "Pacocha", provincia: "1803" },
  { number: "190101", name: "Chaupimarca", provincia: "1901" },
  { number: "190102", name: "Huachon", provincia: "1901" },
  { number: "190103", name: "Huariaca", provincia: "1901" },
  { number: "190104", name: "Huayllay", provincia: "1901" },
  { number: "190105", name: "Ninacaca", provincia: "1901" },
  { number: "190106", name: "Pallanchacra", provincia: "1901" },
  { number: "190107", name: "Paucartambo", provincia: "1901" },
  {
    number: "190108",
    name: "San Francisco de Asis de Yarusyacan",
    provincia: "1901",
  },
  { number: "190109", name: "Simon Bolivar", provincia: "1901" },
  { number: "190110", name: "Ticlacayan", provincia: "1901" },
  { number: "190111", name: "Tinyahuarco", provincia: "1901" },
  { number: "190112", name: "Vicco", provincia: "1901" },
  { number: "190113", name: "Yanacancha", provincia: "1901" },
  { number: "190201", name: "Yanahuanca", provincia: "1902" },
  { number: "190202", name: "Chacayan", provincia: "1902" },
  { number: "190203", name: "Goyllarisquizga", provincia: "1902" },
  { number: "190204", name: "Paucar", provincia: "1902" },
  { number: "190205", name: "San Pedro de Pillao", provincia: "1902" },
  { number: "190206", name: "Santa Ana de Tusi", provincia: "1902" },
  { number: "190207", name: "Tapuc", provincia: "1902" },
  { number: "190208", name: "Vilcabamba", provincia: "1902" },
  { number: "190301", name: "Oxapampa", provincia: "1903" },
  { number: "190302", name: "Chontabamba", provincia: "1903" },
  { number: "190303", name: "Huancabamba", provincia: "1903" },
  { number: "190304", name: "Palcazu", provincia: "1903" },
  { number: "190305", name: "Pozuzo", provincia: "1903" },
  { number: "190306", name: "Puerto Bermudez", provincia: "1903" },
  { number: "190307", name: "Villa Rica", provincia: "1903" },
  { number: "200101", name: "Piura", provincia: "2001" },
  { number: "200104", name: "Castilla", provincia: "2001" },
  { number: "200105", name: "Catacaos", provincia: "2001" },
  { number: "200107", name: "Cura Mori", provincia: "2001" },
  { number: "200108", name: "El Tallan", provincia: "2001" },
  { number: "200109", name: "La Arena", provincia: "2001" },
  { number: "200110", name: "La Union", provincia: "2001" },
  { number: "200111", name: "Las Lomas", provincia: "2001" },
  { number: "200114", name: "Tambo Grande", provincia: "2001" },
  { number: "200201", name: "Ayabaca", provincia: "2002" },
  { number: "200202", name: "Frias", provincia: "2002" },
  { number: "200203", name: "Jilili", provincia: "2002" },
  { number: "200204", name: "Lagunas", provincia: "2002" },
  { number: "200205", name: "Montero", provincia: "2002" },
  { number: "200206", name: "Pacaipampa", provincia: "2002" },
  { number: "200207", name: "Paimas", provincia: "2002" },
  { number: "200208", name: "Sapillica", provincia: "2002" },
  { number: "200209", name: "Sicchez", provincia: "2002" },
  { number: "200210", name: "Suyo", provincia: "2002" },
  { number: "200301", name: "Huancabamba", provincia: "2003" },
  { number: "200302", name: "Canchaque", provincia: "2003" },
  { number: "200303", name: "El Carmen de la Frontera", provincia: "2003" },
  { number: "200304", name: "Huarmaca", provincia: "2003" },
  { number: "200305", name: "Lalaquiz", provincia: "2003" },
  { number: "200306", name: "San Miguel de El Faique", provincia: "2003" },
  { number: "200307", name: "Sondor", provincia: "2003" },
  { number: "200308", name: "Sondorillo", provincia: "2003" },
  { number: "200401", name: "Chulucanas", provincia: "2004" },
  { number: "200402", name: "Buenos Aires", provincia: "2004" },
  { number: "200403", name: "Chalaco", provincia: "2004" },
  { number: "200404", name: "La Matanza", provincia: "2004" },
  { number: "200405", name: "Morropon", provincia: "2004" },
  { number: "200406", name: "Salitral", provincia: "2004" },
  { number: "200407", name: "San Juan de Bigote", provincia: "2004" },
  { number: "200408", name: "Santa Catalina de Mossa", provincia: "2004" },
  { number: "200409", name: "Santo Domingo", provincia: "2004" },
  { number: "200410", name: "Yamango", provincia: "2004" },
  { number: "200501", name: "Paita", provincia: "2005" },
  { number: "200502", name: "Amotape", provincia: "2005" },
  { number: "200503", name: "Arenal", provincia: "2005" },
  { number: "200504", name: "Colan", provincia: "2005" },
  { number: "200505", name: "La Huaca", provincia: "2005" },
  { number: "200506", name: "Tamarindo", provincia: "2005" },
  { number: "200507", name: "Vichayal", provincia: "2005" },
  { number: "200601", name: "Sullana", provincia: "2006" },
  { number: "200602", name: "Bellavista", provincia: "2006" },
  { number: "200603", name: "Ignacio Escudero", provincia: "2006" },
  { number: "200604", name: "Lancones", provincia: "2006" },
  { number: "200605", name: "Marcavelica", provincia: "2006" },
  { number: "200606", name: "Miguel Checa", provincia: "2006" },
  { number: "200607", name: "Querecotillo", provincia: "2006" },
  { number: "200608", name: "Salitral", provincia: "2006" },
  { number: "200701", name: "Pariñas", provincia: "2007" },
  { number: "200702", name: "El Alto", provincia: "2007" },
  { number: "200703", name: "La Brea", provincia: "2007" },
  { number: "200704", name: "Lobitos", provincia: "2007" },
  { number: "200705", name: "Los Organos", provincia: "2007" },
  { number: "200706", name: "Mancora", provincia: "2007" },
  { number: "200801", name: "Sechura", provincia: "2008" },
  { number: "200802", name: "Bellavista de la Union", provincia: "2008" },
  { number: "200803", name: "Bernal", provincia: "2008" },
  { number: "200804", name: "Cristo Nos Valga", provincia: "2008" },
  { number: "200805", name: "Vice", provincia: "2008" },
  { number: "200806", name: "Rinconada Llicuar", provincia: "2008" },
  { number: "210101", name: "Puno", provincia: "2101" },
  { number: "210102", name: "Acora", provincia: "2101" },
  { number: "210103", name: "Amantani", provincia: "2101" },
  { number: "210104", name: "Atuncolla", provincia: "2101" },
  { number: "210105", name: "Capachica", provincia: "2101" },
  { number: "210106", name: "Chucuito", provincia: "2101" },
  { number: "210107", name: "Coata", provincia: "2101" },
  { number: "210108", name: "Huata", provincia: "2101" },
  { number: "210109", name: "Mañazo", provincia: "2101" },
  { number: "210110", name: "Paucarcolla", provincia: "2101" },
  { number: "210111", name: "Pichacani", provincia: "2101" },
  { number: "210112", name: "Plateria", provincia: "2101" },
  { number: "210113", name: "San Antonio /1", provincia: "2101" },
  { number: "210114", name: "Tiquillaca", provincia: "2101" },
  { number: "210115", name: "Vilque", provincia: "2101" },
  { number: "210201", name: "Azangaro", provincia: "2102" },
  { number: "210202", name: "Achaya", provincia: "2102" },
  { number: "210203", name: "Arapa", provincia: "2102" },
  { number: "210204", name: "Asillo", provincia: "2102" },
  { number: "210205", name: "Caminaca", provincia: "2102" },
  { number: "210206", name: "Chupa", provincia: "2102" },
  { number: "210207", name: "Jose Domingo Choquehuanca", provincia: "2102" },
  { number: "210208", name: "Muñani", provincia: "2102" },
  { number: "210209", name: "Potoni", provincia: "2102" },
  { number: "210210", name: "Saman", provincia: "2102" },
  { number: "210211", name: "San Anton", provincia: "2102" },
  { number: "210212", name: "San Jose", provincia: "2102" },
  { number: "210213", name: "San Juan de Salinas", provincia: "2102" },
  { number: "210214", name: "Santiago de Pupuja", provincia: "2102" },
  { number: "210215", name: "Tirapata", provincia: "2102" },
  { number: "210301", name: "Macusani", provincia: "2103" },
  { number: "210302", name: "Ajoyani", provincia: "2103" },
  { number: "210303", name: "Ayapata", provincia: "2103" },
  { number: "210304", name: "Coasa", provincia: "2103" },
  { number: "210305", name: "Corani", provincia: "2103" },
  { number: "210306", name: "Crucero", provincia: "2103" },
  { number: "210307", name: "Ituata 2/", provincia: "2103" },
  { number: "210308", name: "Ollachea", provincia: "2103" },
  { number: "210309", name: "San Gaban", provincia: "2103" },
  { number: "210310", name: "Usicayos", provincia: "2103" },
  { number: "210401", name: "Juli", provincia: "2104" },
  { number: "210402", name: "Desaguadero", provincia: "2104" },
  { number: "210403", name: "Huacullani", provincia: "2104" },
  { number: "210404", name: "Kelluyo", provincia: "2104" },
  { number: "210405", name: "Pisacoma", provincia: "2104" },
  { number: "210406", name: "Pomata", provincia: "2104" },
  { number: "210407", name: "Zepita", provincia: "2104" },
  { number: "210501", name: "Ilave", provincia: "2105" },
  { number: "210502", name: "Capazo", provincia: "2105" },
  { number: "210503", name: "Pilcuyo", provincia: "2105" },
  { number: "210504", name: "Santa Rosa", provincia: "2105" },
  { number: "210505", name: "Conduriri", provincia: "2105" },
  { number: "210601", name: "Huancane", provincia: "2106" },
  { number: "210602", name: "Cojata", provincia: "2106" },
  { number: "210603", name: "Huatasani", provincia: "2106" },
  { number: "210604", name: "Inchupalla", provincia: "2106" },
  { number: "210605", name: "Pusi", provincia: "2106" },
  { number: "210606", name: "Rosaspata", provincia: "2106" },
  { number: "210607", name: "Taraco", provincia: "2106" },
  { number: "210608", name: "Vilque Chico", provincia: "2106" },
  { number: "210701", name: "Lampa", provincia: "2107" },
  { number: "210702", name: "Cabanilla", provincia: "2107" },
  { number: "210703", name: "Calapuja", provincia: "2107" },
  { number: "210704", name: "Nicasio", provincia: "2107" },
  { number: "210705", name: "Ocuviri", provincia: "2107" },
  { number: "210706", name: "Palca", provincia: "2107" },
  { number: "210707", name: "Paratia", provincia: "2107" },
  { number: "210708", name: "Pucara", provincia: "2107" },
  { number: "210709", name: "Santa Lucia", provincia: "2107" },
  { number: "210710", name: "Vilavila", provincia: "2107" },
  { number: "210801", name: "Ayaviri", provincia: "2108" },
  { number: "210802", name: "Antauta", provincia: "2108" },
  { number: "210803", name: "Cupi", provincia: "2108" },
  { number: "210804", name: "Llalli", provincia: "2108" },
  { number: "210805", name: "Macari", provincia: "2108" },
  { number: "210806", name: "Nuñoa", provincia: "2108" },
  { number: "210807", name: "Orurillo", provincia: "2108" },
  { number: "210808", name: "Santa Rosa", provincia: "2108" },
  { number: "210809", name: "Umachiri", provincia: "2108" },
  { number: "210901", name: "Moho", provincia: "2109" },
  { number: "210902", name: "Conima", provincia: "2109" },
  { number: "210903", name: "Huayrapata", provincia: "2109" },
  { number: "210904", name: "Tilali", provincia: "2109" },
  { number: "211001", name: "Putina", provincia: "2110" },
  { number: "211002", name: "Ananea", provincia: "2110" },
  { number: "211003", name: "Pedro Vilca Apaza", provincia: "2110" },
  { number: "211004", name: "Quilcapuncu", provincia: "2110" },
  { number: "211005", name: "Sina", provincia: "2110" },
  { number: "211101", name: "Juliaca", provincia: "2111" },
  { number: "211102", name: "Cabana", provincia: "2111" },
  { number: "211103", name: "Cabanillas", provincia: "2111" },
  { number: "211104", name: "Caracoto", provincia: "2111" },
  { number: "211201", name: "Sandia", provincia: "2112" },
  { number: "211202", name: "Cuyocuyo", provincia: "2112" },
  { number: "211203", name: "Limbani", provincia: "2112" },
  { number: "211204", name: "Patambuco", provincia: "2112" },
  { number: "211205", name: "Phara", provincia: "2112" },
  { number: "211206", name: "Quiaca", provincia: "2112" },
  { number: "211207", name: "San Juan del Oro", provincia: "2112" },
  { number: "211208", name: "Yanahuaya", provincia: "2112" },
  { number: "211209", name: "Alto Inambari", provincia: "2112" },
  { number: "211210", name: "San Pedro de Putina Punco", provincia: "2112" },
  { number: "211301", name: "Yunguyo", provincia: "2113" },
  { number: "211302", name: "Anapia", provincia: "2113" },
  { number: "211303", name: "Copani", provincia: "2113" },
  { number: "211304", name: "Cuturapi", provincia: "2113" },
  { number: "211305", name: "Ollaraya", provincia: "2113" },
  { number: "211306", name: "Tinicachi", provincia: "2113" },
  { number: "211307", name: "Unicachi", provincia: "2113" },
  { number: "220101", name: "Moyobamba", provincia: "2201" },
  { number: "220102", name: "Calzada", provincia: "2201" },
  { number: "220103", name: "Habana", provincia: "2201" },
  { number: "220104", name: "Jepelacio", provincia: "2201" },
  { number: "220105", name: "Soritor", provincia: "2201" },
  { number: "220106", name: "Yantalo", provincia: "2201" },
  { number: "220201", name: "Bellavista", provincia: "2202" },
  { number: "220202", name: "Alto Biavo", provincia: "2202" },
  { number: "220203", name: "Bajo Biavo", provincia: "2202" },
  { number: "220204", name: "Huallaga", provincia: "2202" },
  { number: "220205", name: "San Pablo", provincia: "2202" },
  { number: "220206", name: "San Rafael", provincia: "2202" },
  { number: "220301", name: "San Jose de Sisa", provincia: "2203" },
  { number: "220302", name: "Agua Blanca", provincia: "2203" },
  { number: "220303", name: "San Martin", provincia: "2203" },
  { number: "220304", name: "Santa Rosa", provincia: "2203" },
  { number: "220305", name: "Shatoja", provincia: "2203" },
  { number: "220401", name: "Saposoa", provincia: "2204" },
  { number: "220402", name: "Alto Saposoa", provincia: "2204" },
  { number: "220403", name: "El Eslabon", provincia: "2204" },
  { number: "220404", name: "Piscoyacu", provincia: "2204" },
  { number: "220405", name: "Sacanche", provincia: "2204" },
  { number: "220406", name: "Tingo de Saposoa", provincia: "2204" },
  { number: "220501", name: "Lamas", provincia: "2205" },
  { number: "220502", name: "Alonso de Alvarado", provincia: "2205" },
  { number: "220503", name: "Barranquita", provincia: "2205" },
  { number: "220504", name: "Caynarachi 1/", provincia: "2205" },
  { number: "220505", name: "Cuñumbuqui", provincia: "2205" },
  { number: "220506", name: "Pinto Recodo", provincia: "2205" },
  { number: "220507", name: "Rumisapa", provincia: "2205" },
  { number: "220508", name: "San Roque de Cumbaza", provincia: "2205" },
  { number: "220509", name: "Shanao", provincia: "2205" },
  { number: "220510", name: "Tabalosos", provincia: "2205" },
  { number: "220511", name: "Zapatero", provincia: "2205" },
  { number: "220601", name: "Juanjui", provincia: "2206" },
  { number: "220602", name: "Campanilla", provincia: "2206" },
  { number: "220603", name: "Huicungo", provincia: "2206" },
  { number: "220604", name: "Pachiza", provincia: "2206" },
  { number: "220605", name: "Pajarillo", provincia: "2206" },
  { number: "220701", name: "Picota", provincia: "2207" },
  { number: "220702", name: "Buenos Aires", provincia: "2207" },
  { number: "220703", name: "Caspisapa", provincia: "2207" },
  { number: "220704", name: "Pilluana", provincia: "2207" },
  { number: "220705", name: "Pucacaca", provincia: "2207" },
  { number: "220706", name: "San Cristobal", provincia: "2207" },
  { number: "220707", name: "San Hilarion", provincia: "2207" },
  { number: "220708", name: "Shamboyacu", provincia: "2207" },
  { number: "220709", name: "Tingo de Ponasa", provincia: "2207" },
  { number: "220710", name: "Tres Unidos", provincia: "2207" },
  { number: "220801", name: "Rioja", provincia: "2208" },
  { number: "220802", name: "Awajun", provincia: "2208" },
  { number: "220803", name: "Elias Soplin Vargas", provincia: "2208" },
  { number: "220804", name: "Nueva Cajamarca", provincia: "2208" },
  { number: "220805", name: "Pardo Miguel", provincia: "2208" },
  { number: "220806", name: "Posic", provincia: "2208" },
  { number: "220807", name: "San Fernando", provincia: "2208" },
  { number: "220808", name: "Yorongos", provincia: "2208" },
  { number: "220809", name: "Yuracyacu", provincia: "2208" },
  { number: "220901", name: "Tarapoto", provincia: "2209" },
  { number: "220902", name: "Alberto Leveau", provincia: "2209" },
  { number: "220903", name: "Cacatachi", provincia: "2209" },
  { number: "220904", name: "Chazuta", provincia: "2209" },
  { number: "220905", name: "Chipurana", provincia: "2209" },
  { number: "220906", name: "El Porvenir", provincia: "2209" },
  { number: "220907", name: "Huimbayoc", provincia: "2209" },
  { number: "220908", name: "Juan Guerra", provincia: "2209" },
  { number: "220909", name: "La Banda de Shilcayo", provincia: "2209" },
  { number: "220910", name: "Morales", provincia: "2209" },
  { number: "220911", name: "Papaplaya", provincia: "2209" },
  { number: "220912", name: "San Antonio", provincia: "2209" },
  { number: "220913", name: "Sauce", provincia: "2209" },
  { number: "220914", name: "Shapaja", provincia: "2209" },
  { number: "221001", name: "Tocache", provincia: "2210" },
  { number: "221002", name: "Nuevo Progreso", provincia: "2210" },
  { number: "221003", name: "Polvora", provincia: "2210" },
  { number: "221004", name: "Shunte 2/", provincia: "2210" },
  { number: "221005", name: "Uchiza", provincia: "2210" },
  { number: "230101", name: "Tacna", provincia: "2301" },
  { number: "230102", name: "Alto de la Alianza", provincia: "2301" },
  { number: "230103", name: "Calana", provincia: "2301" },
  { number: "230104", name: "Ciudad Nueva", provincia: "2301" },
  { number: "230105", name: "Inclan", provincia: "2301" },
  { number: "230106", name: "Pachia", provincia: "2301" },
  { number: "230107", name: "Palca", provincia: "2301" },
  { number: "230108", name: "Pocollay", provincia: "2301" },
  { number: "230109", name: "Sama", provincia: "2301" },
  {
    number: "230110",
    name: "Coronel Gregorio Albarracin Lanchipa",
    provincia: "2301",
  },
  { number: "230201", name: "Candarave", provincia: "2302" },
  { number: "230202", name: "Cairani", provincia: "2302" },
  { number: "230203", name: "Camilaca", provincia: "2302" },
  { number: "230204", name: "Curibaya", provincia: "2302" },
  { number: "230205", name: "Huanuara", provincia: "2302" },
  { number: "230206", name: "Quilahuani", provincia: "2302" },
  { number: "230301", name: "Locumba", provincia: "2303" },
  { number: "230302", name: "Ilabaya", provincia: "2303" },
  { number: "230303", name: "Ite", provincia: "2303" },
  { number: "230401", name: "Tarata", provincia: "2304" },
  { number: "230402", name: "Heroes Albarracin", provincia: "2304" },
  { number: "230403", name: "Estique", provincia: "2304" },
  { number: "230404", name: "Estique-Pampa", provincia: "2304" },
  { number: "230405", name: "Sitajara", provincia: "2304" },
  { number: "230406", name: "Susapaya", provincia: "2304" },
  { number: "230407", name: "Tarucachi", provincia: "2304" },
  { number: "230408", name: "Ticaco", provincia: "2304" },
  { number: "240101", name: "Tumbes", provincia: "2401" },
  { number: "240102", name: "Corrales", provincia: "2401" },
  { number: "240103", name: "La Cruz", provincia: "2401" },
  { number: "240104", name: "Pampas de Hospital", provincia: "2401" },
  { number: "240105", name: "San Jacinto", provincia: "2401" },
  { number: "240106", name: "San Juan de la Virgen", provincia: "2401" },
  { number: "240201", name: "Zorritos", provincia: "2402" },
  { number: "240202", name: "Casitas", provincia: "2402" },
  { number: "240203", name: "Canoas de Punta Sal", provincia: "2402" },
  { number: "240301", name: "Zarumilla", provincia: "2403" },
  { number: "240302", name: "Aguas Verdes", provincia: "2403" },
  { number: "240303", name: "Matapalo", provincia: "2403" },
  { number: "240304", name: "Papayal", provincia: "24013" },
  { number: "250101", name: "Calleria", provincia: "2501" },
  { number: "250102", name: "Campoverde", provincia: "2501" },
  { number: "250103", name: "Iparia", provincia: "2501" },
  { number: "250104", name: "Masisea", provincia: "2501" },
  { number: "250105", name: "Yarinacocha", provincia: "2501" },
  { number: "250106", name: "Nueva Requena", provincia: "2501" },
  { number: "250107", name: "Manantay", provincia: "2501" },
  { number: "250201", name: "Raymondi", provincia: "2502" },
  { number: "250202", name: "Sepahua", provincia: "2502" },
  { number: "250203", name: "Tahuania", provincia: "2502" },
  { number: "250204", name: "Yurua", provincia: "2502" },
  { number: "250301", name: "Padre Abad", provincia: "2503" },
  { number: "250302", name: "Irazola", provincia: "2503" },
  { number: "250303", name: "Curimana", provincia: "2503" },
  { number: "250401", name: "Purus", provincia: "2504" },
];

export const direcciones= [
  {code:  "010101", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Chachapoyas"},
  {code:  "010102", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Asuncion"},
  {code:  "010103", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Balsas"},
  {code:  "010104", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Cheto"},
  {code:  "010105", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Chiliquin"},
  {code:  "010106", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Chuquibamba"},
  {code:  "010107", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Granada"},
  {code:  "010108", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Huancas"},
  {code:  "010109", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "La Jalca"},
  {code:  "010110", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Leimebamba"},
  {code:  "010111", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Levanto"},
  {code:  "010112", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Magdalena"},
  {code:  "010113", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Mariscal Castilla"},
  {code:  "010114", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Molinopampa"},
  {code:  "010115", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Montevideo"},
  {code:  "010116", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Olleros"},
  {code:  "010117", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Quinjalca"},
  {code:  "010118", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "San Francisco de Daguas"},
  {code:  "010119", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "San Isidro de Maino"},
  {code:  "010120", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Soloco"},
  {code:  "010121", departamento: "Amazonas", provincia: "Chachapoyas", distrito: "Sonche"},
  {code:  "010201", departamento: "Amazonas", provincia: "Bagua", distrito: "Bagua"},
  {code:  "010202", departamento: "Amazonas", provincia: "Bagua", distrito: "Aramango"},
  {code:  "010203", departamento: "Amazonas", provincia: "Bagua", distrito: "Copallin"},
  {code:  "010204", departamento: "Amazonas", provincia: "Bagua", distrito: "El Parco"},
  {code:  "010205", departamento: "Amazonas", provincia: "Bagua", distrito: "Imaza"},
  {code:  "010206", departamento: "Amazonas", provincia: "Bagua", distrito: "La Peca"},
  {code:  "010301", departamento: "Amazonas", provincia: "Bongara", distrito: "Jumbilla"},
  {code:  "010302", departamento: "Amazonas", provincia: "Bongara", distrito: "Chisquilla"},
  {code:  "010303", departamento: "Amazonas", provincia: "Bongara", distrito: "Churuja"},
  {code:  "010304", departamento: "Amazonas", provincia: "Bongara", distrito: "Corosha"},
  {code:  "010305", departamento: "Amazonas", provincia: "Bongara", distrito: "Cuispes"},
  {code:  "010306", departamento: "Amazonas", provincia: "Bongara", distrito: "Florida"},
  {code:  "010307", departamento: "Amazonas", provincia: "Bongara", distrito: "Jazan"},
  {code:  "010308", departamento: "Amazonas", provincia: "Bongara", distrito: "Recta"},
  {code:  "010309", departamento: "Amazonas", provincia: "Bongara", distrito: "San Carlos"},
  {code:  "010310", departamento: "Amazonas", provincia: "Bongara", distrito: "Shipasbamba"},
  {code:  "010311", departamento: "Amazonas", provincia: "Bongara", distrito: "Valera"},
  {code:  "010312", departamento: "Amazonas", provincia: "Bongara", distrito: "Yambrasbamba"},
  {code:  "010401", departamento: "Amazonas", provincia: "Condorcanqui", distrito: "Nieva"},
  {code:  "010402", departamento: "Amazonas", provincia: "Condorcanqui", distrito: "El Cenepa"},
  {code:  "010403", departamento: "Amazonas", provincia: "Condorcanqui", distrito: "Rio Santiago"},
  {code:  "010501", departamento: "Amazonas", provincia: "Luya", distrito: "Lamud"},
  {code:  "010502", departamento: "Amazonas", provincia: "Luya", distrito: "Camporredondo"},
  {code:  "010503", departamento: "Amazonas", provincia: "Luya", distrito: "Cocabamba"},
  {code:  "010504", departamento: "Amazonas", provincia: "Luya", distrito: "Colcamar"},
  {code:  "010505", departamento: "Amazonas", provincia: "Luya", distrito: "Conila"},
  {code:  "010506", departamento: "Amazonas", provincia: "Luya", distrito: "Inguilpata"},
  {code:  "010507", departamento: "Amazonas", provincia: "Luya", distrito: "Longuita"},
  {code:  "010508", departamento: "Amazonas", provincia: "Luya", distrito: "Lonya Chico"},
  {code:  "010509", departamento: "Amazonas", provincia: "Luya", distrito: "Luya"},
  {code:  "010510", departamento: "Amazonas", provincia: "Luya", distrito: "Luya Viejo"},
  {code:  "010511", departamento: "Amazonas", provincia: "Luya", distrito: "Maria"},
  {code:  "010512", departamento: "Amazonas", provincia: "Luya", distrito: "Ocalli"},
  {code:  "010513", departamento: "Amazonas", provincia: "Luya", distrito: "Ocumal"},
  {code:  "010514", departamento: "Amazonas", provincia: "Luya", distrito: "Pisuquia"},
  {code:  "010515", departamento: "Amazonas", provincia: "Luya", distrito: "Providencia"},
  {code:  "010516", departamento: "Amazonas", provincia: "Luya", distrito: "San Cristobal"},
  {code:  "010517", departamento: "Amazonas", provincia: "Luya", distrito: "San Francisco del Yeso"},
  {code:  "010518", departamento: "Amazonas", provincia: "Luya", distrito: "San Jeronimo"},
  {code:  "010519", departamento: "Amazonas", provincia: "Luya", distrito: "San Juan de Lopecancha"},
  {code:  "010520", departamento: "Amazonas", provincia: "Luya", distrito: "Santa Catalina"},
  {code:  "010521", departamento: "Amazonas", provincia: "Luya", distrito: "Santo Tomas"},
  {code:  "010522", departamento: "Amazonas", provincia: "Luya", distrito: "Tingo"},
  {code:  "010523", departamento: "Amazonas", provincia: "Luya", distrito: "Trita"},
  {code:  "010601", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "San Nicolas"},
  {code:  "010602", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Chirimoto"},
  {code:  "010603", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Cochamal"},
  {code:  "010604", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Huambo"},
  {code:  "010605", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Limabamba"},
  {code:  "010606", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Longar"},
  {code:  "010607", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Mariscal Benavides"},
  {code:  "010608", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Milpuc"},
  {code:  "010609", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Omia"},
  {code:  "010610", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Santa Rosa"},
  {code:  "010611", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Totora"},
  {code:  "010612", departamento: "Amazonas", provincia: "Rodriguez de Mendoza", distrito: "Vista Alegre"},
  {code:  "010701", departamento: "Amazonas", provincia: "Utcubamba", distrito: "Bagua Grande"},
  {code:  "010702", departamento: "Amazonas", provincia: "Utcubamba", distrito: "Cajaruro"},
  {code:  "010703", departamento: "Amazonas", provincia: "Utcubamba", distrito: "Cumba"},
  {code:  "010704", departamento: "Amazonas", provincia: "Utcubamba", distrito: "El Milagro"},
  {code:  "010705", departamento: "Amazonas", provincia: "Utcubamba", distrito: "Jamalca"},
  {code:  "010706", departamento: "Amazonas", provincia: "Utcubamba", distrito: "Lonya Grande"},
  {code:  "010707", departamento: "Amazonas", provincia: "Utcubamba", distrito: "Yamon"},
  {code:  "020101", departamento: "Ancash", provincia: "Huaraz", distrito: "Huaraz"},
  {code:  "020102", departamento: "Ancash", provincia: "Huaraz", distrito: "Cochabamba"},
  {code:  "020103", departamento: "Ancash", provincia: "Huaraz", distrito: "Colcabamba"},
  {code:  "020104", departamento: "Ancash", provincia: "Huaraz", distrito: "Huanchay"},
  {code:  "020105", departamento: "Ancash", provincia: "Huaraz", distrito: "Independencia"},
  {code:  "020106", departamento: "Ancash", provincia: "Huaraz", distrito: "Jangas"},
  {code:  "020107", departamento: "Ancash", provincia: "Huaraz", distrito: "La Libertad"},
  {code:  "020108", departamento: "Ancash", provincia: "Huaraz", distrito: "Olleros"},
  {code:  "020109", departamento: "Ancash", provincia: "Huaraz", distrito: "Pampas"},
  {code:  "020110", departamento: "Ancash", provincia: "Huaraz", distrito: "Pariacoto"},
  {code:  "020111", departamento: "Ancash", provincia: "Huaraz", distrito: "Pira"},
  {code:  "020112", departamento: "Ancash", provincia: "Huaraz", distrito: "Tarica"},
  {code:  "020201", departamento: "Ancash", provincia: "Aija", distrito: "Aija"},
  {code:  "020202", departamento: "Ancash", provincia: "Aija", distrito: "Coris"},
  {code:  "020203", departamento: "Ancash", provincia: "Aija", distrito: "Huacllan"},
  {code:  "020204", departamento: "Ancash", provincia: "Aija", distrito: "La Merced"},
  {code:  "020205", departamento: "Ancash", provincia: "Aija", distrito: "Succha"},
  {code:  "020301", departamento: "Ancash", provincia: "Antonio Raymondi", distrito: "Llamellin"},
  {code:  "020302", departamento: "Ancash", provincia: "Antonio Raymondi", distrito: "Aczo"},
  {code:  "020303", departamento: "Ancash", provincia: "Antonio Raymondi", distrito: "Chaccho"},
  {code:  "020304", departamento: "Ancash", provincia: "Antonio Raymondi", distrito: "Chingas"},
  {code:  "020305", departamento: "Ancash", provincia: "Antonio Raymondi", distrito: "Mirgas"},
  {code:  "020306", departamento: "Ancash", provincia: "Antonio Raymondi", distrito: "San Juan de Rontoy"},
  {code:  "020401", departamento: "Ancash", provincia: "Asuncion", distrito: "Chacas"},
  {code:  "020402", departamento: "Ancash", provincia: "Asuncion", distrito: "Acochaca"},
  {code:  "020501", departamento: "Ancash", provincia: "Bolognesi", distrito: "Chiquian"},
  {code:  "020502", departamento: "Ancash", provincia: "Bolognesi", distrito: "Abelardo Pardo Lezameta"},
  {code:  "020503", departamento: "Ancash", provincia: "Bolognesi", distrito: "Antonio Raymondi"},
  {code:  "020504", departamento: "Ancash", provincia: "Bolognesi", distrito: "Aquia"},
  {code:  "020505", departamento: "Ancash", provincia: "Bolognesi", distrito: "Cajacay"},
  {code:  "020506", departamento: "Ancash", provincia: "Bolognesi", distrito: "Canis"},
  {code:  "020507", departamento: "Ancash", provincia: "Bolognesi", distrito: "Colquioc"},
  {code:  "020508", departamento: "Ancash", provincia: "Bolognesi", distrito: "Huallanca"},
  {code:  "020509", departamento: "Ancash", provincia: "Bolognesi", distrito: "Huasta"},
  {code:  "020510", departamento: "Ancash", provincia: "Bolognesi", distrito: "Huayllacayan"},
  {code:  "020511", departamento: "Ancash", provincia: "Bolognesi", distrito: "La Primavera"},
  {code:  "020512", departamento: "Ancash", provincia: "Bolognesi", distrito: "Mangas"},
  {code:  "020513", departamento: "Ancash", provincia: "Bolognesi", distrito: "Pacllon"},
  {code:  "020514", departamento: "Ancash", provincia: "Bolognesi", distrito: "San Miguel de Corpanqui"},
  {code:  "020515", departamento: "Ancash", provincia: "Bolognesi", distrito: "Ticllos"},
  {code:  "020601", departamento: "Ancash", provincia: "Carhuaz", distrito: "Carhuaz"},
  {code:  "020602", departamento: "Ancash", provincia: "Carhuaz", distrito: "Acopampa"},
  {code:  "020603", departamento: "Ancash", provincia: "Carhuaz", distrito: "Amashca"},
  {code:  "020604", departamento: "Ancash", provincia: "Carhuaz", distrito: "Anta"},
  {code:  "020605", departamento: "Ancash", provincia: "Carhuaz", distrito: "Ataquero"},
  {code:  "020606", departamento: "Ancash", provincia: "Carhuaz", distrito: "Marcara"},
  {code:  "020607", departamento: "Ancash", provincia: "Carhuaz", distrito: "Pariahuanca"},
  {code:  "020608", departamento: "Ancash", provincia: "Carhuaz", distrito: "San Miguel de Aco"},
  {code:  "020609", departamento: "Ancash", provincia: "Carhuaz", distrito: "Shilla"},
  {code:  "020610", departamento: "Ancash", provincia: "Carhuaz", distrito: "Tinco"},
  {code:  "020611", departamento: "Ancash", provincia: "Carhuaz", distrito: "Yungar"},
  {code:  "020701", departamento: "Ancash", provincia: "Carlos Fermin Fitzca", distrito: "San Luis"},
  {code:  "020702", departamento: "Ancash", provincia: "Carlos Fermin Fitzca", distrito: "San Nicolas"},
  {code:  "020703", departamento: "Ancash", provincia: "Carlos Fermin Fitzca", distrito: "Yauya"},
  {code:  "020801", departamento: "Ancash", provincia: "Casma", distrito: "Casma"},
  {code:  "020802", departamento: "Ancash", provincia: "Casma", distrito: "Buena Vista Alta"},
  {code:  "020803", departamento: "Ancash", provincia: "Casma", distrito: "Comandante Noel"},
  {code:  "020804", departamento: "Ancash", provincia: "Casma", distrito: "Yautan"},
  {code:  "020901", departamento: "Ancash", provincia: "Corongo", distrito: "Corongo"},
  {code:  "020902", departamento: "Ancash", provincia: "Corongo", distrito: "Aco"},
  {code:  "020903", departamento: "Ancash", provincia: "Corongo", distrito: "Bambas"},
  {code:  "020904", departamento: "Ancash", provincia: "Corongo", distrito: "Cusca"},
  {code:  "020905", departamento: "Ancash", provincia: "Corongo", distrito: "La Pampa"},
  {code:  "020906", departamento: "Ancash", provincia: "Corongo", distrito: "Yanac"},
  {code:  "020907", departamento: "Ancash", provincia: "Corongo", distrito: "Yupan"},
  {code:  "021001", departamento: "Ancash", provincia: "Huari", distrito: "Huari"},
  {code:  "021002", departamento: "Ancash", provincia: "Huari", distrito: "Anra"},
  {code:  "021003", departamento: "Ancash", provincia: "Huari", distrito: "Cajay"},
  {code:  "021004", departamento: "Ancash", provincia: "Huari", distrito: "Chavin de Huantar"},
  {code:  "021005", departamento: "Ancash", provincia: "Huari", distrito: "Huacachi"},
  {code:  "021006", departamento: "Ancash", provincia: "Huari", distrito: "Huacchis"},
  {code:  "021007", departamento: "Ancash", provincia: "Huari", distrito: "Huachis"},
  {code:  "021008", departamento: "Ancash", provincia: "Huari", distrito: "Huantar"},
  {code:  "021009", departamento: "Ancash", provincia: "Huari", distrito: "Masin"},
  {code:  "021010", departamento: "Ancash", provincia: "Huari", distrito: "Paucas"},
  {code:  "021011", departamento: "Ancash", provincia: "Huari", distrito: "Ponto"},
  {code:  "021012", departamento: "Ancash", provincia: "Huari", distrito: "Rahuapampa"},
  {code:  "021013", departamento: "Ancash", provincia: "Huari", distrito: "Rapayan"},
  {code:  "021014", departamento: "Ancash", provincia: "Huari", distrito: "San Marcos"},
  {code:  "021015", departamento: "Ancash", provincia: "Huari", distrito: "San Pedro de Chana"},
  {code:  "021016", departamento: "Ancash", provincia: "Huari", distrito: "Uco"},
  {code:  "021101", departamento: "Ancash", provincia: "Huarmey", distrito: "Huarmey"},
  {code:  "021102", departamento: "Ancash", provincia: "Huarmey", distrito: "Cochapeti"},
  {code:  "021103", departamento: "Ancash", provincia: "Huarmey", distrito: "Culebras"},
  {code:  "021104", departamento: "Ancash", provincia: "Huarmey", distrito: "Huayan"},
  {code:  "021105", departamento: "Ancash", provincia: "Huarmey", distrito: "Malvas"},
  {code:  "021201", departamento: "Ancash", provincia: "Huaylas", distrito: "Caraz"},
  {code:  "021202", departamento: "Ancash", provincia: "Huaylas", distrito: "Huallanca"},
  {code:  "021203", departamento: "Ancash", provincia: "Huaylas", distrito: "Huata"},
  {code:  "021204", departamento: "Ancash", provincia: "Huaylas", distrito: "Huaylas"},
  {code:  "021205", departamento: "Ancash", provincia: "Huaylas", distrito: "Mato"},
  {code:  "021206", departamento: "Ancash", provincia: "Huaylas", distrito: "Pamparomas"},
  {code:  "021207", departamento: "Ancash", provincia: "Huaylas", distrito: "Pueblo Libre"},
  {code:  "021208", departamento: "Ancash", provincia: "Huaylas", distrito: "Santa Cruz"},
  {code:  "021209", departamento: "Ancash", provincia: "Huaylas", distrito: "Santo Toribio"},
  {code:  "021210", departamento: "Ancash", provincia: "Huaylas", distrito: "Yuracmarca"},
  {code:  "021301", departamento: "Ancash", provincia: "Mariscal Luzuriaga", distrito: "Piscobamba"},
  {code:  "021302", departamento: "Ancash", provincia: "Mariscal Luzuriaga", distrito: "Casca"},
  {code:  "021303", departamento: "Ancash", provincia: "Mariscal Luzuriaga", distrito: "Eleazar Guzman Barron"},
  {code:  "021304", departamento: "Ancash", provincia: "Mariscal Luzuriaga", distrito: "Fidel Olivas Escudero"},
  {code:  "021305", departamento: "Ancash", provincia: "Mariscal Luzuriaga", distrito: "Llama"},
  {code:  "021306", departamento: "Ancash", provincia: "Mariscal Luzuriaga", distrito: "Llumpa"},
  {code:  "021307", departamento: "Ancash", provincia: "Mariscal Luzuriaga", distrito: "Lucma"},
  {code:  "021308", departamento: "Ancash", provincia: "Mariscal Luzuriaga", distrito: "Musga"},
  {code:  "021401", departamento: "Ancash", provincia: "Ocros", distrito: "Ocros"},
  {code:  "021402", departamento: "Ancash", provincia: "Ocros", distrito: "Acas"},
  {code:  "021403", departamento: "Ancash", provincia: "Ocros", distrito: "Cajamarquilla"},
  {code:  "021404", departamento: "Ancash", provincia: "Ocros", distrito: "Carhuapampa"},
  {code:  "021405", departamento: "Ancash", provincia: "Ocros", distrito: "Cochas"},
  {code:  "021406", departamento: "Ancash", provincia: "Ocros", distrito: "Congas"},
  {code:  "021407", departamento: "Ancash", provincia: "Ocros", distrito: "Llipa"},
  {code:  "021408", departamento: "Ancash", provincia: "Ocros", distrito: "San Cristobal de Rajan"},
  {code:  "021409", departamento: "Ancash", provincia: "Ocros", distrito: "San Pedro"},
  {code:  "021410", departamento: "Ancash", provincia: "Ocros", distrito: "Santiago de Chilcas"},
  {code:  "021501", departamento: "Ancash", provincia: "Pallasca", distrito: "Cabana"},
  {code:  "021502", departamento: "Ancash", provincia: "Pallasca", distrito: "Bolognesi"},
  {code:  "021503", departamento: "Ancash", provincia: "Pallasca", distrito: "Conchucos"},
  {code:  "021504", departamento: "Ancash", provincia: "Pallasca", distrito: "Huacaschuque"},
  {code:  "021505", departamento: "Ancash", provincia: "Pallasca", distrito: "Huandoval"},
  {code:  "021506", departamento: "Ancash", provincia: "Pallasca", distrito: "Lacabamba"},
  {code:  "021507", departamento: "Ancash", provincia: "Pallasca", distrito: "Llapo"},
  {code:  "021508", departamento: "Ancash", provincia: "Pallasca", distrito: "Pallasca"},
  {code:  "021509", departamento: "Ancash", provincia: "Pallasca", distrito: "Pampas"},
  {code:  "021510", departamento: "Ancash", provincia: "Pallasca", distrito: "Santa Rosa"},
  {code:  "021511", departamento: "Ancash", provincia: "Pallasca", distrito: "Tauca"},
  {code:  "021601", departamento: "Ancash", provincia: "Pomabamba", distrito: "Pomabamba"},
  {code:  "021602", departamento: "Ancash", provincia: "Pomabamba", distrito: "Huayllan"},
  {code:  "021603", departamento: "Ancash", provincia: "Pomabamba", distrito: "Parobamba"},
  {code:  "021604", departamento: "Ancash", provincia: "Pomabamba", distrito: "Quinuabamba"},
  {code:  "021701", departamento: "Ancash", provincia: "Recuay", distrito: "Recuay"},
  {code:  "021702", departamento: "Ancash", provincia: "Recuay", distrito: "Catac"},
  {code:  "021703", departamento: "Ancash", provincia: "Recuay", distrito: "Cotaparaco"},
  {code:  "021704", departamento: "Ancash", provincia: "Recuay", distrito: "Huayllapampa"},
  {code:  "021705", departamento: "Ancash", provincia: "Recuay", distrito: "Llacllin"},
  {code:  "021706", departamento: "Ancash", provincia: "Recuay", distrito: "Marca"},
  {code:  "021707", departamento: "Ancash", provincia: "Recuay", distrito: "Pampas Chico"},
  {code:  "021708", departamento: "Ancash", provincia: "Recuay", distrito: "Pararin"},
  {code:  "021709", departamento: "Ancash", provincia: "Recuay", distrito: "Tapacocha"},
  {code:  "021710", departamento: "Ancash", provincia: "Recuay", distrito: "Ticapampa"},
  {code:  "021801", departamento: "Ancash", provincia: "Santa", distrito: "Chimbote"},
  {code:  "021802", departamento: "Ancash", provincia: "Santa", distrito: "Caceres del Peru"},
  {code:  "021803", departamento: "Ancash", provincia: "Santa", distrito: "Coishco"},
  {code:  "021804", departamento: "Ancash", provincia: "Santa", distrito: "Macate"},
  {code:  "021805", departamento: "Ancash", provincia: "Santa", distrito: "Moro"},
  {code:  "021806", departamento: "Ancash", provincia: "Santa", distrito: "Nepeña"},
  {code:  "021807", departamento: "Ancash", provincia: "Santa", distrito: "Samanco"},
  {code:  "021808", departamento: "Ancash", provincia: "Santa", distrito: "Santa"},
  {code:  "021809", departamento: "Ancash", provincia: "Santa", distrito: "Nuevo Chimbote"},
  {code:  "021901", departamento: "Ancash", provincia: "Sihuas", distrito: "Sihuas"},
  {code:  "021902", departamento: "Ancash", provincia: "Sihuas", distrito: "Acobamba"},
  {code:  "021903", departamento: "Ancash", provincia: "Sihuas", distrito: "Alfonso Ugarte"},
  {code:  "021904", departamento: "Ancash", provincia: "Sihuas", distrito: "Cashapampa"},
  {code:  "021905", departamento: "Ancash", provincia: "Sihuas", distrito: "Chingalpo"},
  {code:  "021906", departamento: "Ancash", provincia: "Sihuas", distrito: "Huayllabamba"},
  {code:  "021907", departamento: "Ancash", provincia: "Sihuas", distrito: "Quiches"},
  {code:  "021908", departamento: "Ancash", provincia: "Sihuas", distrito: "Ragash"},
  {code:  "021909", departamento: "Ancash", provincia: "Sihuas", distrito: "San Juan"},
  {code:  "021910", departamento: "Ancash", provincia: "Sihuas", distrito: "Sicsibamba"},
  {code:  "022001", departamento: "Ancash", provincia: "Yungay", distrito: "Yungay"},
  {code:  "022002", departamento: "Ancash", provincia: "Yungay", distrito: "Cascapara"},
  {code:  "022003", departamento: "Ancash", provincia: "Yungay", distrito: "Mancos"},
  {code:  "022004", departamento: "Ancash", provincia: "Yungay", distrito: "Matacoto"},
  {code:  "022005", departamento: "Ancash", provincia: "Yungay", distrito: "Quillo"},
  {code:  "022006", departamento: "Ancash", provincia: "Yungay", distrito: "Ranrahirca"},
  {code:  "022007", departamento: "Ancash", provincia: "Yungay", distrito: "Shupluy"},
  {code:  "022008", departamento: "Ancash", provincia: "Yungay", distrito: "Yanama"},
  {code:  "030101", departamento: "Apurimac", provincia: "Abancay", distrito: "Abancay"},
  {code:  "030102", departamento: "Apurimac", provincia: "Abancay", distrito: "Chacoche"},
  {code:  "030103", departamento: "Apurimac", provincia: "Abancay", distrito: "Circa"},
  {code:  "030104", departamento: "Apurimac", provincia: "Abancay", distrito: "Curahuasi"},
  {code:  "030105", departamento: "Apurimac", provincia: "Abancay", distrito: "Huanipaca"},
  {code:  "030106", departamento: "Apurimac", provincia: "Abancay", distrito: "Lambrama"},
  {code:  "030107", departamento: "Apurimac", provincia: "Abancay", distrito: "Pichirhua"},
  {code:  "030108", departamento: "Apurimac", provincia: "Abancay", distrito: "San Pedro de Cachora"},
  {code:  "030109", departamento: "Apurimac", provincia: "Abancay", distrito: "Tamburco"},
  {code:  "030201", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Andahuaylas"},
  {code:  "030202", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Andarapa"},
  {code:  "030203", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Chiara"},
  {code:  "030204", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Huancarama"},
  {code:  "030205", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Huancaray"},
  {code:  "030206", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Huayana"},
  {code:  "030207", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Kishuara"},
  {code:  "030208", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Pacobamba"},
  {code:  "030209", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Pacucha"},
  {code:  "030210", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Pampachiri"},
  {code:  "030211", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Pomacocha"},
  {code:  "030212", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "San Antonio de Cachi"},
  {code:  "030213", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "San Jeronimo"},
  {code:  "030214", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "San Miguel de Chaccrampa"},
  {code:  "030215", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Santa Maria de Chicmo"},
  {code:  "030216", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Talavera"},
  {code:  "030217", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Tumay Huaraca"},
  {code:  "030218", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Turpo"},
  {code:  "030219", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "Kaquiabamba"},
  {code:  "030220", departamento: "Apurimac", provincia: "Andahuaylas", distrito: "José María Arguedas"},
  {code:  "030301", departamento: "Apurimac", provincia: "Antabamba", distrito: "Antabamba"},
  {code:  "030302", departamento: "Apurimac", provincia: "Antabamba", distrito: "El Oro"},
  {code:  "030303", departamento: "Apurimac", provincia: "Antabamba", distrito: "Huaquirca"},
  {code:  "030304", departamento: "Apurimac", provincia: "Antabamba", distrito: "Juan Espinoza Medrano"},
  {code:  "030305", departamento: "Apurimac", provincia: "Antabamba", distrito: "Oropesa"},
  {code:  "030306", departamento: "Apurimac", provincia: "Antabamba", distrito: "Pachaconas"},
  {code:  "030307", departamento: "Apurimac", provincia: "Antabamba", distrito: "Sabaino"},
  {code:  "030401", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Chalhuanca"},
  {code:  "030402", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Capaya"},
  {code:  "030403", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Caraybamba"},
  {code:  "030404", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Chapimarca"},
  {code:  "030405", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Colcabamba"},
  {code:  "030406", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Cotaruse"},
  {code:  "030407", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Huayllo"},
  {code:  "030408", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Justo Apu Sahuaraura"},
  {code:  "030409", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Lucre"},
  {code:  "030410", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Pocohuanca"},
  {code:  "030411", departamento: "Apurimac", provincia: "Aymaraes", distrito: "San Juan de Chacña"},
  {code:  "030412", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Sañayca"},
  {code:  "030413", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Soraya"},
  {code:  "030414", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Tapairihua"},
  {code:  "030415", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Tintay"},
  {code:  "030416", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Toraya"},
  {code:  "030417", departamento: "Apurimac", provincia: "Aymaraes", distrito: "Yanaca"},
  {code:  "030501", departamento: "Apurimac", provincia: "Cotabambas", distrito: "Tambobamba"},
  {code:  "030502", departamento: "Apurimac", provincia: "Cotabambas", distrito: "Cotabambas"},
  {code:  "030503", departamento: "Apurimac", provincia: "Cotabambas", distrito: "Coyllurqui"},
  {code:  "030504", departamento: "Apurimac", provincia: "Cotabambas", distrito: "Haquira"},
  {code:  "030505", departamento: "Apurimac", provincia: "Cotabambas", distrito: "Mara"},
  {code:  "030506", departamento: "Apurimac", provincia: "Cotabambas", distrito: "Challhuahuacho"},
  {code:  "030601", departamento: "Apurimac", provincia: "Chincheros", distrito: "Chincheros"},
  {code:  "030602", departamento: "Apurimac", provincia: "Chincheros", distrito: "Anco_Huallo"},
  {code:  "030603", departamento: "Apurimac", provincia: "Chincheros", distrito: "Cocharcas"},
  {code:  "030604", departamento: "Apurimac", provincia: "Chincheros", distrito: "Huaccana"},
  {code:  "030605", departamento: "Apurimac", provincia: "Chincheros", distrito: "Ocobamba"},
  {code:  "030606", departamento: "Apurimac", provincia: "Chincheros", distrito: "Ongoy"},
  {code:  "030607", departamento: "Apurimac", provincia: "Chincheros", distrito: "Uranmarca"},
  {code:  "030608", departamento: "Apurimac", provincia: "Chincheros", distrito: "Ranracancha"},
  {code:  "030609", departamento: "Apurimac", provincia: "Chincheros", distrito: "Rocchacc"},
  {code:  "030610", departamento: "Apurimac", provincia: "Chincheros", distrito: "El Porvenir"},
  {code:  "030611", departamento: "Apurimac", provincia: "Chincheros", distrito: "Los Chankas"},
  {code:  "030701", departamento: "Apurimac", provincia: "Grau", distrito: "Chuquibambilla"},
  {code:  "030702", departamento: "Apurimac", provincia: "Grau", distrito: "Curpahuasi"},
  {code:  "030703", departamento: "Apurimac", provincia: "Grau", distrito: "Gamarra"},
  {code:  "030704", departamento: "Apurimac", provincia: "Grau", distrito: "Huayllati"},
  {code:  "030705", departamento: "Apurimac", provincia: "Grau", distrito: "Mamara"},
  {code:  "030706", departamento: "Apurimac", provincia: "Grau", distrito: "Micaela Bastidas"},
  {code:  "030707", departamento: "Apurimac", provincia: "Grau", distrito: "Pataypampa"},
  {code:  "030708", departamento: "Apurimac", provincia: "Grau", distrito: "Progreso"},
  {code:  "030709", departamento: "Apurimac", provincia: "Grau", distrito: "San Antonio"},
  {code:  "030710", departamento: "Apurimac", provincia: "Grau", distrito: "Santa Rosa"},
  {code:  "030711", departamento: "Apurimac", provincia: "Grau", distrito: "Turpay"},
  {code:  "030712", departamento: "Apurimac", provincia: "Grau", distrito: "Vilcabamba"},
  {code:  "030713", departamento: "Apurimac", provincia: "Grau", distrito: "Virundo"},
  {code:  "030714", departamento: "Apurimac", provincia: "Grau", distrito: "Curasco"},
  {code:  "040101", departamento: "Arequipa", provincia: "Arequipa", distrito: "Arequipa"},
  {code:  "040102", departamento: "Arequipa", provincia: "Arequipa", distrito: "Alto Selva Alegre"},
  {code:  "040103", departamento: "Arequipa", provincia: "Arequipa", distrito: "Cayma"},
  {code:  "040104", departamento: "Arequipa", provincia: "Arequipa", distrito: "Cerro Colorado"},
  {code:  "040105", departamento: "Arequipa", provincia: "Arequipa", distrito: "Characato"},
  {code:  "040106", departamento: "Arequipa", provincia: "Arequipa", distrito: "Chiguata"},
  {code:  "040107", departamento: "Arequipa", provincia: "Arequipa", distrito: "Jacobo Hunter"},
  {code:  "040108", departamento: "Arequipa", provincia: "Arequipa", distrito: "La Joya"},
  {code:  "040109", departamento: "Arequipa", provincia: "Arequipa", distrito: "Mariano Melgar"},
  {code:  "040110", departamento: "Arequipa", provincia: "Arequipa", distrito: "Miraflores"},
  {code:  "040111", departamento: "Arequipa", provincia: "Arequipa", distrito: "Mollebaya"},
  {code:  "040112", departamento: "Arequipa", provincia: "Arequipa", distrito: "Paucarpata"},
  {code:  "040113", departamento: "Arequipa", provincia: "Arequipa", distrito: "Pocsi"},
  {code:  "040114", departamento: "Arequipa", provincia: "Arequipa", distrito: "Polobaya"},
  {code:  "040115", departamento: "Arequipa", provincia: "Arequipa", distrito: "Quequeña"},
  {code:  "040116", departamento: "Arequipa", provincia: "Arequipa", distrito: "Sabandia"},
  {code:  "040117", departamento: "Arequipa", provincia: "Arequipa", distrito: "Sachaca"},
  {code:  "040118", departamento: "Arequipa", provincia: "Arequipa", distrito: "San Juan de Siguas"},
  {code:  "040119", departamento: "Arequipa", provincia: "Arequipa", distrito: "San Juan de Tarucani"},
  {code:  "040120", departamento: "Arequipa", provincia: "Arequipa", distrito: "Santa Isabel de Siguas"},
  {code:  "040121", departamento: "Arequipa", provincia: "Arequipa", distrito: "Santa Rita de Siguas"},
  {code:  "040122", departamento: "Arequipa", provincia: "Arequipa", distrito: "Socabaya"},
  {code:  "040123", departamento: "Arequipa", provincia: "Arequipa", distrito: "Tiabaya"},
  {code:  "040124", departamento: "Arequipa", provincia: "Arequipa", distrito: "Uchumayo"},
  {code:  "040125", departamento: "Arequipa", provincia: "Arequipa", distrito: "Vitor"},
  {code:  "040126", departamento: "Arequipa", provincia: "Arequipa", distrito: "Yanahuara"},
  {code:  "040127", departamento: "Arequipa", provincia: "Arequipa", distrito: "Yarabamba"},
  {code:  "040128", departamento: "Arequipa", provincia: "Arequipa", distrito: "Yura"},
  {code:  "040129", departamento: "Arequipa", provincia: "Arequipa", distrito: "Jose Luis Bustamante y Rivero"},
  {code:  "040201", departamento: "Arequipa", provincia: "Camana", distrito: "Camana"},
  {code:  "040202", departamento: "Arequipa", provincia: "Camana", distrito: "Jose Maria Quimper"},
  {code:  "040203", departamento: "Arequipa", provincia: "Camana", distrito: "Mariano Nicolas Valcarcel"},
  {code:  "040204", departamento: "Arequipa", provincia: "Camana", distrito: "Mariscal Caceres"},
  {code:  "040205", departamento: "Arequipa", provincia: "Camana", distrito: "Nicolas de Pierola"},
  {code:  "040206", departamento: "Arequipa", provincia: "Camana", distrito: "Ocoña"},
  {code:  "040207", departamento: "Arequipa", provincia: "Camana", distrito: "Quilca"},
  {code:  "040208", departamento: "Arequipa", provincia: "Camana", distrito: "Samuel Pastor"},
  {code:  "040301", departamento: "Arequipa", provincia: "Caraveli", distrito: "Caraveli"},
  {code:  "040302", departamento: "Arequipa", provincia: "Caraveli", distrito: "Acari"},
  {code:  "040303", departamento: "Arequipa", provincia: "Caraveli", distrito: "Atico"},
  {code:  "040304", departamento: "Arequipa", provincia: "Caraveli", distrito: "Atiquipa"},
  {code:  "040305", departamento: "Arequipa", provincia: "Caraveli", distrito: "Bella Union"},
  {code:  "040306", departamento: "Arequipa", provincia: "Caraveli", distrito: "Cahuacho"},
  {code:  "040307", departamento: "Arequipa", provincia: "Caraveli", distrito: "Chala"},
  {code:  "040308", departamento: "Arequipa", provincia: "Caraveli", distrito: "Chaparra"},
  {code:  "040309", departamento: "Arequipa", provincia: "Caraveli", distrito: "Huanuhuanu"},
  {code:  "040310", departamento: "Arequipa", provincia: "Caraveli", distrito: "Jaqui"},
  {code:  "040311", departamento: "Arequipa", provincia: "Caraveli", distrito: "Lomas"},
  {code:  "040312", departamento: "Arequipa", provincia: "Caraveli", distrito: "Quicacha"},
  {code:  "040313", departamento: "Arequipa", provincia: "Caraveli", distrito: "Yauca"},
  {code:  "040401", departamento: "Arequipa", provincia: "Castilla", distrito: "Aplao"},
  {code:  "040402", departamento: "Arequipa", provincia: "Castilla", distrito: "Andagua"},
  {code:  "040403", departamento: "Arequipa", provincia: "Castilla", distrito: "Ayo"},
  {code:  "040404", departamento: "Arequipa", provincia: "Castilla", distrito: "Chachas"},
  {code:  "040405", departamento: "Arequipa", provincia: "Castilla", distrito: "Chilcaymarca"},
  {code:  "040406", departamento: "Arequipa", provincia: "Castilla", distrito: "Choco"},
  {code:  "040407", departamento: "Arequipa", provincia: "Castilla", distrito: "Huancarqui"},
  {code:  "040408", departamento: "Arequipa", provincia: "Castilla", distrito: "Machaguay"},
  {code:  "040409", departamento: "Arequipa", provincia: "Castilla", distrito: "Orcopampa"},
  {code:  "040410", departamento: "Arequipa", provincia: "Castilla", distrito: "Pampacolca"},
  {code:  "040411", departamento: "Arequipa", provincia: "Castilla", distrito: "Tipan"},
  {code:  "040412", departamento: "Arequipa", provincia: "Castilla", distrito: "Uñon"},
  {code:  "040413", departamento: "Arequipa", provincia: "Castilla", distrito: "Uraca"},
  {code:  "040414", departamento: "Arequipa", provincia: "Castilla", distrito: "Viraco"},
  {code:  "040501", departamento: "Arequipa", provincia: "Caylloma", distrito: "Chivay"},
  {code:  "040502", departamento: "Arequipa", provincia: "Caylloma", distrito: "Achoma"},
  {code:  "040503", departamento: "Arequipa", provincia: "Caylloma", distrito: "Cabanaconde"},
  {code:  "040504", departamento: "Arequipa", provincia: "Caylloma", distrito: "Callalli"},
  {code:  "040505", departamento: "Arequipa", provincia: "Caylloma", distrito: "Caylloma"},
  {code:  "040506", departamento: "Arequipa", provincia: "Caylloma", distrito: "Coporaque"},
  {code:  "040507", departamento: "Arequipa", provincia: "Caylloma", distrito: "Huambo"},
  {code:  "040508", departamento: "Arequipa", provincia: "Caylloma", distrito: "Huanca"},
  {code:  "040509", departamento: "Arequipa", provincia: "Caylloma", distrito: "Ichupampa"},
  {code:  "040510", departamento: "Arequipa", provincia: "Caylloma", distrito: "Lari"},
  {code:  "040511", departamento: "Arequipa", provincia: "Caylloma", distrito: "Lluta"},
  {code:  "040512", departamento: "Arequipa", provincia: "Caylloma", distrito: "Maca"},
  {code:  "040513", departamento: "Arequipa", provincia: "Caylloma", distrito: "Madrigal"},
  {code:  "040514", departamento: "Arequipa", provincia: "Caylloma", distrito: "San Antonio de Chuca"},
  {code:  "040515", departamento: "Arequipa", provincia: "Caylloma", distrito: "Sibayo"},
  {code:  "040516", departamento: "Arequipa", provincia: "Caylloma", distrito: "Tapay"},
  {code:  "040517", departamento: "Arequipa", provincia: "Caylloma", distrito: "Tisco"},
  {code:  "040518", departamento: "Arequipa", provincia: "Caylloma", distrito: "Tuti"},
  {code:  "040519", departamento: "Arequipa", provincia: "Caylloma", distrito: "Yanque"},
  {code:  "040520", departamento: "Arequipa", provincia: "Caylloma", distrito: "Majes"},
  {code:  "040601", departamento: "Arequipa", provincia: "Condesuyos", distrito: "Chuquibamba"},
  {code:  "040602", departamento: "Arequipa", provincia: "Condesuyos", distrito: "Andaray"},
  {code:  "040603", departamento: "Arequipa", provincia: "Condesuyos", distrito: "Cayarani"},
  {code:  "040604", departamento: "Arequipa", provincia: "Condesuyos", distrito: "Chichas"},
  {code:  "040605", departamento: "Arequipa", provincia: "Condesuyos", distrito: "Iray"},
  {code:  "040606", departamento: "Arequipa", provincia: "Condesuyos", distrito: "Rio Grande"},
  {code:  "040607", departamento: "Arequipa", provincia: "Condesuyos", distrito: "Salamanca"},
  {code:  "040608", departamento: "Arequipa", provincia: "Condesuyos", distrito: "Yanaquihua"},
  {code:  "040701", departamento: "Arequipa", provincia: "Islay", distrito: "Mollendo"},
  {code:  "040702", departamento: "Arequipa", provincia: "Islay", distrito: "Cocachacra"},
  {code:  "040703", departamento: "Arequipa", provincia: "Islay", distrito: "Dean Valdivia"},
  {code:  "040704", departamento: "Arequipa", provincia: "Islay", distrito: "Islay"},
  {code:  "040705", departamento: "Arequipa", provincia: "Islay", distrito: "Mejia"},
  {code:  "040706", departamento: "Arequipa", provincia: "Islay", distrito: "Punta de Bombon"},
  {code:  "040801", departamento: "Arequipa", provincia: "La Union", distrito: "Cotahuasi"},
  {code:  "040802", departamento: "Arequipa", provincia: "La Union", distrito: "Alca"},
  {code:  "040803", departamento: "Arequipa", provincia: "La Union", distrito: "Charcana"},
  {code:  "040804", departamento: "Arequipa", provincia: "La Union", distrito: "Huaynacotas"},
  {code:  "040805", departamento: "Arequipa", provincia: "La Union", distrito: "Pampamarca"},
  {code:  "040806", departamento: "Arequipa", provincia: "La Union", distrito: "Puyca"},
  {code:  "040807", departamento: "Arequipa", provincia: "La Union", distrito: "Quechualla"},
  {code:  "040808", departamento: "Arequipa", provincia: "La Union", distrito: "Sayla"},
  {code:  "040809", departamento: "Arequipa", provincia: "La Union", distrito: "Tauria"},
  {code:  "040810", departamento: "Arequipa", provincia: "La Union", distrito: "Tomepampa"},
  {code:  "040811", departamento: "Arequipa", provincia: "La Union", distrito: "Toro"},
  {code:  "050101", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Ayacucho"},
  {code:  "050102", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Acocro"},
  {code:  "050103", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Acos Vinchos"},
  {code:  "050104", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Carmen Alto"},
  {code:  "050105", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Chiara"},
  {code:  "050106", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Ocros"},
  {code:  "050107", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Pacaycasa"},
  {code:  "050108", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Quinua"},
  {code:  "050109", departamento: "Ayacucho", provincia: "Huamanga", distrito: "San Jose de Ticllas"},
  {code:  "050110", departamento: "Ayacucho", provincia: "Huamanga", distrito: "San Juan Bautista"},
  {code:  "050111", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Santiago de Pischa"},
  {code:  "050112", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Socos"},
  {code:  "050113", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Tambillo"},
  {code:  "050114", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Vinchos"},
  {code:  "050115", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Jesus Nazareno"},
  {code:  "050116", departamento: "Ayacucho", provincia: "Huamanga", distrito: "Andrés Avelino Cáceres Dorregaray"},
  {code:  "050201", departamento: "Ayacucho", provincia: "Cangallo", distrito: "Cangallo"},
  {code:  "050202", departamento: "Ayacucho", provincia: "Cangallo", distrito: "Chuschi"},
  {code:  "050203", departamento: "Ayacucho", provincia: "Cangallo", distrito: "Los Morochucos"},
  {code:  "050204", departamento: "Ayacucho", provincia: "Cangallo", distrito: "Maria Parado de Bellido"},
  {code:  "050205", departamento: "Ayacucho", provincia: "Cangallo", distrito: "Paras"},
  {code:  "050206", departamento: "Ayacucho", provincia: "Cangallo", distrito: "Totos"},
  {code:  "050301", departamento: "Ayacucho", provincia: "Huanca Sancos", distrito: "Sancos"},
  {code:  "050302", departamento: "Ayacucho", provincia: "Huanca Sancos", distrito: "Carapo"},
  {code:  "050303", departamento: "Ayacucho", provincia: "Huanca Sancos", distrito: "Sacsamarca"},
  {code:  "050304", departamento: "Ayacucho", provincia: "Huanca Sancos", distrito: "Santiago de Lucanamarca"},
  {code:  "050401", departamento: "Ayacucho", provincia: "Huanta", distrito: "Huanta"},
  {code:  "050402", departamento: "Ayacucho", provincia: "Huanta", distrito: "Ayahuanco"},
  {code:  "050403", departamento: "Ayacucho", provincia: "Huanta", distrito: "Huamanguilla"},
  {code:  "050404", departamento: "Ayacucho", provincia: "Huanta", distrito: "Iguain"},
  {code:  "050405", departamento: "Ayacucho", provincia: "Huanta", distrito: "Luricocha"},
  {code:  "050406", departamento: "Ayacucho", provincia: "Huanta", distrito: "Santillana"},
  {code:  "050407", departamento: "Ayacucho", provincia: "Huanta", distrito: "Sivia"},
  {code:  "050408", departamento: "Ayacucho", provincia: "Huanta", distrito: "Llochegua"},
  {code:  "050409", departamento: "Ayacucho", provincia: "Huanta", distrito: "Canayre"},
  {code:  "050410", departamento: "Ayacucho", provincia: "Huanta", distrito: "Uchuraccay"},
  {code:  "050411", departamento: "Ayacucho", provincia: "Huanta", distrito: "Pucacolpa"},
  {code:  "050412", departamento: "Ayacucho", provincia: "Huanta", distrito: "Chaca"},
  {code:  "050501", departamento: "Ayacucho", provincia: "La Mar", distrito: "San Miguel"},
  {code:  "050502", departamento: "Ayacucho", provincia: "La Mar", distrito: "Anco"},
  {code:  "050503", departamento: "Ayacucho", provincia: "La Mar", distrito: "Ayna"},
  {code:  "050504", departamento: "Ayacucho", provincia: "La Mar", distrito: "Chilcas"},
  {code:  "050505", departamento: "Ayacucho", provincia: "La Mar", distrito: "Chungui"},
  {code:  "050506", departamento: "Ayacucho", provincia: "La Mar", distrito: "Luis Carranza"},
  {code:  "050507", departamento: "Ayacucho", provincia: "La Mar", distrito: "Santa Rosa"},
  {code:  "050508", departamento: "Ayacucho", provincia: "La Mar", distrito: "Tambo"},
  {code:  "050509", departamento: "Ayacucho", provincia: "La Mar", distrito: "Samugari"},
  {code:  "050510", departamento: "Ayacucho", provincia: "La Mar", distrito: "Anchihuay"},
  {code:  "050511", departamento: "Ayacucho", provincia: "La Mar", distrito: "Oronccoy"},
  {code:  "050601", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Puquio"},
  {code:  "050602", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Aucara"},
  {code:  "050603", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Cabana"},
  {code:  "050604", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Carmen Salcedo"},
  {code:  "050605", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Chaviña"},
  {code:  "050606", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Chipao"},
  {code:  "050607", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Huac-Huas"},
  {code:  "050608", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Laramate"},
  {code:  "050609", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Leoncio Prado"},
  {code:  "050610", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Llauta"},
  {code:  "050611", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Lucanas"},
  {code:  "050612", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Ocaña"},
  {code:  "050613", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Otoca"},
  {code:  "050614", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Saisa"},
  {code:  "050615", departamento: "Ayacucho", provincia: "Lucanas", distrito: "San Cristobal"},
  {code:  "050616", departamento: "Ayacucho", provincia: "Lucanas", distrito: "San Juan"},
  {code:  "050617", departamento: "Ayacucho", provincia: "Lucanas", distrito: "San Pedro"},
  {code:  "050618", departamento: "Ayacucho", provincia: "Lucanas", distrito: "San Pedro de Palco"},
  {code:  "050619", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Sancos"},
  {code:  "050620", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Santa Ana de Huaycahuacho"},
  {code:  "050621", departamento: "Ayacucho", provincia: "Lucanas", distrito: "Santa Lucia"},
  {code:  "050701", departamento: "Ayacucho", provincia: "Parinacochas", distrito: "Coracora"},
  {code:  "050702", departamento: "Ayacucho", provincia: "Parinacochas", distrito: "Chumpi"},
  {code:  "050703", departamento: "Ayacucho", provincia: "Parinacochas", distrito: "Coronel Castañeda"},
  {code:  "050704", departamento: "Ayacucho", provincia: "Parinacochas", distrito: "Pacapausa"},
  {code:  "050705", departamento: "Ayacucho", provincia: "Parinacochas", distrito: "Pullo"},
  {code:  "050706", departamento: "Ayacucho", provincia: "Parinacochas", distrito: "Puyusca"},
  {code:  "050707", departamento: "Ayacucho", provincia: "Parinacochas", distrito: "San Francisco de Ravacayco"},
  {code:  "050708", departamento: "Ayacucho", provincia: "Parinacochas", distrito: "Upahuacho"},
  {code:  "050801", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "Pausa"},
  {code:  "050802", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "Colta"},
  {code:  "050803", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "Corculla"},
  {code:  "050804", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "Lampa"},
  {code:  "050805", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "Marcabamba"},
  {code:  "050806", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "Oyolo"},
  {code:  "050807", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "Pararca"},
  {code:  "050808", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "San Javier de Alpabamba"},
  {code:  "050809", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "San Jose de Ushua"},
  {code:  "050810", departamento: "Ayacucho", provincia: "Paucar del Sara Sara", distrito: "Sara Sara"},
  {code:  "050901", departamento: "Ayacucho", provincia: "Sucre", distrito: "Querobamba"},
  {code:  "050902", departamento: "Ayacucho", provincia: "Sucre", distrito: "Belen"},
  {code:  "050903", departamento: "Ayacucho", provincia: "Sucre", distrito: "Chalcos"},
  {code:  "050904", departamento: "Ayacucho", provincia: "Sucre", distrito: "Chilcayoc"},
  {code:  "050905", departamento: "Ayacucho", provincia: "Sucre", distrito: "Huacaña"},
  {code:  "050906", departamento: "Ayacucho", provincia: "Sucre", distrito: "Morcolla"},
  {code:  "050907", departamento: "Ayacucho", provincia: "Sucre", distrito: "Paico"},
  {code:  "050908", departamento: "Ayacucho", provincia: "Sucre", distrito: "San Pedro de Larcay"},
  {code:  "050909", departamento: "Ayacucho", provincia: "Sucre", distrito: "San Salvador de Quije"},
  {code:  "050910", departamento: "Ayacucho", provincia: "Sucre", distrito: "Santiago de Paucaray"},
  {code:  "050911", departamento: "Ayacucho", provincia: "Sucre", distrito: "Soras"},
  {code:  "051001", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Huancapi"},
  {code:  "051002", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Alcamenca"},
  {code:  "051003", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Apongo"},
  {code:  "051004", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Asquipata"},
  {code:  "051005", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Canaria"},
  {code:  "051006", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Cayara"},
  {code:  "051007", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Colca"},
  {code:  "051008", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Huamanquiquia"},
  {code:  "051009", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Huancaraylla"},
  {code:  "051010", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Huaya"},
  {code:  "051011", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Sarhua"},
  {code:  "051012", departamento: "Ayacucho", provincia: "Victor Fajardo", distrito: "Vilcanchos"},
  {code:  "051101", departamento: "Ayacucho", provincia: "Vilcas Huaman", distrito: "Vilcas Huaman"},
  {code:  "051102", departamento: "Ayacucho", provincia: "Vilcas Huaman", distrito: "Accomarca"},
  {code:  "051103", departamento: "Ayacucho", provincia: "Vilcas Huaman", distrito: "Carhuanca"},
  {code:  "051104", departamento: "Ayacucho", provincia: "Vilcas Huaman", distrito: "Concepcion"},
  {code:  "051105", departamento: "Ayacucho", provincia: "Vilcas Huaman", distrito: "Huambalpa"},
  {code:  "051106", departamento: "Ayacucho", provincia: "Vilcas Huaman", distrito: "Independencia"},
  {code:  "051107", departamento: "Ayacucho", provincia: "Vilcas Huaman", distrito: "Saurama"},
  {code:  "051108", departamento: "Ayacucho", provincia: "Vilcas Huaman", distrito: "Vischongo"},
  {code:  "060101", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Cajamarca"},
  {code:  "060102", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Asuncion"},
  {code:  "060103", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Chetilla"},
  {code:  "060104", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Cospan"},
  {code:  "060105", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Encañada"},
  {code:  "060106", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Jesus"},
  {code:  "060107", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Llacanora"},
  {code:  "060108", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Los Baños del Inca"},
  {code:  "060109", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Magdalena"},
  {code:  "060110", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Matara"},
  {code:  "060111", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "Namora"},
  {code:  "060112", departamento: "Cajamarca", provincia: "Cajamarca", distrito: "San Juan"},
  {code:  "060201", departamento: "Cajamarca", provincia: "Cajabamba", distrito: "Cajabamba"},
  {code:  "060202", departamento: "Cajamarca", provincia: "Cajabamba", distrito: "Cachachi"},
  {code:  "060203", departamento: "Cajamarca", provincia: "Cajabamba", distrito: "Condebamba"},
  {code:  "060204", departamento: "Cajamarca", provincia: "Cajabamba", distrito: "Sitacocha"},
  {code:  "060301", departamento: "Cajamarca", provincia: "Celendin", distrito: "Celendin"},
  {code:  "060302", departamento: "Cajamarca", provincia: "Celendin", distrito: "Chumuch"},
  {code:  "060303", departamento: "Cajamarca", provincia: "Celendin", distrito: "Cortegana"},
  {code:  "060304", departamento: "Cajamarca", provincia: "Celendin", distrito: "Huasmin"},
  {code:  "060305", departamento: "Cajamarca", provincia: "Celendin", distrito: "Jorge Chavez"},
  {code:  "060306", departamento: "Cajamarca", provincia: "Celendin", distrito: "Jose Galvez"},
  {code:  "060307", departamento: "Cajamarca", provincia: "Celendin", distrito: "Miguel Iglesias"},
  {code:  "060308", departamento: "Cajamarca", provincia: "Celendin", distrito: "Oxamarca"},
  {code:  "060309", departamento: "Cajamarca", provincia: "Celendin", distrito: "Sorochuco"},
  {code:  "060310", departamento: "Cajamarca", provincia: "Celendin", distrito: "Sucre"},
  {code:  "060311", departamento: "Cajamarca", provincia: "Celendin", distrito: "Utco"},
  {code:  "060312", departamento: "Cajamarca", provincia: "Celendin", distrito: "La Libertad de Pallan"},
  {code:  "060401", departamento: "Cajamarca", provincia: "Chota", distrito: "Chota"},
  {code:  "060402", departamento: "Cajamarca", provincia: "Chota", distrito: "Anguia"},
  {code:  "060403", departamento: "Cajamarca", provincia: "Chota", distrito: "Chadin"},
  {code:  "060404", departamento: "Cajamarca", provincia: "Chota", distrito: "Chiguirip"},
  {code:  "060405", departamento: "Cajamarca", provincia: "Chota", distrito: "Chimban"},
  {code:  "060406", departamento: "Cajamarca", provincia: "Chota", distrito: "Choropampa"},
  {code:  "060407", departamento: "Cajamarca", provincia: "Chota", distrito: "Cochabamba"},
  {code:  "060408", departamento: "Cajamarca", provincia: "Chota", distrito: "Conchan"},
  {code:  "060409", departamento: "Cajamarca", provincia: "Chota", distrito: "Huambos"},
  {code:  "060410", departamento: "Cajamarca", provincia: "Chota", distrito: "Lajas"},
  {code:  "060411", departamento: "Cajamarca", provincia: "Chota", distrito: "Llama"},
  {code:  "060412", departamento: "Cajamarca", provincia: "Chota", distrito: "Miracosta"},
  {code:  "060413", departamento: "Cajamarca", provincia: "Chota", distrito: "Paccha"},
  {code:  "060414", departamento: "Cajamarca", provincia: "Chota", distrito: "Pion"},
  {code:  "060415", departamento: "Cajamarca", provincia: "Chota", distrito: "Querocoto"},
  {code:  "060416", departamento: "Cajamarca", provincia: "Chota", distrito: "San Juan de Licupis"},
  {code:  "060417", departamento: "Cajamarca", provincia: "Chota", distrito: "Tacabamba"},
  {code:  "060418", departamento: "Cajamarca", provincia: "Chota", distrito: "Tocmoche"},
  {code:  "060419", departamento: "Cajamarca", provincia: "Chota", distrito: "Chalamarca"},
  {code:  "060501", departamento: "Cajamarca", provincia: "Contumaza", distrito: "Contumaza"},
  {code:  "060502", departamento: "Cajamarca", provincia: "Contumaza", distrito: "Chilete"},
  {code:  "060503", departamento: "Cajamarca", provincia: "Contumaza", distrito: "Cupisnique"},
  {code:  "060504", departamento: "Cajamarca", provincia: "Contumaza", distrito: "Guzmango"},
  {code:  "060505", departamento: "Cajamarca", provincia: "Contumaza", distrito: "San Benito"},
  {code:  "060506", departamento: "Cajamarca", provincia: "Contumaza", distrito: "Santa Cruz de Toled"},
  {code:  "060507", departamento: "Cajamarca", provincia: "Contumaza", distrito: "Tantarica"},
  {code:  "060508", departamento: "Cajamarca", provincia: "Contumaza", distrito: "Yonan"},
  {code:  "060601", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Cutervo"},
  {code:  "060602", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Callayuc"},
  {code:  "060603", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Choros"},
  {code:  "060604", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Cujillo"},
  {code:  "060605", departamento: "Cajamarca", provincia: "Cutervo", distrito: "La Ramada"},
  {code:  "060606", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Pimpingos"},
  {code:  "060607", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Querocotillo"},
  {code:  "060608", departamento: "Cajamarca", provincia: "Cutervo", distrito: "San Andres de Cutervo"},
  {code:  "060609", departamento: "Cajamarca", provincia: "Cutervo", distrito: "San Juan de Cutervo"},
  {code:  "060610", departamento: "Cajamarca", provincia: "Cutervo", distrito: "San Luis de Lucma"},
  {code:  "060611", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Santa Cruz"},
  {code:  "060612", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Santo Domingo de La Capilla"},
  {code:  "060613", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Santo Tomas"},
  {code:  "060614", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Socota"},
  {code:  "060615", departamento: "Cajamarca", provincia: "Cutervo", distrito: "Toribio Casanova"},
  {code:  "060701", departamento: "Cajamarca", provincia: "Hualgayoc", distrito: "Bambamarca"},
  {code:  "060702", departamento: "Cajamarca", provincia: "Hualgayoc", distrito: "Chugur"},
  {code:  "060703", departamento: "Cajamarca", provincia: "Hualgayoc", distrito: "Hualgayoc"},
  {code:  "060801", departamento: "Cajamarca", provincia: "Jaen", distrito: "Jaen"},
  {code:  "060802", departamento: "Cajamarca", provincia: "Jaen", distrito: "Bellavista"},
  {code:  "060803", departamento: "Cajamarca", provincia: "Jaen", distrito: "Chontali"},
  {code:  "060804", departamento: "Cajamarca", provincia: "Jaen", distrito: "Colasay"},
  {code:  "060805", departamento: "Cajamarca", provincia: "Jaen", distrito: "Huabal"},
  {code:  "060806", departamento: "Cajamarca", provincia: "Jaen", distrito: "Las Pirias"},
  {code:  "060807", departamento: "Cajamarca", provincia: "Jaen", distrito: "Pomahuaca"},
  {code:  "060808", departamento: "Cajamarca", provincia: "Jaen", distrito: "Pucara"},
  {code:  "060809", departamento: "Cajamarca", provincia: "Jaen", distrito: "Sallique"},
  {code:  "060810", departamento: "Cajamarca", provincia: "Jaen", distrito: "San Felipe"},
  {code:  "060811", departamento: "Cajamarca", provincia: "Jaen", distrito: "San Jose del Alto"},
  {code:  "060812", departamento: "Cajamarca", provincia: "Jaen", distrito: "Santa Rosa"},
  {code:  "060901", departamento: "Cajamarca", provincia: "San Ignacio", distrito: "San Ignacio"},
  {code:  "060902", departamento: "Cajamarca", provincia: "San Ignacio", distrito: "Chirinos"},
  {code:  "060903", departamento: "Cajamarca", provincia: "San Ignacio", distrito: "Huarango"},
  {code:  "060904", departamento: "Cajamarca", provincia: "San Ignacio", distrito: "La Coipa"},
  {code:  "060905", departamento: "Cajamarca", provincia: "San Ignacio", distrito: "Namballe"},
  {code:  "060906", departamento: "Cajamarca", provincia: "San Ignacio", distrito: "San Jose de Lourdes"},
  {code:  "060907", departamento: "Cajamarca", provincia: "San Ignacio", distrito: "Tabaconas"},
  {code:  "061001", departamento: "Cajamarca", provincia: "San Marcos", distrito: "Pedro Galvez"},
  {code:  "061002", departamento: "Cajamarca", provincia: "San Marcos", distrito: "Chancay"},
  {code:  "061003", departamento: "Cajamarca", provincia: "San Marcos", distrito: "Eduardo Villanueva"},
  {code:  "061004", departamento: "Cajamarca", provincia: "San Marcos", distrito: "Gregorio Pita"},
  {code:  "061005", departamento: "Cajamarca", provincia: "San Marcos", distrito: "Ichocan"},
  {code:  "061006", departamento: "Cajamarca", provincia: "San Marcos", distrito: "Jose Manuel Quiroz"},
  {code:  "061007", departamento: "Cajamarca", provincia: "San Marcos", distrito: "Jose Sabogal"},
  {code:  "061101", departamento: "Cajamarca", provincia: "San Miguel", distrito: "San Miguel"},
  {code:  "061102", departamento: "Cajamarca", provincia: "San Miguel", distrito: "Bolivar"},
  {code:  "061103", departamento: "Cajamarca", provincia: "San Miguel", distrito: "Calquis"},
  {code:  "061104", departamento: "Cajamarca", provincia: "San Miguel", distrito: "Catilluc"},
  {code:  "061105", departamento: "Cajamarca", provincia: "San Miguel", distrito: "El Prado"},
  {code:  "061106", departamento: "Cajamarca", provincia: "San Miguel", distrito: "La Florida"},
  {code:  "061107", departamento: "Cajamarca", provincia: "San Miguel", distrito: "Llapa"},
  {code:  "061108", departamento: "Cajamarca", provincia: "San Miguel", distrito: "Nanchoc"},
  {code:  "061109", departamento: "Cajamarca", provincia: "San Miguel", distrito: "Niepos"},
  {code:  "061110", departamento: "Cajamarca", provincia: "San Miguel", distrito: "San Gregorio"},
  {code:  "061111", departamento: "Cajamarca", provincia: "San Miguel", distrito: "San Silvestre de Cochan"},
  {code:  "061112", departamento: "Cajamarca", provincia: "San Miguel", distrito: "Tongod"},
  {code:  "061113", departamento: "Cajamarca", provincia: "San Miguel", distrito: "Union Agua Blanca"},
  {code:  "061201", departamento: "Cajamarca", provincia: "San Pablo", distrito: "San Pablo"},
  {code:  "061202", departamento: "Cajamarca", provincia: "San Pablo", distrito: "San Bernardino"},
  {code:  "061203", departamento: "Cajamarca", provincia: "San Pablo", distrito: "San Luis"},
  {code:  "061204", departamento: "Cajamarca", provincia: "San Pablo", distrito: "Tumbaden"},
  {code:  "061301", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Santa Cruz"},
  {code:  "061302", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Andabamba"},
  {code:  "061303", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Catache"},
  {code:  "061304", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Chancaybaños"},
  {code:  "061305", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "La Esperanza"},
  {code:  "061306", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Ninabamba"},
  {code:  "061307", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Pulan"},
  {code:  "061308", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Saucepampa"},
  {code:  "061309", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Sexi"},
  {code:  "061310", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Uticyacu"},
  {code:  "061311", departamento: "Cajamarca", provincia: "Santa Cruz", distrito: "Yauyucan"},
  {code:  "070101", departamento: "Callao", provincia: "Callao", distrito: "Callao"},
  {code:  "070102", departamento: "Callao", provincia: "Callao", distrito: "Bellavista"},
  {code:  "070103", departamento: "Callao", provincia: "Callao", distrito: "Carmen de La Legua"},
  {code:  "070104", departamento: "Callao", provincia: "Callao", distrito: "La Perla"},
  {code:  "070105", departamento: "Callao", provincia: "Callao", distrito: "La Punta"},
  {code:  "070106", departamento: "Callao", provincia: "Callao", distrito: "Ventanilla"},
  {code:  "070107", departamento: "Callao", provincia: "Callao", distrito: "Mi Peru"},
  {code:  "080101", departamento: "Cusco", provincia: "Cusco", distrito: "Cusco"},
  {code:  "080102", departamento: "Cusco", provincia: "Cusco", distrito: "Ccorca"},
  {code:  "080103", departamento: "Cusco", provincia: "Cusco", distrito: "Poroy"},
  {code:  "080104", departamento: "Cusco", provincia: "Cusco", distrito: "San Jeronimo"},
  {code:  "080105", departamento: "Cusco", provincia: "Cusco", distrito: "San Sebastian"},
  {code:  "080106", departamento: "Cusco", provincia: "Cusco", distrito: "Santiago"},
  {code:  "080107", departamento: "Cusco", provincia: "Cusco", distrito: "Saylla"},
  {code:  "080108", departamento: "Cusco", provincia: "Cusco", distrito: "Wanchaq"},
  {code:  "080201", departamento: "Cusco", provincia: "Acomayo", distrito: "Acomayo"},
  {code:  "080202", departamento: "Cusco", provincia: "Acomayo", distrito: "Acopia"},
  {code:  "080203", departamento: "Cusco", provincia: "Acomayo", distrito: "Acos"},
  {code:  "080204", departamento: "Cusco", provincia: "Acomayo", distrito: "Mosoc Llacta"},
  {code:  "080205", departamento: "Cusco", provincia: "Acomayo", distrito: "Pomacanchi"},
  {code:  "080206", departamento: "Cusco", provincia: "Acomayo", distrito: "Rondocan"},
  {code:  "080207", departamento: "Cusco", provincia: "Acomayo", distrito: "Sangarara"},
  {code:  "080301", departamento: "Cusco", provincia: "Anta", distrito: "Anta"},
  {code:  "080302", departamento: "Cusco", provincia: "Anta", distrito: "Ancahuasi"},
  {code:  "080303", departamento: "Cusco", provincia: "Anta", distrito: "Cachimayo"},
  {code:  "080304", departamento: "Cusco", provincia: "Anta", distrito: "Chinchaypujio"},
  {code:  "080305", departamento: "Cusco", provincia: "Anta", distrito: "Huarocondo"},
  {code:  "080306", departamento: "Cusco", provincia: "Anta", distrito: "Limatambo"},
  {code:  "080307", departamento: "Cusco", provincia: "Anta", distrito: "Mollepata"},
  {code:  "080308", departamento: "Cusco", provincia: "Anta", distrito: "Pucyura"},
  {code:  "080309", departamento: "Cusco", provincia: "Anta", distrito: "Zurite"},
  {code:  "080401", departamento: "Cusco", provincia: "Calca", distrito: "Calca"},
  {code:  "080402", departamento: "Cusco", provincia: "Calca", distrito: "Coya"},
  {code:  "080403", departamento: "Cusco", provincia: "Calca", distrito: "Lamay"},
  {code:  "080404", departamento: "Cusco", provincia: "Calca", distrito: "Lares"},
  {code:  "080405", departamento: "Cusco", provincia: "Calca", distrito: "Pisac"},
  {code:  "080406", departamento: "Cusco", provincia: "Calca", distrito: "San Salvador"},
  {code:  "080407", departamento: "Cusco", provincia: "Calca", distrito: "Taray"},
  {code:  "080408", departamento: "Cusco", provincia: "Calca", distrito: "Yanatile"},
  {code:  "080501", departamento: "Cusco", provincia: "Canas", distrito: "Yanaoca"},
  {code:  "080502", departamento: "Cusco", provincia: "Canas", distrito: "Checca"},
  {code:  "080503", departamento: "Cusco", provincia: "Canas", distrito: "Kunturkanki"},
  {code:  "080504", departamento: "Cusco", provincia: "Canas", distrito: "Langui"},
  {code:  "080505", departamento: "Cusco", provincia: "Canas", distrito: "Layo"},
  {code:  "080506", departamento: "Cusco", provincia: "Canas", distrito: "Pampamarca"},
  {code:  "080507", departamento: "Cusco", provincia: "Canas", distrito: "Quehue"},
  {code:  "080508", departamento: "Cusco", provincia: "Canas", distrito: "Tupac Amaru"},
  {code:  "080601", departamento: "Cusco", provincia: "Canchis", distrito: "Sicuani"},
  {code:  "080602", departamento: "Cusco", provincia: "Canchis", distrito: "Checacupe"},
  {code:  "080603", departamento: "Cusco", provincia: "Canchis", distrito: "Combapata"},
  {code:  "080604", departamento: "Cusco", provincia: "Canchis", distrito: "Marangani"},
  {code:  "080605", departamento: "Cusco", provincia: "Canchis", distrito: "Pitumarca"},
  {code:  "080606", departamento: "Cusco", provincia: "Canchis", distrito: "San Pablo"},
  {code:  "080607", departamento: "Cusco", provincia: "Canchis", distrito: "San Pedro"},
  {code:  "080608", departamento: "Cusco", provincia: "Canchis", distrito: "Tinta"},
  {code:  "080701", departamento: "Cusco", provincia: "Chumbivilcas", distrito: "Santo Tomas"},
  {code:  "080702", departamento: "Cusco", provincia: "Chumbivilcas", distrito: "Capacmarca"},
  {code:  "080703", departamento: "Cusco", provincia: "Chumbivilcas", distrito: "Chamaca"},
  {code:  "080704", departamento: "Cusco", provincia: "Chumbivilcas", distrito: "Colquemarca"},
  {code:  "080705", departamento: "Cusco", provincia: "Chumbivilcas", distrito: "Livitaca"},
  {code:  "080706", departamento: "Cusco", provincia: "Chumbivilcas", distrito: "Llusco"},
  {code:  "080707", departamento: "Cusco", provincia: "Chumbivilcas", distrito: "Quiñota"},
  {code:  "080708", departamento: "Cusco", provincia: "Chumbivilcas", distrito: "Velille"},
  {code:  "080801", departamento: "Cusco", provincia: "Espinar", distrito: "Espinar"},
  {code:  "080802", departamento: "Cusco", provincia: "Espinar", distrito: "Condoroma"},
  {code:  "080803", departamento: "Cusco", provincia: "Espinar", distrito: "Coporaque"},
  {code:  "080804", departamento: "Cusco", provincia: "Espinar", distrito: "Ocoruro"},
  {code:  "080805", departamento: "Cusco", provincia: "Espinar", distrito: "Pallpata"},
  {code:  "080806", departamento: "Cusco", provincia: "Espinar", distrito: "Pichigua"},
  {code:  "080807", departamento: "Cusco", provincia: "Espinar", distrito: "Suyckutambo"},
  {code:  "080808", departamento: "Cusco", provincia: "Espinar", distrito: "Alto Pichigua"},
  {code:  "080901", departamento: "Cusco", provincia: "La Convencion", distrito: "Santa Ana"},
  {code:  "080902", departamento: "Cusco", provincia: "La Convencion", distrito: "Echarate"},
  {code:  "080903", departamento: "Cusco", provincia: "La Convencion", distrito: "Huayopata"},
  {code:  "080904", departamento: "Cusco", provincia: "La Convencion", distrito: "Maranura"},
  {code:  "080905", departamento: "Cusco", provincia: "La Convencion", distrito: "Ocobamba"},
  {code:  "080906", departamento: "Cusco", provincia: "La Convencion", distrito: "Quellouno"},
  {code:  "080907", departamento: "Cusco", provincia: "La Convencion", distrito: "Kimbiri"},
  {code:  "080908", departamento: "Cusco", provincia: "La Convencion", distrito: "Santa Teresa"},
  {code:  "080909", departamento: "Cusco", provincia: "La Convencion", distrito: "Vilcabamba"},
  {code:  "080910", departamento: "Cusco", provincia: "La Convencion", distrito: "Pichari"},
  {code:  "080911", departamento: "Cusco", provincia: "La Convencion", distrito: "Inkawasi"},
  {code:  "080912", departamento: "Cusco", provincia: "La Convencion", distrito: "Villa Virgen"},
  {code:  "080913", departamento: "Cusco", provincia: "La Convencion", distrito: "Villa Kintiarina"},
  {code:  "080914", departamento: "Cusco", provincia: "La Convencion", distrito: "Megantoni"},
  {code:  "081001", departamento: "Cusco", provincia: "Paruro", distrito: "Paruro"},
  {code:  "081002", departamento: "Cusco", provincia: "Paruro", distrito: "Accha"},
  {code:  "081003", departamento: "Cusco", provincia: "Paruro", distrito: "Ccapi"},
  {code:  "081004", departamento: "Cusco", provincia: "Paruro", distrito: "Colcha"},
  {code:  "081005", departamento: "Cusco", provincia: "Paruro", distrito: "Huanoquite"},
  {code:  "081006", departamento: "Cusco", provincia: "Paruro", distrito: "Omacha"},
  {code:  "081007", departamento: "Cusco", provincia: "Paruro", distrito: "Paccaritambo"},
  {code:  "081008", departamento: "Cusco", provincia: "Paruro", distrito: "Pillpinto"},
  {code:  "081009", departamento: "Cusco", provincia: "Paruro", distrito: "Yaurisque"},
  {code:  "081101", departamento: "Cusco", provincia: "Paucartambo", distrito: "Paucartambo"},
  {code:  "081102", departamento: "Cusco", provincia: "Paucartambo", distrito: "Caicay"},
  {code:  "081103", departamento: "Cusco", provincia: "Paucartambo", distrito: "Challabamba"},
  {code:  "081104", departamento: "Cusco", provincia: "Paucartambo", distrito: "Colquepata"},
  {code:  "081105", departamento: "Cusco", provincia: "Paucartambo", distrito: "Huancarani"},
  {code:  "081106", departamento: "Cusco", provincia: "Paucartambo", distrito: "Kosñipata"},
  {code:  "081201", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Urcos"},
  {code:  "081202", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Andahuaylillas"},
  {code:  "081203", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Camanti"},
  {code:  "081204", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Ccarhuayo"},
  {code:  "081205", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Ccatca"},
  {code:  "081206", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Cusipata"},
  {code:  "081207", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Huaro"},
  {code:  "081208", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Lucre"},
  {code:  "081209", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Marcapata"},
  {code:  "081210", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Ocongate"},
  {code:  "081211", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Oropesa"},
  {code:  "081212", departamento: "Cusco", provincia: "Quispicanchi", distrito: "Quiquijana"},
  {code:  "081301", departamento: "Cusco", provincia: "Urubamba", distrito: "Urubamba"},
  {code:  "081302", departamento: "Cusco", provincia: "Urubamba", distrito: "Chinchero"},
  {code:  "081303", departamento: "Cusco", provincia: "Urubamba", distrito: "Huayllabamba"},
  {code:  "081304", departamento: "Cusco", provincia: "Urubamba", distrito: "Machupicchu"},
  {code:  "081305", departamento: "Cusco", provincia: "Urubamba", distrito: "Maras"},
  {code:  "081306", departamento: "Cusco", provincia: "Urubamba", distrito: "Ollantaytambo"},
  {code:  "081307", departamento: "Cusco", provincia: "Urubamba", distrito: "Yucay"},
  {code:  "090101", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Huancavelica"},
  {code:  "090102", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Acobambilla"},
  {code:  "090103", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Acoria"},
  {code:  "090104", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Conayca"},
  {code:  "090105", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Cuenca"},
  {code:  "090106", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Huachocolpa"},
  {code:  "090107", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Huayllahuara"},
  {code:  "090108", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Izcuchaca"},
  {code:  "090109", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Laria"},
  {code:  "090110", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Manta"},
  {code:  "090111", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Mariscal Caceres"},
  {code:  "090112", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Moya"},
  {code:  "090113", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Nuevo Occoro"},
  {code:  "090114", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Palca"},
  {code:  "090115", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Pilchaca"},
  {code:  "090116", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Vilca"},
  {code:  "090117", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Yauli"},
  {code:  "090118", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Ascension"},
  {code:  "090119", departamento: "Huancavelica", provincia: "Huancavelica", distrito: "Huando"},
  {code:  "090201", departamento: "Huancavelica", provincia: "Acobamba", distrito: "Acobamba"},
  {code:  "090202", departamento: "Huancavelica", provincia: "Acobamba", distrito: "Andabamba"},
  {code:  "090203", departamento: "Huancavelica", provincia: "Acobamba", distrito: "Anta"},
  {code:  "090204", departamento: "Huancavelica", provincia: "Acobamba", distrito: "Caja"},
  {code:  "090205", departamento: "Huancavelica", provincia: "Acobamba", distrito: "Marcas"},
  {code:  "090206", departamento: "Huancavelica", provincia: "Acobamba", distrito: "Paucara"},
  {code:  "090207", departamento: "Huancavelica", provincia: "Acobamba", distrito: "Pomacocha"},
  {code:  "090208", departamento: "Huancavelica", provincia: "Acobamba", distrito: "Rosario"},
  {code:  "090301", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Lircay"},
  {code:  "090302", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Anchonga"},
  {code:  "090303", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Callanmarca"},
  {code:  "090304", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Ccochaccasa"},
  {code:  "090305", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Chincho"},
  {code:  "090306", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Congalla"},
  {code:  "090307", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Huanca-Huanca"},
  {code:  "090308", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Huayllay Grande"},
  {code:  "090309", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Julcamarca"},
  {code:  "090310", departamento: "Huancavelica", provincia: "Angaraes", distrito: "San Antonio de Antaparco"},
  {code:  "090311", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Santo Tomas de Pata"},
  {code:  "090312", departamento: "Huancavelica", provincia: "Angaraes", distrito: "Secclla"},
  {code:  "090401", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Castrovirreyna"},
  {code:  "090402", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Arma"},
  {code:  "090403", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Aurahua"},
  {code:  "090404", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Capillas"},
  {code:  "090405", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Chupamarca"},
  {code:  "090406", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Cocas"},
  {code:  "090407", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Huachos"},
  {code:  "090408", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Huamatambo"},
  {code:  "090409", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Mollepampa"},
  {code:  "090410", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "San Juan"},
  {code:  "090411", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Santa Ana"},
  {code:  "090412", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Tantara"},
  {code:  "090413", departamento: "Huancavelica", provincia: "Castrovirreyna", distrito: "Ticrapo"},
  {code:  "090501", departamento: "Huancavelica", provincia: "Churcampa", distrito: "Churcampa"},
  {code:  "090502", departamento: "Huancavelica", provincia: "Churcampa", distrito: "Anco"},
  {code:  "090503", departamento: "Huancavelica", provincia: "Churcampa", distrito: "Chinchihuasi"},
  {code:  "090504", departamento: "Huancavelica", provincia: "Churcampa", distrito: "El Carmen"},
  {code:  "090505", departamento: "Huancavelica", provincia: "Churcampa", distrito: "La Merced"},
  {code:  "090506", departamento: "Huancavelica", provincia: "Churcampa", distrito: "Locroja"},
  {code:  "090507", departamento: "Huancavelica", provincia: "Churcampa", distrito: "Paucarbamba"},
  {code:  "090508", departamento: "Huancavelica", provincia: "Churcampa", distrito: "San Miguel de Mayocc"},
  {code:  "090509", departamento: "Huancavelica", provincia: "Churcampa", distrito: "San Pedro de Coris"},
  {code:  "090510", departamento: "Huancavelica", provincia: "Churcampa", distrito: "Pachamarca"},
  {code:  "090511", departamento: "Huancavelica", provincia: "Churcampa", distrito: "Cosme"},
  {code:  "090601", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Huaytara"},
  {code:  "090602", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Ayavi"},
  {code:  "090603", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Cordova"},
  {code:  "090604", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Huayacundo Arma"},
  {code:  "090605", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Laramarca"},
  {code:  "090606", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Ocoyo"},
  {code:  "090607", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Pilpichaca"},
  {code:  "090608", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Querco"},
  {code:  "090609", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Quito-Arma"},
  {code:  "090610", departamento: "Huancavelica", provincia: "Huaytara", distrito: "San Antonio de Cusicancha"},
  {code:  "090611", departamento: "Huancavelica", provincia: "Huaytara", distrito: "San Francisco de Sangayaico"},
  {code:  "090612", departamento: "Huancavelica", provincia: "Huaytara", distrito: "San Isidro"},
  {code:  "090613", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Santiago de Chocorvos"},
  {code:  "090614", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Santiago de Quirahuara"},
  {code:  "090615", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Santo Domingo de Capillas"},
  {code:  "090616", departamento: "Huancavelica", provincia: "Huaytara", distrito: "Tambo"},
  {code:  "090701", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Pampas"},
  {code:  "090702", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Acostambo"},
  {code:  "090703", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Acraquia"},
  {code:  "090704", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Ahuaycha"},
  {code:  "090705", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Colcabamba"},
  {code:  "090706", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Daniel Hernandez"},
  {code:  "090707", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Huachocolpa"},
  {code:  "090709", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Huaribamba"},
  {code:  "090710", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Ñahuimpuquio"},
  {code:  "090711", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Pazos"},
  {code:  "090713", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Quishuar"},
  {code:  "090714", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Salcabamba"},
  {code:  "090715", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Salcahuasi"},
  {code:  "090716", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "San Marcos de Rocchac"},
  {code:  "090717", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Surcubamba"},
  {code:  "090718", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Tintay Puncu"},
  {code:  "090719", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Quichuas"},
  {code:  "090720", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Andaymarca"},
  {code:  "090721", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Roble"},
  {code:  "090722", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Pichos"},
  {code:  "090723", departamento: "Huancavelica", provincia: "Tayacaja", distrito: "Santiago de Túcuma"},
  {code:  "100101", departamento: "Huanuco", provincia: "Huanuco", distrito: "Huanuco"},
  {code:  "100102", departamento: "Huanuco", provincia: "Huanuco", distrito: "Amarilis"},
  {code:  "100103", departamento: "Huanuco", provincia: "Huanuco", distrito: "Chinchao"},
  {code:  "100104", departamento: "Huanuco", provincia: "Huanuco", distrito: "Churubamba"},
  {code:  "100105", departamento: "Huanuco", provincia: "Huanuco", distrito: "Margos"},
  {code:  "100106", departamento: "Huanuco", provincia: "Huanuco", distrito: "Quisqui"},
  {code:  "100107", departamento: "Huanuco", provincia: "Huanuco", distrito: "San Francisco de Cayran"},
  {code:  "100108", departamento: "Huanuco", provincia: "Huanuco", distrito: "San Pedro de Chaulan"},
  {code:  "100109", departamento: "Huanuco", provincia: "Huanuco", distrito: "Santa Maria del Valle"},
  {code:  "100110", departamento: "Huanuco", provincia: "Huanuco", distrito: "Yarumayo"},
  {code:  "100111", departamento: "Huanuco", provincia: "Huanuco", distrito: "Pillco Marca"},
  {code:  "100112", departamento: "Huanuco", provincia: "Huanuco", distrito: "Yacus"},
  {code:  "100113", departamento: "Huanuco", provincia: "Huanuco", distrito: "San Pablo de Pillao"},
  {code:  "100201", departamento: "Huanuco", provincia: "Ambo", distrito: "Ambo"},
  {code:  "100202", departamento: "Huanuco", provincia: "Ambo", distrito: "Cayna"},
  {code:  "100203", departamento: "Huanuco", provincia: "Ambo", distrito: "Colpas"},
  {code:  "100204", departamento: "Huanuco", provincia: "Ambo", distrito: "Conchamarca"},
  {code:  "100205", departamento: "Huanuco", provincia: "Ambo", distrito: "Huacar"},
  {code:  "100206", departamento: "Huanuco", provincia: "Ambo", distrito: "San Francisco"},
  {code:  "100207", departamento: "Huanuco", provincia: "Ambo", distrito: "San Rafael"},
  {code:  "100208", departamento: "Huanuco", provincia: "Ambo", distrito: "Tomay Kichwa"},
  {code:  "100301", departamento: "Huanuco", provincia: "Dos de Mayo", distrito: "La Union"},
  {code:  "100307", departamento: "Huanuco", provincia: "Dos de Mayo", distrito: "Chuquis"},
  {code:  "100311", departamento: "Huanuco", provincia: "Dos de Mayo", distrito: "Marias"},
  {code:  "100313", departamento: "Huanuco", provincia: "Dos de Mayo", distrito: "Pachas"},
  {code:  "100316", departamento: "Huanuco", provincia: "Dos de Mayo", distrito: "Quivilla"},
  {code:  "100317", departamento: "Huanuco", provincia: "Dos de Mayo", distrito: "Ripan"},
  {code:  "100321", departamento: "Huanuco", provincia: "Dos de Mayo", distrito: "Shunqui"},
  {code:  "100322", departamento: "Huanuco", provincia: "Dos de Mayo", distrito: "Sillapata"},
  {code:  "100323", departamento: "Huanuco", provincia: "Dos de Mayo", distrito: "Yanas"},
  {code:  "100401", departamento: "Huanuco", provincia: "Huacaybamba", distrito: "Huacaybamba"},
  {code:  "100402", departamento: "Huanuco", provincia: "Huacaybamba", distrito: "Canchabamba"},
  {code:  "100403", departamento: "Huanuco", provincia: "Huacaybamba", distrito: "Cochabamba"},
  {code:  "100404", departamento: "Huanuco", provincia: "Huacaybamba", distrito: "Pinra"},
  {code:  "100501", departamento: "Huanuco", provincia: "Huamalies", distrito: "Llata"},
  {code:  "100502", departamento: "Huanuco", provincia: "Huamalies", distrito: "Arancay"},
  {code:  "100503", departamento: "Huanuco", provincia: "Huamalies", distrito: "Chavin de Pariarca"},
  {code:  "100504", departamento: "Huanuco", provincia: "Huamalies", distrito: "Jacas Grande"},
  {code:  "100505", departamento: "Huanuco", provincia: "Huamalies", distrito: "Jircan"},
  {code:  "100506", departamento: "Huanuco", provincia: "Huamalies", distrito: "Miraflores"},
  {code:  "100507", departamento: "Huanuco", provincia: "Huamalies", distrito: "Monzon"},
  {code:  "100508", departamento: "Huanuco", provincia: "Huamalies", distrito: "Punchao"},
  {code:  "100509", departamento: "Huanuco", provincia: "Huamalies", distrito: "Puños"},
  {code:  "100510", departamento: "Huanuco", provincia: "Huamalies", distrito: "Singa"},
  {code:  "100511", departamento: "Huanuco", provincia: "Huamalies", distrito: "Tantamayo"},
  {code:  "100601", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Rupa-Rupa"},
  {code:  "100602", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Daniel Alomias Robles"},
  {code:  "100603", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Hermilio Valdizan"},
  {code:  "100604", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Jose Crespo y Castillo"},
  {code:  "100605", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Luyando"},
  {code:  "100606", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Mariano Damaso Beraun"},
  {code:  "100607", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Pucayacu"},
  {code:  "100608", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Castillo Grande"},
  {code:  "100609", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Pueblo Nuevo"},
  {code:  "100610", departamento: "Huanuco", provincia: "Leoncio Prado", distrito: "Santo Domingo de Anda"},
  {code:  "100701", departamento: "Huanuco", provincia: "Marañon", distrito: "Huacrachuco"},
  {code:  "100702", departamento: "Huanuco", provincia: "Marañon", distrito: "Cholon"},
  {code:  "100703", departamento: "Huanuco", provincia: "Marañon", distrito: "San Buenaventura"},
  {code:  "100704", departamento: "Huanuco", provincia: "Marañon", distrito: "La Morada"},
  {code:  "100705", departamento: "Huanuco", provincia: "Marañon", distrito: "Santa Rosa de Alto Yanajanca"},
  {code:  "100801", departamento: "Huanuco", provincia: "Pachitea", distrito: "Panao"},
  {code:  "100802", departamento: "Huanuco", provincia: "Pachitea", distrito: "Chaglla"},
  {code:  "100803", departamento: "Huanuco", provincia: "Pachitea", distrito: "Molino"},
  {code:  "100804", departamento: "Huanuco", provincia: "Pachitea", distrito: "Umari"},
  {code:  "100901", departamento: "Huanuco", provincia: "Puerto Inca", distrito: "Puerto Inca"},
  {code:  "100902", departamento: "Huanuco", provincia: "Puerto Inca", distrito: "Codo del Pozuzo"},
  {code:  "100903", departamento: "Huanuco", provincia: "Puerto Inca", distrito: "Honoria"},
  {code:  "100904", departamento: "Huanuco", provincia: "Puerto Inca", distrito: "Tournavista"},
  {code:  "100905", departamento: "Huanuco", provincia: "Puerto Inca", distrito: "Yuyapichis"},
  {code:  "101001", departamento: "Huanuco", provincia: "Lauricocha", distrito: "Jesus"},
  {code:  "101002", departamento: "Huanuco", provincia: "Lauricocha", distrito: "Baños"},
  {code:  "101003", departamento: "Huanuco", provincia: "Lauricocha", distrito: "Jivia"},
  {code:  "101004", departamento: "Huanuco", provincia: "Lauricocha", distrito: "Queropalca"},
  {code:  "101005", departamento: "Huanuco", provincia: "Lauricocha", distrito: "Rondos"},
  {code:  "101006", departamento: "Huanuco", provincia: "Lauricocha", distrito: "San Francisco de Asis"},
  {code:  "101007", departamento: "Huanuco", provincia: "Lauricocha", distrito: "San Miguel de Cauri"},
  {code:  "101101", departamento: "Huanuco", provincia: "Yarowilca", distrito: "Chavinillo"},
  {code:  "101102", departamento: "Huanuco", provincia: "Yarowilca", distrito: "Cahuac"},
  {code:  "101103", departamento: "Huanuco", provincia: "Yarowilca", distrito: "Chacabamba"},
  {code:  "101104", departamento: "Huanuco", provincia: "Yarowilca", distrito: "Aparicio Pomares"},
  {code:  "101105", departamento: "Huanuco", provincia: "Yarowilca", distrito: "Jacas Chico"},
  {code:  "101106", departamento: "Huanuco", provincia: "Yarowilca", distrito: "Obas"},
  {code:  "101107", departamento: "Huanuco", provincia: "Yarowilca", distrito: "Pampamarca"},
  {code:  "101108", departamento: "Huanuco", provincia: "Yarowilca", distrito: "Choras"},
  {code:  "110101", departamento: "Ica", provincia: "Ica", distrito: "Ica"},
  {code:  "110102", departamento: "Ica", provincia: "Ica", distrito: "La Tinguiña"},
  {code:  "110103", departamento: "Ica", provincia: "Ica", distrito: "Los Aquijes"},
  {code:  "110104", departamento: "Ica", provincia: "Ica", distrito: "Ocucaje"},
  {code:  "110105", departamento: "Ica", provincia: "Ica", distrito: "Pachacutec"},
  {code:  "110106", departamento: "Ica", provincia: "Ica", distrito: "Parcona"},
  {code:  "110107", departamento: "Ica", provincia: "Ica", distrito: "Pueblo Nuevo"},
  {code:  "110108", departamento: "Ica", provincia: "Ica", distrito: "Salas"},
  {code:  "110109", departamento: "Ica", provincia: "Ica", distrito: "San Jose de los Molinos"},
  {code:  "110110", departamento: "Ica", provincia: "Ica", distrito: "San Juan Bautista"},
  {code:  "110111", departamento: "Ica", provincia: "Ica", distrito: "Santiago"},
  {code:  "110112", departamento: "Ica", provincia: "Ica", distrito: "Subtanjalla"},
  {code:  "110113", departamento: "Ica", provincia: "Ica", distrito: "Tate"},
  {code:  "110114", departamento: "Ica", provincia: "Ica", distrito: "Yauca del Rosario"},
  {code:  "110201", departamento: "Ica", provincia: "Chincha", distrito: "Chincha Alta"},
  {code:  "110202", departamento: "Ica", provincia: "Chincha", distrito: "Alto Laran"},
  {code:  "110203", departamento: "Ica", provincia: "Chincha", distrito: "Chavin"},
  {code:  "110204", departamento: "Ica", provincia: "Chincha", distrito: "Chincha Baja"},
  {code:  "110205", departamento: "Ica", provincia: "Chincha", distrito: "El Carmen"},
  {code:  "110206", departamento: "Ica", provincia: "Chincha", distrito: "Grocio Prado"},
  {code:  "110207", departamento: "Ica", provincia: "Chincha", distrito: "Pueblo Nuevo"},
  {code:  "110208", departamento: "Ica", provincia: "Chincha", distrito: "San Juan de Yanac"},
  {code:  "110209", departamento: "Ica", provincia: "Chincha", distrito: "San Pedro de Huacarpana"},
  {code:  "110210", departamento: "Ica", provincia: "Chincha", distrito: "Sunampe"},
  {code:  "110211", departamento: "Ica", provincia: "Chincha", distrito: "Tambo de Mora"},
  {code:  "110301", departamento: "Ica", provincia: "Nazca", distrito: "Nazca"},
  {code:  "110302", departamento: "Ica", provincia: "Nazca", distrito: "Changuillo"},
  {code:  "110303", departamento: "Ica", provincia: "Nazca", distrito: "El Ingenio"},
  {code:  "110304", departamento: "Ica", provincia: "Nazca", distrito: "Marcona"},
  {code:  "110305", departamento: "Ica", provincia: "Nazca", distrito: "Vista Alegre"},
  {code:  "110401", departamento: "Ica", provincia: "Palpa", distrito: "Palpa"},
  {code:  "110402", departamento: "Ica", provincia: "Palpa", distrito: "Llipata"},
  {code:  "110403", departamento: "Ica", provincia: "Palpa", distrito: "Rio Grande"},
  {code:  "110404", departamento: "Ica", provincia: "Palpa", distrito: "Santa Cruz"},
  {code:  "110405", departamento: "Ica", provincia: "Palpa", distrito: "Tibillo"},
  {code:  "110501", departamento: "Ica", provincia: "Pisco", distrito: "Pisco"},
  {code:  "110502", departamento: "Ica", provincia: "Pisco", distrito: "Huancano"},
  {code:  "110503", departamento: "Ica", provincia: "Pisco", distrito: "Humay"},
  {code:  "110504", departamento: "Ica", provincia: "Pisco", distrito: "Independencia"},
  {code:  "110505", departamento: "Ica", provincia: "Pisco", distrito: "Paracas"},
  {code:  "110506", departamento: "Ica", provincia: "Pisco", distrito: "San Andres"},
  {code:  "110507", departamento: "Ica", provincia: "Pisco", distrito: "San Clemente"},
  {code:  "110508", departamento: "Ica", provincia: "Pisco", distrito: "Tupac Amaru Inca"},
  {code:  "120101", departamento: "Junin", provincia: "Huancayo", distrito: "Huancayo"},
  {code:  "120104", departamento: "Junin", provincia: "Huancayo", distrito: "Carhuacallanga"},
  {code:  "120105", departamento: "Junin", provincia: "Huancayo", distrito: "Chacapampa"},
  {code:  "120106", departamento: "Junin", provincia: "Huancayo", distrito: "Chicche"},
  {code:  "120107", departamento: "Junin", provincia: "Huancayo", distrito: "Chilca"},
  {code:  "120108", departamento: "Junin", provincia: "Huancayo", distrito: "Chongos Alto"},
  {code:  "120111", departamento: "Junin", provincia: "Huancayo", distrito: "Chupuro"},
  {code:  "120112", departamento: "Junin", provincia: "Huancayo", distrito: "Colca"},
  {code:  "120113", departamento: "Junin", provincia: "Huancayo", distrito: "Cullhuas"},
  {code:  "120114", departamento: "Junin", provincia: "Huancayo", distrito: "El Tambo"},
  {code:  "120116", departamento: "Junin", provincia: "Huancayo", distrito: "Huacrapuquio"},
  {code:  "120117", departamento: "Junin", provincia: "Huancayo", distrito: "Hualhuas"},
  {code:  "120119", departamento: "Junin", provincia: "Huancayo", distrito: "Huancan"},
  {code:  "120120", departamento: "Junin", provincia: "Huancayo", distrito: "Huasicancha"},
  {code:  "120121", departamento: "Junin", provincia: "Huancayo", distrito: "Huayucachi"},
  {code:  "120122", departamento: "Junin", provincia: "Huancayo", distrito: "Ingenio"},
  {code:  "120124", departamento: "Junin", provincia: "Huancayo", distrito: "Pariahuanca"},
  {code:  "120125", departamento: "Junin", provincia: "Huancayo", distrito: "Pilcomayo"},
  {code:  "120126", departamento: "Junin", provincia: "Huancayo", distrito: "Pucara"},
  {code:  "120127", departamento: "Junin", provincia: "Huancayo", distrito: "Quichuay"},
  {code:  "120128", departamento: "Junin", provincia: "Huancayo", distrito: "Quilcas"},
  {code:  "120129", departamento: "Junin", provincia: "Huancayo", distrito: "San Agustin"},
  {code:  "120130", departamento: "Junin", provincia: "Huancayo", distrito: "San Jeronimo de Tunan"},
  {code:  "120132", departamento: "Junin", provincia: "Huancayo", distrito: "Saño"},
  {code:  "120133", departamento: "Junin", provincia: "Huancayo", distrito: "Sapallanga"},
  {code:  "120134", departamento: "Junin", provincia: "Huancayo", distrito: "Sicaya"},
  {code:  "120135", departamento: "Junin", provincia: "Huancayo", distrito: "Santo Domingo de Acobamba"},
  {code:  "120136", departamento: "Junin", provincia: "Huancayo", distrito: "Viques"},
  {code:  "120201", departamento: "Junin", provincia: "Concepcion", distrito: "Concepcion"},
  {code:  "120202", departamento: "Junin", provincia: "Concepcion", distrito: "Aco"},
  {code:  "120203", departamento: "Junin", provincia: "Concepcion", distrito: "Andamarca"},
  {code:  "120204", departamento: "Junin", provincia: "Concepcion", distrito: "Chambara"},
  {code:  "120205", departamento: "Junin", provincia: "Concepcion", distrito: "Cochas"},
  {code:  "120206", departamento: "Junin", provincia: "Concepcion", distrito: "Comas"},
  {code:  "120207", departamento: "Junin", provincia: "Concepcion", distrito: "Heroinas Toledo"},
  {code:  "120208", departamento: "Junin", provincia: "Concepcion", distrito: "Manzanares"},
  {code:  "120209", departamento: "Junin", provincia: "Concepcion", distrito: "Mariscal Castilla"},
  {code:  "120210", departamento: "Junin", provincia: "Concepcion", distrito: "Matahuasi"},
  {code:  "120211", departamento: "Junin", provincia: "Concepcion", distrito: "Mito"},
  {code:  "120212", departamento: "Junin", provincia: "Concepcion", distrito: "Nueve de Julio"},
  {code:  "120213", departamento: "Junin", provincia: "Concepcion", distrito: "Orcotuna"},
  {code:  "120214", departamento: "Junin", provincia: "Concepcion", distrito: "San Jose de Quero"},
  {code:  "120215", departamento: "Junin", provincia: "Concepcion", distrito: "Santa Rosa de Ocopa"},
  {code:  "120301", departamento: "Junin", provincia: "Chanchamayo", distrito: "Chanchamayo"},
  {code:  "120302", departamento: "Junin", provincia: "Chanchamayo", distrito: "Perene"},
  {code:  "120303", departamento: "Junin", provincia: "Chanchamayo", distrito: "Pichanaqui"},
  {code:  "120304", departamento: "Junin", provincia: "Chanchamayo", distrito: "San Luis de Shuaro"},
  {code:  "120305", departamento: "Junin", provincia: "Chanchamayo", distrito: "San Ramon"},
  {code:  "120306", departamento: "Junin", provincia: "Chanchamayo", distrito: "Vitoc"},
  {code:  "120401", departamento: "Junin", provincia: "Jauja", distrito: "Jauja"},
  {code:  "120402", departamento: "Junin", provincia: "Jauja", distrito: "Acolla"},
  {code:  "120403", departamento: "Junin", provincia: "Jauja", distrito: "Apata"},
  {code:  "120404", departamento: "Junin", provincia: "Jauja", distrito: "Ataura"},
  {code:  "120405", departamento: "Junin", provincia: "Jauja", distrito: "Canchayllo"},
  {code:  "120406", departamento: "Junin", provincia: "Jauja", distrito: "Curicaca"},
  {code:  "120407", departamento: "Junin", provincia: "Jauja", distrito: "El Mantaro"},
  {code:  "120408", departamento: "Junin", provincia: "Jauja", distrito: "Huamali"},
  {code:  "120409", departamento: "Junin", provincia: "Jauja", distrito: "Huaripampa"},
  {code:  "120410", departamento: "Junin", provincia: "Jauja", distrito: "Huertas"},
  {code:  "120411", departamento: "Junin", provincia: "Jauja", distrito: "Janjaillo"},
  {code:  "120412", departamento: "Junin", provincia: "Jauja", distrito: "Julcan"},
  {code:  "120413", departamento: "Junin", provincia: "Jauja", distrito: "Leonor Ordoñez"},
  {code:  "120414", departamento: "Junin", provincia: "Jauja", distrito: "Llocllapampa"},
  {code:  "120415", departamento: "Junin", provincia: "Jauja", distrito: "Marco"},
  {code:  "120416", departamento: "Junin", provincia: "Jauja", distrito: "Masma"},
  {code:  "120417", departamento: "Junin", provincia: "Jauja", distrito: "Masma Chicche"},
  {code:  "120418", departamento: "Junin", provincia: "Jauja", distrito: "Molinos"},
  {code:  "120419", departamento: "Junin", provincia: "Jauja", distrito: "Monobamba"},
  {code:  "120420", departamento: "Junin", provincia: "Jauja", distrito: "Muqui"},
  {code:  "120421", departamento: "Junin", provincia: "Jauja", distrito: "Muquiyauyo"},
  {code:  "120422", departamento: "Junin", provincia: "Jauja", distrito: "Paca"},
  {code:  "120423", departamento: "Junin", provincia: "Jauja", distrito: "Paccha"},
  {code:  "120424", departamento: "Junin", provincia: "Jauja", distrito: "Pancan"},
  {code:  "120425", departamento: "Junin", provincia: "Jauja", distrito: "Parco"},
  {code:  "120426", departamento: "Junin", provincia: "Jauja", distrito: "Pomacancha"},
  {code:  "120427", departamento: "Junin", provincia: "Jauja", distrito: "Ricran"},
  {code:  "120428", departamento: "Junin", provincia: "Jauja", distrito: "San Lorenzo"},
  {code:  "120429", departamento: "Junin", provincia: "Jauja", distrito: "San Pedro de Chunan"},
  {code:  "120430", departamento: "Junin", provincia: "Jauja", distrito: "Sausa"},
  {code:  "120431", departamento: "Junin", provincia: "Jauja", distrito: "Sincos"},
  {code:  "120432", departamento: "Junin", provincia: "Jauja", distrito: "Tunan Marca"},
  {code:  "120433", departamento: "Junin", provincia: "Jauja", distrito: "Yauli"},
  {code:  "120434", departamento: "Junin", provincia: "Jauja", distrito: "Yauyos"},
  {code:  "120501", departamento: "Junin", provincia: "Junin", distrito: "Junin"},
  {code:  "120502", departamento: "Junin", provincia: "Junin", distrito: "Carhuamayo"},
  {code:  "120503", departamento: "Junin", provincia: "Junin", distrito: "Ondores"},
  {code:  "120504", departamento: "Junin", provincia: "Junin", distrito: "Ulcumayo"},
  {code:  "120601", departamento: "Junin", provincia: "Satipo", distrito: "Satipo"},
  {code:  "120602", departamento: "Junin", provincia: "Satipo", distrito: "Coviriali"},
  {code:  "120603", departamento: "Junin", provincia: "Satipo", distrito: "Llaylla"},
  {code:  "120604", departamento: "Junin", provincia: "Satipo", distrito: "Mazamari"},
  {code:  "120605", departamento: "Junin", provincia: "Satipo", distrito: "Pampa Hermosa"},
  {code:  "120606", departamento: "Junin", provincia: "Satipo", distrito: "Pangoa"},
  {code:  "120607", departamento: "Junin", provincia: "Satipo", distrito: "Rio Negro"},
  {code:  "120608", departamento: "Junin", provincia: "Satipo", distrito: "Rio Tambo"},
  {code:  "120609", departamento: "Junin", provincia: "Satipo", distrito: "Vizcatán del Ene"},
  {code:  "120701", departamento: "Junin", provincia: "Tarma", distrito: "Tarma"},
  {code:  "120702", departamento: "Junin", provincia: "Tarma", distrito: "Acobamba"},
  {code:  "120703", departamento: "Junin", provincia: "Tarma", distrito: "Huaricolca"},
  {code:  "120704", departamento: "Junin", provincia: "Tarma", distrito: "Huasahuasi"},
  {code:  "120705", departamento: "Junin", provincia: "Tarma", distrito: "La Union"},
  {code:  "120706", departamento: "Junin", provincia: "Tarma", distrito: "Palca"},
  {code:  "120707", departamento: "Junin", provincia: "Tarma", distrito: "Palcamayo"},
  {code:  "120708", departamento: "Junin", provincia: "Tarma", distrito: "San Pedro de Cajas"},
  {code:  "120709", departamento: "Junin", provincia: "Tarma", distrito: "Tapo"},
  {code:  "120801", departamento: "Junin", provincia: "Yauli", distrito: "La Oroya"},
  {code:  "120802", departamento: "Junin", provincia: "Yauli", distrito: "Chacapalpa"},
  {code:  "120803", departamento: "Junin", provincia: "Yauli", distrito: "Huay-Huay"},
  {code:  "120804", departamento: "Junin", provincia: "Yauli", distrito: "Marcapomacocha"},
  {code:  "120805", departamento: "Junin", provincia: "Yauli", distrito: "Morococha"},
  {code:  "120806", departamento: "Junin", provincia: "Yauli", distrito: "Paccha"},
  {code:  "120807", departamento: "Junin", provincia: "Yauli", distrito: "Santa Barbara de Carhuacayan"},
  {code:  "120808", departamento: "Junin", provincia: "Yauli", distrito: "Santa Rosa de Sacco"},
  {code:  "120809", departamento: "Junin", provincia: "Yauli", distrito: "Suitucancha"},
  {code:  "120810", departamento: "Junin", provincia: "Yauli", distrito: "Yauli"},
  {code:  "120901", departamento: "Junin", provincia: "Chupaca", distrito: "Chupaca"},
  {code:  "120902", departamento: "Junin", provincia: "Chupaca", distrito: "Ahuac"},
  {code:  "120903", departamento: "Junin", provincia: "Chupaca", distrito: "Chongos Bajo"},
  {code:  "120904", departamento: "Junin", provincia: "Chupaca", distrito: "Huachac"},
  {code:  "120905", departamento: "Junin", provincia: "Chupaca", distrito: "Huamancaca Chico"},
  {code:  "120906", departamento: "Junin", provincia: "Chupaca", distrito: "San Juan de Yscos"},
  {code:  "120907", departamento: "Junin", provincia: "Chupaca", distrito: "San Juan de Jarpa"},
  {code:  "120908", departamento: "Junin", provincia: "Chupaca", distrito: "Tres de Diciembre"},
  {code:  "120909", departamento: "Junin", provincia: "Chupaca", distrito: "Yanacancha"},
  {code:  "130101", departamento: "La Libertad", provincia: "Trujillo", distrito: "Trujillo"},
  {code:  "130102", departamento: "La Libertad", provincia: "Trujillo", distrito: "El Porvenir"},
  {code:  "130103", departamento: "La Libertad", provincia: "Trujillo", distrito: "Florencia de Mora"},
  {code:  "130104", departamento: "La Libertad", provincia: "Trujillo", distrito: "Huanchaco"},
  {code:  "130105", departamento: "La Libertad", provincia: "Trujillo", distrito: "La Esperanza"},
  {code:  "130106", departamento: "La Libertad", provincia: "Trujillo", distrito: "Laredo"},
  {code:  "130107", departamento: "La Libertad", provincia: "Trujillo", distrito: "Moche"},
  {code:  "130108", departamento: "La Libertad", provincia: "Trujillo", distrito: "Poroto"},
  {code:  "130109", departamento: "La Libertad", provincia: "Trujillo", distrito: "Salaverry"},
  {code:  "130110", departamento: "La Libertad", provincia: "Trujillo", distrito: "Simbal"},
  {code:  "130111", departamento: "La Libertad", provincia: "Trujillo", distrito: "Victor Larco Herrera"},
  {code:  "130201", departamento: "La Libertad", provincia: "Ascope", distrito: "Ascope"},
  {code:  "130202", departamento: "La Libertad", provincia: "Ascope", distrito: "Chicama"},
  {code:  "130203", departamento: "La Libertad", provincia: "Ascope", distrito: "Chocope"},
  {code:  "130204", departamento: "La Libertad", provincia: "Ascope", distrito: "Magdalena de Cao"},
  {code:  "130205", departamento: "La Libertad", provincia: "Ascope", distrito: "Paijan"},
  {code:  "130206", departamento: "La Libertad", provincia: "Ascope", distrito: "Razuri"},
  {code:  "130207", departamento: "La Libertad", provincia: "Ascope", distrito: "Santiago de Cao"},
  {code:  "130208", departamento: "La Libertad", provincia: "Ascope", distrito: "Casa Grande"},
  {code:  "130301", departamento: "La Libertad", provincia: "Bolivar", distrito: "Bolivar"},
  {code:  "130302", departamento: "La Libertad", provincia: "Bolivar", distrito: "Bambamarca"},
  {code:  "130303", departamento: "La Libertad", provincia: "Bolivar", distrito: "Condormarca"},
  {code:  "130304", departamento: "La Libertad", provincia: "Bolivar", distrito: "Longotea"},
  {code:  "130305", departamento: "La Libertad", provincia: "Bolivar", distrito: "Uchumarca"},
  {code:  "130306", departamento: "La Libertad", provincia: "Bolivar", distrito: "Ucuncha"},
  {code:  "130401", departamento: "La Libertad", provincia: "Chepen", distrito: "Chepen"},
  {code:  "130402", departamento: "La Libertad", provincia: "Chepen", distrito: "Pacanga"},
  {code:  "130403", departamento: "La Libertad", provincia: "Chepen", distrito: "Pueblo Nuevo"},
  {code:  "130501", departamento: "La Libertad", provincia: "Julcan", distrito: "Julcan"},
  {code:  "130502", departamento: "La Libertad", provincia: "Julcan", distrito: "Calamarca"},
  {code:  "130503", departamento: "La Libertad", provincia: "Julcan", distrito: "Carabamba"},
  {code:  "130504", departamento: "La Libertad", provincia: "Julcan", distrito: "Huaso"},
  {code:  "130601", departamento: "La Libertad", provincia: "Otuzco", distrito: "Otuzco"},
  {code:  "130602", departamento: "La Libertad", provincia: "Otuzco", distrito: "Agallpampa"},
  {code:  "130604", departamento: "La Libertad", provincia: "Otuzco", distrito: "Charat"},
  {code:  "130605", departamento: "La Libertad", provincia: "Otuzco", distrito: "Huaranchal"},
  {code:  "130606", departamento: "La Libertad", provincia: "Otuzco", distrito: "La Cuesta"},
  {code:  "130608", departamento: "La Libertad", provincia: "Otuzco", distrito: "Mache"},
  {code:  "130610", departamento: "La Libertad", provincia: "Otuzco", distrito: "Paranday"},
  {code:  "130611", departamento: "La Libertad", provincia: "Otuzco", distrito: "Salpo"},
  {code:  "130613", departamento: "La Libertad", provincia: "Otuzco", distrito: "Sinsicap"},
  {code:  "130614", departamento: "La Libertad", provincia: "Otuzco", distrito: "Usquil"},
  {code:  "130701", departamento: "La Libertad", provincia: "Pacasmayo", distrito: "San Pedro de Lloc"},
  {code:  "130702", departamento: "La Libertad", provincia: "Pacasmayo", distrito: "Guadalupe"},
  {code:  "130703", departamento: "La Libertad", provincia: "Pacasmayo", distrito: "Jequetepeque"},
  {code:  "130704", departamento: "La Libertad", provincia: "Pacasmayo", distrito: "Pacasmayo"},
  {code:  "130705", departamento: "La Libertad", provincia: "Pacasmayo", distrito: "San Jose"},
  {code:  "130801", departamento: "La Libertad", provincia: "Pataz", distrito: "Tayabamba"},
  {code:  "130802", departamento: "La Libertad", provincia: "Pataz", distrito: "Buldibuyo"},
  {code:  "130803", departamento: "La Libertad", provincia: "Pataz", distrito: "Chillia"},
  {code:  "130804", departamento: "La Libertad", provincia: "Pataz", distrito: "Huancaspata"},
  {code:  "130805", departamento: "La Libertad", provincia: "Pataz", distrito: "Huaylillas"},
  {code:  "130806", departamento: "La Libertad", provincia: "Pataz", distrito: "Huayo"},
  {code:  "130807", departamento: "La Libertad", provincia: "Pataz", distrito: "Ongon"},
  {code:  "130808", departamento: "La Libertad", provincia: "Pataz", distrito: "Parcoy"},
  {code:  "130809", departamento: "La Libertad", provincia: "Pataz", distrito: "Pataz"},
  {code:  "130810", departamento: "La Libertad", provincia: "Pataz", distrito: "Pias"},
  {code:  "130811", departamento: "La Libertad", provincia: "Pataz", distrito: "Santiago de Challas"},
  {code:  "130812", departamento: "La Libertad", provincia: "Pataz", distrito: "Taurija"},
  {code:  "130813", departamento: "La Libertad", provincia: "Pataz", distrito: "Urpay"},
  {code:  "130901", departamento: "La Libertad", provincia: "Sanchez Carrion", distrito: "Huamachuco"},
  {code:  "130902", departamento: "La Libertad", provincia: "Sanchez Carrion", distrito: "Chugay"},
  {code:  "130903", departamento: "La Libertad", provincia: "Sanchez Carrion", distrito: "Cochorco"},
  {code:  "130904", departamento: "La Libertad", provincia: "Sanchez Carrion", distrito: "Curgos"},
  {code:  "130905", departamento: "La Libertad", provincia: "Sanchez Carrion", distrito: "Marcabal"},
  {code:  "130906", departamento: "La Libertad", provincia: "Sanchez Carrion", distrito: "Sanagoran"},
  {code:  "130907", departamento: "La Libertad", provincia: "Sanchez Carrion", distrito: "Sarin"},
  {code:  "130908", departamento: "La Libertad", provincia: "Sanchez Carrion", distrito: "Sartimbamba"},
  {code:  "131001", departamento: "La Libertad", provincia: "Santiago de Chuco", distrito: "Santiago de Chuco"},
  {code:  "131002", departamento: "La Libertad", provincia: "Santiago de Chuco", distrito: "Angasmarca"},
  {code:  "131003", departamento: "La Libertad", provincia: "Santiago de Chuco", distrito: "Cachicadan"},
  {code:  "131004", departamento: "La Libertad", provincia: "Santiago de Chuco", distrito: "Mollebamba"},
  {code:  "131005", departamento: "La Libertad", provincia: "Santiago de Chuco", distrito: "Mollepata"},
  {code:  "131006", departamento: "La Libertad", provincia: "Santiago de Chuco", distrito: "Quiruvilca"},
  {code:  "131007", departamento: "La Libertad", provincia: "Santiago de Chuco", distrito: "Santa Cruz de Chuca"},
  {code:  "131008", departamento: "La Libertad", provincia: "Santiago de Chuco", distrito: "Sitabamba"},
  {code:  "131101", departamento: "La Libertad", provincia: "Gran Chimu", distrito: "Cascas"},
  {code:  "131102", departamento: "La Libertad", provincia: "Gran Chimu", distrito: "Lucma"},
  {code:  "131103", departamento: "La Libertad", provincia: "Gran Chimu", distrito: "Compin"},
  {code:  "131104", departamento: "La Libertad", provincia: "Gran Chimu", distrito: "Sayapullo"},
  {code:  "131201", departamento: "La Libertad", provincia: "Viru", distrito: "Viru"},
  {code:  "131202", departamento: "La Libertad", provincia: "Viru", distrito: "Chao"},
  {code:  "131203", departamento: "La Libertad", provincia: "Viru", distrito: "Guadalupito"},
  {code:  "140101", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Chiclayo"},
  {code:  "140102", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Chongoyape"},
  {code:  "140103", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Eten"},
  {code:  "140104", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Eten Puerto"},
  {code:  "140105", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Jose Leonardo Ortiz"},
  {code:  "140106", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "La Victoria"},
  {code:  "140107", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Lagunas"},
  {code:  "140108", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Monsefu"},
  {code:  "140109", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Nueva Arica"},
  {code:  "140110", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Oyotun"},
  {code:  "140111", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Picsi"},
  {code:  "140112", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Pimentel"},
  {code:  "140113", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Reque"},
  {code:  "140114", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Santa Rosa"},
  {code:  "140115", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Saña"},
  {code:  "140116", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Cayalti"},
  {code:  "140117", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Patapo"},
  {code:  "140118", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Pomalca"},
  {code:  "140119", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Pucala"},
  {code:  "140120", departamento: "Lambayeque", provincia: "Chiclayo", distrito: "Tuman"},
  {code:  "140201", departamento: "Lambayeque", provincia: "Ferreñafe", distrito: "Ferreñafe"},
  {code:  "140202", departamento: "Lambayeque", provincia: "Ferreñafe", distrito: "Cañaris"},
  {code:  "140203", departamento: "Lambayeque", provincia: "Ferreñafe", distrito: "Incahuasi"},
  {code:  "140204", departamento: "Lambayeque", provincia: "Ferreñafe", distrito: "Manuel Antonio Mesones Muro"},
  {code:  "140205", departamento: "Lambayeque", provincia: "Ferreñafe", distrito: "Pitipo"},
  {code:  "140206", departamento: "Lambayeque", provincia: "Ferreñafe", distrito: "Pueblo Nuevo"},
  {code:  "140301", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Lambayeque"},
  {code:  "140302", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Chochope"},
  {code:  "140303", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Illimo"},
  {code:  "140304", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Jayanca"},
  {code:  "140305", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Mochumi"},
  {code:  "140306", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Morrope"},
  {code:  "140307", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Motupe"},
  {code:  "140308", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Olmos"},
  {code:  "140309", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Pacora"},
  {code:  "140310", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Salas"},
  {code:  "140311", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "San Jose"},
  {code:  "140312", departamento: "Lambayeque", provincia: "Lambayeque", distrito: "Tucume"},
  {code:  "150101", departamento: "Lima", provincia: "Lima", distrito: "Lima"},
  {code:  "150102", departamento: "Lima", provincia: "Lima", distrito: "Ancon"},
  {code:  "150103", departamento: "Lima", provincia: "Lima", distrito: "Ate"},
  {code:  "150104", departamento: "Lima", provincia: "Lima", distrito: "Barranco"},
  {code:  "150105", departamento: "Lima", provincia: "Lima", distrito: "Breña"},
  {code:  "150106", departamento: "Lima", provincia: "Lima", distrito: "Carabayllo"},
  {code:  "150107", departamento: "Lima", provincia: "Lima", distrito: "Chaclacayo"},
  {code:  "150108", departamento: "Lima", provincia: "Lima", distrito: "Chorrillos"},
  {code:  "150109", departamento: "Lima", provincia: "Lima", distrito: "Cieneguilla"},
  {code:  "150110", departamento: "Lima", provincia: "Lima", distrito: "Comas"},
  {code:  "150111", departamento: "Lima", provincia: "Lima", distrito: "El Agustino"},
  {code:  "150112", departamento: "Lima", provincia: "Lima", distrito: "Independencia"},
  {code:  "150113", departamento: "Lima", provincia: "Lima", distrito: "Jesus Maria"},
  {code:  "150114", departamento: "Lima", provincia: "Lima", distrito: "La Molina"},
  {code:  "150115", departamento: "Lima", provincia: "Lima", distrito: "La Victoria"},
  {code:  "150116", departamento: "Lima", provincia: "Lima", distrito: "Lince"},
  {code:  "150117", departamento: "Lima", provincia: "Lima", distrito: "Los Olivos"},
  {code:  "150118", departamento: "Lima", provincia: "Lima", distrito: "Lurigancho"},
  {code:  "150119", departamento: "Lima", provincia: "Lima", distrito: "Lurin"},
  {code:  "150120", departamento: "Lima", provincia: "Lima", distrito: "Magdalena del Mar"},
  {code:  "150121", departamento: "Lima", provincia: "Lima", distrito: "Pueblo Libre"},
  {code:  "150122", departamento: "Lima", provincia: "Lima", distrito: "Miraflores"},
  {code:  "150123", departamento: "Lima", provincia: "Lima", distrito: "Pachacamac"},
  {code:  "150124", departamento: "Lima", provincia: "Lima", distrito: "Pucusana"},
  {code:  "150125", departamento: "Lima", provincia: "Lima", distrito: "Puente Piedra"},
  {code:  "150126", departamento: "Lima", provincia: "Lima", distrito: "Punta Hermosa"},
  {code:  "150127", departamento: "Lima", provincia: "Lima", distrito: "Punta Negra"},
  {code:  "150128", departamento: "Lima", provincia: "Lima", distrito: "Rimac"},
  {code:  "150129", departamento: "Lima", provincia: "Lima", distrito: "San Bartolo"},
  {code:  "150130", departamento: "Lima", provincia: "Lima", distrito: "San Borja"},
  {code:  "150131", departamento: "Lima", provincia: "Lima", distrito: "San Isidro"},
  {code:  "150132", departamento: "Lima", provincia: "Lima", distrito: "San Juan de Lurigancho"},
  {code:  "150133", departamento: "Lima", provincia: "Lima", distrito: "San Juan de Miraflores"},
  {code:  "150134", departamento: "Lima", provincia: "Lima", distrito: "San Luis"},
  {code:  "150135", departamento: "Lima", provincia: "Lima", distrito: "San Martin de Porres"},
  {code:  "150136", departamento: "Lima", provincia: "Lima", distrito: "San Miguel"},
  {code:  "150137", departamento: "Lima", provincia: "Lima", distrito: "Santa Anita"},
  {code:  "150138", departamento: "Lima", provincia: "Lima", distrito: "Santa Maria del Mar"},
  {code:  "150139", departamento: "Lima", provincia: "Lima", distrito: "Santa Rosa"},
  {code:  "150140", departamento: "Lima", provincia: "Lima", distrito: "Santiago de Surco"},
  {code:  "150141", departamento: "Lima", provincia: "Lima", distrito: "Surquillo"},
  {code:  "150142", departamento: "Lima", provincia: "Lima", distrito: "Villa El Salvador"},
  {code:  "150143", departamento: "Lima", provincia: "Lima", distrito: "Villa Maria del Triunfo"},
  {code:  "150201", departamento: "Lima", provincia: "Barranca", distrito: "Barranca"},
  {code:  "150202", departamento: "Lima", provincia: "Barranca", distrito: "Paramonga"},
  {code:  "150203", departamento: "Lima", provincia: "Barranca", distrito: "Pativilca"},
  {code:  "150204", departamento: "Lima", provincia: "Barranca", distrito: "Supe"},
  {code:  "150205", departamento: "Lima", provincia: "Barranca", distrito: "Supe Puerto"},
  {code:  "150301", departamento: "Lima", provincia: "Cajatambo", distrito: "Cajatambo"},
  {code:  "150302", departamento: "Lima", provincia: "Cajatambo", distrito: "Copa"},
  {code:  "150303", departamento: "Lima", provincia: "Cajatambo", distrito: "Gorgor"},
  {code:  "150304", departamento: "Lima", provincia: "Cajatambo", distrito: "Huancapon"},
  {code:  "150305", departamento: "Lima", provincia: "Cajatambo", distrito: "Manas"},
  {code:  "150401", departamento: "Lima", provincia: "Canta", distrito: "Canta"},
  {code:  "150402", departamento: "Lima", provincia: "Canta", distrito: "Arahuay"},
  {code:  "150403", departamento: "Lima", provincia: "Canta", distrito: "Huamantanga"},
  {code:  "150404", departamento: "Lima", provincia: "Canta", distrito: "Huaros"},
  {code:  "150405", departamento: "Lima", provincia: "Canta", distrito: "Lachaqui"},
  {code:  "150406", departamento: "Lima", provincia: "Canta", distrito: "San Buenaventura"},
  {code:  "150407", departamento: "Lima", provincia: "Canta", distrito: "Santa Rosa de Quives"},
  {code:  "150501", departamento: "Lima", provincia: "Cañete", distrito: "San Vicente de Cañete"},
  {code:  "150502", departamento: "Lima", provincia: "Cañete", distrito: "Asia"},
  {code:  "150503", departamento: "Lima", provincia: "Cañete", distrito: "Calango"},
  {code:  "150504", departamento: "Lima", provincia: "Cañete", distrito: "Cerro Azul"},
  {code:  "150505", departamento: "Lima", provincia: "Cañete", distrito: "Chilca"},
  {code:  "150506", departamento: "Lima", provincia: "Cañete", distrito: "Coayllo"},
  {code:  "150507", departamento: "Lima", provincia: "Cañete", distrito: "Imperial"},
  {code:  "150508", departamento: "Lima", provincia: "Cañete", distrito: "Lunahuana"},
  {code:  "150509", departamento: "Lima", provincia: "Cañete", distrito: "Mala"},
  {code:  "150510", departamento: "Lima", provincia: "Cañete", distrito: "Nuevo Imperial"},
  {code:  "150511", departamento: "Lima", provincia: "Cañete", distrito: "Pacaran"},
  {code:  "150512", departamento: "Lima", provincia: "Cañete", distrito: "Quilmana"},
  {code:  "150513", departamento: "Lima", provincia: "Cañete", distrito: "San Antonio"},
  {code:  "150514", departamento: "Lima", provincia: "Cañete", distrito: "San Luis"},
  {code:  "150515", departamento: "Lima", provincia: "Cañete", distrito: "Santa Cruz de Flores"},
  {code:  "150516", departamento: "Lima", provincia: "Cañete", distrito: "Zuñiga"},
  {code:  "150601", departamento: "Lima", provincia: "Huaral", distrito: "Huaral"},
  {code:  "150602", departamento: "Lima", provincia: "Huaral", distrito: "Atavillos Alto"},
  {code:  "150603", departamento: "Lima", provincia: "Huaral", distrito: "Atavillos Bajo"},
  {code:  "150604", departamento: "Lima", provincia: "Huaral", distrito: "Aucallama"},
  {code:  "150605", departamento: "Lima", provincia: "Huaral", distrito: "Chancay"},
  {code:  "150606", departamento: "Lima", provincia: "Huaral", distrito: "Ihuari"},
  {code:  "150607", departamento: "Lima", provincia: "Huaral", distrito: "Lampian"},
  {code:  "150608", departamento: "Lima", provincia: "Huaral", distrito: "Pacaraos"},
  {code:  "150609", departamento: "Lima", provincia: "Huaral", distrito: "San Miguel de Acos"},
  {code:  "150610", departamento: "Lima", provincia: "Huaral", distrito: "Santa Cruz de Andamarca"},
  {code:  "150611", departamento: "Lima", provincia: "Huaral", distrito: "Sumbilca"},
  {code:  "150612", departamento: "Lima", provincia: "Huaral", distrito: "Veintisiete de Noviembre"},
  {code:  "150701", departamento: "Lima", provincia: "Huarochiri", distrito: "Matucana"},
  {code:  "150702", departamento: "Lima", provincia: "Huarochiri", distrito: "Antioquia"},
  {code:  "150703", departamento: "Lima", provincia: "Huarochiri", distrito: "Callahuanca"},
  {code:  "150704", departamento: "Lima", provincia: "Huarochiri", distrito: "Carampoma"},
  {code:  "150705", departamento: "Lima", provincia: "Huarochiri", distrito: "Chicla"},
  {code:  "150706", departamento: "Lima", provincia: "Huarochiri", distrito: "Cuenca"},
  {code:  "150707", departamento: "Lima", provincia: "Huarochiri", distrito: "Huachupampa"},
  {code:  "150708", departamento: "Lima", provincia: "Huarochiri", distrito: "Huanza"},
  {code:  "150709", departamento: "Lima", provincia: "Huarochiri", distrito: "Huarochiri"},
  {code:  "150710", departamento: "Lima", provincia: "Huarochiri", distrito: "Lahuaytambo"},
  {code:  "150711", departamento: "Lima", provincia: "Huarochiri", distrito: "Langa"},
  {code:  "150712", departamento: "Lima", provincia: "Huarochiri", distrito: "Laraos"},
  {code:  "150713", departamento: "Lima", provincia: "Huarochiri", distrito: "Mariatana"},
  {code:  "150714", departamento: "Lima", provincia: "Huarochiri", distrito: "Ricardo Palma"},
  {code:  "150715", departamento: "Lima", provincia: "Huarochiri", distrito: "San Andres de Tupicocha"},
  {code:  "150716", departamento: "Lima", provincia: "Huarochiri", distrito: "San Antonio"},
  {code:  "150717", departamento: "Lima", provincia: "Huarochiri", distrito: "San Bartolome"},
  {code:  "150718", departamento: "Lima", provincia: "Huarochiri", distrito: "San Damian"},
  {code:  "150719", departamento: "Lima", provincia: "Huarochiri", distrito: "San Juan de Iris"},
  {code:  "150720", departamento: "Lima", provincia: "Huarochiri", distrito: "San Juan de Tantaranche"},
  {code:  "150721", departamento: "Lima", provincia: "Huarochiri", distrito: "San Lorenzo de Quinti"},
  {code:  "150722", departamento: "Lima", provincia: "Huarochiri", distrito: "San Mateo"},
  {code:  "150723", departamento: "Lima", provincia: "Huarochiri", distrito: "San Mateo de Otao"},
  {code:  "150724", departamento: "Lima", provincia: "Huarochiri", distrito: "San Pedro de Casta"},
  {code:  "150725", departamento: "Lima", provincia: "Huarochiri", distrito: "San Pedro de Huancayre"},
  {code:  "150726", departamento: "Lima", provincia: "Huarochiri", distrito: "Sangallaya"},
  {code:  "150727", departamento: "Lima", provincia: "Huarochiri", distrito: "Santa Cruz de Cocachacra"},
  {code:  "150728", departamento: "Lima", provincia: "Huarochiri", distrito: "Santa Eulalia"},
  {code:  "150729", departamento: "Lima", provincia: "Huarochiri", distrito: "Santiago de Anchucaya"},
  {code:  "150730", departamento: "Lima", provincia: "Huarochiri", distrito: "Santiago de Tuna"},
  {code:  "150731", departamento: "Lima", provincia: "Huarochiri", distrito: "Santo Domingo de los Olleros"},
  {code:  "150732", departamento: "Lima", provincia: "Huarochiri", distrito: "Surco"},
  {code:  "150801", departamento: "Lima", provincia: "Huaura", distrito: "Huacho"},
  {code:  "150802", departamento: "Lima", provincia: "Huaura", distrito: "Ambar"},
  {code:  "150803", departamento: "Lima", provincia: "Huaura", distrito: "Caleta de Carquin"},
  {code:  "150804", departamento: "Lima", provincia: "Huaura", distrito: "Checras"},
  {code:  "150805", departamento: "Lima", provincia: "Huaura", distrito: "Hualmay"},
  {code:  "150806", departamento: "Lima", provincia: "Huaura", distrito: "Huaura"},
  {code:  "150807", departamento: "Lima", provincia: "Huaura", distrito: "Leoncio Prado"},
  {code:  "150808", departamento: "Lima", provincia: "Huaura", distrito: "Paccho"},
  {code:  "150809", departamento: "Lima", provincia: "Huaura", distrito: "Santa Leonor"},
  {code:  "150810", departamento: "Lima", provincia: "Huaura", distrito: "Santa Maria"},
  {code:  "150811", departamento: "Lima", provincia: "Huaura", distrito: "Sayan"},
  {code:  "150812", departamento: "Lima", provincia: "Huaura", distrito: "Vegueta"},
  {code:  "150901", departamento: "Lima", provincia: "Oyon", distrito: "Oyon"},
  {code:  "150902", departamento: "Lima", provincia: "Oyon", distrito: "Andajes"},
  {code:  "150903", departamento: "Lima", provincia: "Oyon", distrito: "Caujul"},
  {code:  "150904", departamento: "Lima", provincia: "Oyon", distrito: "Cochamarca"},
  {code:  "150905", departamento: "Lima", provincia: "Oyon", distrito: "Navan"},
  {code:  "150906", departamento: "Lima", provincia: "Oyon", distrito: "Pachangara"},
  {code:  "151001", departamento: "Lima", provincia: "Yauyos", distrito: "Yauyos"},
  {code:  "151002", departamento: "Lima", provincia: "Yauyos", distrito: "Alis"},
  {code:  "151003", departamento: "Lima", provincia: "Yauyos", distrito: "Ayauca"},
  {code:  "151004", departamento: "Lima", provincia: "Yauyos", distrito: "Ayaviri"},
  {code:  "151005", departamento: "Lima", provincia: "Yauyos", distrito: "Azangaro"},
  {code:  "151006", departamento: "Lima", provincia: "Yauyos", distrito: "Cacra"},
  {code:  "151007", departamento: "Lima", provincia: "Yauyos", distrito: "Carania"},
  {code:  "151008", departamento: "Lima", provincia: "Yauyos", distrito: "Catahuasi"},
  {code:  "151009", departamento: "Lima", provincia: "Yauyos", distrito: "Chocos"},
  {code:  "151010", departamento: "Lima", provincia: "Yauyos", distrito: "Cochas"},
  {code:  "151011", departamento: "Lima", provincia: "Yauyos", distrito: "Colonia"},
  {code:  "151012", departamento: "Lima", provincia: "Yauyos", distrito: "Hongos"},
  {code:  "151013", departamento: "Lima", provincia: "Yauyos", distrito: "Huampara"},
  {code:  "151014", departamento: "Lima", provincia: "Yauyos", distrito: "Huancaya"},
  {code:  "151015", departamento: "Lima", provincia: "Yauyos", distrito: "Huangascar"},
  {code:  "151016", departamento: "Lima", provincia: "Yauyos", distrito: "Huantan"},
  {code:  "151017", departamento: "Lima", provincia: "Yauyos", distrito: "Huañec"},
  {code:  "151018", departamento: "Lima", provincia: "Yauyos", distrito: "Laraos"},
  {code:  "151019", departamento: "Lima", provincia: "Yauyos", distrito: "Lincha"},
  {code:  "151020", departamento: "Lima", provincia: "Yauyos", distrito: "Madean"},
  {code:  "151021", departamento: "Lima", provincia: "Yauyos", distrito: "Miraflores"},
  {code:  "151022", departamento: "Lima", provincia: "Yauyos", distrito: "Omas"},
  {code:  "151023", departamento: "Lima", provincia: "Yauyos", distrito: "Putinza"},
  {code:  "151024", departamento: "Lima", provincia: "Yauyos", distrito: "Quinches"},
  {code:  "151025", departamento: "Lima", provincia: "Yauyos", distrito: "Quinocay"},
  {code:  "151026", departamento: "Lima", provincia: "Yauyos", distrito: "San Joaquin"},
  {code:  "151027", departamento: "Lima", provincia: "Yauyos", distrito: "San Pedro de Pilas"},
  {code:  "151028", departamento: "Lima", provincia: "Yauyos", distrito: "Tanta"},
  {code:  "151029", departamento: "Lima", provincia: "Yauyos", distrito: "Tauripampa"},
  {code:  "151030", departamento: "Lima", provincia: "Yauyos", distrito: "Tomas"},
  {code:  "151031", departamento: "Lima", provincia: "Yauyos", distrito: "Tupe"},
  {code:  "151032", departamento: "Lima", provincia: "Yauyos", distrito: "Viñac"},
  {code:  "151033", departamento: "Lima", provincia: "Yauyos", distrito: "Vitis"},
  {code:  "160101", departamento: "Loreto", provincia: "Maynas", distrito: "Iquitos"},
  {code:  "160102", departamento: "Loreto", provincia: "Maynas", distrito: "Alto Nanay"},
  {code:  "160103", departamento: "Loreto", provincia: "Maynas", distrito: "Fernando Lores"},
  {code:  "160104", departamento: "Loreto", provincia: "Maynas", distrito: "Indiana"},
  {code:  "160105", departamento: "Loreto", provincia: "Maynas", distrito: "Las Amazonas"},
  {code:  "160106", departamento: "Loreto", provincia: "Maynas", distrito: "Mazan"},
  {code:  "160107", departamento: "Loreto", provincia: "Maynas", distrito: "Napo"},
  {code:  "160108", departamento: "Loreto", provincia: "Maynas", distrito: "Punchana"},
  {code:  "160110", departamento: "Loreto", provincia: "Maynas", distrito: "Torres Causana"},
  {code:  "160112", departamento: "Loreto", provincia: "Maynas", distrito: "Belen"},
  {code:  "160113", departamento: "Loreto", provincia: "Maynas", distrito: "San Juan Bautista"},
  {code:  "160201", departamento: "Loreto", provincia: "Alto Amazonas", distrito: "Yurimaguas"},
  {code:  "160202", departamento: "Loreto", provincia: "Alto Amazonas", distrito: "Balsapuerto"},
  {code:  "160205", departamento: "Loreto", provincia: "Alto Amazonas", distrito: "Jeberos"},
  {code:  "160206", departamento: "Loreto", provincia: "Alto Amazonas", distrito: "Lagunas"},
  {code:  "160210", departamento: "Loreto", provincia: "Alto Amazonas", distrito: "Santa Cruz"},
  {code:  "160211", departamento: "Loreto", provincia: "Alto Amazonas", distrito: "Teniente Cesar Lopez Rojas"},
  {code:  "160301", departamento: "Loreto", provincia: "Loreto", distrito: "Nauta"},
  {code:  "160302", departamento: "Loreto", provincia: "Loreto", distrito: "Parinari"},
  {code:  "160303", departamento: "Loreto", provincia: "Loreto", distrito: "Tigre"},
  {code:  "160304", departamento: "Loreto", provincia: "Loreto", distrito: "Trompeteros"},
  {code:  "160305", departamento: "Loreto", provincia: "Loreto", distrito: "Urarinas"},
  {code:  "160401", departamento: "Loreto", provincia: "Mariscal Ramon Castilla", distrito: "Ramon Castilla"},
  {code:  "160402", departamento: "Loreto", provincia: "Mariscal Ramon Castilla", distrito: "Pebas"},
  {code:  "160403", departamento: "Loreto", provincia: "Mariscal Ramon Castilla", distrito: "Yavari"},
  {code:  "160404", departamento: "Loreto", provincia: "Mariscal Ramon Castilla", distrito: "San Pablo"},
  {code:  "160501", departamento: "Loreto", provincia: "Requena", distrito: "Requena"},
  {code:  "160502", departamento: "Loreto", provincia: "Requena", distrito: "Alto Tapiche"},
  {code:  "160503", departamento: "Loreto", provincia: "Requena", distrito: "Capelo"},
  {code:  "160504", departamento: "Loreto", provincia: "Requena", distrito: "Emilio San Martin"},
  {code:  "160505", departamento: "Loreto", provincia: "Requena", distrito: "Maquia"},
  {code:  "160506", departamento: "Loreto", provincia: "Requena", distrito: "Puinahua"},
  {code:  "160507", departamento: "Loreto", provincia: "Requena", distrito: "Saquena"},
  {code:  "160508", departamento: "Loreto", provincia: "Requena", distrito: "Soplin"},
  {code:  "160509", departamento: "Loreto", provincia: "Requena", distrito: "Tapiche"},
  {code:  "160510", departamento: "Loreto", provincia: "Requena", distrito: "Jenaro Herrera"},
  {code:  "160511", departamento: "Loreto", provincia: "Requena", distrito: "Yaquerana"},
  {code:  "160601", departamento: "Loreto", provincia: "Ucayali", distrito: "Contamana"},
  {code:  "160602", departamento: "Loreto", provincia: "Ucayali", distrito: "Inahuaya"},
  {code:  "160603", departamento: "Loreto", provincia: "Ucayali", distrito: "Padre Marquez"},
  {code:  "160604", departamento: "Loreto", provincia: "Ucayali", distrito: "Pampa Hermosa"},
  {code:  "160605", departamento: "Loreto", provincia: "Ucayali", distrito: "Sarayacu"},
  {code:  "160606", departamento: "Loreto", provincia: "Ucayali", distrito: "Vargas Guerra"},
  {code:  "160701", departamento: "Loreto", provincia: "Datem del Marañon", distrito: "Barranca"},
  {code:  "160702", departamento: "Loreto", provincia: "Datem del Marañon", distrito: "Cahuapanas"},
  {code:  "160703", departamento: "Loreto", provincia: "Datem del Marañon", distrito: "Manseriche"},
  {code:  "160704", departamento: "Loreto", provincia: "Datem del Marañon", distrito: "Morona"},
  {code:  "160705", departamento: "Loreto", provincia: "Datem del Marañon", distrito: "Pastaza"},
  {code:  "160706", departamento: "Loreto", provincia: "Datem del Marañon", distrito: "Andoas"},
  {code:  "160801", departamento: "Loreto", provincia: "Maynas", distrito: "Putumayo"},
  {code:  "160802", departamento: "Loreto", provincia: "Maynas", distrito: "Rosa Panduro"},
  {code:  "160803", departamento: "Loreto", provincia: "Maynas", distrito: "Teniente Manuel Clavero"},
  {code:  "160804", departamento: "Loreto", provincia: "Maynas", distrito: "Yaguas"},
  {code:  "170101", departamento: "Madre de Dios", provincia: "Tambopata", distrito: "Tambopata"},
  {code:  "170102", departamento: "Madre de Dios", provincia: "Tambopata", distrito: "Inambari"},
  {code:  "170103", departamento: "Madre de Dios", provincia: "Tambopata", distrito: "Las Piedras"},
  {code:  "170104", departamento: "Madre de Dios", provincia: "Tambopata", distrito: "Laberinto"},
  {code:  "170201", departamento: "Madre de Dios", provincia: "Manu", distrito: "Manu"},
  {code:  "170202", departamento: "Madre de Dios", provincia: "Manu", distrito: "Fitzcarrald"},
  {code:  "170203", departamento: "Madre de Dios", provincia: "Manu", distrito: "Madre de Dios"},
  {code:  "170204", departamento: "Madre de Dios", provincia: "Manu", distrito: "Huepetuhe"},
  {code:  "170301", departamento: "Madre de Dios", provincia: "Tahuamanu", distrito: "Iñapari"},
  {code:  "170302", departamento: "Madre de Dios", provincia: "Tahuamanu", distrito: "Iberia"},
  {code:  "170303", departamento: "Madre de Dios", provincia: "Tahuamanu", distrito: "Tahuamanu"},
  {code:  "180101", departamento: "Moquegua", provincia: "Mariscal Nieto", distrito: "Moquegua"},
  {code:  "180102", departamento: "Moquegua", provincia: "Mariscal Nieto", distrito: "Carumas"},
  {code:  "180103", departamento: "Moquegua", provincia: "Mariscal Nieto", distrito: "Cuchumbaya"},
  {code:  "180104", departamento: "Moquegua", provincia: "Mariscal Nieto", distrito: "Samegua"},
  {code:  "180105", departamento: "Moquegua", provincia: "Mariscal Nieto", distrito: "San Cristobal"},
  {code:  "180106", departamento: "Moquegua", provincia: "Mariscal Nieto", distrito: "Torata"},
  {code:  "180201", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Omate"},
  {code:  "180202", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Chojata"},
  {code:  "180203", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Coalaque"},
  {code:  "180204", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Ichuña"},
  {code:  "180205", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "La Capilla"},
  {code:  "180206", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Lloque"},
  {code:  "180207", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Matalaque"},
  {code:  "180208", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Puquina"},
  {code:  "180209", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Quinistaquillas"},
  {code:  "180210", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Ubinas"},
  {code:  "180211", departamento: "Moquegua", provincia: "General Sanchez Cerr", distrito: "Yunga"},
  {code:  "180301", departamento: "Moquegua", provincia: "Ilo", distrito: "Ilo"},
  {code:  "180302", departamento: "Moquegua", provincia: "Ilo", distrito: "El Algarrobal"},
  {code:  "180303", departamento: "Moquegua", provincia: "Ilo", distrito: "Pacocha"},
  {code:  "190101", departamento: "Pasco", provincia: "Pasco", distrito: "Chaupimarca"},
  {code:  "190102", departamento: "Pasco", provincia: "Pasco", distrito: "Huachon"},
  {code:  "190103", departamento: "Pasco", provincia: "Pasco", distrito: "Huariaca"},
  {code:  "190104", departamento: "Pasco", provincia: "Pasco", distrito: "Huayllay"},
  {code:  "190105", departamento: "Pasco", provincia: "Pasco", distrito: "Ninacaca"},
  {code:  "190106", departamento: "Pasco", provincia: "Pasco", distrito: "Pallanchacra"},
  {code:  "190107", departamento: "Pasco", provincia: "Pasco", distrito: "Paucartambo"},
  {code:  "190108", departamento: "Pasco", provincia: "Pasco", distrito: "San Francisco de Asis de Yarusyacan"},
  {code:  "190109", departamento: "Pasco", provincia: "Pasco", distrito: "Simon Bolivar"},
  {code:  "190110", departamento: "Pasco", provincia: "Pasco", distrito: "Ticlacayan"},
  {code:  "190111", departamento: "Pasco", provincia: "Pasco", distrito: "Tinyahuarco"},
  {code:  "190112", departamento: "Pasco", provincia: "Pasco", distrito: "Vicco"},
  {code:  "190113", departamento: "Pasco", provincia: "Pasco", distrito: "Yanacancha"},
  {code:  "190201", departamento: "Pasco", provincia: "Daniel Alcides Carri", distrito: "Yanahuanca"},
  {code:  "190202", departamento: "Pasco", provincia: "Daniel Alcides Carri", distrito: "Chacayan"},
  {code:  "190203", departamento: "Pasco", provincia: "Daniel Alcides Carri", distrito: "Goyllarisquizga"},
  {code:  "190204", departamento: "Pasco", provincia: "Daniel Alcides Carri", distrito: "Paucar"},
  {code:  "190205", departamento: "Pasco", provincia: "Daniel Alcides Carri", distrito: "San Pedro de Pillao"},
  {code:  "190206", departamento: "Pasco", provincia: "Daniel Alcides Carri", distrito: "Santa Ana de Tusi"},
  {code:  "190207", departamento: "Pasco", provincia: "Daniel Alcides Carri", distrito: "Tapuc"},
  {code:  "190208", departamento: "Pasco", provincia: "Daniel Alcides Carri", distrito: "Vilcabamba"},
  {code:  "190301", departamento: "Pasco", provincia: "Oxapampa", distrito: "Oxapampa"},
  {code:  "190302", departamento: "Pasco", provincia: "Oxapampa", distrito: "Chontabamba"},
  {code:  "190303", departamento: "Pasco", provincia: "Oxapampa", distrito: "Huancabamba"},
  {code:  "190304", departamento: "Pasco", provincia: "Oxapampa", distrito: "Palcazu"},
  {code:  "190305", departamento: "Pasco", provincia: "Oxapampa", distrito: "Pozuzo"},
  {code:  "190306", departamento: "Pasco", provincia: "Oxapampa", distrito: "Puerto Bermudez"},
  {code:  "190307", departamento: "Pasco", provincia: "Oxapampa", distrito: "Villa Rica"},
  {code:  "190308", departamento: "Pasco", provincia: "Oxapampa", distrito: "Constitución"},
  {code:  "200101", departamento: "Piura", provincia: "Piura", distrito: "Piura"},
  {code:  "200104", departamento: "Piura", provincia: "Piura", distrito: "Castilla"},
  {code:  "200105", departamento: "Piura", provincia: "Piura", distrito: "Catacaos"},
  {code:  "200107", departamento: "Piura", provincia: "Piura", distrito: "Cura Mori"},
  {code:  "200108", departamento: "Piura", provincia: "Piura", distrito: "El Tallan"},
  {code:  "200109", departamento: "Piura", provincia: "Piura", distrito: "La Arena"},
  {code:  "200110", departamento: "Piura", provincia: "Piura", distrito: "La Union"},
  {code:  "200111", departamento: "Piura", provincia: "Piura", distrito: "Las Lomas"},
  {code:  "200114", departamento: "Piura", provincia: "Piura", distrito: "Tambo Grande"},
  {code:  "200115", departamento: "Piura", provincia: "Piura", distrito: "26 de Octubre"},
  {code:  "200201", departamento: "Piura", provincia: "Ayabaca", distrito: "Ayabaca"},
  {code:  "200202", departamento: "Piura", provincia: "Ayabaca", distrito: "Frias"},
  {code:  "200203", departamento: "Piura", provincia: "Ayabaca", distrito: "Jilili"},
  {code:  "200204", departamento: "Piura", provincia: "Ayabaca", distrito: "Lagunas"},
  {code:  "200205", departamento: "Piura", provincia: "Ayabaca", distrito: "Montero"},
  {code:  "200206", departamento: "Piura", provincia: "Ayabaca", distrito: "Pacaipampa"},
  {code:  "200207", departamento: "Piura", provincia: "Ayabaca", distrito: "Paimas"},
  {code:  "200208", departamento: "Piura", provincia: "Ayabaca", distrito: "Sapillica"},
  {code:  "200209", departamento: "Piura", provincia: "Ayabaca", distrito: "Sicchez"},
  {code:  "200210", departamento: "Piura", provincia: "Ayabaca", distrito: "Suyo"},
  {code:  "200301", departamento: "Piura", provincia: "Huancabamba", distrito: "Huancabamba"},
  {code:  "200302", departamento: "Piura", provincia: "Huancabamba", distrito: "Canchaque"},
  {code:  "200303", departamento: "Piura", provincia: "Huancabamba", distrito: "El Carmen de La Frontera"},
  {code:  "200304", departamento: "Piura", provincia: "Huancabamba", distrito: "Huarmaca"},
  {code:  "200305", departamento: "Piura", provincia: "Huancabamba", distrito: "Lalaquiz"},
  {code:  "200306", departamento: "Piura", provincia: "Huancabamba", distrito: "San Miguel de El Faique"},
  {code:  "200307", departamento: "Piura", provincia: "Huancabamba", distrito: "Sondor"},
  {code:  "200308", departamento: "Piura", provincia: "Huancabamba", distrito: "Sondorillo"},
  {code:  "200401", departamento: "Piura", provincia: "Morropon", distrito: "Chulucanas"},
  {code:  "200402", departamento: "Piura", provincia: "Morropon", distrito: "Buenos Aires"},
  {code:  "200403", departamento: "Piura", provincia: "Morropon", distrito: "Chalaco"},
  {code:  "200404", departamento: "Piura", provincia: "Morropon", distrito: "La Matanza"},
  {code:  "200405", departamento: "Piura", provincia: "Morropon", distrito: "Morropon"},
  {code:  "200406", departamento: "Piura", provincia: "Morropon", distrito: "Salitral"},
  {code:  "200407", departamento: "Piura", provincia: "Morropon", distrito: "San Juan de Bigote"},
  {code:  "200408", departamento: "Piura", provincia: "Morropon", distrito: "Santa Catalina de Mossa"},
  {code:  "200409", departamento: "Piura", provincia: "Morropon", distrito: "Santo Domingo"},
  {code:  "200410", departamento: "Piura", provincia: "Morropon", distrito: "Yamango"},
  {code:  "200501", departamento: "Piura", provincia: "Paita", distrito: "Paita"},
  {code:  "200502", departamento: "Piura", provincia: "Paita", distrito: "Amotape"},
  {code:  "200503", departamento: "Piura", provincia: "Paita", distrito: "Arenal"},
  {code:  "200504", departamento: "Piura", provincia: "Paita", distrito: "Colan"},
  {code:  "200505", departamento: "Piura", provincia: "Paita", distrito: "La Huaca"},
  {code:  "200506", departamento: "Piura", provincia: "Paita", distrito: "Tamarindo"},
  {code:  "200507", departamento: "Piura", provincia: "Paita", distrito: "Vichayal"},
  {code:  "200601", departamento: "Piura", provincia: "Sullana", distrito: "Sullana"},
  {code:  "200602", departamento: "Piura", provincia: "Sullana", distrito: "Bellavista"},
  {code:  "200603", departamento: "Piura", provincia: "Sullana", distrito: "Ignacio Escudero"},
  {code:  "200604", departamento: "Piura", provincia: "Sullana", distrito: "Lancones"},
  {code:  "200605", departamento: "Piura", provincia: "Sullana", distrito: "Marcavelica"},
  {code:  "200606", departamento: "Piura", provincia: "Sullana", distrito: "Miguel Checa"},
  {code:  "200607", departamento: "Piura", provincia: "Sullana", distrito: "Querecotillo"},
  {code:  "200608", departamento: "Piura", provincia: "Sullana", distrito: "Salitral"},
  {code:  "200701", departamento: "Piura", provincia: "Talara", distrito: "Pariñas"},
  {code:  "200702", departamento: "Piura", provincia: "Talara", distrito: "El Alto"},
  {code:  "200703", departamento: "Piura", provincia: "Talara", distrito: "La Brea"},
  {code:  "200704", departamento: "Piura", provincia: "Talara", distrito: "Lobitos"},
  {code:  "200705", departamento: "Piura", provincia: "Talara", distrito: "Los Organos"},
  {code:  "200706", departamento: "Piura", provincia: "Talara", distrito: "Mancora"},
  {code:  "200801", departamento: "Piura", provincia: "Sechura", distrito: "Sechura"},
  {code:  "200802", departamento: "Piura", provincia: "Sechura", distrito: "Bellavista de La Union"},
  {code:  "200803", departamento: "Piura", provincia: "Sechura", distrito: "Bernal"},
  {code:  "200804", departamento: "Piura", provincia: "Sechura", distrito: "Cristo Nos Valga"},
  {code:  "200805", departamento: "Piura", provincia: "Sechura", distrito: "Vice"},
  {code:  "200806", departamento: "Piura", provincia: "Sechura", distrito: "Rinconada Llicuar"},
  {code:  "210101", departamento: "Puno", provincia: "Puno", distrito: "Puno"},
  {code:  "210102", departamento: "Puno", provincia: "Puno", distrito: "Acora"},
  {code:  "210103", departamento: "Puno", provincia: "Puno", distrito: "Amantani"},
  {code:  "210104", departamento: "Puno", provincia: "Puno", distrito: "Atuncolla"},
  {code:  "210105", departamento: "Puno", provincia: "Puno", distrito: "Capachica"},
  {code:  "210106", departamento: "Puno", provincia: "Puno", distrito: "Chucuito"},
  {code:  "210107", departamento: "Puno", provincia: "Puno", distrito: "Coata"},
  {code:  "210108", departamento: "Puno", provincia: "Puno", distrito: "Huata"},
  {code:  "210109", departamento: "Puno", provincia: "Puno", distrito: "Mañazo"},
  {code:  "210110", departamento: "Puno", provincia: "Puno", distrito: "Paucarcolla"},
  {code:  "210111", departamento: "Puno", provincia: "Puno", distrito: "Pichacani"},
  {code:  "210112", departamento: "Puno", provincia: "Puno", distrito: "Plateria"},
  {code:  "210113", departamento: "Puno", provincia: "Puno", distrito: "San Antonio"},
  {code:  "210114", departamento: "Puno", provincia: "Puno", distrito: "Tiquillaca"},
  {code:  "210115", departamento: "Puno", provincia: "Puno", distrito: "Vilque"},
  {code:  "210201", departamento: "Puno", provincia: "Azangaro", distrito: "Azangaro"},
  {code:  "210202", departamento: "Puno", provincia: "Azangaro", distrito: "Achaya"},
  {code:  "210203", departamento: "Puno", provincia: "Azangaro", distrito: "Arapa"},
  {code:  "210204", departamento: "Puno", provincia: "Azangaro", distrito: "Asillo"},
  {code:  "210205", departamento: "Puno", provincia: "Azangaro", distrito: "Caminaca"},
  {code:  "210206", departamento: "Puno", provincia: "Azangaro", distrito: "Chupa"},
  {code:  "210207", departamento: "Puno", provincia: "Azangaro", distrito: "Jose Domingo Choquehuanca"},
  {code:  "210208", departamento: "Puno", provincia: "Azangaro", distrito: "Muñani"},
  {code:  "210209", departamento: "Puno", provincia: "Azangaro", distrito: "Potoni"},
  {code:  "210210", departamento: "Puno", provincia: "Azangaro", distrito: "Saman"},
  {code:  "210211", departamento: "Puno", provincia: "Azangaro", distrito: "San Anton"},
  {code:  "210212", departamento: "Puno", provincia: "Azangaro", distrito: "San Jose"},
  {code:  "210213", departamento: "Puno", provincia: "Azangaro", distrito: "San Juan de Salinas"},
  {code:  "210214", departamento: "Puno", provincia: "Azangaro", distrito: "Santiago de Pupuja"},
  {code:  "210215", departamento: "Puno", provincia: "Azangaro", distrito: "Tirapata"},
  {code:  "210301", departamento: "Puno", provincia: "Carabaya", distrito: "Macusani"},
  {code:  "210302", departamento: "Puno", provincia: "Carabaya", distrito: "Ajoyani"},
  {code:  "210303", departamento: "Puno", provincia: "Carabaya", distrito: "Ayapata"},
  {code:  "210304", departamento: "Puno", provincia: "Carabaya", distrito: "Coasa"},
  {code:  "210305", departamento: "Puno", provincia: "Carabaya", distrito: "Corani"},
  {code:  "210306", departamento: "Puno", provincia: "Carabaya", distrito: "Crucero"},
  {code:  "210307", departamento: "Puno", provincia: "Carabaya", distrito: "Ituata"},
  {code:  "210308", departamento: "Puno", provincia: "Carabaya", distrito: "Ollachea"},
  {code:  "210309", departamento: "Puno", provincia: "Carabaya", distrito: "San Gaban"},
  {code:  "210310", departamento: "Puno", provincia: "Carabaya", distrito: "Usicayos"},
  {code:  "210401", departamento: "Puno", provincia: "Chucuito", distrito: "Juli"},
  {code:  "210402", departamento: "Puno", provincia: "Chucuito", distrito: "Desaguadero"},
  {code:  "210403", departamento: "Puno", provincia: "Chucuito", distrito: "Huacullani"},
  {code:  "210404", departamento: "Puno", provincia: "Chucuito", distrito: "Kelluyo"},
  {code:  "210405", departamento: "Puno", provincia: "Chucuito", distrito: "Pisacoma"},
  {code:  "210406", departamento: "Puno", provincia: "Chucuito", distrito: "Pomata"},
  {code:  "210407", departamento: "Puno", provincia: "Chucuito", distrito: "Zepita"},
  {code:  "210501", departamento: "Puno", provincia: "El Collao", distrito: "Ilave"},
  {code:  "210502", departamento: "Puno", provincia: "El Collao", distrito: "Capazo"},
  {code:  "210503", departamento: "Puno", provincia: "El Collao", distrito: "Pilcuyo"},
  {code:  "210504", departamento: "Puno", provincia: "El Collao", distrito: "Santa Rosa"},
  {code:  "210505", departamento: "Puno", provincia: "El Collao", distrito: "Conduriri"},
  {code:  "210601", departamento: "Puno", provincia: "Huancane", distrito: "Huancane"},
  {code:  "210602", departamento: "Puno", provincia: "Huancane", distrito: "Cojata"},
  {code:  "210603", departamento: "Puno", provincia: "Huancane", distrito: "Huatasani"},
  {code:  "210604", departamento: "Puno", provincia: "Huancane", distrito: "Inchupalla"},
  {code:  "210605", departamento: "Puno", provincia: "Huancane", distrito: "Pusi"},
  {code:  "210606", departamento: "Puno", provincia: "Huancane", distrito: "Rosaspata"},
  {code:  "210607", departamento: "Puno", provincia: "Huancane", distrito: "Taraco"},
  {code:  "210608", departamento: "Puno", provincia: "Huancane", distrito: "Vilque Chico"},
  {code:  "210701", departamento: "Puno", provincia: "Lampa", distrito: "Lampa"},
  {code:  "210702", departamento: "Puno", provincia: "Lampa", distrito: "Cabanilla"},
  {code:  "210703", departamento: "Puno", provincia: "Lampa", distrito: "Calapuja"},
  {code:  "210704", departamento: "Puno", provincia: "Lampa", distrito: "Nicasio"},
  {code:  "210705", departamento: "Puno", provincia: "Lampa", distrito: "Ocuviri"},
  {code:  "210706", departamento: "Puno", provincia: "Lampa", distrito: "Palca"},
  {code:  "210707", departamento: "Puno", provincia: "Lampa", distrito: "Paratia"},
  {code:  "210708", departamento: "Puno", provincia: "Lampa", distrito: "Pucara"},
  {code:  "210709", departamento: "Puno", provincia: "Lampa", distrito: "Santa Lucia"},
  {code:  "210710", departamento: "Puno", provincia: "Lampa", distrito: "Vilavila"},
  {code:  "210801", departamento: "Puno", provincia: "Melgar", distrito: "Ayaviri"},
  {code:  "210802", departamento: "Puno", provincia: "Melgar", distrito: "Antauta"},
  {code:  "210803", departamento: "Puno", provincia: "Melgar", distrito: "Cupi"},
  {code:  "210804", departamento: "Puno", provincia: "Melgar", distrito: "Llalli"},
  {code:  "210805", departamento: "Puno", provincia: "Melgar", distrito: "Macari"},
  {code:  "210806", departamento: "Puno", provincia: "Melgar", distrito: "Nuñoa"},
  {code:  "210807", departamento: "Puno", provincia: "Melgar", distrito: "Orurillo"},
  {code:  "210808", departamento: "Puno", provincia: "Melgar", distrito: "Santa Rosa"},
  {code:  "210809", departamento: "Puno", provincia: "Melgar", distrito: "Umachiri"},
  {code:  "210901", departamento: "Puno", provincia: "Moho", distrito: "Moho"},
  {code:  "210902", departamento: "Puno", provincia: "Moho", distrito: "Conima"},
  {code:  "210903", departamento: "Puno", provincia: "Moho", distrito: "Huayrapata"},
  {code:  "210904", departamento: "Puno", provincia: "Moho", distrito: "Tilali"},
  {code:  "211001", departamento: "Puno", provincia: "San Antonio de Putin", distrito: "Putina"},
  {code:  "211002", departamento: "Puno", provincia: "San Antonio de Putin", distrito: "Ananea"},
  {code:  "211003", departamento: "Puno", provincia: "San Antonio de Putin", distrito: "Pedro Vilca Apaza"},
  {code:  "211004", departamento: "Puno", provincia: "San Antonio de Putin", distrito: "Quilcapuncu"},
  {code:  "211005", departamento: "Puno", provincia: "San Antonio de Putin", distrito: "Sina"},
  {code:  "211101", departamento: "Puno", provincia: "San Roman", distrito: "Juliaca"},
  {code:  "211102", departamento: "Puno", provincia: "San Roman", distrito: "Cabana"},
  {code:  "211103", departamento: "Puno", provincia: "San Roman", distrito: "Cabanillas"},
  {code:  "211104", departamento: "Puno", provincia: "San Roman", distrito: "Caracoto"},
  {code:  "211105", departamento: "Puno", provincia: "San Roman", distrito: "San Miguel"},
  {code:  "211201", departamento: "Puno", provincia: "Sandia", distrito: "Sandia"},
  {code:  "211202", departamento: "Puno", provincia: "Sandia", distrito: "Cuyocuyo"},
  {code:  "211203", departamento: "Puno", provincia: "Sandia", distrito: "Limbani"},
  {code:  "211204", departamento: "Puno", provincia: "Sandia", distrito: "Patambuco"},
  {code:  "211205", departamento: "Puno", provincia: "Sandia", distrito: "Phara"},
  {code:  "211206", departamento: "Puno", provincia: "Sandia", distrito: "Quiaca"},
  {code:  "211207", departamento: "Puno", provincia: "Sandia", distrito: "San Juan del Oro"},
  {code:  "211208", departamento: "Puno", provincia: "Sandia", distrito: "Yanahuaya"},
  {code:  "211209", departamento: "Puno", provincia: "Sandia", distrito: "Alto Inambari"},
  {code:  "211210", departamento: "Puno", provincia: "Sandia", distrito: "San Pedro de Putina Punco"},
  {code:  "211301", departamento: "Puno", provincia: "Yunguyo", distrito: "Yunguyo"},
  {code:  "211302", departamento: "Puno", provincia: "Yunguyo", distrito: "Anapia"},
  {code:  "211303", departamento: "Puno", provincia: "Yunguyo", distrito: "Copani"},
  {code:  "211304", departamento: "Puno", provincia: "Yunguyo", distrito: "Cuturapi"},
  {code:  "211305", departamento: "Puno", provincia: "Yunguyo", distrito: "Ollaraya"},
  {code:  "211306", departamento: "Puno", provincia: "Yunguyo", distrito: "Tinicachi"},
  {code:  "211307", departamento: "Puno", provincia: "Yunguyo", distrito: "Unicachi"},
  {code:  "220101", departamento: "San Martin", provincia: "Moyobamba", distrito: "Moyobamba"},
  {code:  "220102", departamento: "San Martin", provincia: "Moyobamba", distrito: "Calzada"},
  {code:  "220103", departamento: "San Martin", provincia: "Moyobamba", distrito: "Habana"},
  {code:  "220104", departamento: "San Martin", provincia: "Moyobamba", distrito: "Jepelacio"},
  {code:  "220105", departamento: "San Martin", provincia: "Moyobamba", distrito: "Soritor"},
  {code:  "220106", departamento: "San Martin", provincia: "Moyobamba", distrito: "Yantalo"},
  {code:  "220201", departamento: "San Martin", provincia: "Bellavista", distrito: "Bellavista"},
  {code:  "220202", departamento: "San Martin", provincia: "Bellavista", distrito: "Alto Biavo"},
  {code:  "220203", departamento: "San Martin", provincia: "Bellavista", distrito: "Bajo Biavo"},
  {code:  "220204", departamento: "San Martin", provincia: "Bellavista", distrito: "Huallaga"},
  {code:  "220205", departamento: "San Martin", provincia: "Bellavista", distrito: "San Pablo"},
  {code:  "220206", departamento: "San Martin", provincia: "Bellavista", distrito: "San Rafael"},
  {code:  "220301", departamento: "San Martin", provincia: "El Dorado", distrito: "San Jose de Sisa"},
  {code:  "220302", departamento: "San Martin", provincia: "El Dorado", distrito: "Agua Blanca"},
  {code:  "220303", departamento: "San Martin", provincia: "El Dorado", distrito: "San Martin"},
  {code:  "220304", departamento: "San Martin", provincia: "El Dorado", distrito: "Santa Rosa"},
  {code:  "220305", departamento: "San Martin", provincia: "El Dorado", distrito: "Shatoja"},
  {code:  "220401", departamento: "San Martin", provincia: "Huallaga", distrito: "Saposoa"},
  {code:  "220402", departamento: "San Martin", provincia: "Huallaga", distrito: "Alto Saposoa"},
  {code:  "220403", departamento: "San Martin", provincia: "Huallaga", distrito: "El Eslabon"},
  {code:  "220404", departamento: "San Martin", provincia: "Huallaga", distrito: "Piscoyacu"},
  {code:  "220405", departamento: "San Martin", provincia: "Huallaga", distrito: "Sacanche"},
  {code:  "220406", departamento: "San Martin", provincia: "Huallaga", distrito: "Tingo de Saposoa"},
  {code:  "220501", departamento: "San Martin", provincia: "Lamas", distrito: "Lamas"},
  {code:  "220502", departamento: "San Martin", provincia: "Lamas", distrito: "Alonso de Alvarado"},
  {code:  "220503", departamento: "San Martin", provincia: "Lamas", distrito: "Barranquita"},
  {code:  "220504", departamento: "San Martin", provincia: "Lamas", distrito: "Caynarachi"},
  {code:  "220505", departamento: "San Martin", provincia: "Lamas", distrito: "Cuñumbuqui"},
  {code:  "220506", departamento: "San Martin", provincia: "Lamas", distrito: "Pinto Recodo"},
  {code:  "220507", departamento: "San Martin", provincia: "Lamas", distrito: "Rumisapa"},
  {code:  "220508", departamento: "San Martin", provincia: "Lamas", distrito: "San Roque de Cumbaza"},
  {code:  "220509", departamento: "San Martin", provincia: "Lamas", distrito: "Shanao"},
  {code:  "220510", departamento: "San Martin", provincia: "Lamas", distrito: "Tabalosos"},
  {code:  "220511", departamento: "San Martin", provincia: "Lamas", distrito: "Zapatero"},
  {code:  "220601", departamento: "San Martin", provincia: "Mariscal Caceres", distrito: "Juanjui"},
  {code:  "220602", departamento: "San Martin", provincia: "Mariscal Caceres", distrito: "Campanilla"},
  {code:  "220603", departamento: "San Martin", provincia: "Mariscal Caceres", distrito: "Huicungo"},
  {code:  "220604", departamento: "San Martin", provincia: "Mariscal Caceres", distrito: "Pachiza"},
  {code:  "220605", departamento: "San Martin", provincia: "Mariscal Caceres", distrito: "Pajarillo"},
  {code:  "220701", departamento: "San Martin", provincia: "Picota", distrito: "Picota"},
  {code:  "220702", departamento: "San Martin", provincia: "Picota", distrito: "Buenos Aires"},
  {code:  "220703", departamento: "San Martin", provincia: "Picota", distrito: "Caspisapa"},
  {code:  "220704", departamento: "San Martin", provincia: "Picota", distrito: "Pilluana"},
  {code:  "220705", departamento: "San Martin", provincia: "Picota", distrito: "Pucacaca"},
  {code:  "220706", departamento: "San Martin", provincia: "Picota", distrito: "San Cristobal"},
  {code:  "220707", departamento: "San Martin", provincia: "Picota", distrito: "San Hilarion"},
  {code:  "220708", departamento: "San Martin", provincia: "Picota", distrito: "Shamboyacu"},
  {code:  "220709", departamento: "San Martin", provincia: "Picota", distrito: "Tingo de Ponasa"},
  {code:  "220710", departamento: "San Martin", provincia: "Picota", distrito: "Tres Unidos"},
  {code:  "220801", departamento: "San Martin", provincia: "Rioja", distrito: "Rioja"},
  {code:  "220802", departamento: "San Martin", provincia: "Rioja", distrito: "Awajun"},
  {code:  "220803", departamento: "San Martin", provincia: "Rioja", distrito: "Elias Soplin Vargas"},
  {code:  "220804", departamento: "San Martin", provincia: "Rioja", distrito: "Nueva Cajamarca"},
  {code:  "220805", departamento: "San Martin", provincia: "Rioja", distrito: "Pardo Miguel"},
  {code:  "220806", departamento: "San Martin", provincia: "Rioja", distrito: "Posic"},
  {code:  "220807", departamento: "San Martin", provincia: "Rioja", distrito: "San Fernando"},
  {code:  "220808", departamento: "San Martin", provincia: "Rioja", distrito: "Yorongos"},
  {code:  "220809", departamento: "San Martin", provincia: "Rioja", distrito: "Yuracyacu"},
  {code:  "220901", departamento: "San Martin", provincia: "San Martin", distrito: "Tarapoto"},
  {code:  "220902", departamento: "San Martin", provincia: "San Martin", distrito: "Alberto Leveau"},
  {code:  "220903", departamento: "San Martin", provincia: "San Martin", distrito: "Cacatachi"},
  {code:  "220904", departamento: "San Martin", provincia: "San Martin", distrito: "Chazuta"},
  {code:  "220905", departamento: "San Martin", provincia: "San Martin", distrito: "Chipurana"},
  {code:  "220906", departamento: "San Martin", provincia: "San Martin", distrito: "El Porvenir"},
  {code:  "220907", departamento: "San Martin", provincia: "San Martin", distrito: "Huimbayoc"},
  {code:  "220908", departamento: "San Martin", provincia: "San Martin", distrito: "Juan Guerra"},
  {code:  "220909", departamento: "San Martin", provincia: "San Martin", distrito: "La Banda de Shilcayo"},
  {code:  "220910", departamento: "San Martin", provincia: "San Martin", distrito: "Morales"},
  {code:  "220911", departamento: "San Martin", provincia: "San Martin", distrito: "Papaplaya"},
  {code:  "220912", departamento: "San Martin", provincia: "San Martin", distrito: "San Antonio"},
  {code:  "220913", departamento: "San Martin", provincia: "San Martin", distrito: "Sauce"},
  {code:  "220914", departamento: "San Martin", provincia: "San Martin", distrito: "Shapaja"},
  {code:  "221001", departamento: "San Martin", provincia: "Tocache", distrito: "Tocache"},
  {code:  "221002", departamento: "San Martin", provincia: "Tocache", distrito: "Nuevo Progreso"},
  {code:  "221003", departamento: "San Martin", provincia: "Tocache", distrito: "Polvora"},
  {code:  "221004", departamento: "San Martin", provincia: "Tocache", distrito: "Shunte"},
  {code:  "221005", departamento: "San Martin", provincia: "Tocache", distrito: "Uchiza"},
  {code:  "230101", departamento: "Tacna", provincia: "Tacna", distrito: "Tacna"},
  {code:  "230102", departamento: "Tacna", provincia: "Tacna", distrito: "Alto de La Alianza"},
  {code:  "230103", departamento: "Tacna", provincia: "Tacna", distrito: "Calana"},
  {code:  "230104", departamento: "Tacna", provincia: "Tacna", distrito: "Ciudad Nueva"},
  {code:  "230105", departamento: "Tacna", provincia: "Tacna", distrito: "Inclan"},
  {code:  "230106", departamento: "Tacna", provincia: "Tacna", distrito: "Pachia"},
  {code:  "230107", departamento: "Tacna", provincia: "Tacna", distrito: "Palca"},
  {code:  "230108", departamento: "Tacna", provincia: "Tacna", distrito: "Pocollay"},
  {code:  "230109", departamento: "Tacna", provincia: "Tacna", distrito: "Sama"},
  {code:  "230110", departamento: "Tacna", provincia: "Tacna", distrito: "Coronel Gregorio Albarracin Lanchipa"},
  {code:  "230111", departamento: "Tacna", provincia: "Tacna", distrito: "La Yarada-Los Palos"},
  {code:  "230201", departamento: "Tacna", provincia: "Candarave", distrito: "Candarave"},
  {code:  "230202", departamento: "Tacna", provincia: "Candarave", distrito: "Cairani"},
  {code:  "230203", departamento: "Tacna", provincia: "Candarave", distrito: "Camilaca"},
  {code:  "230204", departamento: "Tacna", provincia: "Candarave", distrito: "Curibaya"},
  {code:  "230205", departamento: "Tacna", provincia: "Candarave", distrito: "Huanuara"},
  {code:  "230206", departamento: "Tacna", provincia: "Candarave", distrito: "Quilahuani"},
  {code:  "230301", departamento: "Tacna", provincia: "Jorge Basadre", distrito: "Locumba"},
  {code:  "230302", departamento: "Tacna", provincia: "Jorge Basadre", distrito: "Ilabaya"},
  {code:  "230303", departamento: "Tacna", provincia: "Jorge Basadre", distrito: "Ite"},
  {code:  "230401", departamento: "Tacna", provincia: "Tarata", distrito: "Tarata"},
  {code:  "230402", departamento: "Tacna", provincia: "Tarata", distrito: "Heroes Albarracin"},
  {code:  "230403", departamento: "Tacna", provincia: "Tarata", distrito: "Estique"},
  {code:  "230404", departamento: "Tacna", provincia: "Tarata", distrito: "Estique-Pampa"},
  {code:  "230405", departamento: "Tacna", provincia: "Tarata", distrito: "Sitajara"},
  {code:  "230406", departamento: "Tacna", provincia: "Tarata", distrito: "Susapaya"},
  {code:  "230407", departamento: "Tacna", provincia: "Tarata", distrito: "Tarucachi"},
  {code:  "230408", departamento: "Tacna", provincia: "Tarata", distrito: "Ticaco"},
  {code:  "240101", departamento: "Tumbes", provincia: "Tumbes", distrito: "Tumbes"},
  {code:  "240102", departamento: "Tumbes", provincia: "Tumbes", distrito: "Corrales"},
  {code:  "240103", departamento: "Tumbes", provincia: "Tumbes", distrito: "La Cruz"},
  {code:  "240104", departamento: "Tumbes", provincia: "Tumbes", distrito: "Pampas de Hospital"},
  {code:  "240105", departamento: "Tumbes", provincia: "Tumbes", distrito: "San Jacinto"},
  {code:  "240106", departamento: "Tumbes", provincia: "Tumbes", distrito: "San Juan de La Virgen"},
  {code:  "240201", departamento: "Tumbes", provincia: "Contralmirante Villa", distrito: "Zorritos"},
  {code:  "240202", departamento: "Tumbes", provincia: "Contralmirante Villa", distrito: "Casitas"},
  {code:  "240203", departamento: "Tumbes", provincia: "Contralmirante Villa", distrito: "Canoas de Punta Sal"},
  {code:  "240301", departamento: "Tumbes", provincia: "Zarumilla", distrito: "Zarumilla"},
  {code:  "240302", departamento: "Tumbes", provincia: "Zarumilla", distrito: "Aguas Verdes"},
  {code:  "240303", departamento: "Tumbes", provincia: "Zarumilla", distrito: "Matapalo"},
  {code:  "240304", departamento: "Tumbes", provincia: "Zarumilla", distrito: "Papayal"},
  {code:  "250101", departamento: "Ucayali", provincia: "Coronel Portillo", distrito: "Calleria"},
  {code:  "250102", departamento: "Ucayali", provincia: "Coronel Portillo", distrito: "Campoverde"},
  {code:  "250103", departamento: "Ucayali", provincia: "Coronel Portillo", distrito: "Iparia"},
  {code:  "250104", departamento: "Ucayali", provincia: "Coronel Portillo", distrito: "Masisea"},
  {code:  "250105", departamento: "Ucayali", provincia: "Coronel Portillo", distrito: "Yarinacocha"},
  {code:  "250106", departamento: "Ucayali", provincia: "Coronel Portillo", distrito: "Nueva Requena"},
  {code:  "250107", departamento: "Ucayali", provincia: "Coronel Portillo", distrito: "Manantay"},
  {code:  "250201", departamento: "Ucayali", provincia: "Atalaya", distrito: "Raymondi"},
  {code:  "250202", departamento: "Ucayali", provincia: "Atalaya", distrito: "Sepahua"},
  {code:  "250203", departamento: "Ucayali", provincia: "Atalaya", distrito: "Tahuania"},
  {code:  "250204", departamento: "Ucayali", provincia: "Atalaya", distrito: "Yurua"},
  {code:  "250301", departamento: "Ucayali", provincia: "Padre Abad", distrito: "Padre Abad"},
  {code:  "250302", departamento: "Ucayali", provincia: "Padre Abad", distrito: "Irazola"},
  {code:  "250303", departamento: "Ucayali", provincia: "Padre Abad", distrito: "Curimana"},
  {code:  "250304", departamento: "Ucayali", provincia: "Padre Abad", distrito: "Neshuya"},
  {code:  "250305", departamento: "Ucayali", provincia: "Padre Abad", distrito: "Alexander von Humboldt"},
  {code:  "250401", departamento: "Ucayali", provincia: "Purus", distrito: "Purus"},
 
]
