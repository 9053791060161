import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Menu,
  Paper,
  MenuItem,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import FileDownload from "js-file-download";
import {
  faFileExcel as excelIcon,
  faEnvelope,
  faFolder,
  faChartBar,
} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { ConfirmDialog } from "../confirmDialog";

interface Props {
  anchorEl: null | HTMLElement;
  onCloseMenu: () => void;
  totals: any;
}
export const MenuExcelFile: React.FC<Props> = ({
  anchorEl,
  onCloseMenu,
  totals,
}) => {
  const config = useSelector((state: any) => state.config);
  const [blockDownloadExcel, setBlockDownloadExcel] =
    React.useState<boolean>(false);
  const [blockDownloadEmailExcel, setBlockDownloadEmailExcel] =
    React.useState<boolean>(false);
  const [downloadingExcel, setDownloadingExcel] =
    React.useState<boolean>(false);
  const [showDialogConfirmExcel, setShowDialogConfirmExcel] =
    React.useState<boolean>(false);
  const [showDialogBackendExcel, setShowDialogBackendExcel] =
    React.useState<boolean>(false);
  const [downloadingEmailExcel, setDownloadingEmailExcel] =
    React.useState<boolean>(false);

  //Excel
  const handleDownloadExcel = async () => {
    if (totals <= 20000) {
      if (totals >= 10000) {
        toast.info("El proceso pudiera tardar unos minutos!");
      }
      // 15 mil
      let { data } = await axios.get(
        `api/report/download/emission/${config.workspace}?dateOf=${
          config.typeDate
        }&startDate=${config.reportStartDate}&endDate=${config.reportEndDate}${
          config.reportFilterSerie
            ? `&serie=${config.reportFilterSerie}&seqInit=${config.reportFilterInitSeq}&seqEnd=${config.reportFilterEndSeq}`
            : ""
        }${config.typeDoc ? `&docType=${config.typeDoc}` : ""}${
          config.stateDoc
            ? `&docStatus=${
                config.stateDoc.split(",").includes("1-3")
                  ? config.stateDoc.replace("1-3", "1,3")
                  : config.stateDoc
              }`
            : ""
        }`,
        {
          responseType: "blob",
        }
      );
      //estado del proceso de descarga
      setDownloadingExcel(true);
      setBlockDownloadExcel(true);
      onCloseMenu();

      FileDownload(
        new Blob([data]),
        `docs_emitidos_${config.workspace}_${config.reportStartDate}_${config.reportEndDate}.xlsx`
      );
      setDownloadingExcel(false);
      setTimeout(() => setBlockDownloadExcel(false), 3000);
    } /* else if (totals <= 30000) {
        // mostrar dialog de que son bastante y se puede demorar
        window.alert("Esto puede tardar")
        setShowDialogConfirmExcel(true);
    } */ else {
      // TODO: mostrar mensaje de que el reporte se le enviara mas adelante por email
      setShowDialogBackendExcel(true);
    }
  };

  //Report Financial
  const handleDownloadFinancial = async () => {
    if (totals <= 20000) {
      // 20 mil
      if (totals >= 10000) {
        toast.info("El proceso pudiera tardar unos minutos!");
      }
      let { data } = await axios.get(
        `api/report/download/financial/${config.workspace}?dateOf=${
          config.typeDate
        }&startDate=${config.reportStartDate}&endDate=${config.reportEndDate}${
          config.reportFilterSerie
            ? `&serie=${config.reportFilterSerie}&seqInit=${config.reportFilterInitSeq}&seqEnd=${config.reportFilterEndSeq}`
            : ""
        }${config.typeDoc ? `&docType=${config.typeDoc}` : ""}${
          config.stateDoc
            ? `&docStatus=${
                config.stateDoc.split(",").includes("1-3")
                  ? config.stateDoc.replace("1-3", "1,3")
                  : config.stateDoc
              }`
            : ""
        }`,
        {
          responseType: "blob",
        }
      );
      //estado del proceso de descarga
      setDownloadingExcel(true);
      setBlockDownloadExcel(true);
      onCloseMenu();

      FileDownload(
        new Blob([data]),
        `reporte_financiero_${config.workspace}_${config.reportStartDate}_${config.reportEndDate}.xlsx`
      );
      setDownloadingExcel(false);
      setTimeout(() => setBlockDownloadExcel(false), 3000);
    } /*  else if (totals<= 30000) {
        // mostrar dialog de que son bastante y se puede demorar
        setShowDialogConfirmExcel(true);
    }  */ else {
      // TODO: mostrar mensaje de que el reporte se le enviara mas adelante por email
      setShowDialogBackendExcel(true);
    }
  };

  //Reporte Email
  const handleDownloadEmailReportExcel = async () => {
    if (config.reportTotal <= 150000) {
      // 15 mil

      let { data } = await axios.get(
        `/api/reports/download/emails/${config.workspace}?dateOf=${
          config.typeDate
        }&startDate=${config.reportStartDate}&endDate=${config.reportEndDate}${
          config.reportFilterSerie
            ? `&serie=${config.reportFilterSerie}&seqInit=${config.reportFilterInitSeq}&seqEnd=${config.reportFilterEndSeq}`
            : ""
        }${config.typeDoc ? `&docType=${config.typeDoc}` : ""}${
          config.stateDoc
            ? `&docStatus=${
                config.stateDoc.split(",").includes("1-3")
                  ? config.stateDoc.replace("1-3", "1,3")
                  : config.stateDoc
              }`
            : ""
        }`,
        {
          responseType: "blob",
        }
      );
      //estado del proceso de descarga
      setDownloadingExcel(true);
      setBlockDownloadExcel(true);
      onCloseMenu();

      FileDownload(
        new Blob([data]),
        `reporte_correos_${config.workspace}_${config.reportStartDate}_${config.reportEndDate}.xlsx`
      );
      setDownloadingEmailExcel(false);
      setTimeout(() => setBlockDownloadEmailExcel(false), 3000);
    } else {
      // TODO: mostrar mensaje de que el reporte se le enviara mas adelante por email
      setShowDialogBackendExcel(true);
    }
  };

  const handleDownloadVentasReport = async () => {
    if (config.reportTotal <= 15000) {
      let { data } = await axios.get(
        `api/emission/download/txt/${config.workspace}?dateOf=${
          config.typeDate
        }&startDate=${config.reportStartDate}&endDate=${config.reportEndDate}${
          config.reportFilterSerie
            ? `&serie=${config.reportFilterSerie}&seqInit=${config.reportFilterInitSeq}&seqEnd=${config.reportFilterEndSeq}`
            : ""
        }${config.typeDoc ? `&docType=${config.typeDoc}` : ""}${
          config.stateDoc
            ? `&docStatus=${
                config.stateDoc.split(",").includes("1-3")
                  ? config.stateDoc.replace("1-3", "1,3")
                  : config.stateDoc
              }`
            : ""
        }`,

        {
          responseType: "blob",
        }
      );
      const anio = moment(config.reportEndDate).format("YYYY");
      const mes = moment(config.reportEndDate).format("MM");
      FileDownload(
        new Blob([data]),
        //LE	20538751228	2022	01	00	140100	00	1	1	1	1
        `LE${config.workspace}${anio}${mes}00140100001111.txt`
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div>
          <Paper
            style={{
              padding: "5px",
              margin: "5px",
              backgroundColor: "rgb(196 231 155)",
            }}
          >
            <MenuItem onClick={handleDownloadExcel}>
              {downloadingExcel ? (
                <div>
                  <CircularProgress
                    variant="indeterminate"
                    size={14}
                    style={{ marginRight: 5 }}
                  />
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon
                    icon={excelIcon}
                    style={{ fontSize: "20px", paddingRight: "3px" }}
                  />
                  <Typography variant="caption">
                    Descargar reporte Doc. emitidos
                  </Typography>
                </div>
              )}
            </MenuItem>
          </Paper>

          <Paper
            style={{
              padding: "5px",
              margin: "5px",
              backgroundColor: "rgb(196 231 155)",
            }}
          >
            <MenuItem onClick={handleDownloadFinancial}>
              {downloadingEmailExcel ? (
                <CircularProgress
                  variant="indeterminate"
                  size={14}
                  style={{ marginRight: 5 }}
                />
              ) : (
                <div>
                  <FontAwesomeIcon
                    icon={faFolder}
                    style={{ fontSize: "20px", paddingRight: "3px" }}
                  />
                  <Typography variant="caption">
                    Descargar reporte Financiero
                  </Typography>
                </div>
              )}
            </MenuItem>
          </Paper>

          <Paper
            style={{
              padding: "5px",
              margin: "5px",
              backgroundColor: "rgb(196 231 155)",
            }}
          >
            <MenuItem onClick={handleDownloadEmailReportExcel}>
              {downloadingEmailExcel ? (
                <CircularProgress
                  variant="indeterminate"
                  size={14}
                  style={{ marginRight: 5 }}
                />
              ) : (
                <div>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ fontSize: "20px", paddingRight: "3px" }}
                  />
                  <Typography variant="caption">
                    Descargar reporte de Correos
                  </Typography>
                </div>
              )}
            </MenuItem>
          </Paper>
          <Paper
            style={{
              padding: "5px",
              margin: "5px",
              backgroundColor: "rgb(196 231 155)",
            }}
          >
            <MenuItem onClick={handleDownloadVentasReport}>
              {downloadingEmailExcel ? (
                <CircularProgress
                  variant="indeterminate"
                  size={14}
                  style={{ marginRight: 5 }}
                />
              ) : (
                <div>
                  <FontAwesomeIcon
                    icon={faChartBar}
                    style={{ fontSize: "20px", paddingRight: "3px" }}
                  />
                  <Typography variant="caption">
                    Descargar txt PLE (Beta)
                  </Typography>
                </div>
              )}
            </MenuItem>
          </Paper>
        </div>
      </Menu>
      <ConfirmDialog
        title="Cantidad no soportada aun"
        body={`Para generar un reporte de ${totals} documentos, debe contactar a soporte momentaneamente`}
        acceptBtnText="Listo"
        open={showDialogBackendExcel}
        handleClose={() => setShowDialogBackendExcel(false)}
        handleAccept={() => setShowDialogBackendExcel(false)}
      />
    </React.Fragment>
  );
};
