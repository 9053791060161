import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Grid from "@material-ui/core/Grid";

const Footer: React.FC<{}> = () => {
  return (

      <footer
        style={{
          paddingLeft: 24,         
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <p
              className="mx-2"
              style={{
                color: "black",
                fontSize: 16,
                borderTop: "2px solid #e6e6e6",
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              © 2022 RATIFIKA - Todos los derechos reservados.
            </p>
            <a
              style={{
                color: "black",
                fontSize: 14,
              }}
              href="https://eximio.com.pe/"
              target="blank"
            >
              Innovación y Tecnología de EXIMIO S.A.C.
            </a>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div
              style={{
                textAlign: "center",
                padding: "0px",
              }}
            >
              <a
                style={{
                  color: "rgb(16, 124, 65)",
                  marginRight: 5,
                }}
                href="https://www.facebook.com/eximioperu"
                target="blank"
              >
                <FacebookIcon />
              </a>
              <a
                style={{
                  color: "rgb(16, 124, 65)",
                  marginRight: 5,
                }}
                href="https://www.instagram.com/eximioperu/?hl=es-la"
                target="blank"
              >
                <InstagramIcon />
              </a>
              <a
                style={{
                  color: "rgb(16, 124, 65)",
                }}
                href="https://www.linkedin.com/company/eximioperu/?viewAsMember=true"
                target="blank"
              >
                <LinkedInIcon />
              </a>
            </div>
          </Grid>
        </Grid>
      </footer>    
  );
};

export default Footer;