import React from "react";
import DashboardReception from "../components/dashboardReception";
import DownloadEmitted from "../components/downloadEmitted";
import { EmittedDocList } from "../components/emittedDocList";
import Header from "../components/header";
import LayoutFilter from "../components/layoutFilter";
import Loading from "../components/skeleton/loading";

const Emission = () => {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <Header />
      <LayoutFilter
        type="emitted"
        title="Documentos Emitidos"
        componentLoading={Loading}
      >
        <DownloadEmitted />
        <EmittedDocList />
      </LayoutFilter>
    </div>
  );
};

export default Emission;
