import { ReportDoc, ReportDocsActionTypes } from "../types/reportDocs";

const initalState: ReportDoc = {
  bulkDownloadFileList: [],
  reportClient: [],
  result: false,
  stateDocuments: [],
  totalContadoPEN: "0.00",
  totalContadoUSD: "0.00",
  totalCreditoPEN: "0.00",
  totalCreditoUSD: "0.00",
  totalDOLLAR: "0.00",
  totalPEN: "0.00",
  totalSend: 1,
};

const reportsDocsReducer = (
  state = initalState,
  action: ReportDocsActionTypes
) => {
  switch (action.type) {
    case "SET_REPORT_DOCS":
      return action.payload;
    case "RESET_REPORT_DOCS":
      return initalState;
    default:
      return state;
  }
};

export default reportsDocsReducer;
