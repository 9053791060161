import React from "react";
import FormNewRetention from "../components/formNewRetention";
import Header from "../components/header";

const NewRetention = () => {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <Header />
      <FormNewRetention />
    </div>
  );
};

export default NewRetention;
