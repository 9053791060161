import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useStyles } from "./styled";
import { Colors } from "../../static/colors";

interface ICustomSelectProps {
  placeholder: string;
  options: any[];
  name: string;
  value: any;
  onChange: any;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: boolean;
  helperText?: string;
  selectHandle?: boolean;
}

const CustomSelect: React.FC<ICustomSelectProps> = ({
  name,
  onChange,
  options,
  placeholder,
  value,
  error,
  helperText,
  onBlur,
  onFocus,
  selectHandle = true,
}) => {
  const [focus, setFocus] = React.useState(false);

  const classes = useStyles({
    color: !focus ? Colors.TEXT : Colors.GREEN,
    border: !focus ? 1 : 2,
  });

  const handleChange = (event: any) => {
    onChange(name, event);
  };

  return (
    <FormControl className={classes.customFormControl}>
      <InputLabel className={classes.customLabel}>{placeholder}</InputLabel>
      <Select
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
          setFocus(true);
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
          setFocus(false);
        }}
        className={classes.customSelect}
        name={name}
        value={value}
        error={error}
        onChange={(e) => (selectHandle ? handleChange(e) : onChange(e))}
      >
        {options.map((item, index) => {
          return (
            <MenuItem value={item.id} key={index}>
              {item.text || `${item.id} ${item.pais}`}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
