import moment from "moment";
import { getCurrentDate, statusPayment } from "src/infrastructure/static/util";
import { paymentRepository } from "../../infrastructure/repositories/Payment.repository";
import { IEmittedDoc } from "../models/Emitted";

const paymentVoucher = async (file_name: string) => {
  const response = await paymentRepository.paymentVoucher(file_name);
  return response;
};

const paymentVoucherPDF = async (data: any) => {
  const response = await paymentRepository.paymentVoucherPDF(data);
  return response;
};

const paymentInvoice = async (file_name: string, data: any) => {
  const response = await paymentRepository.paymentInvoice(file_name, data);
  return response;
};

const paymentReceiptPDF = async (data: any) => {
  const response = await paymentRepository.paymentReceiptPDF(data);
  return response;
};

const operationCharge = (infoFile: IEmittedDoc) => {
  let expirationDate;
  if (infoFile.payment !== null) {
    if (
      statusPayment(
        infoFile.payment.status,
        infoFile.payment.expiration_date
      ) === "expirado"
    ) {
      expirationDate = moment(getCurrentDate())
        .add(1, "days")
        .subtract(1, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");
    } else {
      expirationDate = moment(infoFile.payment.expiration_date).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
  } else {
    expirationDate = infoFile.metadata
      ? infoFile.metadata.formaPago === "Contado"
        ? moment(infoFile.issue_date)
            .add(47, "hours")
            .add(59, "minutes")
            .add(59, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
        : moment(infoFile.due_date)
            .add(1, "days")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
      : moment(getCurrentDate())
          .add(1, "days")
          .subtract(1, "seconds")
          .format("YYYY-MM-DD HH:mm:ss");
  }
  return expirationDate;
};

const hasCredentialsOpenPay = (companyInfo: any, infoFile: IEmittedDoc) => {
  if (!companyInfo.credentials_openpay) {
    return false;
  }
  if (
    JSON.parse(companyInfo.credentials_openpay)[infoFile.currency][
      "merchantId"
    ] === ""
  ) {
    return false;
  } else {
    return true;
  }
};

export const paymentService = {
  paymentVoucher,
  paymentVoucherPDF,
  paymentInvoice,
  operationCharge,
  hasCredentialsOpenPay,
  paymentReceiptPDF,
};
