import { EmittedDoc, EmittedDocsActionTypes } from "../types/emittedDoc";

const initialState: EmittedDoc[] = [];

const emittedDocsReducer = (state = initialState, action: EmittedDocsActionTypes) => {
  switch (action.type) {
    case "SET_EMITTED_DOCS":
      return action.payload;
    case "RESET_EMITTED_DOCS":
      return initialState;
    default:
      return state;
  }
};

export default emittedDocsReducer;
