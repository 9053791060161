import React, { useState } from "react";
import { Divider, Slide, CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import emailSent from "../../assets/imgs/email.sent.png";
import { useSelector, useDispatch } from "react-redux";
import { setConfigParam } from "../../../domain/store/actions/config";
import { afterLogin } from "../../../domain/store/afterLogin";
import { InputPassword, InputText } from "../input";
import TextStyle from "../textStyle";
import { Colors } from "../../static/colors";
import { ButtonAuth, ButtonPrimary, ButtonSecondary } from "../button";
import CustomCheckbox from "../checkbox";
import { useForm } from "../../hooks/useForm";
import { userService } from "../../../domain/services/User.service";
import {
  validatorForgotPassword,
  validatorLogin,
} from "../../static/validations";
import { PATH } from "../../http/Path";
import { handleBlurValidation } from "../../static/util";
import { Container } from "./styled";

interface Props {
  openRegisterForm: () => void;
}

export const LoginForm: React.FC<Props> = ({ openRegisterForm }) => {
  const config = useSelector((state: any) => state.config);

  const { form, onChange } = useForm({
    email: config.savedEmail || "",
    password: "",
  });

  const {
    form: formError,
    onChangeError,
    onReset,
  } = useForm({
    emailError: false,
    emailErrorText: "",
    passwordError: false,
    passwordErrorText: "",
  });

  const { form: formForgotPassword, onChange: onChangeForgotPassword } =
    useForm({
      emailForgotPassword: config.savedEmail || "",
    });

  const {
    form: formForgotPasswordError,
    onChangeError: onChangeErrorForgotPassword,
    onReset: onResetForgotPassword,
  } = useForm({
    emailForgotPasswordError: false,
    emailForgotPasswordErrorText: "",
  });

  const [saveEmail, setSaveEmail] = useState<boolean>(
    config.savedEmail || false
  );
  const [wrongCredentialsMsg, setWrongCredentialsMsg] = useState<string | null>(
    null
  );
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [emailResetPasswordErrorMsg, setEmailResetPasswordErrorMsg] = useState<
    string | null
  >(null);
  const [sentResetPassword, setSentResetPassword] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      const validateLogin = validatorLogin(form, formError);
      if (validateLogin) {
        onChangeError(validateLogin);
      } else {
        onReset();
        setIsloading(true);
        let res = await userService.postUserLogin(form);
        const data = res.data;
        if (data.result) {
          if (saveEmail) {
            dispatch(setConfigParam("savedEmail", form.email));
          } else if (config.savedEmail) {
            dispatch(setConfigParam("savedEmail", null));
          }
          afterLogin(data.token, data.userName, data.email, onLoginEnd());
        } else {
          setWrongCredentialsMsg("Credenciales incorrectas");
          setIsloading(false);
        }
      }
    } catch (error: any) {
      setIsloading(false);
      if (error.response && error.response.status === 403)
        setWrongCredentialsMsg("No se ha podido verificar el usuario");
      else setWrongCredentialsMsg("Credenciales incorrectas");
    }
  };

  const onLoginEnd = () => {};

  const handleResetPassword = async () => {
    try {
      const validateForgotPassword = validatorForgotPassword(
        formForgotPassword,
        formForgotPasswordError
      );
      if (validateForgotPassword) {
        onChangeErrorForgotPassword(validateForgotPassword);
      } else {
        setIsloading(true);
        onResetForgotPassword();
        let response = await userService.postUserForgotPassword(
          formForgotPassword
        );
        const data = response.data;
        if (data.result) setSentResetPassword(true);
        setIsloading(false);
      }
    } catch (error) {
      setEmailResetPasswordErrorMsg(
        "No se ha podido verificar el usuario o el usuario no se encuentra activo"
      );
    }
  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Container>
        <TextStyle bold={900} color={Colors.GREEN} type="h1" margin="10px 0px">
          {!forgotPassword ? "Iniciar sesión" : "Restablecer contraseña"}
        </TextStyle>
        <div>
          <Slide
            direction="left"
            in={!forgotPassword}
            unmountOnExit={forgotPassword}
          >
            <div>
              <InputText
                name="email"
                value={form.email}
                placeholder="Correo electrónico"
                onChange={onChange}
                error={formError.emailError}
                helperText={formError.emailErrorText}
                onBlur={() =>
                  handleBlurValidation(
                    validatorLogin,
                    onChangeError,
                    form,
                    formError,
                    "email"
                  )
                }
              />
              <InputPassword
                name="password"
                value={form.password}
                placeholder="Contraseña"
                onChange={onChange}
                error={formError.passwordError}
                helperText={formError.passwordErrorText}
                onBlur={() =>
                  handleBlurValidation(
                    validatorLogin,
                    onChangeError,
                    form,
                    formError,
                    "password"
                  )
                }
              />
              {wrongCredentialsMsg && (
                <TextStyle bold={400} color={Colors.RED} type="h3">
                  {wrongCredentialsMsg}
                </TextStyle>
              )}
              <CustomCheckbox
                color={Colors.GREEN}
                name="saveEmail"
                value={saveEmail}
                onChange={() => setSaveEmail(!saveEmail)}
                renderText={() => (
                  <TextStyle bold={400} color={Colors.TEXT} type="h4">
                    Recordar correo electrónico
                  </TextStyle>
                )}
              />
              <ButtonPrimary
                background={Colors.GREEN}
                color={Colors.WHITE}
                size="16px"
                onClick={handleLogin}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      variant="indeterminate"
                      size={20}
                      style={{ marginRight: 5, color: "white" }}
                    />
                  </>
                ) : (
                  "Ingresar"
                )}
              </ButtonPrimary>
              <div
                onClick={() => setForgotPassword(true)}
                style={{ cursor: "pointer", marginBottom: 10 }}
              >
                <TextStyle
                  bold={400}
                  color={Colors.GREEN}
                  type="h4"
                  textAlign="end"
                >
                  ¿Olvidaste tu contraseña?
                </TextStyle>
              </div>
              <div
                onClick={() => openRegisterForm()}
                style={{ cursor: "pointer" }}
              >
                <TextStyle
                  bold={400}
                  color={Colors.GREEN}
                  type="h4"
                  textAlign="end"
                >
                  ¿No tienes cuenta aún? Regístrate
                </TextStyle>
              </div>
              <Divider
                style={{
                  marginTop: "3em",
                  marginBottom: "2em",
                  marginInline: "3em",
                }}
              />
              <ButtonAuth
                border={Colors.GREEN}
                color={Colors.GREEN}
                size="16px"
                href={PATH.AUTH_GOOGLE}
              >
                <FontAwesomeIcon
                  icon={faGoogle}
                  style={{ fontSize: "14px", marginRight: 15 }}
                />
                Ingresa con Google
              </ButtonAuth>
            </div>
          </Slide>
          <Slide
            direction="left"
            in={forgotPassword}
            unmountOnExit={!forgotPassword}
          >
            <div>
              {!sentResetPassword ? (
                <>
                  <InputText
                    name="emailForgotPassword"
                    value={formForgotPassword.emailForgotPassword}
                    placeholder="Correo electrónico"
                    onChange={onChangeForgotPassword}
                    onBlur={() =>
                      handleBlurValidation(
                        validatorForgotPassword,
                        onChangeErrorForgotPassword,
                        formForgotPassword,
                        formForgotPasswordError,
                        "emailForgotPassword"
                      )
                    }
                    error={formForgotPasswordError.emailForgotPasswordError}
                    helperText={
                      formForgotPasswordError.emailForgotPasswordErrorText
                    }
                  />
                  {emailResetPasswordErrorMsg && (
                    <TextStyle bold={400} color={Colors.RED} type="h3">
                      {emailResetPasswordErrorMsg}
                    </TextStyle>
                  )}
                  <ButtonPrimary
                    background={Colors.GREEN}
                    color={Colors.WHITE}
                    size="16px"
                    onClick={handleResetPassword}
                  >
                    {isLoading ? (
                      <>
                        <CircularProgress
                          variant="indeterminate"
                          size={20}
                          style={{ marginRight: 5, color: "white" }}
                        />
                      </>
                    ) : (
                      "Reestablecer"
                    )}
                  </ButtonPrimary>
                  <ButtonSecondary
                    border={Colors.GREEN}
                    color={Colors.GREEN}
                    size="16px"
                    onClick={() => setForgotPassword(false)}
                  >
                    Cancelar
                  </ButtonSecondary>
                </>
              ) : (
                <>
                  <TextStyle bold={400} color={Colors.TEXT} type="h4">
                    Se ha enviado a su correo las instruciones para restablecer
                    su contraseña.
                  </TextStyle>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={emailSent}
                      style={{ marginTop: 10, height: 120 }}
                      alt="correo enviado"
                    />
                  </div>
                  <ButtonSecondary
                    border={Colors.GREEN}
                    color={Colors.GREEN}
                    size="16px"
                    onClick={() => setForgotPassword(false)}
                  >
                    Listo
                  </ButtonSecondary>
                </>
              )}
            </div>
          </Slide>
        </div>
      </Container>
    </Slide>
  );
};
