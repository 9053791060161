import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CompanyInfo } from "../../store/types/companies";
import {
  RButton,
  RButtonSecondary,
  RSwitch,
  RTextField,
  RRadio,
} from "../ratifikaElements";

import { direcciones, ubigeos } from "../../static/ubigeo";
import { fetch } from "../../store/fetch";
import ListAltIcon from "@material-ui/icons/ListAlt";

import CommentIcon from "@material-ui/icons/Comment";
import {
  DeleteTwoTone as DeleteIcon,
  CommentOutlined as NoteIcon,
  Send,
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { incrementSequential, Measure, measureList } from "../../static/util";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import moment from "moment";
import {
  faCubes,
  faHome,
  faPercent,
  faBusinessTime,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./style.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Alert from "@material-ui/lab/Alert";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Ubicacion {
  code: string;
  departamento: string;
  provincia: string;
  distrito: string;
}
interface Props {
  isOpen: boolean;

  close: () => void;
}
interface ErrorReport {
  title: string;
  message: string;
  details?: Array<string>;
}
interface Doc {
  tipo: string;
  serie: string;
  numeroComprobante: string;
  fechaEmision: Date;
  total: string;
  moneda: string;

  fechaPago: Date;
  numero: number;
  importePago: string;
  monedaPago: string;

  importe: number;
  fecha: Date;
  monto: string;
  monedaRetencion: string;

  monedaRef: number;
  monedaObj: number;
  tasaCambio: string;
  fechaCambio: Date;
}

interface Contributor {
  addresses: Array<object>;
  number_type: string;
  number: string;
  legal_name: string;
  is_new?: boolean;
}

interface Transportista {
  addresses: string;
  number_type: string;
  number: string;
  legal_name: string;
  is_new?: boolean;
}

interface Conductor {
  name: string;
  number: string;
  number_type: string;
}
interface MultiSelectItem {
  label: string;
  value: string;
}

interface Anotacion {
  codigo?: string;
  valor: string;
}

interface pago {
  fecha: Date;
  numero: number;
  importe: string;
  moneda: string;
}

interface ComprobanteItem {
  tipoComprobante: string;
  selectedDate: Date | null;
  serieReference: string;
  numberReference: string;
  currency: string;
  tipoCambio: number;
  totalRetenido: string;
  totalPagado: string;
  total: number;
  pago: pago;
}

function ColorlibStepIcon(props: StepIconProps) {
  const icons: { [index: string]: React.ReactElement } = {
    1: <ListAltIcon />,
    2: <LibraryBooksIcon />,
    3: <AssignmentTurnedInIcon />,
    4: <CheckCircleIcon />,
  };

  return <div>{icons[String(props.icon)]}</div>;
}

function getSteps() {
  return [
    "INFORMACION BASICA",
    "DATOS DE RETENCION",
    "COMPROBANTE",
    "CONFIRMAR",
  ];
}

export const NewRetencionModal: React.FC<Props> = ({ isOpen, close }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  ///
  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const emitter: CompanyInfo = companies.find(
    (c: CompanyInfo) => c.ruc === config.workspace
  );
  // Response
  const [responseError, setResponseError] = useState<ErrorReport | null>(null);
  //estados
  const [totalPagado, settotalPagado] = useState<string>("");
  const [totalRetenido, settotalRetenido] = useState<string>("");
  const [total, settotal] = useState<number>(0);

  const [codigo, setcodigo] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [hasAdressOrigenError, setHasAdressOrigenError] = useState(false);
  const [hasUbigeoOrigenError, setHasUbigeoOrigenError] = useState(false);
  const [notes, setNotes] = useState<Array<ComprobanteItem>>([])
  const [addDescription, setAddDescription] = useState<Array<string>>([]);
  const [tipoEnvio, setTipoEnvio] = useState<string>("");
  const [TipoComprobante, setTipoComprobante] = useState<string>("");
  const [tipoRegimen, setTipoRegimen] = useState<string>("");
  const [description, setdescription] = useState<string>("");
  const [hasTypeSendError, setHasTypeSendError] = useState(false);
  const [typeTransport, setTypeTransport] = useState<string>("02");
  // Buyer
  const [buyerKey, setBuyerKey] = useState<string>("");
  const [availableCustomers, setAvailableCustomers] = useState<
    Array<Contributor>
  >([]);
  const [autocompleteCustomers, setAutocompleteCustomers] = useState<
    Array<Contributor>
  >([]);

  const [tipoCambio, setTipoCambio] = useState<number>(0);

  const [currency, setCurrency] = useState<string>("PEN");
  const [legalName, setLegalName] = React.useState<string>("");
  const [errorMsgLegalName, setErrorMsgLegalName] = React.useState<
    string | null
  >(null);

  const [identification, setIdentification] = React.useState<string>("");
  const [errorMsgIdentification, setErrorMsgIdentification] = React.useState<
    string | null
  >(null);
  const [docType, setDocType] = React.useState<string>("");
  const [selectedAddress, setSelectedAddress] =
    useState<MultiSelectItem | null>(null);

  // Products
  const [docReferencia, setdocReferencia] = useState<Array<Doc>>([]);
  const [hasErrorItem, setHasErrorItem] = useState(false);
  const [errorMsgDoc, seterrorMsgDoc] = useState<string | null>(null);

  const [observaciones, setObservaciones] = useState<string>("");

  //

  /*Conductor */
  const [dniConductor, setDniConductor] = useState<string>("");
  const [conductorSuggestions, setConductorSuggestions] = useState<
    Array<Conductor>
  >([]);
  const [numberDriver, setNumberDriver] = useState<string>("");
  const [numberTypeDriver, setNumberTypeDriver] = useState<string>("");
  const [hasNumTypeDriverError, setHasNumTypeDriverError] = useState(false);
  const [nameDriver, setNameDriver] = useState<string>("");
  const [errorMsgDni, setErrorMsgDni] = React.useState<string | null>(null);

  const [rucCarrier, setRucCarrier] = useState<string>("");
  const [numberCarrier, setNumberCarrier] = useState<string>("");
  const [numberTypeCarrier, setNumberTypeCarrier] = useState<string>("");
  const [nameCarrier, setNameCarrier] = useState<string>("");
  const [hasNumTypeCarrierError, setHasNumTypeCarrierError] = useState(false);
  const [errorMsgCarrier, setErrorMsgCarrier] = React.useState<string | null>(
    null
  );
  const [carrierSuggestions, setCarrierSuggestions] = useState<
    Array<Transportista>
  >([]);
  const [referenceDoc, setReferenceDoc] = useState<boolean>(false);
  const [serieReference, setSerieReference] = useState<string>("");
  const [hasSerieError, setHasSerieError] = useState(false);
  const [numberReference, setNumberReference] = useState<string>("");
  const [hasCorrelativeError, setHasCorrelativeError] = useState(false);
  const [disableAll, setDisableAll] = useState<boolean>(false);
  const [errorMsgTryingSend, setErrorMsgTryingSend] = React.useState<
    string | null
  >("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsgAddressLine, setErrorMsgAddressLine] = React.useState<string | null>(null);
  const [availableAddresses, setAvailableAddresses] = useState<Array<MultiSelectItem>>([]);
  const [focusComponent, setFocusComponent] = useState<string>("");
  //Fecha de emision
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  useEffect(() => {
    async function fetchCostumersRuc() {
      const { data } = await axios.get<Contributor[]>(
        "/api/contributor/customersWithTaxID"
      );

      setAvailableCustomers(data);
    }
    async function fetchCostumersDni() {
      const { data } = await axios.get<Contributor[]>(
        "/api/contributor/customersWithoutTaxID"
      );
      let customer = [...availableCustomers];
      customer = data;
      setAvailableCustomers(customer);
    }
    if (!referenceDoc) {
      setDisableAll(false);
      setdocReferencia([]);
    }
    
    fetchCostumersRuc();
    fetchCostumersDni();
  }, [referenceDoc]);

  /*Funciones */
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  /*         settotal =setotalpagado +setotalretenido;
      }; */

  const deleteItemNoteClick = (index: any) => {
    let newArr = [...notes];
    newArr.splice(index, 1);
    setNotes(newArr);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      let hasGuideErrors = false;
      if (identification.trim() === "") {
        setErrorMsgIdentification(
          "Especifique una identificación para el comprador"
        );
        hasGuideErrors = true;
      }
      if (!hasGuideErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 1) {
      let hasDetailSendErrors = false;
      if (tipoRegimen === "") {
        setHasTypeSendError(true);
        hasDetailSendErrors = true;
      }

      if (!hasDetailSendErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 2) {
      let hasItemErrors = false;
      if (notes.length === 0) {
        hasItemErrors = true;
        setHasErrorItem(true);
        seterrorMsgDoc("Debes incluir algún documento en la retencion");
      }
      //console.log("error antes", notes[0].currency)
      for(let i=0; i<notes.length;i++){
        if(!notes[i].currency){
          setHasErrorItem(true)
          hasItemErrors = true;
          console.log("error")
        }
      }
      if (!hasItemErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    if (activeStep === 3) {
      setLoading(true);
      sendGuia();
    }
  };

  const sendGuia = async () => {
    const responseSequential = await axios.get(
      `/api/company/lastSequential/${emitter.ruc}/20`
    );
    if (!responseSequential.data.result) {
      setResponseError({
        title: "Ha ocurrido un problema",
        message: responseSequential.data.msg,
      });
      return;
    }

    const json = generateJsonRetenciones(
      responseSequential.data.serie,
      responseSequential.data.sequential
    );

    let { data } = await axios.post("/api/emission/send", json);

    setLoading(false);
    if (data.procesado) {
      close();
      setTimeout(() => fetch(["emission"]), 1000);
    } else {
      setErrorMsgTryingSend(`${data.mensaje}`);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*  const handleReset = () => {
        setActiveStep(0);
    }; */
  const updateItemVoucherTypeChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...notes];
    newArr[index].tipoComprobante = e.target.value as string;
    setNotes(newArr);
    setHasTypeSendError(false);
  };
  const updateItemSelectedDateChanged = async(index: any, Date: any) => {
    let newArr = [...notes];
    newArr[index].selectedDate = Date;
    if(newArr[index].currency === 'USD'){
      const { data } = await axios.get(`/api/tipoCambio/day?fecha=${moment(Date).format("YYYY-MM-DD")}`);
      newArr[index].tipoCambio = data.venta;
    }
    else{
      newArr[index].tipoCambio = 0;
    }
    setNotes(newArr);
  };
  const updateSerieReferenceChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...notes];
    newArr[index].serieReference = e.target.value as string;
    setNotes(newArr);
  };
  const updateNumberReferenceChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...notes];
    newArr[index].numberReference = e.target.value as string;
    setNotes(newArr);
  };
  const updateCurrencyChanged = async (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...notes];
    newArr[index].currency = e.target.value as string;
    setNotes(newArr);
    setHasErrorItem(false)
  };
  const updateTotalChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...notes];
    newArr[index].total = e.target.value as number;
    newArr[index].totalRetenido = String(
      ((newArr[index].total/100) * Number(tipoRegimen)).toFixed(2)
    );

    newArr[index].totalPagado = String(
      (newArr[index].total - Number(newArr[index].totalRetenido)).toFixed(2)
    );
    setNotes(newArr);
  };

  const updateTotalPagadoChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...notes];
    newArr[index].totalPagado = e.target.value as string;
    newArr[index].total = Number(newArr[index].total - Number(totalRetenido));
    setNotes(newArr);
  };
  const handleChangeTipoRegimen = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTipoRegimen(event.target.value as string);
    setHasTypeSendError(false);
  };

  /*Contributor */
  const getCustomersSuggestions = async (
    value: string
  ): Promise<Contributor[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results = [];

    if (Number.isInteger(Number(inputValue))) {
      // posiblemente sea un RUC
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter(
                (contrib) => contrib.number.slice(0, inputLength) === inputValue
              )
              .sort((a, b) => {
                return (
                  a.number.indexOf(inputValue) - b.number.indexOf(inputValue)
                );
              });

      if (results.length === 0) {
        if (inputLength === 8) {
          await axios
            .get(`/api/info/by/dni/${Number(inputValue)}`)
            .then((e) => {
              const data = e.data;
              results = [
                {
                  legal_name: data.nombre,
                  number: inputValue,
                  number_type: data.tipoDocumento,
                  addresses: [
                    {
                      ubigeo: data.ubigeo || null,
                      address_line: data.direccion || null,
                      district: data.distrito || null,
                      province: data.provincia || null,
                      department: data.departamento || null,
                      default: true,
                    },
                  ],
                  is_new: true,
                },
              ];
            });
        }
        if (inputLength === 11) {
          await axios
            .get<any>(`/api/info/by/ruc/?ruc=${Number(inputValue)}`)
            .then((e) => {
              const data = e.data;
              results = [
                {
                  legal_name: data.nombre,
                  number: inputValue,
                  number_type: data.tipoDocumento,
                  addresses: [
                    {
                      ubigeo: data.ubigeo || null,
                      address_line: data.direccion || null,
                      district: data.distrito || null,
                      province: data.provincia || null,
                      department: data.departamento || null,
                      default: true,
                    },
                  ],
                  is_new: true,
                },
              ];
            });
        }
      }
    } else {
      // Posiblemente sea un nombre legal
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter((contrib) =>
                contrib.legal_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
              .sort((a, b) => {
                return (
                  a.legal_name.indexOf(inputValue) -
                  b.legal_name.indexOf(inputValue)
                );
              });
    }
    return results;
  };

  const getSuggestionValue = (suggestion: Contributor) => {
    if (suggestion.is_new) {
      ////Direccion del Nuevo sugerido
      const defaultAddress =
        suggestion.addresses.length !== 0
          ? (suggestion.addresses.find(
              (item: any) => item.default === true
            ) as any)
          : null;

      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        const codeUbigeo = direcciones.find(
          (e) => e.code === defaultAddress.ubigeo
        );
      }

      //
      setLegalName(suggestion.legal_name || "");
      setIdentification(suggestion.number || "");
      setDocType(suggestion.number_type);
      setSelectedAddress(
        defaultAddress
          ? {
              label: defaultAddress.address_line,
              value: defaultAddress.address_line,
            }
          : null
      );
    } else {
      /*  debugger */
      // Ya existe data del cliente
      setLegalName(suggestion.legal_name);
      setIdentification(suggestion.number);
      setDocType(suggestion.number_type);

      const defaultAddress = suggestion.addresses
        ? (suggestion.addresses.find(
            (item: any) => item.default === true
          ) as any)
        : null;

      setSelectedAddress(
        defaultAddress
          ? {
              label: defaultAddress.address_line,
              value: defaultAddress.address_line,
            }
          : null
      );

      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        const codeUbigeo = direcciones.find(
          (e) => e.code === defaultAddress.ubigeo
        );
      }
    }

    if (errorMsgLegalName) {
      setErrorMsgLegalName(null);
    }
    if (errorMsgIdentification) {
      setErrorMsgIdentification(null);
    }
    // console.log("setSelectedAddress", selectedAddress)
    return suggestion.legal_name || suggestion.number;
  };

  const renderSuggestion = (suggestion: Contributor, { query }: any) => {
    if (suggestion.is_new) {
      if (suggestion.legal_name) {
        return (
          <span>
            "<strong>{suggestion.legal_name}</strong>"
          </span>
        );
      } else {
        return (
          <span>
            "<strong>Ruc invalido</strong>"
          </span>
        );
      }
    } else {
      const handleNumber = Number.isInteger(Number(query));
      const suggestionText = handleNumber
        ? suggestion.number
        : suggestion.legal_name;

      const indexOfSuggestion = suggestionText
        .toLowerCase()
        .indexOf(query.toLowerCase());
      const substring = suggestionText.substring(
        indexOfSuggestion,
        indexOfSuggestion + query.length
      );
      const highlight = suggestionText.replace(
        substring,
        `<strong ${
          handleNumber ? `style="font-size: 12px"` : ""
        }>${substring}</strong>`
      );
      return (
        <div>
          {!handleNumber ? (
            <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
          ) : (
            <span>{suggestion.legal_name}</span>
          )}
          <br></br>
          {handleNumber ? (
            <span
              style={{ fontSize: 12 }}
              dangerouslySetInnerHTML={{ __html: highlight }}
            ></span>
          ) : (
            <strong style={{ fontSize: 12 }}>{suggestion.number}</strong>
          )}
        </div>
      );
    }
  };

  const onSuggestionsFetchRequested = async (param: any) => {
    setAutocompleteCustomers(await getCustomersSuggestions(param.value));
  };

  const onSuggestionsClearRequested = () => {
    setAutocompleteCustomers([]);
  };

  /*Transportista */
  const getRucSuggestions = async (value: string): Promise<Transportista[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results: any[] = [];
    if (Number.isInteger(Number(inputValue))) {
      if (inputLength === 11) {
        await axios
          .get<any>(`/api/info/by/ruc/?ruc=${Number(inputValue)}`)
          .then((e) => {
            const data = e.data;
            results = [
              {
                legal_name: data.nombre,
                number: inputValue,
                number_type: data.tipoDocumento,
              },
            ];
          });
      } else {
        results = [
          {
            number: inputValue,
            number_type: "",
            legal_name: "",
          },
        ];
      }
    }

    return results;
  };

  /*Conductor*/
  const getDniSuggestions = async (value: string): Promise<Conductor[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results: any[] = [];
    if (Number.isInteger(Number(inputValue))) {
      if (inputLength === 8) {
        await axios.get(`/api/info/by/dni/${Number(inputValue)}`).then((e) => {
          const data = e.data;
          results = [
            {
              name: data.nombre,
              number: inputValue,
              number_type: data.tipoDocumento,
            },
          ];
        });
      } else {
        results = [
          {
            number: inputValue,
            number_type: "",
          },
        ];
      }
    }

    return results;
  };
  const onSuggestionsFetchConductor = async (param: any) => {
    setConductorSuggestions(await getDniSuggestions(param.value));
  };

  const getSuggestionValConductor = (suggestion: Conductor) => {
    setNumberDriver(suggestion.number);
    setNumberTypeDriver(suggestion.number_type);
    setNameDriver(suggestion.name);
    setHasNumTypeDriverError(false);
    setErrorMsgDni(null);
    return suggestion.name || suggestion.number;
  };

  const renderConductor = (suggestion: Conductor, { query }: any) => {
    return (
      <span>
        <strong>{suggestion.name}</strong>
      </span>
    );
  };
  const conductorClearRequested = () => {
    setConductorSuggestions([]);
  };

  /*Procesamiento de retenciones */
  const generateJsonRetenciones = (serie: string, sequential: string) => {
    let jsonRetencion: any = {
      ubl: "2.0",
      tipoComprobante: "20",
      serie: `R${serie}`,
      numeroComprobante: incrementSequential(sequential),
      actualizarSecuencial: true,
      fechaEmision: moment(selectedDate).format("YYYY-MM-DD"),
      horaEmision: moment().format("HH:mm:ss"),
      emisor: {
        numeroDocumento: emitter.ruc,
        tipoDocumento: "6",
        nombre: emitter.legal_name,
        direccion: {
          codigo: "0000",
          ubigeo: emitter.ubigeo,
          provincia: emitter.province,
          departament: emitter.department,
          distrito: emitter.district,
          direccionCompleta: emitter.address_line,
        },
      },
      receptor: {
        numeroDocumento: identification,
        tipoDocumento: docType,
        nombre: legalName,
        direccion: {
          direccionCompleta:
            selectedAddress?.value != null ? selectedAddress?.value : "-",
          pais: "PE", // TODO: incluir pais
        },
      },

      retencion: {
        codigo: tipoRegimen==="6"?"02":"01",
        tasa: tipoRegimen,
        descripcion: observaciones,
        totalRetenido: String((notes.map((item)=> {
          if(item.currency === "USD"){
            return Number(item.totalRetenido)*item.tipoCambio
          }
          else{
            return Number(item.totalRetenido)
          }
        }).reduce((prev, curr)=> prev + curr,0)).toFixed(2)),
        totalPagado: String((notes.map((item)=> {
          if(item.currency === "USD"){
            return Number(item.totalPagado)*item.tipoCambio
          }
          else{
            return Number(item.totalPagado)
          }
        }).reduce((prev, curr)=> prev + curr,0)).toFixed(2)),
        redondeo: "0.00",
        moneda: currency,
      },
      docReferencia: notes.map((doc, index) => {
        if(doc.currency === "USD"){
          return {
            tipoComprobante: doc.tipoComprobante,
            serie: doc.serieReference,
            numeroComprobante: doc.numberReference,
            fechaEmision: moment(doc.selectedDate).format("YYYY-MM-DD"),
            total: doc.total,
            moneda: doc.currency,
            pago: {
              fecha: moment(doc.selectedDate).format("YYYY-MM-DD"),
              numero: "1",
              importe: String((Number(doc.totalPagado)*doc.tipoCambio).toFixed(2)),
              moneda: doc.currency,
            },
            retencion: {
              importe: String((Number(doc.totalRetenido)*doc.tipoCambio).toFixed(2)),
              fecha: moment(doc.selectedDate).format("YYYY-MM-DD"),
              monto: String((Number(doc.totalPagado)*doc.tipoCambio).toFixed(2)),
              moneda: "PEN",
            },
            tipoCambio: {
              monedaRef: doc.currency,
              monedaObj: "PEN",
              tasaCambio: doc.tipoCambio,
              fechaCambio: moment(selectedDate).format("YYYY-MM-DD"),
            },
          };  
        } 
        else{
          return {
            tipoComprobante: doc.tipoComprobante,
            serie: doc.serieReference,
            numeroComprobante: doc.numberReference,
            fechaEmision: moment(doc.selectedDate).format("YYYY-MM-DD"),
            total: doc.total,
            moneda: doc.currency,
            pago: {
              fecha: moment(doc.selectedDate).format("YYYY-MM-DD"),
              numero: "1",
              importe: doc.totalPagado,
              moneda: doc.currency,
            },
            retencion: {
              importe: doc.totalRetenido,
              fecha: moment(doc.selectedDate).format("YYYY-MM-DD"),
              monto: doc.totalPagado,
              moneda: "PEN",
            }
          };
        }
      }),
    };
    return jsonRetencion;
  };
  /*Notas */

  const onSuggestionsFetchCarrier = async (param: any) => {
    setCarrierSuggestions(await getRucSuggestions(param.value));
  };
  const carrierClearRequested = () => {
    setCarrierSuggestions([]);
  };
  const getSuggestionValCarrier = (suggestion: Transportista) => {
    setNumberCarrier(suggestion.number);
    setNumberTypeCarrier(suggestion.number_type);
    setNameCarrier(suggestion.legal_name);
    setHasNumTypeCarrierError(false);
    setErrorMsgCarrier(null);
    return suggestion.legal_name || suggestion.number;
  };
  const renderCarrier = (suggestion: Transportista, { query }: any) => {
    if (suggestion.legal_name) {
      return (
        <span>
          <strong>{suggestion.legal_name || suggestion.number}</strong>
        </span>
      );
    }
  };

  const calculateTotalRetenido = () => {
    let totalRetenido = String((notes.map((item)=> {
      if(item.currency === "USD"){
        return Number(item.totalRetenido)*item.tipoCambio
      }
      else{
        return Number(item.totalRetenido)
      }
    }).reduce((prev, curr)=> prev + curr,0)).toFixed(2))
    return "S/ "+totalRetenido
  }

  const calculateTotalPagado = () => {
    let totalPagado = String((notes.map((item)=> {
      if(item.currency === "USD"){
        return Number(item.totalPagado)*item.tipoCambio
      }
      else{
        return Number(item.totalPagado)
      }
    }).reduce((prev, curr)=> prev + curr,0)).toFixed(2))
    return "S/ " + totalPagado
  }

  return (
    <>
      <Dialog
        open={isOpen}
        scroll={"paper"}
        keepMounted
        onClose={close}
        fullWidth={true}
        maxWidth={"md"}
        color="inherit"
      >
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DialogTitle>
          {"Retenciones"}
          <span style={{ fontSize: 15, marginLeft: 15 }}>
            / emitida por: {emitter.legal_name}
          </span>
          {responseError && (
            <Grid item xs={12} md={8} sm={10}>
              {/*  <NoteBox
                type={NoteType.Error}
                title={responseError.title}
                body={responseError.message}
                details={responseError.details}
              /> */}
            </Grid>
          )}
        </DialogTitle>
        <DialogContent dividers={false}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                    <StepContent>
                      {index === 0 && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6}>
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                PROVEEDOR
                              </Typography>
                              <br />
                              <div
                                className={
                                  disableAll
                                    ? "autocompleteDisable"
                                    : "autocomplete"
                                }
                              >
                                <Autosuggest
                                  suggestions={autocompleteCustomers}
                                  onSuggestionsFetchRequested={
                                    onSuggestionsFetchRequested
                                  }
                                  onSuggestionsClearRequested={
                                    onSuggestionsClearRequested
                                  }
                                  getSuggestionValue={getSuggestionValue}
                                  renderSuggestion={renderSuggestion}
                                  inputProps={{
                                    placeholder:
                                      "Identificación o nombre legal",
                                    value: buyerKey,
                                    onChange: (
                                      event: React.FormEvent<any>,
                                      {
                                        newValue,
                                        method,
                                      }: Autosuggest.ChangeEvent
                                    ) => {
                                      setBuyerKey(newValue);
                                      if (newValue === "") {
                                        setLegalName("");
                                        setIdentification("");
                                        setDocType("");
                                      }
                                    },
                                    ref: React.createRef<HTMLInputElement>(),
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6}>
                              <div style={{ float: "right" }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date_emission"
                                    label="Fecha de Emisión "
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} style={{ marginBottom: 5 }}>
                      <Grid item>
                        <RTextField
                          id="id-number"
                          label="RUC"
                          variant="outlined"
                          size="small"
                          required
                          autoFocus={focusComponent === "number" ? true : false}
                          autoComplete="off"
                          disabled={true}
                          helperText={errorMsgIdentification}
                          error={errorMsgIdentification !== null}
                          value={identification}
                          onChange={(e) => {
                            setIdentification(e.target.value);
                            if (errorMsgIdentification && e.target.value.trim() !== "") {
                              setErrorMsgIdentification(null);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                          {/* <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6}>
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                RUC
                              </Typography>
                              <br />

                              
                              <Autosuggest
                                suggestions={carrierSuggestions}
                                onSuggestionsFetchRequested={
                                  onSuggestionsFetchCarrier
                                }
                                onSuggestionsClearRequested={
                                  carrierClearRequested
                                }
                                getSuggestionValue={getSuggestionValCarrier}
                                renderSuggestion={renderCarrier}
                                inputProps={{
                                  placeholder: "Ruc",
                                  value: identification,
                                  //type: "search",
                                  onChange: (
                                    event: React.FormEvent<any>,
                                    {
                                      newValue,
                                      method,
                                    }: Autosuggest.ChangeEvent
                                  ) => {
                                    setRucCarrier(newValue);
                                    if (newValue === "") {
                                      setNumberCarrier("");
                                      setNumberTypeCarrier("");
                                      setHasNumTypeDriverError(true);
                                    }
                                  },
                                  ref: React.createRef<HTMLInputElement>(),
                                }}
                              />
                            </Grid>{" "}
                          </Grid> */}
                          {/* <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6}>
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                Dirección
                              </Typography>
                              <br />
                              <RTextField
                                id="direccion"
                                label=""
                                variant="outlined"
                                size="small"
                                autoFocus={true}
                                required
                                fullWidth={true}
                                margin="normal"
                                error={hasAdressOrigenError}
                                value={selectedAddress?.label}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                  setHasAdressOrigenError(false);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <FontAwesomeIcon
                                      icon={faHome}
                                      style={{
                                        fontSize: "18px",
                                        paddingRight: "3px",
                                      }}
                                    />
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid> */}
                          <Autocomplete
                      id="autocomplete-addresses"
                      freeSolo
                      size="small"
                      style={{ marginTop: 10, width: "50%" }}
                      value={selectedAddress}
                      onChange={(e, newAddress) => {
                        setSelectedAddress(newAddress as MultiSelectItem | null);
                        if (errorMsgAddressLine && newAddress) {
                          setErrorMsgAddressLine(null);
                        }
                      }}
                      options={availableAddresses}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <RTextField
                          {...params}
                          label="Dirección"
                          required
                          variant="outlined"
                          autoFocus={focusComponent === "address_line" ? true : false}
                          helperText={errorMsgAddressLine}
                          error={errorMsgAddressLine !== null}
                          InputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            endAdornment: (
                              <FontAwesomeIcon
                                icon={faHome}
                                style={{
                                  fontSize: "18px",
                                  paddingRight: "3px",
                                }}
                              />
                            ),
                          }}
                          onChange={(e) => {
                            let newAddress: MultiSelectItem | null =
                              e.target.value.trim() !== ""
                                ? {
                                  label: e.target.value,
                                  value: e.target.value,
                                }
                                : null;
                            setSelectedAddress(newAddress);
                            if (errorMsgAddressLine && newAddress) {
                              setErrorMsgAddressLine(null);
                            }
                          }}
                        />
                      )}
                    />
                        </>
                      )}
                      {/* SECCION 2 */}
                      {index === 1 && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6}>
                              <FormControl variant="outlined" error={hasTypeSendError}>
                                <InputLabel id="demo-simple-select-outlined-label">
                                  REGIMEN
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={tipoRegimen}
                                  onChange={handleChangeTipoRegimen}
                                  label="REGIMEN"
                                  style={{ minWidth: 220 }}
                                  error={hasTypeSendError}
                                  
                                >
                                  <MenuItem value="6">Tasa 6%</MenuItem>
                                  <MenuItem value="3">Tasa 3%</MenuItem>
                                </Select>
                                {hasTypeSendError && <FormHelperText>Especifique el tipo de regimen</FormHelperText>}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6} sm={6}></Grid>

                            <Grid item xs={12} md={6} sm={6}>
                              <RTextField
                                id="nota"
                                label="Observaciones"
                                variant="outlined"
                                size="small"
                                multiline
                                rows={2}
                                fullWidth={true}
                                margin="normal"
                                onChange={(e) => {
                                  let newArr = [];
                                  newArr.push(e.target.value);
                                  // setNotes(newArr);
                                  setObservaciones(e.target.value);
                                }}
                                value={observaciones}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {/* SECCION 3 */}
                      {index === 2 && (
                        <>
                          <div style={{ width: "100%" }}>
                            <Button
                              variant="contained"
                              color="default"
                              disableElevation
                              onClick={() =>
                                setNotes([...notes, {} as ComprobanteItem])
                              }
                            >
                              <AssignmentIcon
                                fontSize="small"
                                style={{ marginRight: 5 }}
                              />{" "}
                              AGREGAR COMPROBANTE
                            </Button>
                            {notes.map((note, index) => (
                              <div
                                key={index}
                                style={{ display: "flex", marginTop: 20 }}
                              >
                                <Tooltip title="Retirar comprobante" arrow>
                                  <IconButton
                                    style={{ marginRight: 10, width: 40 }}
                                    size="small"
                                    onClick={() => deleteItemNoteClick(index)}
                                  >
                                    <DeleteIcon
                                      fontSize="small"
                                      color="error"
                                    />
                                  </IconButton>
                                </Tooltip>

                                <Grid item xs={12} md={10} sm={8}>
                                  <Card variant="outlined" className="card">
                                    <CardContent style={{ paddingBottom: 10 }}>
                                      {" "}
                                      <Grid
                                        container
                                        spacing={5}
                                        style={{ marginBottom: 2 }}
                                      >
                                        <Grid item>
                                          <FormControl variant="outlined">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                              TIPO COMPROBANTE
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-outlined-label"
                                              id="demo-simple-select-outlined"
                                              value={note.tipoComprobante}
                                              onChange={(e) =>
                                                updateItemVoucherTypeChanged(
                                                  index,
                                                  e
                                                )
                                              }
                                              label="REGIMEN"
                                              style={{ minWidth: 220 }}
                                              error={hasTypeSendError}
                                            >
                                              <MenuItem value="01">
                                                FACTURA
                                              </MenuItem>
                                              <MenuItem value="12">
                                                TICKET DE MÁQUINA REGISTRADORA
                                              </MenuItem>
                                              <MenuItem value="07">
                                                NOTA DE CRÉDITO
                                              </MenuItem>
                                              <MenuItem value="08">
                                                NOTA DE DÉBITO
                                              </MenuItem>
                                              <MenuItem value="20">
                                                COMPROBANTE DE RETENCIÓN
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>{" "}
                                        <br />
                                        <Grid item style={{ marginTop: -10 }}>
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              disableToolbar
                                              variant="inline"
                                              format="dd/MM/yyyy"
                                              margin="normal"
                                              id="date_emission"
                                              label="Fecha de Emisión "
                                              value={note.selectedDate}
                                              onChange={(Date) =>
                                                updateItemSelectedDateChanged(
                                                  index,
                                                  Date
                                                )
                                              }
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </Grid>
                                      </Grid>
                                      <Typography
                                        color="textSecondary"
                                        variant="caption"
                                      >
                                        Documento
                                      </Typography>
                                      <Grid
                                        container
                                        spacing={2}
                                        style={{ marginBottom: 5 }}
                                      >
                                        <Grid item>
                                          <RTextField
                                            variant="outlined"
                                            autoFocus
                                            size="small"
                                            autoComplete="off"
                                            label="Serie *"
                                            style={{ width: 100 }}
                                            value={note.serieReference}
                                            onChange={(e) => {
                                              updateSerieReferenceChanged(
                                                index,
                                                e
                                              );
                                              if (
                                                e.target.value.trim().length ===
                                                4
                                              ) {
                                                setHasSerieError(false);
                                              }
                                            }}
                                            error={hasSerieError}
                                          />
                                        </Grid>
                                        <h6>-</h6>
                                        <Grid item>
                                          <RTextField
                                            variant="outlined"
                                            size="small"
                                            autoComplete="off"
                                            label="Correlativo"
                                            style={{ width: 120 }}
                                            type="number"
                                            value={note.numberReference}
                                            onChange={(e) => {
                                              updateNumberReferenceChanged(
                                                index,
                                                e
                                              );
                                              if (
                                                e.target.value.trim().length <=
                                                5
                                              ) {
                                                setHasCorrelativeError(false);
                                              }
                                            }}
                                            error={hasCorrelativeError}
                                            required
                                          />
                                        </Grid>

                                        <Grid item style={{ marginBottom: 5 }}>
                                          <RTextField
                                            id="total"
                                            label="Total"
                                            variant="outlined"
                                            autoFocus={true}
                                            autoComplete="number"
                                            required
                                            size="small"
                                            type="number"
                                            value={note.total}
                                            onChange={(e) => {
                                              updateTotalChanged(index, e);
                                            }}
                                          />{" "}
                                        </Grid>
                                      </Grid>
                                      <Typography
                                        color="textSecondary"
                                        variant="caption"
                                      >
                                        Moneda
                                      </Typography>
                                      <Grid
                                        container
                                        spacing={2}
                                        style={{ marginBottom: 5 }}
                                      >
                                        <Grid item>
                                          <FormControl error={hasErrorItem}>
                                            <RadioGroup
                                              row
                                              value={note.currency}
                                              onChange={(e) =>
                                                updateCurrencyChanged(index, e)
                                              }
                                              style={{ padding: 10 }}
                                            >
                                              <FormControlLabel
                                                value="PEN"
                                                control={
                                                  <RRadio color="primary"/>
                                                }
                                                label="SOLES"
                                              />
                                              <FormControlLabel
                                                value="USD"
                                                control={
                                                  <RRadio color="primary" />
                                                }
                                                label="DÓLARES"
                                              />
                                            </RadioGroup>
                                            {hasErrorItem && <FormHelperText>Seleccione el tipo de moneda</FormHelperText>}
                                          </FormControl>

                                          <Grid item xs={4}>
                                            {note.currency === "USD" ? (
                                              <TextField
                                                style={{ width: 120 }}
                                                disabled
                                                id="filled-disabled"
                                                label="Tipo de cambio"
                                                value={note.tipoCambio}
                                                variant="outlined"
                                                size="small"
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid item>
                                          <RTextField
                                            id="total-retenido"
                                            label="Total Retenido"
                                            variant="outlined"
                                            autoFocus={true}
                                            autoComplete="number"
                                            required
                                            size="small"
                                            type="number"
                                            value={note.currency==="USD"? (Number(note.totalRetenido)*note.tipoCambio).toFixed(2):note.totalRetenido}
                                            InputLabelProps={{ shrink: true }}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={2}
                                        style={{ marginBottom: 5 }}
                                      >
                                        <Grid item></Grid>
                                        <Grid item>
                                          <RTextField
                                            id="total-pagado"
                                            label="Total Pagado"
                                            variant="outlined"
                                            autoFocus={true}
                                            autoComplete="number"
                                            required
                                            size="small"
                                            type="number"
                                            value={note.currency==="USD"?  (Number(note.totalPagado)*note.tipoCambio).toFixed(2):note.totalPagado}
                                            InputLabelProps={{ shrink: true }}
                                          />
                                        </Grid>{" "}
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      <div style={{ marginBottom: 5, marginTop: 25 }}>
                        
                        <div>
                        {activeStep === steps.length - 1 ?(<div>
                        <TextField
                          style={{ width: 120 }}
                          disabled
                          id="filled-disabled"
                          label="Total Retenido"
                          value={calculateTotalRetenido()}
                          variant="outlined"
                          size="small"
                        />
                        <TextField
                          style={{ width: 120, marginLeft: 5}}
                          disabled
                          id="filled-disabled"
                          label="Total Pagado"
                          value={calculateTotalPagado()}
                          variant="outlined"
                          size="small"
                        />
                        </div>):("")}
                        
                          {activeStep !== 0 && (
                            <RButtonSecondary
                              onClick={handleBack}
                              style={{ marginTop: 5, marginRight: 10 }}
                            >
                              Atras
                            </RButtonSecondary>
                          )}
                          <RButton
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            style={{ marginTop: 5, marginRight: 3 }}
                            disabled={loading}
                          >
                            {activeStep === steps.length - 1 ? (
                              <>
                                {loading ? (
                                  <>
                                    <CircularProgress
                                      color="inherit"
                                      size={14}
                                    />
                                    Enviando...
                                  </>
                                ) : (
                                  <>
                                    <Send
                                      fontSize="small"
                                      style={{ marginRight: 5 }}
                                    />
                                    {"Enviar"}
                                  </>
                                )}
                              </>
                            ) : (
                              "Continuar"
                            )}
                          </RButton>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <RButtonSecondary onClick={close} color="primary">
            Cancelar
          </RButtonSecondary>
        </DialogActions>
      </Dialog>

      {/*   {loading?(
            <Backdrop className={classes.backdrop} open={loading}> </Backdrop>
            ):""}    */}
    </>
  );
};
