import React, { useEffect, useState } from "react";
import { ReceivedDoc } from "../../store/types/receivedDoc";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  CheckCircleOutline,
  WarningTwoTone,
  DescriptionTwoTone,
  ReplyAllTwoTone,
  PrintTwoTone,
  GetAppTwoTone as DownloadIcon,
  CreditCard,
} from "@material-ui/icons";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  TablePagination,
  Tooltip,
  Typography,
  TableContainer,
  Paper,
  Button,
  InputLabel,
  Link,
  Dialog,
  DialogContent,
  Grid,
  Chip,
  FormControlLabel,
} from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import { getCurrencySymbol, getDocumentTypeDesc } from "../../static/util";
import emptyDocsIllustration from "../../imgs/emptyDocs.png";
import { Colors } from "../../static/colors";
import axios from "axios";
import FileDownload from "js-file-download";
import { generatePrintableVoucher } from "../emittedDocList";
import { ResendDocumentEmail } from "../resendDocumentEmail/index_received";
import { CompanyInfo } from "../../store/types/companies";
import { RCheckbox, RChip } from "../ratifikaElements";
import logoPay from "../../imgs/openPay.png";
import "./style.css"
import Alert from '@material-ui/lab/Alert'
import { PrivacyPolicy } from "../privacyPolicy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";

interface Props {
  text: string;
  optional?: string;
  fn?: (bob: string) => string;
}

interface detailsPayment {
  expiration: string;
  url: string;
  status: string;
  description: string
  total: number
}

interface Column {
  id: "fecha" | "numero" | "tipo" | "proveedor" | "total";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "fecha", label: "Fecha\u00a0emisión", minWidth: 80 },
  { id: "numero", label: "Número", minWidth: 150 },
  { id: "tipo", label: "Tipo", minWidth: 100 },
  { id: "proveedor", label: "Proveedor", minWidth: 170 },
  {
    id: "total",
    label: "Total",
    minWidth: 100,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

const ReceivedDocItem = (props: { doc: ReceivedDoc, onResentDocumentEmail: (document: ReceivedDoc) => void; }) => {
  const { doc, onResentDocumentEmail } = props;
  const [open, setOpen] = React.useState(false);
  const [showBtnXml, setShowBtnXml] = React.useState<boolean>(true);
  const [showBtnCDR, setShowBtnCDR] = React.useState<boolean>(true);
  const [notFile, setNotFile] = useState<string | null>(null);
  const [openCharge, setOpenCharge] = React.useState(false);
  const [chargeDetails, setChargeDetails] = React.useState<detailsPayment | null>(null);
  const [expired, setExpired] = React.useState<boolean>(false);
  const [privacy, setPrivacy] = React.useState<boolean>(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);

  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const receiver: CompanyInfo = companies.find(
    (c: CompanyInfo) => c.ruc === config.workspace
  );

  //this is a test for to see the doc provided
  //functions about buttons
  const printVoucher = async (fileName: string, id: number) => {
    let { data } = await axios.get(
      `/api/reception/download/info/${id}/${fileName}`
    );

    if (data.result) {
      
      const content: any =   await generatePrintableVoucher(data.doc, data.QR);
      let winPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0"
      );
      winPrint?.document.write(content);
      winPrint?.document.close();
      winPrint?.focus();
      winPrint?.print();
      winPrint?.close();

    }
  };

  const downloadPdf = async (fileName: string, id: number) => {
    let { data } = await axios.get(
      `/api/reception/download/pdf/${id}/${fileName}`,
      {
        responseType: "blob",
      }
    );
    if (data.size > 700) {
      FileDownload(new Blob([data]), `${fileName}.pdf`);
    }
  };

  const downloadXml = async (fileName: string, id: number) => {
    try {
      let { data } = await axios.get(
        `/api/reception/download/xml/${id}/${fileName}`,
        {
          responseType: "blob",
        }
      );
      FileDownload(new Blob([data]), `${fileName}.zip`);
    } catch (error) {
      console.log(error)
    }



  };

  const downloadCDR = async (fileName: string, id: number) => {
    try {
      let { data } = await axios.get(
        `/api/reception/download/cdr/${id}/${fileName}`,
        {
          responseType: "blob",
        }

      );

      if (data.type !== 'application/json') {
        FileDownload(new Blob([data]), `CDR_${fileName}.zip`);
      } else {
        setShowBtnCDR(false);
        setNotFile("Archivo CDR no encontrado");
      }

    } catch (error) {

      console.log(error)
    }

  };

  const handleOpenChargeDetail = () => {
    setOpenCharge(true);
  };

  const handleCloseChargeDetail = () => {
    setOpenCharge(false);
  };

  /*  const payCharge = async (id_file: number) => {
     let { data } = await axios.get(`api/payment/invoice/${config.workspace}/${id_file}`)
 
     if (data.result) {
       setChargeDetails({
         expiration: data.pay.expiration_date,
         url: data.pay.redirect_url,
         status: data.pay.status,
         description: data.pay.description,
         total: data.pay.amount
       })
 
     }
 
   } */
  /*cuando el tiempo haya expirado */
  const Completionist = () => {
    setExpired(true);
    return <Alert severity="error">Cuenta expirada</Alert>
  };

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <>
          <Typography className="texto" variant="caption">Expira en:</Typography>
          <Table>
            <TableRow>
              <TableCell style={{ padding: "3px", textAlign: "center" }}>
                <Chip className="timer" label={days}></Chip>
              </TableCell>
              <TableCell style={{ padding: "3px", textAlign: "center" }}>
                <Chip className="timer" label={hours}></Chip>
              </TableCell>
              <TableCell style={{ padding: "3px", textAlign: "center" }}>
                <Chip className="timer" label={minutes}></Chip>
              </TableCell>
              <TableCell style={{ padding: "3px", textAlign: "center" }}>
                <Chip className="timer" label={seconds}></Chip>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ padding: "3px", textAlign: "center" }}>
                <Typography variant="caption">  Días </Typography>
              </TableCell>
              <TableCell style={{ padding: "3px", textAlign: "center" }}>
                <Typography variant="caption"> Horas </Typography>
              </TableCell>
              <TableCell style={{ padding: "3px", textAlign: "center" }}>
                <Typography variant="caption">Minutos</Typography>
              </TableCell>
              <TableCell style={{ padding: "3px", textAlign: "center" }}>
                <Typography variant="caption">Segundos</Typography>
              </TableCell>
            </TableRow>
          </Table>




          <br />

        </>
      )
    }
  };

  const checkPrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacy(!privacy)
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Tooltip title="Detalles" arrow disableHoverListener={open}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row">
          {moment(doc.issue_date).calendar()}
        </TableCell>
        <TableCell align="left">
          <Tooltip
            arrow
            title={
              doc.state === "1"
                ? "Documento aprobado en SUNAT"
                : "Documento no existe o no ha sido aprobado"
            }
          >
            <IconButton aria-label="expand row" size="small">
              {doc.state === "1" ? (
                <CheckCircleOutline
                  style={{
                    fontSize: 20,
                    color: "rgb(38 152 63)",
                  }}
                />
              ) : (
                <WarningTwoTone
                  style={{
                    fontSize: 20,
                    color: "rgb(255 178 47)",
                  }}
                />
              )}
            </IconButton>
          </Tooltip>{" "}
          {`${doc.serie}-${doc.number}`}
        </TableCell>
        <TableCell align="left">
          {getDocumentTypeDesc(doc.document_type)}
        </TableCell>
        <TableCell align="left">
          {doc.emitter ?
            doc.emitter.legal_name
            : doc.emitter_company
              ? doc.emitter_company.legal_name
              : ""}
        </TableCell>
        <TableCell align="right">
          {doc.currency==="PEN" ? 
            "S/ "+doc.total_value
            : "$ " + doc.total_value
          }
        </TableCell>
        <TableCell align="left" style={{ minWidth: 80 }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
            <Tooltip title="Enviar copia a correo electrónico" arrow>
              <IconButton
                size="small"
                onClick={() => onResentDocumentEmail(doc)}
              >
                <ReplyAllTwoTone
                  style={{
                    fontSize: 20,
                    color: Colors.iconOlive,
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Imprimir voucher" arrow>
              <IconButton
                size="small"
                onClick={() => printVoucher(doc.file_name, doc.id)}
              >
                <PrintTwoTone
                  style={{
                    fontSize: 20,
                    color: Colors.iconOlive,
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Descargar PDF" arrow>
              <IconButton
                size="small"
                onClick={() => downloadPdf(doc.file_name, doc.id)}
              >
                <DescriptionTwoTone
                  style={{
                    fontSize: 20,
                    color: Colors.iconOlive,
                  }}
                />
              </IconButton>
            </Tooltip>

            {doc.emitter_company?.customize &&
              JSON.parse(doc.emitter_company.customize).openPay === "YES" ?
              doc.payment !== null && (
                doc.payment.status === "pagado" ?
                  <Tooltip title="Pagado">
                    <IconButton
                      size="small"
                    >
                      <FontAwesomeIcon
                        icon={faWallet}
                        style={{
                          fontSize: 20,
                          color: Colors.border,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  :
                  <Tooltip title="Ver cuenta"
                    onClick={() => {
                      handleOpenChargeDetail();
                    }}
                    arrow>
                    <IconButton
                      size="small"
                    >
                      <FontAwesomeIcon
                        icon={faWallet}
                        style={{
                          fontSize: 20,
                          color: Colors.iconOlive,
                        }}

                      />
                    </IconButton>
                  </Tooltip>
              )
              : ""
            }


          </div>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "#f7f7f7" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                style={{ fontFamily: "Corbel", fontWeight: "bold", fontSize: 18 }}
              >
                Detalles
              </Typography> */}
              <Table
                size="small"
                aria-label="purchases"
                style={{ marginBottom: 20 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Cantidad
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Código</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Descripción
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Valor unitario
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Precio total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {doc.products.map((product) => (
                    <TableRow key={product.line}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ minWidth: 70 }}
                      >
                        {/*                        <IconButton aria-label="expand row" size="small">
                          <LabelTwoTone
                            style={{
                              fontSize: 15,
                              color: "gray",
                            }}
                          />
                        </IconButton>{" "} */}
                        {product.quantity} {product.measure}
                      </TableCell>
                      <TableCell>{product.code}</TableCell>
                      <TableCell>{product.description}</TableCell>
                      <TableCell align="right" style={{ minWidth: 105 }}>
                        S/ {Number(product.unit_price)}
                      </TableCell>
                      <TableCell align="right" style={{ minWidth: 80 }}>
                        S/{" "}
                        {Number(product.unit_price) > 0
                          ? product.price
                          : "0.00"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div style={{ textAlign: "end", marginBottom: "2.5rem", }}>
                <Tooltip title="Descargar archivo Xml" arrow style={{ visibility: showBtnXml ? "visible" : "hidden", }}>
                  <Button
                    variant="contained"
                    color="default"
                    size="small"
                    disableElevation
                    style={{ marginRight: 5 }}
                    startIcon={
                      <DownloadIcon
                        style={{
                          fontSize: 20,
                          color: Colors.iconOlive,
                        }}
                      />
                    }
                    onClick={() => downloadXml(doc.file_name, doc.id)}
                  >
                    XML
                  </Button>
                </Tooltip>
                {doc.state !== null && ["1", "2", "3", "-2"].includes(doc.state) && (
                  <Tooltip title="Descargar archivo CDR" arrow style={{ visibility: showBtnCDR ? "visible" : "hidden", }}>
                    <Button
                      variant="contained"
                      color="default"
                      size="small"
                      disableElevation
                      startIcon={
                        <DownloadIcon
                          style={{
                            fontSize: 20,
                            color: Colors.iconOlive,
                          }}
                        />
                      }
                      onClick={() => downloadCDR(doc.file_name, doc.id)}
                    >
                      CDR
                    </Button>
                  </Tooltip>
                )}

                {notFile && (
                  <InputLabel style={{ color: "#f44336", fontSize: "10px", marginTop: 14 }}>
                    {notFile}
                  </InputLabel>
                )}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {/*   Modal de cobro */}
      <Dialog
        open={openCharge}
        onClose={handleCloseChargeDetail}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6} md={5} >
              <Typography variant="caption">TOTAL</Typography>
              <Typography gutterBottom variant="h4">
                {getCurrencySymbol(doc.currency)} {doc.total_value}
              </Typography>

              <Typography variant="caption">Cargo generado a:</Typography>
              <Typography variant="h6">{receiver.legal_name}</Typography>
            </Grid>
            <Grid item xs={6} md={7} className="cardRight" justify="center">
              <Typography className="texto" variant="caption">Fecha límite de Pago:</Typography>
              <br />
              <RChip
                label={moment(doc.payment?.expiration_date).format('YYYY-MM-DD HH:mm:ss')}
              />
              <br />
              <br />

              {!expired ? (
                <>
                  {doc.payment && doc.payment.status === "progreso" ?
                    <>
                      <Typography variant="caption">
                        Tu pago está  pendiente
                      </Typography>
                      <Typography variant="h6">
                      Código de referencia: <strong> {doc.payment.payment_reference}</strong>
                      </Typography> 
                        
                      </>                 
                    :
                    <> <div style={{ paddingTop: "10px" }}>
                      <Typography className="texto" variant="caption">PAGAR CON</Typography>
                    </div>
                      <img src={logoPay} alt="Open Pay" width={100} style={{ padding: "5px 0px 20px 0px" }} />
                      <Link href={doc.payment?.redirect_url}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="btnPagar">
                          Pagar
                        </div>

                      </Link>
                      <br />

                    </>

                  }

                </>
              )

                : ""}


            </Grid>


          </Grid>



        </DialogContent>

        <PrivacyPolicy isOpen={openPrivacyPolicy} close={() => setOpenPrivacyPolicy(false)} />
      </Dialog>
    </React.Fragment>
  );
};

export const ReceivedDocList: React.FC<Props> = () => {
  const receivedDocs = useSelector((state: any) => state.receivedDocs);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [refDocumentToResent, setRefDocumentToResent] =
    React.useState<ReceivedDoc | null>(null); // Documento de referencia para reenviar por email
  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const receiver: CompanyInfo = companies.find(
    (c: CompanyInfo) => c.ruc === config.workspace
  );




  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const resentDocumentByEmail = (document: ReceivedDoc) => {
    setRefDocumentToResent(document);
  };

  return (
    <Paper>
      <TableContainer style={{ minHeight: 450 }}>
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {receivedDocs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((doc: ReceivedDoc) => {
                return <ReceivedDocItem key={doc.id} doc={doc} onResentDocumentEmail={resentDocumentByEmail} />;
              })}
          </TableBody>
        </Table>
        {receivedDocs.length === 0 && (
          <div className="noResultContainer">
            <img
              src={emptyDocsIllustration}
              height={150}
              style={{ padding: 10 }}
              alt="No existen documentos"
            />
            <Typography variant="h5" style={{ color: Colors.textOlive }}>
              Aún no tienes documentos recibidos
            </Typography>
            <Typography className="noResultLabel">
              Agrega nuevos documentos emitidos a tu nombre
            </Typography>
          </div>
        )}
      </TableContainer>
      {receivedDocs.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          labelRowsPerPage="Filas por página"
          component="div"
          count={receivedDocs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      {refDocumentToResent && (
        <ResendDocumentEmail
          receiver={receiver}
          document={refDocumentToResent}
          open={refDocumentToResent !== null}
          handleClose={() => setRefDocumentToResent(null)}
        />
      )}
    </Paper>
  );
};