import React from "react";
import TextStyle from "../textStyle";
import { ContainerFooter, Footer } from "./styled";

interface IPublicFooterProps {
  color: string;
}

const PublicFooter: React.FC<IPublicFooterProps> = ({ color }) => {
  return (
    <Footer>
      <ContainerFooter>
        <div>
          <a
            href="https://www.facebook.com/eximioperu"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="20"
              height="30"
              viewBox="0 0 32 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32 15.0906C32 6.75709 24.8357 0 16 0C7.16429 0 0 6.75709 0 15.0906C0 22.6224 5.85 28.8655 13.5 29.9987V19.4541H9.43643V15.0906H13.5V11.766C13.5 7.98455 15.8893 5.8941 19.5436 5.8941C21.2943 5.8941 23.1257 6.18917 23.1257 6.18917V9.90321H21.1071C19.1207 9.90321 18.4993 11.066 18.4993 12.2611V15.0906H22.9364L22.2279 19.4541H18.5V30C26.15 28.8675 32 22.6245 32 15.0906V15.0906Z"
                fill={color}
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/eximioperu/?hl=es-la"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="20"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.4686 4.0623C21.9177 4.06663 23.3063 4.64423 24.331 5.66896C25.3558 6.69369 25.9334 8.08227 25.9377 9.53145V20.4686C25.9334 21.9177 25.3558 23.3063 24.331 24.331C23.3063 25.3558 21.9177 25.9334 20.4686 25.9377H9.53145C8.08227 25.9334 6.69369 25.3558 5.66896 24.331C4.64423 23.3063 4.06663 21.9177 4.0623 20.4686V9.53145C4.06663 8.08227 4.64423 6.69369 5.66896 5.66896C6.69369 4.64423 8.08227 4.06663 9.53145 4.0623H20.4686ZM20.4686 1.875H9.53145C5.32031 1.875 1.875 5.32031 1.875 9.53145V20.4686C1.875 24.6797 5.32031 28.125 9.53145 28.125H20.4686C24.6797 28.125 28.125 24.6797 28.125 20.4686V9.53145C28.125 5.32031 24.6797 1.875 20.4686 1.875Z"
                fill={color}
              />
              <path
                d="M22.1092 9.53149C21.7847 9.53149 21.4675 9.43527 21.1977 9.255C20.9279 9.07473 20.7176 8.81849 20.5934 8.51871C20.4693 8.21892 20.4368 7.88905 20.5001 7.5708C20.5634 7.25255 20.7196 6.96022 20.9491 6.73077C21.1785 6.50133 21.4709 6.34507 21.7891 6.28177C22.1074 6.21847 22.4372 6.25095 22.737 6.37513C23.0368 6.49931 23.293 6.70959 23.4733 6.97939C23.6536 7.24919 23.7498 7.56638 23.7498 7.89087C23.7503 8.10645 23.7081 8.32 23.6259 8.51926C23.5436 8.71852 23.4228 8.89956 23.2703 9.052C23.1179 9.20444 22.9368 9.32527 22.7376 9.40755C22.5383 9.48984 22.3248 9.53196 22.1092 9.53149ZM15 10.6249C15.8653 10.6249 16.7112 10.8815 17.4307 11.3622C18.1502 11.843 18.711 12.5263 19.0422 13.3257C19.3733 14.1252 19.4599 15.0049 19.2911 15.8536C19.1223 16.7023 18.7056 17.4819 18.0937 18.0938C17.4819 18.7057 16.7023 19.1224 15.8536 19.2912C15.0049 19.46 14.1252 19.3734 13.3257 19.0422C12.5262 18.7111 11.8429 18.1503 11.3622 17.4308C10.8814 16.7113 10.6248 15.8654 10.6248 15C10.626 13.8401 11.0874 12.7279 11.9076 11.9077C12.7279 11.0875 13.84 10.6261 15 10.6249ZM15 8.43755C13.7021 8.43755 12.4333 8.82243 11.3541 9.54353C10.2749 10.2646 9.43374 11.2895 8.93704 12.4887C8.44034 13.6878 8.31038 15.0073 8.5636 16.2803C8.81682 17.5533 9.44183 18.7227 10.3596 19.6404C11.2774 20.5582 12.4467 21.1832 13.7197 21.4365C14.9927 21.6897 16.3122 21.5597 17.5114 21.063C18.7105 20.5663 19.7354 19.7252 20.4565 18.646C21.1776 17.5668 21.5625 16.298 21.5625 15C21.5625 13.2596 20.8711 11.5904 19.6404 10.3597C18.4097 9.12895 16.7405 8.43755 15 8.43755Z"
                fill={color}
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/eximioperu/?viewAsMember=true"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="20"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.9205 0H2.2216C1.03594 0 0 0.853125 0 2.02487V23.772C0 24.9501 1.03594 26 2.2216 26H23.9142C25.1062 26 26 24.9432 26 23.772V2.02487C26.0069 0.853125 25.1062 0 23.9205 0ZM8.05941 21.6723H4.33468V10.0913H8.05941V21.6723ZM6.32588 8.33045H6.29919C5.10713 8.33045 4.33526 7.44308 4.33526 6.33228C4.33526 5.20116 5.12745 4.33469 6.3462 4.33469C7.56494 4.33469 8.3107 5.19478 8.3374 6.33228C8.33682 7.44308 7.56494 8.33045 6.32588 8.33045ZM21.6722 21.6723H17.9475V15.34C17.9475 13.8229 17.4055 12.7864 16.0579 12.7864C15.0283 12.7864 14.419 13.4829 14.1479 14.1613C14.0464 14.405 14.0191 14.737 14.0191 15.0759V21.6723H10.2944V10.0913H14.0191V11.7029C14.5611 10.931 15.4079 9.82022 17.3782 9.82022C19.8232 9.82022 21.6728 11.4319 21.6728 14.9065L21.6722 21.6723Z"
                fill={color}
              />
            </svg>
          </a>
          <TextStyle bold={400} color={color} type="h4">
            © Copyright {new Date().getFullYear()} - RATIFIKA. Todos los
            derechos reservados
          </TextStyle>
        </div>
        <a href="https://eximio.com.pe/" target="_blank">
          <svg
            width="100"
            height="40"
            viewBox="0 0 719 115"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M665.26 114.52C635.89 114.52 612 90.63 612 61.26C612 31.89 635.89 8 665.26 8C694.63 8 718.52 31.89 718.52 61.26C718.52 90.63 694.63 114.52 665.26 114.52ZM665.26 29.3C647.64 29.3 633.3 43.64 633.3 61.26C633.3 78.88 647.64 93.22 665.26 93.22C682.88 93.22 697.22 78.88 697.22 61.26C697.22 43.64 682.88 29.3 665.26 29.3Z"
              fill={color}
            />
            <path
              d="M586.65 114.52C580.77 114.52 576 109.75 576 103.87V18.65C576 12.77 580.77 8 586.65 8C592.53 8 597.3 12.77 597.3 18.65V103.86C597.3 109.75 592.53 114.52 586.65 114.52Z"
              fill={color}
            />
            <path
              d="M567.93 100.17C567.93 106.15 563.08 111 557.1 111C520.84 111 501.33 75.09 485.64 36.3C481.93 27.13 474.84 21.66 466.65 21.66H466.62C458.43 21.67 451.33 27.17 447.65 36.36C444.95 43.11 442.42 49.04 439.96 54.38C452.76 78.89 464.91 89.34 479.45 89.34C485.42 89.34 490.28 94.19 490.28 100.17C490.28 106.15 485.42 111 479.45 111C443.18 111 423.67 75.09 407.99 36.3C404.29 27.13 397.2 21.66 389.01 21.66H388.97C380.78 21.67 373.69 27.17 370.01 36.36C354.49 75.13 335.13 111 298.83 111C292.85 111 288 106.15 288 100.17C288 94.19 292.85 89.34 298.83 89.34C317.75 89.34 332.55 71.66 349.9 28.32C356.88 10.88 371.84 0.03 388.93 0H389C405.89 0 420.71 10.56 427.82 27.62C434.91 10.58 449.7 0.02 466.57 0H466.63C483.7 0 498.67 10.79 505.7 28.18C523.26 71.62 538.14 89.34 557.08 89.34C563.09 89.34 567.93 94.19 567.93 100.17Z"
              fill={color}
            />
            <path
              d="M270.65 113.34C264.77 113.34 260 108.57 260 102.69V17.65C260 11.77 264.77 7 270.65 7C276.53 7 281.3 11.77 281.3 17.65V102.68C281.3 108.57 276.53 113.34 270.65 113.34Z"
              fill={color}
            />
            <path
              d="M108.63 57.65C108.63 60.59 107.44 63.25 105.51 65.19C103.59 67.11 100.93 68.3 97.98 68.3H70.55C70.23 68.3 69.92 68.28 69.61 68.25C69.29 68.28 68.98 68.3 68.66 68.3C62.78 68.3 58 63.54 58 57.65C58 51.77 62.77 47 68.66 47C68.98 47 69.29 47.02 69.61 47.05C69.92 47.02 70.23 47 70.55 47H97.98C103.86 47 108.63 51.76 108.63 57.65Z"
              fill={color}
            />
            <path
              d="M239.87 89.04H225.49C224.07 89.04 222.74 88.49 221.73 87.49L191.65 57.54L221.73 27.59C222.74 26.59 224.08 26.04 225.49 26.04H239.87C245.75 26.04 250.52 21.28 250.52 15.39C250.52 9.51 245.76 4.73 239.87 4.73H225.49C218.4 4.73 211.72 7.49 206.7 12.49L176.54 42.5L145.68 11.76C140.65 6.76 133.98 4 126.89 4H53.53C24.01 4 0 28.01 0 57.53C0 86.01 22.35 109.36 50.43 110.97C51.46 111.03 52.5 111.06 53.53 111.06H103.06C108.94 111.06 113.7 106.3 113.71 100.41C113.71 106.29 118.47 111.06 124.36 111.06H126.9C133.99 111.06 140.66 108.3 145.69 103.3L147.68 101.32L176.57 72.55L206.71 102.57C211.73 107.58 218.41 110.33 225.5 110.33H239.88C245.76 110.33 250.53 105.57 250.53 99.68C250.53 93.79 245.75 89.04 239.87 89.04ZM130.65 88.21C129.65 89.21 128.31 89.76 126.9 89.76H124.36C118.48 89.76 113.72 94.52 113.71 100.41C113.71 94.53 108.95 89.76 103.06 89.76H53.53C35.76 89.76 21.3 75.3 21.3 57.53C21.3 39.76 35.76 25.3 53.53 25.3H126.89C128.31 25.3 129.64 25.85 130.64 26.85L161.45 57.52L130.65 88.21Z"
              fill={color}
            />
          </svg>
        </a>
      </ContainerFooter>
    </Footer>
  );
};

export default PublicFooter;
