import { Colors, Sizes } from "src/infrastructure/static/colors";
import styled from "styled-components";

export const ContainerCard = styled.div`
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  border: 1px solid ${Colors.GREEN};
  padding: 20px;
  margin: 15px 0px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  div:first-child {
    flex-grow: 1;
  }

  @media ${Sizes.TABLET} {
    display: block;
  }
`;
